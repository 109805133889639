import React from "react";
import Dropzone from "react-dropzone";
import Loader from "./../loader/Loader";
import { UncontrolledCollapse } from "reactstrap";
import ErrorView from "./../error-view/ErrorView";
import "./../../../styles/_variables.scss";
import "./../../../styles/_mixins.scss";


export const FilesThumbnailView = props => {
  const errorFiles = props.files
    .filter(file => file.uploadError === true)
    .map(filterFile => filterFile.name);

  let enableNextBtn = true;

  if (errorFiles.length > 0 && props.files.length === 1) {
    enableNextBtn = false;
  } else {
    enableNextBtn =
      props.files.filter(file => file.uploading === true).length > 0
        ? false
        : true;
  }

  return (
    <React.Fragment>
      <div id="upload-edit">
        <div
          className="d-flex flex-column upload-edit"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="d-flex align-items-center flex-wrap"
            style={{ width: "100%" }}
          >
            {props.files.map((file, index) => {
              return (
                <SelectionView
                  key={index}
                  uploadedURL={file.uploadedURL}
                  fileName={file.name}
                  previewImg={file.preview}
                  fileType={file.type}
                  zoomedImageIndex={props.instance.zoomedImageIndex}
                  instance={props.instance}
                  index={index}
                  uploadError={file.uploadError}
                  uploading={file.uploading}
                />
              );
            })}
            <AddFiles instance={props.instance} />
          </div>
          <div className="d-flex flex-column">
            {
              <p
                className={
                  props.type === "MR" ? "d-none" : "d-md-none d-sm-flex"
                }
              >
                <span className="note-text">Note: </span>
                <span className="note-subtext">
                  {" "}
                  If you have multiple invoices please submit each as a separate
                  claim.
                </span>
              </p>
            }

            {errorFiles.length > 0 && (
              <ErrorView>
                <p className="mb-0">
                  The following file(s) are not uploaded correctly. Please try
                  again.
                </p>
                <ul className="mb-0">
                  {errorFiles.map(file => (
                    <li className="text-danger">
                      <strong>{file}</strong>
                    </li>
                  ))}
                </ul>
              </ErrorView>
            )}
            <button
              style={{ margin: "0 auto" }}
              className={
                "button-primaryV2 " +
                (enableNextBtn ? "" : "button-disabled")
              }
              onClick={() => props.instance.handleNextStep()}
            >
              Next Step
            </button>
            <FileUploadInfoTogglerDesktop type={props.type} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const ZoomedFileMobileView = props => {
  return (
    <React.Fragment>
      <div className="mobile-zoomed-image-container">
        <div
          key={props.index + "-mobile-view"}
          id={props.fileName + ":zoom-mobile-view"}
          className="selection-container d-block d-sm-none preview-selected"
          //onClick={this.zoomPreview}
        >
          <div
            style={{ width: "100%", height: "100%" }}
            className="d-flex justify-content-center align-items-center  mobile-zoomed-image-view"
          >
            {props.fileType.startsWith("image") ? (
              <img
                alt=""
                style={{ maxHeight: "100%", maxWidth: "100%" }}
                className="flex-shrink-0"
                src={props.previewImg}
              />
            ) : (
              <object
                data={props.uploadedURL}
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <div className="preview-fallback">
                  <i
                    className="fa fa-download download-icon fa-3x"
                    aria-hidden="true"
                  />
                  <p>
                    {" "}
                    Preview is not supported for this file.{" "}
                    <a href={props.uploadedURL}>
                      {" "}
                      Click here to download the file.
                    </a>
                  </p>
                </div>
              </object>
            )}
          </div>

          <p className="image-number">{props.index + 1}</p>
          <i
            className="garpage-icon fa fa-trash"
            aria-hidden="true"
            onClick={e => props.instance.openDeleteFileModal(e)}
          />
          <i
            className="zoom-out-icon fa fa-search-minus"
            onClick={e => props.instance.closePreview(e)}
          />
        </div>
      </div>

      <div
        key={props.index + "-desktop-view"}
        id={props.fileName + ":zoom-desktop-view"}
        className="selection-container d-none d-sm-block"
        //onClick={this.zoomPreview}
      >
        {props.fileType.startsWith("image") ? (
          <div className="prev-img">
            <img alt="" src={props.previewImg} />
          </div>
        ) : props.fileType.includes("pdf") ? (
          <img
            alt=""
            className="prev-img"
            src="../../assets/images/pdf_thumbnail.png"
          />
        ) : (
          <embed src={props.previewImg} className="prev-img" />
        )}

        <p className="image-number">{props.index + 1}</p>

        <img
          alt=""
          className="delete-icon"
          src="../../assets/images/delete_white.svg"
          onClick={e => props.instance.openDeleteFileModal(e)}
        />
        <img
          alt=""
          className="zoom-icon"
          src="../../assets/images/zoom_white.svg"
          onClick={e => props.instance.handlePreviewClick(e)}
        />
      </div>
    </React.Fragment>
  );
};

const SelectionView = props => {
  return (
    <React.Fragment>
      {props.zoomedImageIndex === props.index ? (
        <ZoomedFileMobileView {...props} />
      ) : (
        <div
          key={props.index}
          id={props.fileName + ":selection-view"}
          className={
            !props.uploading && props.uploadError
              ? "selection-container upload-error"
              : "selection-container"
          }
          //onClick={this.zoomPreview}
        >
          {props.fileType.startsWith("image") ? (
            <div className="prev-img">
              <img alt="" src={props.previewImg} />
            </div>
          ) : props.fileType.includes("pdf") ? (
            <img
              alt=""
              className="prev-img"
              src="../../assets/images/pdf_thumbnail.png"
            />
          ) : (
            <embed src={props.previewImg} className="prev-img" />
          )}

          <p
            className={
              "image-number" +
              (props.uploadError === true ? " file-upload-error" : "")
            }
          >
            {props.index + 1}
          </p>
          {props.uploading ? null : (
            <img
              alt=""
              className="delete-icon"
              src="../../assets/images/delete_white.svg"
              onClick={e => props.instance.openDeleteFileModal(e)}
            />
          )}

          {props.uploading || props.uploadError ? (
            props.uploadError ? (
              <i
                className="zoom-icon fa fa-exclamation-triangle"
                style={{
                  fontSize: 20,
                  textAlign: "center",
                  color: "#E60005"
                }}
              />
            ) : null
          ) : (
            <img
              alt=""
              className="zoom-icon"
              src="../../assets/images/zoom_white.svg"
              onClick={e => props.instance.handlePreviewClick(e)}
            />
          )}
          {props.uploading && (
            <Loader position="absolute" height="25px" width="25px" />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

const AddFiles = props => {
  return (
    <div>
      <Dropzone
        // accept="image/png,image/jpg,image/jpeg,image/tiff,application/pdf"
        maxSize={Number(2.2e7)}
        style={{ border: "none", alignItems: "center" }}
        onDrop={props.instance.onFileDropped}
      >
        <div className="btn-add-files">
          <div style={{ marginTop: 10}}>
            <span style={{color: "#038062"}}>Add</span>
            <br />
            <strong>
              <span style={{color: "#038062"}}>+</span>
            </strong>
            <br />
            <span style={{color: "#038062"}}>Files</span>
          </div>
        </div>
      </Dropzone>
    </div>
  );
};

export const FileUploadInfoTogglerMobile = ({ type }) => {
  return (
    <div className="d-block d-sm-none">
      {type === "Claim" && (
      <p
        style={{
          fontSize: 14,
          marginBottom: 10,
          marginTop: 5
        }}
      >
        Please be sure to upload all the pages of your invoice to complete your
        claim.
      </p>
      )}
      {type === "MR" && (
      <p
        style={{
          fontSize: 14,
          marginBottom: 10,
          marginTop: 5
        }}
      >
        Please be sure to upload all the pages of medical records.
      </p>
      )}
      <div
        className="problem-upload-toggler text-secondary"
        id={"problemuploadingtoggler-" + type}
      >
        Requirements for uploading
        <i
          className="fa fa-question-circle"
          style={{
            fontSize: 14,
            color: "$text-Blue",
            padding: 3
          }}
        />
      </div>
      <UncontrolledCollapse toggler={"#problemuploadingtoggler-" + type}>
        <FileUploadInfoList />
      </UncontrolledCollapse>
    </div>
  );
};

export const FileUploadInfoTogglerDesktop = ({ type }) => {
  return (
    <div className="d-none d-sm-block">
      {type === "Claim" && (
      <p
        style={{
          fontSize: 14,
          marginBottom: 10,
          marginTop: 5
        }}
      >
        Please be sure to upload all the pages of your invoice to complete your
        claim.
      </p>
      )}
      {type === "MR" && (
      <p
        style={{
          fontSize: 12,
          marginBottom: 10,
          marginTop: 5,
          color: "#657493"
        }}
      >
        Please be sure to upload all the pages of medical records.
      </p>
      )}
      <div
        className="problem-upload-toggler text-secondary"
        id={"problemuploadingtoggler-" + type} style={{color:"#6b61f2 !important"}}
      >
              <span class="text-color-violet">Requirements for uploading</span>
        <i
          className="fa fa-question-circle"
          style={{
            fontSize: 14,
            color: "#6b61f2",
            padding: 3
          }}
        />
      </div>

      <UncontrolledCollapse toggler={"#problemuploadingtoggler-" + type}>
        <FileUploadInfoList />
      </UncontrolledCollapse>
    </div>
  );
};

const FileUploadInfoList = () => {
  return (
    <div className="file-info">
      <ul>
        <li>
          MUST be in one of the following formats: .JPG, .PNG, .TIFF, or .PDF
        </li>
        <li>MUST be 20 MB or smaller</li>
        <li>Avoid marking or highlighting invoices for best results</li>
      </ul>
    </div>
  );
};
