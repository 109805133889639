import AES from "crypto-js/aes";
import CryptoJSUtf8Enc from "crypto-js/enc-utf8";

import { store } from "../../index";

export const clearSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("auth-token");
  localStorage.removeItem("expiresOn");
  localStorage.removeItem("users");
  localStorage.removeItem("totalUser");
  localStorage.removeItem("storeLastUpdated");
}


export const getCredentialsIfValid = () => {
  let userDetailsState = store.getState().account.userAccountDetails;
  var currentEmail = null;
  let credentials = null;
  if(userDetailsState != null){
    currentEmail = userDetailsState.PetParentInfo.Email;
  }
  let params = new URL(document.location.toString()).searchParams;
  let userId = params.get("userId");
  var checkUsers = localStorage.getItem("users");
  if(checkUsers != null ){
    var users = JSON.parse(checkUsers);
    if(users.length > 1){
      
      users.map((item) =>{
        
        if(item.username == currentEmail || item.user == userId){
          let nowTimeStamp = new Date().getTime();
          if (parseInt(item.expiresOn) && nowTimeStamp <= item.expiresOn)
          {
            credentials =  item;
          }
        }}
      );
    }
    else{
      if(users.username == currentEmail || users.user == userId){
        let nowTimeStamp = new Date().getTime();
        if (parseInt(users.expiresOn) && nowTimeStamp <= users.expiresOn)
        { 
          credentials =  users;
        }
      }
    }
  }
  if(credentials == null){
  let expiryTimeStamp = localStorage.getItem("expiresOn");
  let nowTimeStamp = new Date().getTime();
  if (expiryTimeStamp && nowTimeStamp <= expiryTimeStamp) {
    let token = localStorage.getItem("token");
    if (token) {
      credentials = JSON.parse(token);
      
      return credentials;
    }
  }
  }
  return credentials;
  
};


export const getAuthToken = () => {
  let token = localStorage.getItem("auth-token");
  if (token) {
    return token;
  }
  return null;
}




export const saveCredentials = ({username, password, rememberMe}, authResponse) => {
   // Expiry TimeStamp Based on Auth API
   let expiryTimeStamp;
   // Better to use .issued timeStamp from API
   let currentTimeStamp = new Date().getTime();
   expiryTimeStamp = currentTimeStamp + authResponse.expires_in * 1000; // Converting seconds in API to milliseconds.
  let checkUser = localStorage.getItem("users");  
  var data = new Array();
  if(checkUser == null){
    data = {
      user:"u1",
      username,
      expiresOn:expiryTimeStamp,
      token:authResponse.access_token
    }
    localStorage.setItem("totalUser",1);
    localStorage.setItem("users",JSON.stringify(data));
  }
  else{
    var userCount = parseInt(localStorage.getItem("totalUser")) + 1;
    let newUser = {
        user:"u"+userCount,
        username,
        expiresOn:expiryTimeStamp,
        token:authResponse.access_token
    };
    data.push(JSON.parse(checkUser));
    data.push(newUser);
    localStorage.setItem("totalUser",userCount);
    localStorage.setItem("users",JSON.stringify(data));
  }

  let credentials = {
    username
  }
  let cipherText = JSON.stringify(credentials);
  localStorage.setItem("token", cipherText);
  localStorage.setItem("auth-token", authResponse.access_token);
  
 

  localStorage.setItem("expiresOn", expiryTimeStamp);
};


