import React from "react";
import { Route } from "react-router-dom";
import TopAlertBanner from "./../modules/common/alerts-notifications/TopAlertBanner";

const MobileAppRoute = ({
  component: Component,
  loggedIn,
  location,
  compState,
  ...rest
}) => {

  // get the token from the querystring parameter
  /*
  var inputParams = location.search.trim().replace(/^[?#&]/, "");
  var authToken;
  for (const param of inputParams.split("&")) {
    let [key, value] = param.split("=");
    if (key && key.toLowerCase() === "token") {
      authToken = value;
    }
  }

  if (authToken) {
    localStorage.setItem("auth-token", authToken);

    validateSession
  }
  */
 
  if (loggedIn) {
    return (
      <Route
        {...rest}
        render={props => (
          <div>
            <TopAlertBanner compState = {compState} path = {location} />
            <Component {...props} />
          </div>
        )}
      />
    );
  } else {
    

    return;
  }
};

export default MobileAppRoute;
