import * as actionTypes from "../../constants/action-types";

export const getMessagesByAccount = () => ({
  type: actionTypes.MESSAGES_FETCH_REQUEST
});

export const setMessagesDataRefresh = () => ({
  type: actionTypes.SET_MESSAGES_DATA_REFRESH
});

export const setMessageAsRead = messageId => ({
  type: actionTypes.SET_MESSAGE_READ,
  messageId
});

export const deleteMessage = messageId => ({
  type: actionTypes.DELETE_MESSAGE,
  messageId
});


export const setExpandMessage = messageId => ({
  type: actionTypes.MESSAGE_EXPAND,
  messageId
})