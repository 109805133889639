import React from "react";
import { Redirect } from 'react-router';

// Simple
const EOBLink = (props) => {
    let filePath = "/file/eob/" + props.match.params.id; 
        return (
            <Redirect to={filePath}></Redirect>
        );
};

export default EOBLink;