import React, { Component } from "react";
import { asField } from "informed";
import "./SegmentButtonV2.css";

class SegmentButtonV2 extends Component {
  handleChange(value, isDisabled) {
    const { onDisabledClicked, fieldApi } = this.props;
    if (isDisabled) {
      if (onDisabledClicked) {
        onDisabledClicked(value);
      }
    } else {
      fieldApi.setValue(value);
    }
  }

  renderSegment = (data, fieldState, value) => {
    const { length } = data;
    let rightTopCell = Math.floor(length / 3) * 3;
    let leftBottomCell = rightTopCell + 1;
    if (length % 3 === 0) {
      leftBottomCell = length - 2;
    }

    return data.map((child, index) => {
      let borderCls = "";
      if (length > 3) {
        const idx = index + 1;
        if (idx === 3) {
          borderCls += " rtr";
        }
        if (length % 3 === 0) {
          if (idx === leftBottomCell) {
            borderCls += " lbr";
          }
        } else {
          if (idx === leftBottomCell) {
            borderCls += " lbr";
          } else if (idx === rightTopCell) {
            borderCls += " rbr";
          }
        }
      }

      return (
        <div
          className={`d-flex segment align-items-center justify-content-center 
          ${borderCls} ${
            child.value === (fieldState.value || value || "") && !child.disabled
              ? "active"
              : ""
          }${child.disabled ? "disabled" : ""}`}
          onClick={() => {
            this.handleChange(child.value, child.disabled);
          }}
          key={child.value}
          {...child}
        >
          <div className="segment-content">
            {child.info ? (
              <div className="segment-info">{child.info}</div>
            ) : null}
            <div className="segment-label">
              {child.image ? (
                <img
                  alt="icon"
                  className="segment-icon"
                  src={
                    child.value === (fieldState.value || value || "")
                      ? child.activeimage || child.image
                      : child.image
                  }
                />
              ) : null}
              <span>{child.label}</span>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    const { data, className, style, fieldState, value } = this.props;

    return (
      <div
        style={style}
        className={`segment-control d-flex flex-row ${className} ${
          data.length > 3 ? "triplet" : ""
        }`}
      >
        {this.renderSegment(data, fieldState, value)}
      </div>
    );
  }
}

export default asField(SegmentButtonV2);
