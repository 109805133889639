import React from "react";
import "./UpdateReimbursementSuccessView.component.css";

import MaskValidateUtil from "../../common/util/MaskValidateUtil";

import { DIRECT_DEPOSIT, MAIL_CHECK } from "./UpdateReimbursement";
import { CHECKING } from "./UpdateReimbursementView.component";

const UpdateReimbursementSuccessView = props => {
  return (
    <div className="d-flex flex-column">
      {
        props.activeTab === DIRECT_DEPOSIT &&
        <div className="pt-2">
          <p className="status-description mb-3">
            Thank you for entering your account information to receive Direct Deposit!
          </p>
          <p className="status-description mb-3">
            Our direct deposit provider will be issuing a micro-deposit to your account to confirm the routing and account numbers. You will receive notification via email once your account has been confirmed.
          </p>
          <p className="status-description mb-3">
            Please note that until the account information has been confirmed, your claim reimbursement method will remain via check to the mailing address on your account.
          </p>
        </div>
      }
      <span className="status-description">
        Please review your new claim reimbursement information.
      </span>

      <div className="d-flex flex-column success-container padding mt-3">     
        {props.activeTab === MAIL_CHECK && (
          <React.Fragment>
            <h4>Reimbursement Type</h4>
            <span className="margin-bottom">
              Mail Check*
            </span>
            <h4>Mailing Address</h4>
            <span className="margin-bottom">
              {props.value.Street}
              <br />
              {props.value.City +
                ", " +
                props.value.State +
                " " +
                props.value.PostalCode}
            </span>
          </React.Fragment>
        )}

        {props.activeTab === DIRECT_DEPOSIT && (
          <React.Fragment>
            <h4>Account Type</h4>
            <span className="margin-bottom">
              {props.value.BankAccountType === CHECKING
                ? "Checking Account"
                : "Savings Account"}
            </span>

            <h4>Account Number</h4>
            <span className="margin-bottom">{props.value.AccountNumber.replace(/\d(?=\d{4})/g, "X")}</span>
          </React.Fragment>
        )}
{/* 
        <h4 className="keep-in-mind">*Keep in mind</h4>
        <span className="keep-in-mind">
          {props.activeTab === DIRECT_DEPOSIT &&
            <React.Fragment>
              All open claims that result in reimbursement will be deposited directly into your account.
            </React.Fragment>
          }
          {props.activeTab === MAIL_CHECK &&
            <React.Fragment>
              All open claims that result in reimbursement will be deliver to your new mailing address.
            </React.Fragment>
          }
        </span> */}
      </div>
      <button
        className="button-secondary update-success-button"
        type="submit"
        onClick={props.backToHome}
      >
        Home
      </button>
    </div>
  );
};

export default UpdateReimbursementSuccessView;
