import React from "react";
import { Route, Redirect } from "react-router-dom";
import TopAlertBanner from "./../modules/common/alerts-notifications/TopAlertBanner";
import Header from "./../modules/common/header/Header";
import Footer from "./../modules/common/footer/Footer";
import AlertsNotifications from "./../modules/common/alerts-notifications/AlertsNotifications";

const AuthenticatedRoute = ({
  component: Component,
  loggedIn,
  location,
  compState,
  ...rest
}) => {
  // Check if Authenticated from proper place

  if (loggedIn) {
    return (
      <Route
        {...rest}
        render={props => (
          <div>
            <TopAlertBanner compState = {compState} path = {location} />
            <Header />
            <div className="content-container">
              <AlertsNotifications />
              <Component {...props} />
            </div>
            <Footer />
          </div>
        )}
      />
    );
  } else {

    let userMailId;
    //If the url has e-mail address in query param , prefill it when login
    //We have kept the email id in location state
    //After successful login , in login component unmount we can clear off this state value
    if (
      location &&
      location.pathname && location.pathname === "/" &&   
      location.search
    ) {
      var inputParams = location.search.trim().replace(/^[?#&]/, "");
      for (const param of inputParams.split("&")) {
        let [key, value] = param.split("=");
        if (key && key.toLowerCase() === "e") {
          userMailId = value;
        }
      }
    }


    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location , userMailId}
        }}
      />
    );
  }
};

export default AuthenticatedRoute;
