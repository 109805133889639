import React, { PureComponent } from "react";
import { Collapse } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import injectExtole from "../extole-hoc/extole-hoc";

import "./RAFBottomBanner.css";

class RAFBottomBanner extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      status: "Closed",
      dogCollapse: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ status: "Opened", collapse: true });
    }, 100);
  }

  onEntered = () => {
    this.setState({ status: "Opened", dogCollapse: true });
  };

  onExited = () => {
    this.setState({ status: "Closed" });
  };

  dogToggle = () => {
    this.setState(prevState => {
      return {
        dogCollapse: !prevState.dogCollapse,
        collapse: !prevState.collapse
      };
    });
  };

  toggle = () => {
    this.setState(prevState => {
      return { collapse: !prevState.collapse };
    });
  };

  collapseLink = () => (
    <div className="collapse-link" onClick={this.toggle}>
      <div className="container position-relative">
        <h4 className="link-title d-md-none m-0">Refer Your Friends!</h4>
        <h4 className="link-title d-none d-md-block m-0">
          Refer Your Friends & We'll Donate to Homeless Pets!
        </h4>
        <div className="collapse-icon">
          <i className="fa fa-angle-up" />
        </div>
      </div>
    </div>
  );

  collapseContent = () => {
    const { friendlyURL } = this.props;
    return (
      <React.Fragment>
        {/* Mobile */}
        <MobileRaFBannerWithExtole dogToggle={this.dogToggle}></MobileRaFBannerWithExtole>
        {/* Desktop */}
        <div className="collapse-content d-none d-md-block">
          <div className="container position-relative">
            <div className="collapse-icon" onClick={this.dogToggle}>
              <i className="fa fa-angle-down" />
            </div>
            <img
              alt="banner-dog"
              src="./assets/images/banner_dog.png"
              className="banner-dog d-none d-lg-block"
              style={{ visibility: "hidden" }}
            />
            <h2 className="font-weight-bold">
              Refer Your Friends & We'll Donate to Homeless Pets!
            </h2>

            <div className="row m-0 p-0 refer-content">
              <div className="col-6 p-0">
                <h4 className="font-weight-normal">
                  Share this link with your friends.
                </h4>
                <h4
                  id="extole-banner-refer"
                  name="clickEvent"
                  track="RAFShareWidget"
                  className="text-secondary font-weight-normal text-underline"
                >
                  See how it works
                </h4>
              </div>
              <div className="col-6 p-0 align-self-center">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control copy-input"
                    id="inlineFormInputGroup"
                    value={friendlyURL}
                    readOnly
                  />
                  <div className="input-group-append">
                    <CopyToClipboard text={friendlyURL}>
                      <button type="button" className="btn btn-secondary">
                        Copy
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    const { collapse, status } = this.state;

    return (
      <div className="raf-bottom-banner">
        {!collapse && status === "Closed" ? this.collapseLink() : null}
        <div className="container">
          <Collapse
            className="banner-dog-container"
            isOpen={this.state.dogCollapse}
          >
            <img
              alt="banner-dog"
              src="./assets/images/banner_dog.png"
              className="banner-dog d-none d-lg-block position-absolute"
            />
          </Collapse>
        </div>
        <Collapse
          isOpen={this.state.collapse}
          onEntered={this.onEntered}
          onExited={this.onExited}
        >
          {this.collapseContent()}
        </Collapse>
      </div>
    );
  }
}

const MobileRaFBanner = (props) => {
  return (
        <div className="container collapse-content d-md-none position-relative text-center">
          <div className="collapse-icon" onClick={props.dogToggle}>
            <i className="fa fa-angle-down" />
          </div>
          <div id="extole-banner-refer-mobile">
            <h3 className="font-weight-bold text-primary">Refer Your Friends!</h3>
            <h4 className="text-secondary">
              Learn More >
            </h4>
          </div>
        </div>
  )
}

const MobileRaFBannerWithExtole = injectExtole(MobileRaFBanner, { ctaID: "extole-banner-refer-mobile" });

export default injectExtole(RAFBottomBanner, { ctaID: "extole-banner-refer" });
