import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { compose } from "redux";

const ExtoleWrapper = (WrappedComponent, { ctaID }) => {
  class HOC extends Component {
    componentDidMount() {
      let el = ReactDOM.findDOMNode(this);
      if (el instanceof HTMLElement && this.props.userAccountDetails && this.props.userAccountDetails.PetParentInfo && ctaID) {
        const { PetParentInfo } = this.props.userAccountDetails;
        const { FirstName, LastName, Email } = PetParentInfo;
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.innerHTML = `
            (function (c, e, k, l, a) {
              c[e] = c[e] || {};
              for (c[e].q = c[e].q || []; a < l.length;) k(l[a++], c[e])
            })(window, "extole", function (c, e) {
              e[c] = e[c] || function () {
                e.q.push([c, arguments])
              }
            }, [" createZone"], 0);
            extole.createZone({
            name: 'customer_center_banner',
            element_id: '${ctaID}',
            data: {
              "email":"${Email}",
              "first_name": "${FirstName}",
              "last_name": "${LastName}"
            }
            });
        `;
        el.insertAdjacentElement("beforebegin", s);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return HOC;
};

const mapStateToProps = state => ({
  userAccountDetails: state.account.userAccountDetails
});

const injectExtole = compose(
  connect(
    mapStateToProps,
    null
  ),
  ExtoleWrapper
);
export default injectExtole;
