import { AppLinks } from "./../../../constants/app-links";

export const AlertConfigurationList = [
  {
    alertKey:"PENDING_PHYSICAL_ADDRESS_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails
    ],
    actions: [],
    dismissible: true
  },
  {
    alertKey:"PENDING_PET_POLICY_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails
    ],
    actions: [],
    dismissible: true
  },
  {
    alertKey:"PENDING_POLICY_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails
    ],
    actions: [],
    dismissible: true
  },
  {
    alertKey:"PENDING_DISCOUNT_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails
    ],
    actions: [],
    dismissible: true
  },
  {
    alertKey:"PENDING_BILLING_DAY_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails
    ],
    actions: [],
    dismissible: true
  },
  {
    alertKey:"PENDING_ANNUAL_PREMIUM_ADJUSTMENT_DAY_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails
    ],
    actions: [],
    dismissible: true
  }, 
  {
    alertKey:"PENDING_ANNUAL_PREMIUM_ADJUSTMENT_DAY_CHANGE_KEY_ANNUALOPTIONSPAGE",
    pageLocation: [
      AppLinks.policy.anniversaryCoverageOptions
    ],
    actions: [],
    dismissible: true
  }, 
  {
    alertKey:"PENDING_ANNUAL_PREMIUM_ADJUSTMENT_DAY_CHANGE_KEY_ANNUALOPTIONSPAGE_NOOPTIONS",
    pageLocation: [
      AppLinks.policy.anniversaryCoverageOptions
    ],
    actions: [],
    dismissible: true
  }, 
  {
    alertKey:"ADVANCENOTICE_ANNUAL_PREMIUM_ADJUSTMENT_DAY_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails,
      AppLinks.policy.changePolicyOptions
    ],
    actions: ["AnniversaryCoverageOptions"],
    dismissible: true
  }, 
  {
    alertKey:"ANNIVERSARY_POLICY_CHANGE_SCHEDULED",
    pageLocation: [
      AppLinks.policy.anniversaryCoverageOptions
    ],
    actions: [],
    dismissible: true
  },
  {
    alertKey:"PENDING_NEW_MONTHLY_PREMIUM_CHANGE_KEY",
    pageLocation: [
      AppLinks.policy.changePolicyOptions + "/Step1"
    ],
    actions: [],
    dismissible: true
  },
  {
    alertKey:"PENDING_CREDIT_CARD_EXPIRE_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails,
      AppLinks.policy.addPet + "/Step1",
      AppLinks.account.updateBillingInfo
    ],
    actions: ["UpdateBillingInfo"],
    dismissible: false
  },
  {
    alertKey:"PENDING_REPAYMENT_ALL_PREMIUMS_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails,
      AppLinks.policy.addPet + "/Step1",
      AppLinks.account.updateBillingInfo
    ],
    actions: ["UpdateBillingInfo", "RetryBilling"],
    dismissible: false
  },
  {
    alertKey: "PENDING_CREDIT_CARD_IS_EXPIRED_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails,
      AppLinks.policy.addPet + "/Step1",
      AppLinks.account.updateBillingInfo
    ],
    actions: ["UpdateBillingInfo"],
    dismissible: false
  },
  {
    alertKey:"PENDING_UNABLE_PROCESS_PAYMENT_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails,
      AppLinks.policy.addPet + "/Step1",
      AppLinks.account.updateBillingInfo
    ],
    actions: ["UpdateBillingInfo", "RetryBilling"],
    dismissible: false
  }, 
  {
    alertKey:"PENDING_UNABLE_POSTPONE_PAYMENT_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails,
      AppLinks.policy.addPet + "/Step1",
      AppLinks.account.updateBillingInfo
    ],
    actions: ["UpdateBillingInfo", "RetryBilling"],
    link: [{ contactUs: "ContactUs" }],
    dismissible: false
  },
  {
    alertKey:"PENDING_UNABLE_POSTPONE_PAYMENT_12_DAYS_CHANGE_KEY",
    pageLocation: [AppLinks.others.postponePayment + "/Step1"],
    actions: [],
    link: [{ contactUs: "ContactUs" }],
    dismissible: false
  },
  {
    alertKey:"PENDING_POSTPONE_PAYMENT_7_DAYS_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails,
      AppLinks.account.updateBillingInfo,
      AppLinks.others.postponePayment + "/Step1"
    ],
    actions: [],
    link: [{ contactUs: "ContactUs" }],
    dismissible: false
  },
  {
    alertKey:"PENDING_POSTPONE_PAYMENT_NO_NEW_PETS_CHANGE_KEY",
    pageLocation: [AppLinks.policy.addPet + "/Step1"],
    actions: [],
    link: [{ contactUs: "ContactUs" }],
    dismissible: false
  },
  {
    alertKey:"PENDING_UNABLE_PROCESS_COLLECT_PREMIUM_CHANGE_KEY",
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.viewProfile,
      AppLinks.claims.claimStatus,
      AppLinks.policy.viewPetDetails,
      AppLinks.account.updateBillingInfo,
      AppLinks.policy.addPet + "/Step1"
    ],
    actions: ["UpdateBillingInfo", "RetryBilling"],
    link: [{ contactUs: "ContactUs" }],
    dismissible: false
  },  
  {
    alertKey:"CLAIM_MISSING_INFORMATION_KEY",
    pageLocation: [AppLinks.home.home, AppLinks.claims.claimStatus],
    actions: ["UpdateClaim"],
    dismissible: true
  },
  // Below Alerts will be created in client side
  {
    alertKey:"PENDING_THANKS_UPDATING_ADDRESS_INFO_PREMIUM_CHANGED_CHANGE_KEY",    
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.updateBillingInfo,
      AppLinks.account.viewProfile,
      AppLinks.policy.changePolicyOptions + "/Step1"
    ],
    actions: [],
    link: [{ contactUs: "ContactUs" }],
    dismissible: true
  },
  {
    alertKey:"PENDING_ACCOUNT_ALMOST_READY_CHANGE_KEY",  
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.updateBillingInfo,
      AppLinks.claims.claimStatus,
      AppLinks.policy.addPet + "/Step1",
      AppLinks.account.viewProfile,
      AppLinks.policy.viewPetDetails
    ],
    actions: [],
    link: [{ contactUs: "ContactUs" }],
    dismissible: false
  },
  {
    alertKey:"POLICIY_CHANGES_APPLIED_KEY",  
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.changeProfile,
      AppLinks.others.postponePayment + "/Step1",
      AppLinks.account.changePhysicalAddress,
      AppLinks.policy.changePolicyOptions + "/Step1",
      AppLinks.account.updateBillingInfo,
      AppLinks.claims.fileClaim,
      AppLinks.others.cancelPolicy + "/Step1",
      AppLinks.policy.addPet + "/Step1",
    ],
    actions: [],
    link: [{ contactUs: "ContactUs" }],
    dismissible: false
  },
  {
    alertKey:"ALERT_FETCHING_ERROR",  
    pageLocation: [
      AppLinks.home.home,
      AppLinks.account.changeProfile,
      AppLinks.others.postponePayment + "/Step1",
      AppLinks.account.changePhysicalAddress,
      AppLinks.policy.changePolicyOptions + "/Step1",
      AppLinks.account.updateBillingInfo,
      AppLinks.claims.fileClaim,
      AppLinks.others.cancelPolicy + "/Step1",
      AppLinks.policy.addPet + "/Step1",
    ],
    actions: [],
    link: [{ contactUs: "ContactUs" }],
    dismissible: false
  },


];


//This list object mimics like API response for Alert , So that handling of both would be much easier.
export const ClientAlerts = [
  {
    message_name:"PENDING_THANKS_UPDATING_ADDRESS_INFO_PREMIUM_CHANGED_CHANGE_KEY", 
    message:
      "Thank you for updating your address information. Your monthly total payment has changed to: $XX.XX.",
    message_type: "Notification"
  },
  /*
  {
    message_name:"PENDING_ACCOUNT_ALMOST_READY_CHANGE_KEY", 
    message:
      "Your account is almost ready. Please update your billing information to ensure continuous pet health coverage. If you have questions, <span class='contactus'>click here</span> for our contact information. ",
    message_type: "Alert"
  },
  */
  {
    message_name:"POLICIY_CHANGES_APPLIED_KEY",
    message:"Changes are currently being applied to your policy. During this time some features of this site are disabled, and will be re-enabled once that process has completed. Thank you for your patience.",
    message_type: "Alert"
  },
  {
    message_name:"ALERT_FETCHING_ERROR", 
    message:
    "Unable to to load Alert(s) or notification(s) due to an unknown error. If you have questions, <span class='contact'>Click here</span> for our contact information.",
    message_type: "Alert"
  }
];


export const CreateAddressUpdateAlert = (newPremium) => {
  const alertObj =  {
    message:
      "Thank you for updating your address information. Your monthly total payment has changed to: <span class='alertBoldStyle'>$" +
      newPremium +
      "</span>.",
    alertKey:
      "PENDING_THANKS_UPDATING_ADDRESS_INFO_PREMIUM_CHANGED_CHANGE_KEY"
  };
  return alertObj; 
}
