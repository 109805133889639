import * as types from "../../constants/action-types";
import { ClientAlerts } from "./../../modules/common/alerts-notifications/AlertsConfigurationList";

//[TO-DO] Need to remove this method, once token expiry in auth handles this logic as well
const checkLocalStoreValidity = () => {
  const storeLastUpdated = new Date(localStorage.getItem("storeLastUpdated"));
  if (!(storeLastUpdated === "" || storeLastUpdated === null)) {
    var hours = Math.abs(new Date() - storeLastUpdated) / 36e5;
    if (hours > 24) {
      localStorage.removeItem("dismissedNotifications");
      localStorage.removeItem("clientAlertsNotifications");
      localStorage.removeItem("storeLastUpdated");
    }
  }
};

const getNotificationIdsFromLocal = () => {
  checkLocalStoreValidity();
  const dismissedIdsFromStorage =
    localStorage.getItem("dismissedNotifications") === "" ||
    localStorage.getItem("dismissedNotifications") === null
      ? []
      : localStorage.getItem("dismissedNotifications").split(",");
  return dismissedIdsFromStorage;
};

const getClientAlertsFromLocal = () => {
  const clientAlertsFromLocal =
    localStorage.getItem("clientAlertsNotifications") === "" ||
    localStorage.getItem("clientAlertsNotifications") === null
      ? []
      : localStorage.getItem("clientAlertsNotifications");
  return clientAlertsFromLocal instanceof Array
    ? clientAlertsFromLocal
    : JSON.parse(clientAlertsFromLocal);
};

const initialState = {
  alertsNotifications: [],
  dismissedNotificationIds: getNotificationIdsFromLocal(),
  clientAlertsNotifications: getClientAlertsFromLocal(),
  currentPageAlerts: [],
  alertsFetchError: false,
  needsRefresh: false,
  pageLocation: "/",
  fetchingAlerts: false
};

// Handles image related actions
export default function(state = initialState, action) {
  switch (action.type) {
    case types.ALERTS_FETCH_REQUEST:
      return {
        ...state,
        fetchingAlerts: true
      };
    case types.ALERTS_FETCH_SUCCESS:
      return {
        ...state,
        alertsNotifications: action.alerts,
        fetchingAlerts: false
      };
    case types.ALERTS_FETCH_ERROR:
      const errorAlert = ClientAlerts.filter(
        alert => alert.message_name === "ALERT_FETCHING_ERROR"
      );
      return {
        ...state,
        alertsFetchError: true,
        alertsNotifications: [...errorAlert],
        fetchingAlerts: false
      };
    case types.SET_ALERTS_DATA_REFRESH:
      return {
        ...state,
        needsRefresh: true
      };
    case types.SET_NEW_ROUTE:
      return {
        ...state,
        pageLocation: action.pageLocation
      };
    case types.SET_NOTIFICATIONS_DISMISSED:
      const newDismissArray = [
        ...state.dismissedNotificationIds,
        ...action.dismissedNotificationIds
      ].filter((x, i, a) => a.indexOf(x) === i);
      localStorage.setItem("dismissedNotifications", newDismissArray);
      localStorage.setItem("storeLastUpdated", new Date());
      return {
        ...state,
        dismissedNotificationIds: [...newDismissArray]
      };
    case types.CLIENT_ALERT_ADD:
      const newAlertObj = action.newAlert;
      let newDismissedItemArray = [];
      let newAlert = ClientAlerts.find(
        alert => alert.message_name === newAlertObj.alertKey
      );
      //As we are adding this based on user action , we need to remove if the user dismissed this notification already
      //Since this action is created out of new user action , so we need to show the notification
      //We are suppressing all the notification ids present in localStorage

      //If the notification is dismissed , we need to remove from local , as we need to show it again
      const dismissedIds = state.dismissedNotificationIds;
      newDismissedItemArray = dismissedIds
        .filter(
          notificationId => Number(notificationId) !== newAlertObj.alertKey
        )
        .filter((x, i, a) => a.indexOf(x) === i);

      localStorage.setItem("dismissedNotifications", newDismissedItemArray);
      localStorage.setItem("storeLastUpdated", new Date());

      //Filter out the alert if already exists
      let clientAlertsNotifications = [];
      if (state.clientAlertsNotifications.length > 0) {
        clientAlertsNotifications = [...state.clientAlertsNotifications];
        clientAlertsNotifications = clientAlertsNotifications.filter(
          alert => alert.message_name !== newAlert.message_name
        );
      }

      if (newAlertObj.message) {
        newAlert.message = newAlertObj.message;
      }

      //Sets ClientAlerts  ids to local storeage
      localStorage.setItem(
        "clientAlertsNotifications",
        JSON.stringify([...clientAlertsNotifications, newAlert])
      );
      localStorage.setItem("storeLastUpdated", new Date());

      return {
        ...state,
        clientAlertsNotifications: [...clientAlertsNotifications, newAlert],
        dismissedNotificationIds: newDismissedItemArray
      };
    case types.CLIENT_ALERT_REMOVE:
      const clientAlertsFromState = [...state.clientAlertsNotifications];
      let notificationIndex = clientAlertsFromState.findIndex(
        alert => alert.message_name === action.alertKey
      ); 
      const newClientAlerts = [
        ...state.clientAlertsNotifications.slice(0, notificationIndex),
        ...state.clientAlertsNotifications.slice(notificationIndex + 1)
      ]
      //Sets ClientAlerts  ids to local storeage
      localStorage.setItem(
        "clientAlertsNotifications",
        JSON.stringify(newClientAlerts)
      );
      localStorage.setItem("storeLastUpdated", new Date());
      return {
        ...state,
        clientAlertsNotifications: newClientAlerts
      };
    case types.SET_CURRENT_PAGE_ALERTS:
      return {
        ...state,
        currentPageAlerts: action.currentPageAlerts
      };
    default:
      return state;
  }
}
