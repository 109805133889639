import CoverageQuestions from "../../faq/detail/CoverageQuestions";
import PreExistingQuestion from "../../faq/detail/PreExistingQuestion";
import GettingReimbursed from "../../faq/detail/GettingReimbursed";
import PetEligible from "../../faq/detail/PetEligible";
import filingClaims from "../../faq/detail/FilingClaims";
import Billing from "../../faq/detail/Billing";
import ManagingPolicy from "../../faq/detail/ManagingPolicy";
import PostPonepaymentQuestion from "../../faq/detail/PostPonepaymentQuestion";

export const updateReimbursementFaqLinks = [
  {
    text: "How long does it take to get reimbursed ?",
    answer: GettingReimbursed.howLongItGetReimbursed(),
    link: "/faq?qid=How_long_it_get_reimbursed"
  },
  {
    text: "What are my options for reimbursement ?",
    answer: GettingReimbursed.optionReimbrushment(),
    link: "/faq?qid=What_options_for_reimbursement"
  },
  {
    text: "What if I can't wait for reimbursement?",
    answer: GettingReimbursed.cantWaitReimbrushment(),
    link: "/faq?qid=What_cant_wait_for_reimbursement"
  }
];

export const submitClaimFaqLinks = [
  {
    text: "Where can I get a claim form?",
    answer: filingClaims.claimForm(),
    link: "/faq?qid=claim-form"
  },
  {
    text: "What medical records do I need?",
    answer: filingClaims.medicalRecords(),
    link: "/faq?qid=medical-records"
  },
  {
    text: "How is reimbursement calculated?",
    answer: filingClaims.reimbursement(),
    link: "/faq?qid=reimbursement"
  },
  {
    text: "What is covered?",
    answer: CoverageQuestions.whatIsCoveredAnswer(),
    link: "/faq?qid=What_is_covered"
  },
  {
    text: "What is not covered?",
    answer: CoverageQuestions.whatIsNotCovered(),
    link: "/faq?qid=What_is_not_covered"
  }
];

export const submitMrFaqLinks = [
  {
    text: "What is a pre existing condition?",
    answer: PreExistingQuestion.preExsisitingCondition(),
    link: "/faq?qid=pre-existing_condition"
  },
  {
    text: "Are cruciate ligament (CL, CCL, ACL) injuries covered?",
    answer: PreExistingQuestion.preExsisitingConditionCoverage(),
    link: "/faq?qid=pre-existing_condition_coverage"
  },
  {
    text: "What medical records do you need for my pet?",
    answer: filingClaims.medicalRecords(),
    link: "/faq?qid=medical-records"
  },
  {
    text: "Does my pet need a vet exam to qualify for coverage?",
    answer: PetEligible.vetExam(),
    link: "/faq?qid=vet_exam"
  },
  {
    text: "How do I know if my pet has had an examination?",
    answer: PetEligible.petExam()
  }
];

export const postponepaymentFAQLink = [
  {
    text: "When you postpone your payment?",
    answer: PostPonepaymentQuestion.whenYouPostPonePayment()
  }
];

export const updateParentFAQLink = [
  {
    text: "Transfer of pet ownership",
    answer: Billing.ownershipTransfer()
  }
];

export const addAPetFAQLink = [
  {
    text: "What is the 30-day Free Look Period?",
    answer: Billing.freeLookPeriod()
  },
  {
    text: "Does my pet need a vet exam to qualify for coverage?",
    answer: PetEligible.vetExam()
  },
  {
    text: "What is a pre-existing condition?",
    answer: PreExistingQuestion.preExsisitingCondition()
  },
  {
    text: "Do you cover hip dysplasia?",
    answer: CoverageQuestions.didYouCoverHipDysplasia()
  },
  {
    text: "Is there a waiting period?",
    answer: filingClaims.waitingPeriod()
  }
];

export const updateUpdatePhysicalAddressFaqLinks = [
  {
    text: "Can I change my deductible or reimbursement level after enrolling?",
    answer: ManagingPolicy.afterEnroll(),
    link: "/faq?qid=after_enroll"
  }
  //https://www.pivotaltracker.com/story/show/169580978 - Fix
  // {
  //   text: "Transfer of pet ownership",
  //   answer: Billing.ownershipTransfer(),
  //   link: "/faq?qid=ownership_transfer"
  // }
];
export const changePolicyOptionsFAQLink = [
  {
    text: "How do I add a new pet?",
    answer: ""
  },
  {
    text: "How can I contact Healthy Paws about a claim?",
    answer: ""
  },
  {
    text: "Where can I get a claim form?",
    answer: ""
  },
  {
    text: "Do I need a claim form?",
    answer: ""
  },
  {
    text: "How can I know what is going on with my claim?",
    answer: ""
  }
];
