import React from "react";
import { Link } from "react-router-dom";
import "./ClaimStatusMessage.css";
import { AppLinks } from "../../../../constants/app-links";
import { StatusTypes } from "../../status-model";
import parse from "html-react-parser";
import "./ClaimStatusMessage.scss";

const ClaimStatusMessage = ({
  status,
  claimID,
  claimNumber,
  petId,
  petName,
  receivedData,
  missingData,
}) => {
  //For google analytics
  window.dataLayer.push({
    event: "Pageview",
    url: window.location.pathname + "/Confirm",
  });

  switch (status.toLowerCase()) {
    case StatusTypes.open:
      return (
        <div className="status-message">
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-md-block d-none col-md-1 text-primary">
              <i className="fa fa-cogs icons" />
            </div>
            <div className="col-md-10 pl-0" style={{ marginLeft: 5 + "px" }}>
              {petName}'s claim has been opened and will be assigned to a claims
              processor shortly. No additional action is required at this time.
            </div>
          </div>
          <div className="d-block d-md-none">
            <br />
            <div>
              <strong className="mr-1"> Claim Number: </strong>
              <span> {claimNumber ? claimNumber : "n/a"} </span>
            </div>
          </div>
        </div>
      );

    case StatusTypes.new:
    case StatusTypes.inProcess:
      return (
        <div className="status-message">
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-md-block d-none col-md-1 text-primary">
              <i className="fa fa-cogs icons" />
            </div>
            <div className="col-md-10 pl-0" style={{ marginLeft: 5 + "px" }}>
              {petName}'s claim documents have been submitted.
            </div>
          </div>
        </div>
      );

    case StatusTypes.waitingOnSomeone:
    case StatusTypes.waitingForApproval:
    case StatusTypes.pendingClose:
    case StatusTypes.directDepositError:
    case StatusTypes.directDepositInProgress:
    case StatusTypes.adjustorRejected:
    case StatusTypes.adjustorReview:
      return (
        <div className="status-message">
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-md-block d-none col-md-1 text-primary">
              <i className="fa fa-cogs icons" />
            </div>
            <div className="col-md-10 pl-0" style={{ marginLeft: 5 + "px" }}>
              A claims processor is reviewing {petName}'s claim.
            </div>
          </div>
          <div className="d-block d-md-none">
            <br />
            <div>
              <strong className="mr-1"> Claim Number: </strong>
              <span> {claimNumber ? claimNumber : "n/a"} </span>
            </div>
          </div>
        </div>
      );

    case StatusTypes.waitingForInfo:
      return (
        <div className="status-message">
          <div className="d-flex justify-content-start">
            <div
              className="d-md-block d-none col-md-1 text-primary"
              style={{ marginTop: 5 + "px" }}
            >
              <i className="fa fa-cogs icons" />
            </div>
            <div className="col-md-10 pl-0" style={{ marginLeft: 5 + "px" }}>
              <div className="d-md-flex">
                <div className="col-md-6 p-0 d-md-none">
                  <Link
                    to={`${AppLinks.claims.updateClaim}?claimId=${claimID}&petId=${petId}`}
                  >
                    <button className="btn btn-outline-secondary">
                      {" "}
                      Upload Missing Documents
                    </button>
                  </Link>
                  <br />
                  <br />
                </div>
                <div className="col-md-6 p-0">
                  Assistance is needed to complete {petName}'s claim. We are in
                  need of the following information:
                </div>
                <div className="col-md-6 p-0 d-md-block d-none">
                  <Link
                    to={`${AppLinks.claims.updateClaim}?claimId=${claimID}&petId=${petId}`}
                  >
                    <button className="btn btn-outline-secondary">
                      {" "}
                      Upload Missing Documents
                    </button>
                  </Link>
                </div>
              </div>
              <br/>
              {receivedData.map((data) => {
                return parse(data.data + "<br/><br/>");
              })}
              {missingData.map((data) => {
                return parse(data.data + "<br/><br/>");
              })}
            </div>
          </div>
          <div className="d-block d-md-none">
            <br />
            <div>
              <strong className="mr-1"> Claim Number: </strong>
              <span> {claimNumber ? claimNumber : "n/a"} </span>
            </div>
          </div>
        </div>
      );

    case StatusTypes.waitingForReview:
      return (
        <div className="status-message">
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-md-block d-none col-md-1 text-primary">
              <i className="fa fa-cogs icons" />
            </div>
            <div className="col-md-10 pl-0" style={{ marginLeft: 5 + "px" }}>
              We received the additional documents you uploaded for {petName}. A
              claims processor will review this information shortly.
            </div>
          </div>
          <div className="d-block d-md-none">
            <br />
            <div>
              <strong className="mr-1"> Claim Number: </strong>
              <span> {claimNumber ? claimNumber : "n/a"} </span>
            </div>
          </div>
        </div>
      );

    case StatusTypes.approvedForPayment:
      return (
        <div className="status-message">
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-md-block d-none col-md-1 text-primary">
              <i className="fa fa-check-circle icons" />
            </div>
            <div className="col-md-10 pl-0" style={{ marginLeft: 5 + "px" }}>
              {petName}'s claim has been reviewed. You will receive your
              Explanation of Benefits statement via email within 24 hours.
            </div>
          </div>
          <div className="d-block d-md-none">
            <br />
            <div>
              <strong className="mr-1"> Claim Number: </strong>
              <span> {claimNumber ? claimNumber : "n/a"} </span>
            </div>
          </div>
        </div>
      );

    case StatusTypes.closedWithoutPaymentCovered:
    case StatusTypes.closedWithoutPaymentDenied:
    case StatusTypes.closedWithoutPaymentDuplicate:
    case StatusTypes.closedWithPayment:
    case StatusTypes.reissuingCheck:
      return (
        <div className="status-message">
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-md-block d-none col-md-1 text-primary">
              <i className="fa fa-check-circle icons" />
            </div>
            <div className="col-md-10 pl-0" style={{ marginLeft: 5 + "px" }}>
              {petName}'s claim has been processed.
            </div>
          </div>
          <div className="d-block d-md-none">
            <br />
            <div>
              <strong className="mr-1"> Claim Number: </strong>
              <span> {claimNumber ? claimNumber : "n/a"} </span>
            </div>
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default ClaimStatusMessage;
