import React from "react";
import ImageFilter from "react-image-filter";
import * as canvg from "canvg";

import PhotoControl from "./PhotoControl";

import { filters } from "./filter-types";

import "./PhotoPreview.css";

const PhotoPreview = props => {
  const errorOccured = props.petUpdateError instanceof Object ? true : false;
  const {
    petDetails,
    image,
    onChangePhoto,
    stepper,
    onUpload,
    filter,
    showLoader
  } = props;

  const goToCrop = () => {
    stepper.goToStep(0);
  };

  const goToFilter = () => {
    stepper.goToStep(1);
  };

  const svgToBitMapURI = () => {
    return new Promise((resolve, reject) => {
      const svg = document.querySelector("#preview-image > svg");
      const svgData = new XMLSerializer().serializeToString(svg);

      const canvas = document.getElementById("preview-canvas");

      canvg(canvas, svgData, { useCORS: true });
      setTimeout(() => {
        let image = canvas.toDataURL("image/png");
        resolve(image);
      }, 100);
    });
  };

  const onUploadPhoto = () => {
    showLoader();
    svgToBitMapURI().then(image => {
      onUpload(image);
    });
  };

  return (
    <div className="photo-preview">
      <div className="title-container">
        <h1 className="text-center">
          Preview {petDetails.Name}
          's photo
        </h1>
      </div>
      {errorOccured && (
        <div style={{ textAlign: "center" }}>
          <p className="text-danger">
            {props.petUpdateError.errorMessage}
          </p>
        </div>
      )}
      <div className="d-flex flex-column justify-content-center grow">
        <div className="d-flex justify-content-center mb-3">
          <ImageFilter
            id="preview-image"
            image={image}
            filter={filters[filter].filter}
            svgProps={{
              width: 200,
              height: 200
            }}
          />
          <canvas
            width="200"
            height="200"
            style={{ display: "none" }}
            id="preview-canvas"
          />
        </div>
        <p className="text-center font-weight-bold">
          Would you like to upload this photo?
        </p>
      </div>
      <PhotoControl
        upload={true}
        toCrop={goToCrop}
        toFilter={goToFilter}
        onUpload={onUploadPhoto}
        onChangePhoto={onChangePhoto}
        {...stepper}
      />
    </div>
  );
};

export default PhotoPreview;
