import * as actionTypes from "../constants/action-types";
import { getAccountInfo, getTopAlertMessage } from "../api/accounts-api";
import { updateUserAccountInfo } from "./../modules/common/util/AccountsUtil";
import { call, put, select } from "redux-saga/effects";

const getUserDetailsFromState = state => {
  return {
    userAccount: state.account.userAccountDetails,
    needsRefresh: state.account.needsRefresh
  };
};

export function* getAccountInfoRequest(action) {
  try {
    const userDetailsFromState = yield select(getUserDetailsFromState);
    if (
      userDetailsFromState.userAccount === null ||
      userDetailsFromState.needsRefresh
    ) {
      yield put({ type: actionTypes.SET_USER_ACCOUNT_FETCHING_STATE });
      let userDetails = yield call(getAccountInfo);
      if (userDetails) {
        updateUserAccountInfo(userDetails);
        yield put({ type: actionTypes.ACCOUNT_FETCH_SUCCESS });

      }
    }
  } catch (error) {
    yield put({ type: actionTypes.ACCOUNT_FETCH_ERROR, error });
  }
}


export function* getTopAlertMessageRequest(action) {
  try{
    let alertMessage = yield call(getTopAlertMessage);
    yield put({ type: actionTypes.SET_TOP_ALERT_MESSAGE, alertMessage });
  }catch (error) {
    //Error Message
  }
}
