import React from "react";
import "./SideDrawer.css";

// TODO: Handle cases if content is not there. For now displayin white space. Handle if needed. For now there is no such case.

const sideDrawer = props => {
  let drawerClasses = "side-drawer-container";

  let htmlElement = document.documentElement;
  if (props.state === "left-open") {
    drawerClasses = "side-drawer-container left-open";
    //Add No scroll to html and body
    htmlElement.classList.add("noscroll");
    document.body.classList.add("noscroll");

    //Open mobile menu always on top with (0,0)
    const sideDrawerContainer = document.getElementById("mobile-side-menu");
    if(sideDrawerContainer)
    sideDrawerContainer.scrollIntoView();

  } else if (props.state === "right-open") {
    drawerClasses = "side-drawer-container right-open";
    // Add No scroll to html and body
    htmlElement.classList.add("noscroll");
    document.body.classList.add("noscroll");
  }
  if (props.state === "closed") {
    // Remove No scroll to html and body
    htmlElement.classList.remove("noscroll");
    document.body.classList.remove("noscroll");
    setTimeout(() => {
      let containerElement = document.getElementById("side-drawer-container");
      if (containerElement) {
        containerElement.classList.add("closed");
      }
    }, 300);
  }

  return (
    <div style={{ height: "0px", overflow: "hidden" }}>
      <div id="side-drawer-container" className={drawerClasses}>
        <div id="left-side-drawer" className="d-flex">
          <div className="left-content-holder">{props.leftContent}</div>
          <div
            className="left-drawer-close-button"
            onClick={props.sideDrawerClose}
          >
            <img
                  id="close-icon"
                  alt="close-icon"
                  src="../../assets/images/mobile-close-icon.svg"
                />
          </div>
        </div>
        <div id="right-side-drawer" className="d-flex justify-content-end">
          <div
            className="right-drawer-close-button"
            onClick={props.sideDrawerClose}
          >
            X
          </div>
          <div className="right-content-holder">{props.rightContent}</div>
        </div>
        <div className="overlay" onClick={props.sideDrawerClose} />
      </div>
    </div>
  );
};

export default sideDrawer;
