import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPetsByAccount } from "../../../store/actions/pets-actions";
import { AppLinks } from "../../../constants/app-links";
import ModalDialog from "./../../common/modal-dialog/ModalDialog";
import "./speedyClaims.css";


class SpeedyClaims extends Component {
  today = null;
  constructor(props) {
    super(props);

    this.SpeedyClaimsImg = "../../assets/images/speedy-claims.png";
    this.ctaLink = "/PetDetails"
    this.fetchingError = "";
    this.state={
      showtellMeMore : false
    };
    this.toggleTellMeMore = this.toggleTellMeMore.bind(this);
  }


  toggleTellMeMore (e) {
    e.preventDefault();
    
    this.setState({
      showtellMeMore: !this.state.showtellMeMore
    });

  }; 

  render() {
    
    var bgImage = {
      backgroundImage: "url(" + this.SpeedyClaimsImg + ")"
    };
    return (
      <React.Fragment>
      {this.tellMeMore()}
      <div className="container SpeedyClaims">
        <div className="row">
          <div className="col-md-10 mx-auto main-content">
             <div className="row">
                <div className="col-md-12 align-self-center" >
                  <h2 className="heading">Steps for Faster Claims & Reimbursement</h2>
                </div>
                <div className="sub-content" style={bgImage}>
                  <div className="col-md-6"  >
                    <div className="content">
                      <p className="sub-Heading"><span>1.</span>Get reimbursed faster</p>
                      <p className="desc">Choose direct deposit and reimbursements go directly into your bank account.</p>
                      <a className="cta" href={AppLinks.account.updateReimbursement}>Change Direct Deposit</a>
                    </div>
                    <div className="content">
                      <p className="sub-Heading"><span>2.</span>Tips for a speedy first claim</p>
                      <p className="desc">Obtain SOAP medical records from all the clinics your pet has visited and file with your first claim.</p>
                      <button className="cta TellMeMore" onClick={this.toggleTellMeMore}>Tell Me More</button>
                    </div>
                </div> 
                <div className="col-md-12 d-block d-md-none imgDiv">
                  <img src={this.SpeedyClaimsImg} className="speedyClaimsImg"/>
                </div>
                </div>
                               
             </div>
          </div>
        </div>
      </div>

      </React.Fragment>
    );
  }

  tellMeMore = () => {
    return (
      <ModalDialog
        isOpen={this.state.showtellMeMore}
        toggle={this.toggleTellMeMore}
        className={"tellMeMoreModal"}
        modalHeader={"Tips for filing your first claim"}
        modalBody={
          <div className="tellMeMore">
            <div className="row">
              <div className="col-md-12">
                <p className="title">Medical Records</p>
                <p className="content">Medical records for your pet include your veterinarian's <span className="bold">S</span>ubjective, <span className="bold">O</span>bjective, <span className="bold">A</span>ssessment, and <span className="bold">P</span>lan (SOAP) notes during clinical exams, laboratory results, and other documentation regarding all of your pet's visits to the veterinarian. If you are filing your first claim, our claims processors may need to review your pet's complete medical records.<br/><br/> Are you unsure what SOAP medical records we need? Just ask your vet, “Can you please email me my pet’s medical history including SOAP notes for all of my pet's visits?”</p>
                <br/>
                <p className="title">Enrollment Exam</p>
                <p className="content">Make sure your medical records include one complete clinical examination performed by a licensed veterinarian within the required time frame from when you enrolled in the Healthy Paws plan. This is required for your policy’s coverage to take effect.  We call this your required Enrollment Exam. Please see your policy for specific details.</p>
              </div>
            </div>
          </div>
        }
        
      />
    );
  };
}



// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  
});

const mapDispatchToProps = dispatch => ({
  
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpeedyClaims);
