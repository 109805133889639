import React, { Component, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./MobileAppUpdateBillingView.component.css";
import { Link } from "react-router-dom";
import { Form } from "informed";
import { UncontrolledCollapse } from "reactstrap";
import { getUpdateBillingToken, getCaptureContext } from "../../../api/accounts-api";

import MaskValidateUtil from "../../common/util/MaskValidateUtil";
import StateList from "../../common/data/StateList";

import EditText from "../../common/form-fields/edit-text/EditText";
import DropDownSelect from "../../common/form-fields/drop-down/DropDownSelect";
import Loader from "../../common/loader/Loader";

import ErrorView from "../../common/error-view/ErrorView";
import { AppLinks } from "../../../constants/app-links";

export class MobileAppUpdateBillingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AuthorizeAcceptToken: "",
      loadingForm:true,
      ccNumberError: false,
      ccSecurityCodeError: false,
    };

    this.hideLoader = this.hideLoader.bind(this);

    // initialize microform
    this.flex = null;
    this.microform = null;
    this.microformPaymentToken = '';
  }

  
  hideLoader() {
    this.setState({loadingForm:false})
  }

  fetchInitialValues(userData) {
    if (userData && userData.BillingAddress) {
      var responseData = userData.BillingAddress;

      if (responseData.Name) {

        var index = responseData.Name.indexOf(" ");

        return {
          firstName:
            responseData.Name.length >= index
              ? responseData.Name.substr(0, index)
              : responseData.Name,
          lastName:
            responseData.Name.length > index + 1
              ? responseData.Name.substr(index + 1)
              : "",
          city: responseData.City,
          streetAddress: responseData.Street,
          state: responseData.State,
          zipCode: responseData.PostalCode
        };
      }
    }

    return {};
  }
  

  
  handleFormSubmit(values) {
    console.log('now in handleFormSubmit');

    var self = this;

    var creditCardMonth, creditCardYear;

    if (values.creditCardExpiryDate) {
      creditCardMonth = values.creditCardExpiryDate.substr(0, 2);
      creditCardYear = values.creditCardExpiryDate.substr(3);

      // submit the microform fields to get the transient token
      var microformTokenOptions = {
        expirationMonth: creditCardMonth,
        expirationYear: creditCardYear
      };

      this.microform.createToken(microformTokenOptions, function (err, token) {
        if (err) {
            // handle error
            console.error(err);

        } else {
            // At this point you may pass the token back to your server as you wish.
            // In this example we append a hidden input to the form and submit it.
            values.microformPaymentToken = JSON.stringify(token);
            console.log('values.microformPaymentToken = ' + JSON.stringify(values.microformPaymentToken));

            self.props.updateBilling(self.props.userAccountDetails.AccountID,
              self.props.userAccountDetails.PetParentInfo.Email, values);
        }
      });
    } else {
      self.props.updateBilling(self.props.userAccountDetails.AccountID,
        self.props.userAccountDetails.PetParentInfo.Email, values);
    }
  }

  componentDidMount() {
    //thisHolder.refs.edit_payment.addEventListener('load', this.hideLoader)
    var self = this;

    setTimeout(() => {
      this.hideLoader();
    },500);
   
    var customMicroformStyles;

    customMicroformStyles = {
      'input': {
        'font-weight': '400',
        'font-size': '20px',
        'color': '#333333',
        'font-family': '"OpenSans-Regular", "Open Sans", sans-serif',
        },
      ':focus': { 'border-color': 'blue' },
      '::placeholder': {
        'color': '#999999'
      },
      ':disabled': { 'cursor': 'not-allowed' },
      'valid': { 'color': '#3c763d' },
      'invalid': { 'color': '#a94442', 'border': '2px solid blue' }
    }

    // generate capture context
    var captureContext = '';
    getCaptureContext()
      .then(response => {
        captureContext = response;

        try {
          this.flex = new window.Flex(captureContext);
          this.microform = this.flex.microform({ styles: customMicroformStyles });
          var number = this.microform.createField('number', { placeholder: 'Credit Card Number' });
          var securityCode = this.microform.createField('securityCode', { placeholder: 'Security Code' });

          // add event listeners to new fields
          number.on('change', function (data) {
            // check for valid entry in field
            if (!(data.valid)) {
              self.setState({ccNumberError: true});
            } else {
              self.setState({ccNumberError: false});
            }
          });

          securityCode.on('change', function (data) {
            // check for valid entry in field
            if (!(data.valid)) {
              self.setState({ccSecurityCodeError: true});
            } else {
              self.setState({ccSecurityCodeError: false});
            }
          });

          number.load('#cc_number_container');
          securityCode.load('#cc_cvn_container');
        } catch (ex) {
            console.log(ex);
        }
      })
  }

  render () {
    return (
    <div
      /* style={{ position: this.props.canUserEdit ? "static" : "relative" }} */
      className="container"
    >
      {/*this.props.canUserEdit ? null : <div className="disable-user-edit" />*/}
      <div className="d-flex flex-column mobile-app-update-billing-container">
      <h1 className="margin-bottom mb-lg-0 title-container">Update Billing Information</h1>
      <div className="d-flex flex-row">
        <h3 className="creditOrDebitCard">Credit or Debit Card</h3>
        &nbsp;&nbsp;
        
      </div>
        {this.props.serverError && <ErrorView>{this.props.errorText ? this.props.errorText :
        <span>Unable to update billing details due to an error. Please Contact Us.</span>
        }</ErrorView>}
        {this.props.userAccountDetails ?

          (<div>
            <Form
            onSubmit={values => this.handleFormSubmit(values)}
            initialValues={this.fetchInitialValues(this.props.userAccountDetails)}
          >
            {({ formState }) => (
              <React.Fragment>
                <EditText
                  required
                  className="input-container margin-bottom"
                  id="first_name"
                  field="firstName"
                  type="text"
                  title="First Name on Card"
                  floatingTitle={true}
                />
                <EditText
                  required
                  className="input-container margin-bottom"
                  id="last_name"
                  field="lastName"
                  type="text"
                  title="Last Name on Card"
                  floatingTitle={true}
                />
                
                <div className="d-flex flex-row">
                  <img className="cards-image" src="../../assets/images/cards.png" alt="cards" />
                  <img className="cybersource-logo" src="../../assets/images/cybersource_logo_black.png" alt="Cybersource logo" />
                </div>

                <div className="d-md-flex flex-md-row align-items-md-center margin-bottom">
                  
                  <div className="edit-text-container input-container" id="cc_number">
                    <div id="cc_number_container" className="demo-form-group secure-field" 
                        style={this.state.ccNumberError ? {border : "2px solid red"} : {}}>
                    </div>
                    { this.state.ccNumberError ? (
                    <label class="error">Invalid credit card number</label>
                    ) : null }
                  </div>
                  {/*<EditText
                    required
                    className="input-container"
                    id="credit_card_number"
                    field="creditCardNumber"
                    type="text"
                    title="Credit or Debit Card #"
                    floatingTitle={true}
                    mask={MaskValidateUtil.creditCardNumberMask}
                    validate={MaskValidateUtil.validateCreditCardNumber}
                    validateOnChange={formState.errors.creditCardNumber}
                    validateOnBlur
                    />*/}

                  <div className="d-none d-md-flex flex-row">
                    <Link
                      to="#"
                      onClick={() => {
                        this.props.toggleSecurityDialog();
                      }}
                      className="d-flex flex-row"
                    >
                      <React.Fragment>
                        &nbsp;&nbsp;
                        {onlineProtection(this.props)}
                      </React.Fragment>
                    </Link>
                  </div>
                </div>

                <div className="d-md-none d-flex flex-column margin-bottom-half">
                  <Link to="#" id="online-protection-toggler">
                    {onlineProtection(this.props)}
                  </Link>

                  <UncontrolledCollapse
                    toggler="#online-protection-toggler"
                    className="margin-bottom-half drop-down-text"
                  >
                    We provide a safe and secure online enrollment experience and protect the
                    privacy of your personal information. Your payment is secured by Cybersource
                    and therefore, we're unable to process your payment information over the phone.
                  </UncontrolledCollapse>
                </div>

                <div className="d-md-flex flex-md-row align-items-md-start margin-bottom">
                  <EditText
                    className="expiry-date margin-bottom"
                    id="credit_card_expiry_date"
                    field="creditCardExpiryDate"
                    type="text"
                    title="Expiration Date (MM/YYYY)"
                    floatingTitle={true}
                    mask={MaskValidateUtil.monthFullYearMask}
                    validate={MaskValidateUtil.monthYearValidate}
                    validateOnBlur
                    validateOnChange={formState.errors.creditCardExpiryDate}
                  />

                  <div className="d-flex flex-row align-items-end align-items-md-center margin-bottom cvn-container">

                    <div className="edit-text-container input-container" id="cvn">
                      <div id="cc_cvn_container" className="demo-form-group"
                        style={this.state.ccSecurityCodeError ? {border : "2px solid red"} : {}}>
                      </div>
                      { this.state.ccSecurityCodeError ? (
                        <label class="error">Invalid security code</label>
                      ) : null }
                    </div>
                    {/*
                    <EditText
                      required
                      className="cvn-number"
                      id="credit_card_cvn"
                      field="creditCardCVN"
                      type="text"
                      title="CVN#"
                      floatingTitle={true}
                      mask={MaskValidateUtil.cvvMask}
                      validate={MaskValidateUtil.cvvValidate}
                      validateOnChange={formState.errors.creditCardCVN}
                      validateOnBlur
                    />
                    */}

                    <Link
                      to="#"
                      className="d-none d-md-block"
                      onClick={() => {
                        this.props.toggleCvvDialog();
                      }}
                    >
                      {whatThis(formState)}
                    </Link>

                    <Link
                      to="#"
                      className="d-block d-md-none"
                      id="what-is-this-toggler"
                    >
                      {whatThis(formState)}
                    </Link>
                  </div>
                </div>

                <UncontrolledCollapse
                  toggler="#what-is-this-toggler"
                  className="d-md-none margin-bottom drop-down-text"
                >
                  On Visa, MasterCard and Discover cards, the CVV2 value is the
                  last 3 digit number located in the signature area on the back
                  of your credit card, just to the right of your account number.
                  <br />
                  <br />
                  On American Express cards, the CVV2 value is the 4 digit
                  number located on the front of your credit card, just above
                  and to the right of your account number.
                </UncontrolledCollapse>

                <h4 className="margin-bottom">Billing Address</h4>

                <EditText
                  required
                  className="street-address-container margin-bottom"
                  id="street_address"
                  field="streetAddress"
                  type="text"
                  title="Street Address"
                  floatingTitle={true} 
                  validate={MaskValidateUtil.validateStreetAddress}
                  validateOnBlur
                  validateOnChange={formState.errors.streetAddress}
                />

                <div className="recommended-address-container d-flex flex-column edit-text-container">
                    <ul className="recommended-address-list">
                    </ul>
                </div>

                <EditText
                  required
                  className="address-container margin-bottom"
                  id="city"
                  field="city"
                  type="text"
                  title="City"
                  floatingTitle={true}
                  validate={MaskValidateUtil.validateCity}
                  validateOnBlur
                  validateOnChange={formState.errors.city}
                />

                <DropDownSelect
                  required
                  id="select_state"
                  field="state"
                  className="billing-selected-state d-sm-block address-container margin-bottom display-zindex"
                  data={StateList.getStateList()}
                  validate={MaskValidateUtil.validateStateDropdown}
                  validateOnBlur
                  validateOnChange={formState.errors.state}
                />

                <EditText
                  required
                  className="address-container margin-bottom"
                  id="zip_code"
                  field="zipCode"
                  type="text"
                  title="Zip Code"
                  floatingTitle={true}
                  mask={MaskValidateUtil.zipCodeMask}
                  validate={MaskValidateUtil.zipCodeValidate}
                  validateOnBlur
                  validateOnChange={formState.errors.zipCode}
                />
                <div className="button-container row d-flex flex-column flex-md-row">
                  <button
                    className="button-primary col-sm-12 col-md-8 margin-bottom"
                    type="submit"
                    disabled={this.props.sendingToServer || formState.invalid}
                  >
                    Submit Change
                  </button>

                  { window.location.href.toLowerCase().indexOf("mobileappupdatebillinginfo") == -1 ?
                  <button
                    className="button-link  col-sm-12 col-md-4 margin-bottom"
                    type="submit"
                    onClick={this.props.backToHome}
                  >
                    Cancel
                  </button> : null }
  
                </div>

              </React.Fragment>
            )}
          </Form>
              
            <label className="d-md-none note-text note-text-border margin-bottom">
              Note: Updating your billing address does not change your
              physical address.
              { this.props.showUpdateMailingAddress ? 
                (<span>To update your physical address, please{" "}
                <Link to={AppLinks.account.changePhysicalAddress}>click here</Link>.</span>) 
                : 
                (<span></span>)
              }
            </label>

            <hr className="d-none d-md-block" />
            <label className="d-none d-md-block note-text ">
              Note: Updating your billing address does not change your
              physical address. 
              { this.props.showUpdateMailingAddress ? 
                (<span>To update your physical address, please{" "}
                <Link to={AppLinks.account.changePhysicalAddress}>click here</Link>.</span>) 
                : 
                (<span></span>)
              }
            </label>
            <hr className="d-none d-md-block margin-bottom" />
          </div>
          )
         : (
          this.props.userAccountFetchError && (
            <ErrorView>
              {this.props.userAccountFetchError.errorMessage ||
                "Unable to fetch information right now."}
            </ErrorView>
          )
        )}
        {(this.props.sendingToServer || this.props.userAccountDetailsFetching || this.state.loadingForm) && (
          <Loader position="absolute" fullPage={true} zIndex="300" />
        )}
      </div>
    </div>
  );
        }
};

const onlineProtection = props => {
  return (
    <div className="d-flex flex-row link-text">
      Online Protection&nbsp; <i className="fa fa-lock link-icon" />
    </div>
  );
};

const whatThis = formState => {
  return (
    <div
      style={
        formState.errors.creditCardCVN === "CVN#"
          ? { paddingBottom: "18px" }
          : {}
      }
    >
      <div className="d-flex flex-row link-text ">
        What is this? &nbsp;
        <i className="d-sm-none d-md-block fa fa-question-circle-o link-icon" />
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUpdateBillingToken,
    },
    dispatch
  )
});

export default connect(
  mapDispatchToProps
)(MobileAppUpdateBillingView);
