import React, { Component } from "react";

import './PolicyDocLink.css';

class PolicyDocLink extends Component {
  constructor(props) {
    super(props);
  }

    render() {

        var i = this.props.key;
        var doc = this.props.thisDoc;

        return (
            <div>
                { doc.opened ?
                (<div>
                    <a href={doc.is_url_resource ? doc.resource_url : "/file/" + doc.salesforce_type + "/" + doc.id} className="pb-2 opened" target="_blank" key={i}> <i className="fa fa-file-pdf-o fa-2x pr-3" aria-hidden="true"></i><span className="">{doc.name}</span></a>
                </div>)
                :
                (<div>
                    <a onClick={() => this.props.markDocumentOpened(doc.metadata_id)} href={doc.is_url_resource ? doc.resource_url : "/file/" + doc.salesforce_type + "/" + doc.id} className="pb-2 unopened" target="_blank" key={i}> <i className="fa fa-file-pdf-o fa-2x pr-3" aria-hidden="true"></i><span className="">{doc.name}</span></a> <img alt="Unread document" style={{width:"32px", height:"18px"}} src="/assets/images/new-doc@2x.png" />
                </div>)
                }
            </div>
        );
    }
}

export default PolicyDocLink;