import React from "react";
import { Route } from "react-router-dom";
import Footer from "./../modules/common/footer/Footer";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <div>
          <Component {...props} />
          <Footer commonFooter={true}/>
        </div>
      )}
    />
  );
};

export default PublicRoute;
