const AppLinks = {
  home: {
    home: "/",
  },
  claims: {
    fileClaim: "/submitclaim",
    claimStatus: "/viewclaims",
    uploadMedicalRecords: "/submitmr",
    getMobileApp: "/mobileapp",
    updateClaim: "/updateclaim",
  },
  account: {
    viewProfile: "/policy",
    changePhysicalAddress: "/updateaddress",
    changeProfile: "/updatepetparent",
    changePassword: "/changepassword",
    updateBillingInfo: "/updatebillinginfo",
    updateBillingInfoSuccess: "/updatebillinginfosuccess",
    updateReimbursement: "/updatereimbursement",
    mobileAppUpdateBillingInfo: "/mobileappupdatebillinginfo",
    mobileAppUpdateBillingInfoSuccess: "/mobileappupdatebillinginfosuccess",
    mobileAppChangePhysicalAddress: "/updateaddressmobile"
  },

  faq: {
    faq: "/faq",
    planCoverageFAQ: "/faq#plancoverage",
    policyManagementFAQ: "/faq#policymanagement",
    submittingClaimsFAQ: "/faq#submittingclaims",
    referAFriendFAQ: "/faq#referafriend",
  },

  policy: {
    viewPetDetails: "/petdetails",
    addPet: "/addpet",
    mobileAppAddPet: "/mobileappaddpet",
    changePolicyOptions: "/changepremium",
    anniversaryCoverageOptions: "/anniversaryoptions",
    viewPolicyDocuments: "/documents",
    file: "/file/:type/:id",
    eobFileLink: "/file/eob/:id",
    mobileEOBFileLink: "/mobileeob/:id",
    pubfile: "/pubfile/:type/:id",
  },
  // TODO: Can be removedd
  refer: {
    referAFriend: "/referafriend",
  },

  others: {
    postponePayment: "/postponepayment",
    cancelPolicy: "/cancelpolicy",
    RAFTermsAndConditions: "/raftermsandconditions",
    contact: "/contact",
    retryBillingSuccess: "/retrybillingsuccess",
    postCancelPolicy: "/postcancelpolicy",
    cctlogin: "/cctlogin",
    login: "/login",
    eobLink: "/eob/:id",
    arnLink: "/arn",
    contentDocumentLink: "/content/:type",
  },

  externalLinks: {
    MainSiteURL: "https://www.healthypawspetinsurance.com",
    healthypawsFoundation:
      "https://www.healthypawspetinsurance.com/how-we-help",
    telemedicine:
      "https://www.avma.org/resources-tools/animal-health-and-welfare/telehealth-telemedicine-veterinary-practice",
    healthypawsBlog: "https://www.healthypawspetinsurance.com/blog",
    pharmacy:
      "https://www.avma.org/resources/pet-owners/petcare/online-pharmacies",
      privacyPolicy: process.env.REACT_APP_MAINSITE_URL + "/privacypolicy",
      caPrivacyPolicy: process.env.REACT_APP_MAINSITE_URL +"/privacypolicy#AdditionalNoticeToCaliforniaResidents",
    legal: process.env.REACT_APP_MAINSITE_URL + "/legal",
    faceBook: "https://www.facebook.com/gohealthypaws/",
    twitter: "https://twitter.com/GoHealthyPaws",
    instagram: "https://www.instagram.com/gohealthypaws/",
    pinterest: "https://www.pinterest.com/gohealthypaws/",
    youTube: "https://www.youtube.com/user/HealthyPaws",
    authorizeLogo:
      "https://verify.authorize.net/anetseal/?pid=e12fafbb-8ffb-4b37-8f6c-863a230e2a99&rurl=http%3A//www.healthypawspetinsurance.com/",
    bbb:
      "https://www.bbb.org/western-washington/business-reviews/pet-health-plans/healthy-paws-pet-insurance-in-bellevue-wa-22528158",
    naphia: "https://naphia.org/",
    iOSApp:
      "https://itunes.apple.com/us/app/healthy-paws-pet-insurance/id849105071?ls=1&mt=8",
    androidApp:
      "https://play.google.com/store/apps/details?id=com.healthypaws.android",
      termsandconditions:"https://www.healthypawspetinsurance.com/legal",
  },
};

const FAQLinks = {
  FAQPicker:
        process.env.REACT_APP_MAINSITE_URL+"/faq/documents",
  MainSiteURL: "https://healthypawspetinsurance.com",
  MainSiteContactURL: "https://healthypawspetinsurance.com/contact",
  SamplePolicyURL:
    process.env.REACT_APP_MAINSITE_URL + "/policydocuments/sample%20policy.pdf",
  HowtoCancelPageURL: 
    process.env.REACT_APP_MAINSITE_URL + "/pet-insurance-coverage-and-exclusions/how-to-cancel/",
  ClaimForm:
    process.env.REACT_APP_MAINSITE_URL +
    "/policydocuments/healthy%20paws%20pet%20insurance%20claim%20form.pdf",
  SampleMRURL: process.env.REACT_APP_PLAIN_APIURL + "/documents/sample?id=MR",
  hpMobileApps: process.env.REACT_APP_MAINSITE_URL + "/healthy-paws-mobile-app",
  claimExamples: process.env.REACT_APP_MAINSITE_URL + "/claim-examples",
  PIM_52552:
    process.env.REACT_APP_PLAIN_APIURL +
    "/FiledDocuments/SAMPLE_PIM-52552%20(08-19)%20-%20Claims%20Process%20Endorsement.pdf",
  PIM_52784:
    process.env.REACT_APP_PLAIN_APIURL +
    "/FiledDocuments/SAMPLE_PIM-52784%20(10-19)%20-%20Claims%20Process%20Endorsement%20(IL).pdf",
  PIM_52785:
    process.env.REACT_APP_PLAIN_APIURL +
    "/FiledDocuments/SAMPLE_PIM-52785%20(10-19)%20-%20Claims%20Process%20Endorsement.pdf",
  PIM_52906:
    process.env.REACT_APP_PLAIN_APIURL +
    "/FiledDocuments/SAMPLE_PIM-52906%20(11-19)%20-%20Claims%20Process%20Endorsement%20(VA).pdf",
  PIM_52986:
    process.env.REACT_APP_PLAIN_APIURL +
    "/FiledDocuments/SAMPLE_PIM-52986%20(11-19)%20-%20Claims%20Process%20Endorsement%20(WA).pdf",
  PIM_52785_PIM_53538:
    process.env.REACT_APP_PLAIN_APIURL +
    "/FiledDocuments/SAMPLE_PIM-52785%20(10-19)%20-%20Claims%20Process%20EndorsementPIM-53538_IMPORTANT%20POLICYHOLDER%20NOTICE%20(MA).pdf",
};

const PageTitles = {
  //Keys should be all lower cases , as we are converting pathname to lower cases in app.js

  default: "Healthy Paws Pet Insurance",

  "/login": "Customer Center - Sign In | Healthy Paws Pet Insurance",

  "/": "Healthy Paws Pet Insurance  | Home",

  "/submitclaim": "Healthy Paws Pet Insurance  | Submit Claim",
  "/viewclaims": "Healthy Paws Pet Insurance  | My Claims",
  "/submitmr": "Healthy Paws Pet Insurance  | Submit Medical Records",
  "/mobileapp": "Healthy Paws Pet Insurance  | Download Mobile App",
  "/updateclaim": "Healthy Paws Pet Insurance  | Update Claim",

  "/policy": "Healthy Paws Pet Insurance  | My Policy",
  "/updateaddress": "Healthy Paws Pet Insurance  | Update Address",
  "/updatepetparent": "Healthy Paws Pet Insurance  | Update Pet Parent",
  "/changepassword": "Healthy Paws Pet Insurance  | Change Password",
  "/updatebillinginfo": "Healthy Paws Pet Insurance  | Update Billing Info",
  "/updatebillinginfosuccess":
    "Healthy Paws Pet Insurance  | Update Billing Info Success",
  "/updatereimbursement": "Healthy Paws Pet Insurance  | Update Reimbursement",

  "/faq": "Healthy Paws Pet Insurance  | FAQ",

  "/petdetails": "Healthy Paws Pet Insurance  | My Pet Details",
  "/addpet": "Healthy Paws Pet Insurance  | Add a Pet",
  "/changepremium": "Healthy Paws Pet Insurance  | Change Premium",
  "/documents": "Healthy Paws Pet Insurance  | Policy Documents",
  "/file": "Healthy Paws Pet Insurance  | Policy Documents",

  "/referafriend": "Healthy Paws Pet Insurance  | Refer A Friend",

  "/postponepayment": "Healthy Paws Pet Insurance  | Postpone Payment",
  "/cancelpolicy": "Healthy Paws Pet Insurance  | Cancel Policy",
  "/raftermsandconditions":
    "Healthy Paws Pet Insurance  | Refer A Fried Terms and Conditions",
  "/contact": "Healthy Paws Pet Insurance  | Contact Us",
  "/retrybillingsuccess": "Healthy Paws Pet Insurance  | Retry Billing Success",
  "/postcancelpolicy": "Healthy Paws Pet Insurance  | Cancel Policy",
  "/cctlogin": "Customer Center - Sign In | Healthy Paws Pet Insurance",
};

const statesNotInRAF = ["ME", "NV", "NC", "VT", "ND", "WA"];

module.exports = {
  AppLinks,
  FAQLinks,
  PageTitles,
  statesNotInRAF,
};
