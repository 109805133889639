

export class ClaimInfo {
    constructor(clinicID, invoiceNumber, invoiceTotal, dateOfService, examinationFee , relatedClaimId) {
        this.clinicID = clinicID;
        this.invoiceNumber = invoiceNumber;
        this.invoiceTotal = invoiceTotal;
        this.examinationFee = examinationFee;
        this.relatedClaimId = relatedClaimId;
        if(dateOfService) {
            this.dateOfService = new Date(dateOfService).toISOString();
        }
    }

    asAPIData = () => {
        return {
            "ClinicID" : this.clinicID,
            "InvoiceNumber" : this.invoiceNumber,
            "InvoiceTotal" : this.invoiceTotal,
            "DateOfService" : this.dateOfService,
            "ExaminationFee" : this.examinationFee,
            "RelatedClaimID" : this.relatedClaimId
        }
    }
}