import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../loader/Loader";
import closeIcon from "../../../images/ml_Close_Icon.png";

import "./modal-dialog-ml.css";


const modalDialogML = props => {
  const InnerBody = props.modalBody;
  const InnerFooter = props.modalFooter;
  const innerHeader = props.modalHeader;

 const closeBtn=(
  <button className="modal-header-close-button" style={{background:'none',border:'none',position:'relative',top:'15px',cursor:'pointer'}} onClick={props.toggle}>
     <img src={closeIcon} />
    </button>
 );

  return ( 
    <div>
      <Modal
        className="message-modal"
        centered
        isOpen={props.isOpen}
        toggle={props.toggle}
      >
        {props.showLoader && <Loader position="absolute" zIndex="2000"/>}
        <ModalHeader className="message-header" toggle={props.toggle} close={closeBtn} >
          <div className="message-header-container">{innerHeader}</div>
        </ModalHeader>
        <ModalBody className="message-body">{InnerBody}</ModalBody>
        <ModalFooter className="message-footer justify-content-end">
          {InnerFooter}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default modalDialogML;
