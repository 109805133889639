import React, { Component } from "react";

import { Form, Checkbox } from "informed";
import EditText from "../../common/form-fields/edit-text/EditText";
import EditTextV2 from "../../common/form-fields/edit-text/EditTextV2";
import CheckboxControl from "../../common/form-fields/checkbox/Checkbox";
import FaqWidget from "../../common/faq/FaqWidget";
import ErrorView from "../../common/error-view/ErrorView";
import Loader from "../../common/loader/Loader";

import MaskValidateUtil from "../../common/util/MaskValidateUtil";

import { updatePhysicalAddress, validateZipcode } from "../../../api/accounts-api";

import { updateUpdatePhysicalAddressFaqLinks } from "../../common/faq/FaqLink";
import axios from "axios";

import "./NewAddress.css";

export default class NewAddress extends Component {
  constructor() {
    super();
    this.errorText = null;
    this.state = {
      sendingDataToServer: false,
      serverError: false,
      showZipCodeNote: false,
      formApi: null
    };

    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname + '/Step1'
    });
    this.mobAppRoute= window.location.pathname.indexOf('mobile')> -1 ? 'true':'false';
  }

  showHideZipCodeNote = show => {
    this.setState({
      showZipCodeNote: show
    });
  };

  validateAndUpdatePhysicalAddress = values => {

    // Need to check against PO Boxes
    var hasError = false;
    
    var POBoxPattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');

    if (values.streetAddress.match(POBoxPattern)) {

      this.state.formApi.setError(
        "streetAddress",
        "No PO Boxes allowed"
      );

      hasError = true;
    }
    

    if (hasError === false) {
      this.setState({
        sendingDataToServer: true
      });

      validateZipcode(values.zipCode).then(response => {
        if (response) {
          this.updatePhysicalAddress(values);
        } else {
          this.setState({
            sendingDataToServer: false
          });

          this.state.formApi.setError(
            "zipCode",
            "Please enter a valid zip code."
          );
        }
      }).catch(error => {
        this.updatePhysicalAddress(values);
      })
    }
  };

  updatePhysicalAddress = values => {
    this.setState({
      sendingDataToServer: true,
      serverError: false
    });

    var addressInfo = {
      postal_code: values.zipCode,
      address1: values.streetAddress,
      use_for_billing_address: values.billing ? values.billing : false,
      use_for_mailing_address: values.mailing ? values.mailing : false
    };

    updatePhysicalAddress(addressInfo, true)
      .then(response => {
        this.props.setUpdatedPremiumData(addressInfo, response);
        this.setState({
          sendingDataToServer: false
        });

        //For google analytics
        window.dataLayer.push({
          'event': 'Pageview',
          'url': window.location.pathname + '/Step2'
        });

        this.props.stepper.nextStep();
      })
        .catch(error => {
            console.log("****error updating address. error message:");
            console.log(error);
            if (error?.data?.response?.data?.code === "15006") {
                this.errorText = "In the state of Washington, your cross breed pet will be classified as a mixed breed pet of the same weight class. Please contact us at 1-855-898-8991 and ask a representative to assist you with your address change.";
            } else {
                this.errorText =
                    error && error.errorMessage
                        ? error.errorMessage
                        : "Unable to fetch updated premium details due to an error. Please try later.";
            }
        this.setState({
          sendingDataToServer: false,
          serverError: true
        });
        window.scrollTo(0, 0);
      });
  };

  zipCodeNote = () => {
    return (
      <div className="zip-notes-container">
        <i className="fa fa-question-circle-o note-icon note-icon-size" />
        Note: Your policy or premium may change due to your new address.
        Explanation and submission are available on the next page.
      </div>
    );
  };

  setFormApi = api => {
    this.setState({
      formApi: api
    });
  };

  fetchInitialValues = (userData) => {

    if (userData && userData.PhysicalAddress) {
      var responseData = userData.PhysicalAddress;
      return {
        streetAddress: responseData.Street,
        zipCode: responseData.PostalCode,
        billing: false,
        mailing: false
      };
    } else if (userData.postal_code && userData.address1) {
      return {
        streetAddress: userData.address1,
        zipCode: userData.postal_code,
        billing: userData.use_for_billing_address ? userData.use_for_billing_address : false,
        mailing: userData.use_for_mailing_address ? userData.use_for_mailing_address : false
      };
    }

    return {};
  }

  render() {
    return (
      <div className="new-address-container container">
        {!this.props.userAccountDetailsFetching &&
          <Form
            onSubmit={values => {
              this.validateAndUpdatePhysicalAddress(values);
            }}
            getApi={api => {
              this.setFormApi(api);
            }}
            initialValues={this.fetchInitialValues(this.props.formData ? this.props.formData : this.props.userAccountDetails)}
          >
            {({ formState }) => (
              <React.Fragment>
                <div className="row">
                  <div className="col-12 col-lg-8">
                    <h1 className={this.mobAppRoute === 'false' ? "title-container d-flex align-items-center" : "h1_v2 title-container d-flex align-items-center"}  >
                      1. Change Your Physical Address (Residence)
                    </h1>
                    {this.state.serverError && (
                      <ErrorView>{this.errorText}</ErrorView>
                    )}
                    <p className= {this.mobAppRoute==='false'? "mb-0": "mb-0 mobileTextV2"}>
                      Please review your current physical address (your residence)
                      and make changes below.
                    </p>
                    <p className="mb-3">
                      {" "}
                      <strong>
                        Your monthly premium and policy options may change due to
                        your new address.
                      </strong>
                    </p>
                    <div>
                      <div>
                        <p>
                          <strong>
                            Enter your new physical address and select next.
                          </strong>
                        </p>
                        <div className="d-flex flex-column">
                          {this.mobAppRoute ==='false' ? 
                          ( 
                        <div>
                         <EditText
                          className="mb-3 address-container"
                          id="street_address"
                          field="streetAddress"
                          type="text"
                          title="New Street Address"
                          required
                          floatingTitle={true}
                        />
                        <EditText
                          className="mb-3 button-container"
                          id="changeAddress-zipCode"
                          field="zipCode"
                          type="text"
                          title="New Zip Code"
                          required
                          floatingTitle={true}
                          mask={MaskValidateUtil.zipCodeMask}
                          validate={MaskValidateUtil.zipCodeValidate}
                          validateOnBlur
                          validateOnChange={formState.errors.zipCode}
                          onFocus={() => {
                            this.showHideZipCodeNote(true);
                          }}
                          onBlur={() => {
                            this.showHideZipCodeNote(false);
                          }}
                          note={
                            this.state.showZipCodeNote ? this.zipCodeNote() : null
                          }
                        />
                            </div>
                           ) :
                          (
                          <div>
                            <EditTextV2
                          className="mb-3 address-container"
                          id="street_address"
                          field="streetAddress"
                          type="text"
                          title="New Street Address (No PO Boxes)"
                          required
                          floatingTitle={true}
                        />
                        <EditTextV2
                          className="mb-3 button-container"
                          id="changeAddress-zipCode"
                          field="zipCode"
                          type="text"
                          title="New Zip Code"
                          required
                          floatingTitle={true}
                          mask={MaskValidateUtil.zipCodeMask}
                          validate={MaskValidateUtil.zipCodeValidate}
                          validateOnBlur
                          validateOnChange={formState.errors.zipCode}
                          onFocus={() => {
                            this.showHideZipCodeNote(true);
                          }}
                          onBlur={() => {
                            this.showHideZipCodeNote(false);
                          }}
                          note={
                            this.state.showZipCodeNote ? this.zipCodeNote() : null
                          }
                        />
                          </div>
                          )
                        
                          }
                          <div className="m-1 d-block d-md-none">
                            <Checkbox
                              id="mailing_checkbox"
                              field="mailing"
                              className="ios8-switch m-3"
                            />
                            <label
                              htmlFor="mailing_checkbox"
                              className="remember-me"
                            >
                              Use same address for Mailing.
                            </label>
                          </div>
                          {/*
                          <div className="m-1 d-block d-md-none">
                            <Checkbox
                              id="billing_checkbox"
                              field="billing"
                              className="ios8-switch m-3"
                            />
                            <label
                              htmlFor="billing_checkbox"
                              className="remember-me"
                            >
                              Use same address for Billing.
                            </label>
                          </div>
                          */}
                          <div className="mb-3 check-container d-none d-md-block">
                            <CheckboxControl
                              className="mb-2"
                              id="mailing"
                              field="mailing"
                              label="Use same address for mailing address."
                            />
                            {/*
                            <CheckboxControl
                              id="billing"
                              field="billing"
                              label="Use same address for billing address."
                            />
                            */}
                          </div>
                          <button
                            disabled={formState.errors.zipCode}
                            className={this.mobAppRoute ==='false' ? "button-primary address-container d-none d-md-block mb-3": "button-primaryV2 address-container d-none d-md-block mb-3"}
                            type="submit"
                          >
                            Next {this.mobAppRoute ==='false' ? "": <img className="next-arrow-circle-right-custom" src="../../assets/images/next_icon.svg" alt="next" />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.mobAppRoute ==='false' ? <div className="col-lg-4 mt-3 mb-3">
                    <FaqWidget
                      title="Physical Address FAQs"
                      mobileTitle="FAQs About Physical Address Change"
                      faqLink={updateUpdatePhysicalAddressFaqLinks}
                    />
                  </div>
                  : <div style={{padding:'20px'}}></div>
                  }
                  
                </div>
                <div className={this.mobAppRoute==='false'? "sticky-bottom d-md-none": "sticky-bottomv2 d-md-none"}>
                  <div className="col-md-12 p-0 col-lg-4">
                    <div className="d-md-none text-center mb-1 text-uppercase">
                      <span className="font-weight-bold">Step 1</span> of 2
                    </div>
                    <button
                      className={this.mobAppRoute ==='false' ? "button-primary": "button-primaryV2"} 
                      type="submit"
                    >
                      Next {this.mobAppRoute ==='false' ? "": <img className="next-arrow-circle-right-custom" src="../../assets/images/next_icon.svg" alt="next" />}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Form>
        }
        {(this.state.sendingDataToServer || this.props.userAccountDetailsFetching) && <Loader position="absolute" fullPage={true} />}
      </div>
    );
  }
}
