import React, { useState } from "react";
import { FAQLinks } from "../../../constants/app-links";
import Util from "../../common/util/util";
 

export default class PreExistingQuestion {


  static preExsisitingCondition = (policyFormUrl, stateAbbreviation) => {



    
    var content = '';
    var policyFormLink = '';
      var faqPickerUrl = FAQLinks.FAQPicker;

      //const [isVisible, setIsVisible] = useState(false);;
      var policyFormDocumentUrl = '';
     
 

    if (policyFormUrl) {
      policyFormLink = `<a style="font-weight:600" href="` + policyFormUrl + `" target="_blank" rel="noopener noreferrer">
          To review your policy, please click here.
        </a>`
        policyFormDocumentUrl = policyFormUrl;
        
    } else {
      policyFormLink = `<a style="font-weight:600" href="` + faqPickerUrl + `" target="_blank" rel="noopener noreferrer">
          To review your policy, please click here.
        </a>`

        policyFormDocumentUrl = faqPickerUrl;
    }  

    switch (stateAbbreviation) {
      case 'DE':
        content = `<p>Some states have a specific definition for the term, "pre-existing condition".</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/DE/Sample - PI-57983_1023_DE WFIC Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>
          <p>The Healthy Paws plan helps protect your pet against accidents and illnesses that may occur after any applicable waiting period(s).</p>
          <p>${policyFormLink}</p>
           <div  class="preExistingQuestionExampleContainer faqQuestion" style="margin-left:0px !important">
               Click here for examples of pre-existing conditions.            
            </div>`
        break;
      case "LA":
        content = `<p>Some states have a specific definition for the term, "pre-existing condition".</p>
            <p>
                <a class="orange underline" style="font-weight:600"
                href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/LA/Sample - PI-57956_1023_Amendatory Endorsement.pdf"
                target="_blank">
                Refer to the State Amendatory Statement for your state for more information.
                </a>
            </p>
            <p>The Healthy Paws plan helps protect your pet against accidents and illnesses that may occur after any applicable waiting period(s).</p>
            <p>${policyFormLink}</p>
             <div  class="preExistingQuestionExampleContainer faqQuestion" style="margin-left:0px !important">
               Click here for examples of pre-existing conditions.            
            </div>`;
            break;
        case "MD":
            content = `<p>A pre-existing condition means:<br/>
            An Illness or condition which developed or redeveloped prior to 
            the pet policy effective date or an injury, or recurrence of 
            an injury, as a result of an accident that occurred prior to 
            the pet policy effective date; or any condition or complication 
            resulting from an illness that developed, or any injury as a 
            result of an accident that occurred prior to the pet policy 
            effective date.</p> 
            <p>${policyFormLink}</p>
             <div  class="preExistingQuestionExampleContainer faqQuestion" style="margin-left:0px !important">
               Click here for examples of pre-existing conditions.            
            </div>`;
            break;
      case "ME":
        content = `<p>Some states have a specific definition for the term, "pre-existing condition".</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/ME/LD-50780_1022_WFIC Maine Amendatory_SAMPLE.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>
          <p>The Healthy Paws plan helps protect your pet against accidents and illnesses that may occur after any applicable waiting period(s).</p>
          <p>${policyFormLink}</p>
           <div  class="preExistingQuestionExampleContainer faqQuestion" style="margin-left:0px !important">
               Click here for examples of pre-existing conditions.            
            </div>`;
        break;
      case "MS":
        content = `<p>Some states have a specific definition for the term, "pre-existing condition".</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/MS/Sample - PI-57955_0923_Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>
          <p>The Healthy Paws plan helps protect your pet against accidents and illnesses that may occur after any applicable waiting period(s).</p>
          <p>${policyFormLink}</p>
           <div  class="preExistingQuestionExampleContainer faqQuestion" style="margin-left:0px !important">
               Click here for examples of pre-existing conditions.            
            </div>`;
        break;
      case "NE":
        content = `<p>Some states have a specific definition for the term, "pre-existing condition".</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/NE/Sample - LD-50785_0823_NE 3.0 - Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>
          <p>The Healthy Paws plan helps protect your pet against accidents and illnesses that may occur after any applicable waiting period(s).</p>
          <p>${policyFormLink}</p>
           <div  class="preExistingQuestionExampleContainer faqQuestion" style="margin-left:0px !important">
               Click here for examples of pre-existing conditions.            
            </div>`;
        break;
      case "NH":
        content = `<p>Some states have a specific definition for the term, "pre-existing condition".</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/NH/Sample - LD-50787_0923_Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>
          <p>The Healthy Paws plan helps protect your pet against accidents and illnesses that may occur after any applicable waiting period(s).</p>
          <p>${policyFormLink}</p>
           <div  class="preExistingQuestionExampleContainer faqQuestion" style="margin-left:0px !important">
               Click here for examples of pre-existing conditions.            
            </div>`;
        break;
      case "WA":
        content = `<p>Some states have a specific definition for the term, "pre-existing condition".</p>
          <p>
              <a class="orange underline" style="font-weight:600"
              href="` + process.env.REACT_APP_MAINSITE_URL + `/PolicyDocuments/StateDocuments/State/WA/Sample - LD-50798_0923_WFIC Amendatory Endorsement.pdf"
              target="_blank">
              Refer to the State Amendatory Statement for your state for more information.
              </a>
          </p>
          <p>The Healthy Paws plan helps protect your pet against accidents and illnesses that may occur after any applicable waiting period(s).</p>
          <p>${policyFormLink}</p>
           <div  class="preExistingQuestionExampleContainer faqQuestion" style="margin-left:0px !important">
               Click here for examples of pre-existing conditions.            
            </div>`;
        break;
      default:
            content = `<p>A pre-existing condition means:<br/>
            An Illness or condition which developed or redeveloped prior to 
            the pet policy effective date or an injury, or recurrence of 
            an injury, as a result of an accident that occurred prior to 
            the pet policy effective date; or any condition or complication 
            resulting from an illness that developed, or any injury as a 
            result of an accident that occurred prior to the pet policy 
            effective date.</p>
            <p>
            The Healthy Paws plan helps protect your pet against accidents and 
            illnesses that may occur after any applicable waiting period(s).
            </p>
            <p>${policyFormLink}</p>
             <div  class="preExistingQuestionExampleContainer faqQuestion" style="margin-left:0px !important">
               Click here for examples of pre-existing conditions.            
            </div>`;
      }
      return (
        <div>
        <div className="faqAnswer" dangerouslySetInnerHTML={{ __html: content }}></div>
              <div>

                  <div class="faqAnswer preExistingAnswerExampleContainer" style={{ display:"none" }}>
                      <p>
                          <span class="bold" style={{ fontWeight: "bold" }}>Example 1: </span>Your dog has been itchy ever since you adopted her from the shelter. Over the past month she's been in several times to see her veterinarian for her itchy skin, loss of fur, and hot spots. Your veterinarian is treating the symptoms, but has not diagnosed the condition yet.
                      </p>
                      <p>
                          You enroll her. A couple of months later your dog has another flare-up of the same itchy skin symptoms and you take her to see her veterinarian, who prescribes additional medication.
                      </p>
                      <p class="bold grayBorder" style={{ fontWeight: "bold", marginLeft:0 }}>
                          This would be considered a pre-existing condition, since the clinical signs and symptoms of the condition existed before enrollment.
                      </p>
                      <p>
                          <span class="bold" style={{ fontWeight: "bold" }}>Example 2: </span>Your cat is playing with a feather toy and ingests some of the string. Your veterinarian induces vomiting to expel the string and sends your cat home with some medicine to calm his stomach.
                      </p>
                      <p>
                          The accident inspires you to get pet insurance. Several months later your cat eats a hair tie and needs surgery to remove the foreign body.
                      </p>
                      <p class="bold grayBorder" style={{ fontWeight: "bold" }}>
                          This would be considered a separate accident and not related to the previous foreign body. They are both acute, isolated events.
                      </p>
                     
                      <p>
                  
                          <a class="orange underline"
                              href={policyFormDocumentUrl}
                              target="_blank">
                              To review your policy, please click here.

                          </a>
                      </p>
                  </div>
              </div>
      </div>
    );
  };

  static preExsisitingConditionConsideration = (policyFormUrl) => {
    return (
      <div className="faqAnswer">
        <p>
          If you are aware that your pet has a congenital condition before you
          enroll in pet insurance, this condition would be considered
          pre-existing.{" "}
        </p>
        <p>
          There are many types of congenital conditions that do not become
          apparent until sometime after birth. The Healthy Paws plan can cover these
          types of congenital conditions as long as there were no clinical signs
          or symptoms present before enrollment or during any applicable waiting periods.{" "}
        </p>
        <p>
        { policyFormUrl ? (
          <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
          To review your policy, please click here.
          </a>
        ) : (
          <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                            To review your policy, please click here.
          </a>
        )}
        </p>
      </div>
    );
  };

  static preExsisitingConditionCoverage = (policyFormUrl) => {
    return (
      <div className="faqAnswer">
        <p>
          If your pet's complete medical records review shows no history of a
          cruciate ligament problem, and no history of limping, the injury is eligible 
          for coverage. However, if your pet has shown lameness, or a full/partial tear{" "}
          <span style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: "bold" }}>before enrollment</span>
          </span>{" "}
          or during any applicable{" "}
          <span style={{ fontStyle: "italic" }}>
            <span style={{ fontWeight: "bold" }}>waiting period</span>
          </span>
          &nbsp;as defined above, on either side{" "}
          <u>prior to enrollment</u>, the
          other side is also{" "}
          <u>excluded</u> from coverage.<br/>
          The cruciate ligament is the only bilateral* exclusion in the policy.
        </p>
        <p style={{ fontStyle: "italic" }}>
          *Bilateral: having or relating to two sides; affecting both sides.
        </p>
        <img
          alt="cruciate_ligament"
          style={{ width: "241px", height: "196px" }}
          src="https://www.healthypawspetinsurance.com/ContentSourceFiles/FAQ/faq-images/cruciate_ligament@1.5x.png"
        />
        <p>
            <a className="preExistingExample" onClick={Util.toggleCruciateLigamentExamples} style={{cursor: "pointer", fontWeight:"600"}}>Click here
          &nbsp;for examples of the bilateral cruciate exclusion.</a>
        </p>
        
        <div id="preexisting-cruciate-examples-container" style={{ display: "none" }}>
          <div>
            <p>
              <strong>Example 1:</strong> Your dog has no history of limping or
              lameness prior to enrollment. Six months after enrolling, he comes
              home limping on his <strong>right leg</strong> after playing with
              his friends at doggie daycare. He is seen by the veterinarian and
              diagnosed with a partial anterior cruciate ligament (ACL) tear on
              his <strong>right leg</strong>, which will require surgery to
              repair.
            </p>
            <p className="grayBorderLine" style={{ fontWeight: "bold" }}>
                Because your dog did not show signs or symptoms of any cruciate
                injury prior to enrollment or during the waiting period,
                coverage is available, subject to the policy’s terms and conditions.
            </p>
            <p>
              Eight months after this incident, your dog is diagnosed with a
              complete cranial cruciate ligament (CCL) tear in his{" "}
              <strong>left leg</strong> after he was seen for limping after a
              long session of chasing his favorite Frisbee. He will need surgery
              to repair this injury.
            </p>
            <p className="grayBorderLine" style={{ fontWeight: "bold" }}>
                Because your dog did not show signs or symptoms of any cruciate
                injury prior to enrollment or during the waiting period,
                coverage is available for the second tear, which occurred on
                the other leg, subject to the policy’s terms and conditions. 
            </p>
          </div>

          <div>
            <p>
              <strong>Example 2:</strong> Before enrolling in pet insurance,
              your dog has surgery on his <strong>right leg</strong> for a CCL
              tear that was recently diagnosed. This surgery was quite
              expensive and has inspired you to enroll in pet insurance to be
              protected against similarly large medical bills in the future.
              Twelve months after enrolling, you notice your dog is limping on
              his <strong>left leg</strong> after a long walk. Your veterinarian
              diagnoses your dog with a CCL tear in his{" "}
              <strong>left leg</strong> that will need surgical repair.
            </p>
            <p className="grayBorderLine" style={{ fontWeight: "bold" }}>
                Because your dog showed signs or symptoms of a cruciate injury
                prior to enrollment, this would be considered a pre-existing
                condition. Due to the bilateral exclusion, the injury noted after
                enrollment on the left leg would be excluded from coverage, subject
                to the policy’s terms and conditions.
            </p>
          </div>
        </div>
        <p>
        { policyFormUrl ? (
          <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
          To review your policy, please click here.
          </a>
        ) : (
          <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                            To review your policy, please click here.
          </a>
        )}
        </p>
      </div>
    );
  };
}
