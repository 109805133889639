import React from 'react';
import { AppLinks, FAQLinks } from "../../../constants/app-links";

export default class ManagingPolicy {
   
    static addPet = () => {
        return (
            <div className="faqAnswer">
                <p>
                    Congratulations on a new addition to the family!
                     <br />
                    <br />
                    You can use the <a style={{fontWeight:"600"}} href={AppLinks.policy.addPet} target="_blank" rel="noopener noreferrer">Add a Pet</a> feature in the Customer Center to add your new pet to your policy.
                </p>
            </div>
        )
    }
    static annualDeductible = (policyFormUrl) => {
        return (
            <div className="faqAnswer">
                <p>
                  The annual deductible is by "coverage term." For example, if your policy became
                  effective on May 25, 2022, your coverage term would last through May 24, 2023.
                </p>
                <p>You can view examples of claim reimbursement calculations <a style={{fontWeight:"600"}} href={FAQLinks.claimExamples} target="_blank" rel="noopener noreferrer">here</a>.</p>
                <p>To maximize the annual deductible, we recommend that you submit all claims pertaining to accidents, illnesses and injuries.</p>
                <p>
                { policyFormUrl ? (
                  <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                  To review your policy, please click here.
                  </a>
                ) : (
                  <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                  </a>
                )}
                </p>
            </div>
        )
    }
    static afterEnroll = (policyFormUrl) => {
        return (
            <div className="faqAnswer" >
                <p>
                  Yes. You can lower your deductible and raise your reimbursement level as long
                  as you have not submitted any claims. This would result in lower out-of-pocket
                  expenses and a higher reimbursement, and correspondingly, a higher monthly
                  premium.
                </p>
                <p>
                  If you have submitted any claims, you will only be able to lower your
                  reimbursement and raise your deductible.
                </p>
                <p>You can view your reimbursement and deductible options by clicking <a style={{fontWeight:"600"}} href={AppLinks.policy.changePolicyOptions} target="_blank" rel="noopener noreferrer">Pet Policies</a> in your customer center to change your policy options, subject to the
                    policy terms and conditions.
                </p> 
                <p>
                { policyFormUrl ? (
                  <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                  To review your policy, please click here.
                  </a>
                ) : (
                  <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                  </a>
                )}
                </p>
            </div>

        )
    }
    static howToLowerPremiums = (policyFormUrl) => {
        return (
            <div className="faqAnswer" >
                <p>
                  If you would like to decrease the cost of your monthly premium, you can do this
                  by lowering your reimbursement level and/or raising your deductible.
                </p>
                <p>
                  You can view your reimbursement and deductible options by clicking&nbsp; 
                  <a style={{fontWeight:"600"}} href={AppLinks.policy.changePolicyOptions} target="_blank" rel="noopener noreferrer">Pet Policies</a>
                  &nbsp;in your customer center to change your policy options, subject to the
                  policy terms and conditions.
                </p>
                <p>
                { policyFormUrl ? (
                  <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                  To review your policy, please click here.
                  </a>
                ) : (
                  <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                  </a>
                )}
                </p>
            </div>

        )
    }
}