import React, { Component } from "react";
import Dropzone from "react-dropzone";
import {
  FilesThumbnailView,
  FileUploadInfoTogglerDesktop,
  FileUploadInfoTogglerMobile
} from "./FilesThumbnailView";
import FilePreview from "./../file-preview/FilePreview";
import ModalDialog from "./../../common/modal-dialog/ModalDialog";

import "./FileUpload.css";

import { uploadFile } from "../../../api/upload-api";
class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewView: false,
      show: false,
      windowWidth: document.body.getBoundingClientRect().width,
      windowHeight: document.body.getBoundingClientRect().height,
        isUploadErrorModalOpen: false,
      isDeleteModalOpen: false,
      files: []
    };
    if (this.props.type === "Claim") {
        this.uploadIcon = "../../assets/images/icon_upload-2x.png";
      if (this.props.classification === "MissingDocs") {
        this.header = "Upload Your Missing Invoice";
      } else {
        this.header = "Upload Your Itemized Invoice";
      }
    } else if (this.props.type === "MR") {
      this.uploadIcon = "../../assets/images/Upload_mr.svg";
      if (this.props.classification === "UploadMR") {
        this.header = "Upload Your Medical Records or Other Documentation";
        this.shortHeader = "Upload Your Medical Records";
      } else if (this.props.classification === "MissingDocs") {
        this.header = "Upload Your Missing Claim Documents";
      } else {
        this.header = "Upload Your Medical Records";
      }
      }
      this.uploadErrorMessageBody = "";
    this.rejectedFiles = "";
    this.zoomedImageIndex = -1;
    // this.fileURLs = ;
  }

  updateDimensions = () => {
    this.setState({
      windowWidth: document.body.getBoundingClientRect().width,
      windowHeight: document.body.getBoundingClientRect().height
    });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.petPolicyNo !== state.petPolicyNo) {
      return {
        ...state,
        show: props.show,
        files: [],
        petPolicyNo: props.petPolicyNo,
        previewView: false
      };
    }
    if (props.show !== state.show) {
      return {
        ...state,
        show: props.show
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.updateDimensions();
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handleFileEditClick = () => {
    if (this.state.files.length > 0) {
      this.setState({
        thumbnailView: true
      });
    }
  };

  resetFiles = () => {
    this.setState({ files: [] });
  };
 

  // File is accepted by Dropbox.
  onFileDropped = (acceptedFiles, rejectedFiles) => {
       
    var acceptedFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/tiff", "application/pdf"];
    var rejectedFileNames = "";
  
    // Try to upload file.
    /*
    if (rejectedFiles.length > 0) {
      let fileNames = "";
      rejectedFiles.forEach(file => {
        fileNames += file.name + " , ";
      });
      fileNames = fileNames.substr(0, fileNames.length - 2);
      this.rejectedFiles = fileNames;
      this.setState({ isUploadErrorModalOpen: true });
    }
    */

      console.log('accepted files');
      console.log(acceptedFiles);
      console.log('rejected files');
      console.log(rejectedFiles);

    acceptedFiles.forEach(function(file, index) {
      if (acceptedFileTypes.includes(file.type)) {
        file.uploading = true;
        file.uploadError = false;
        file.timeStamp = new Date().toISOString();
      } else {
        rejectedFileNames += file.name + " , ";
          acceptedFiles.splice(index, 1); 
      }
    });
      //if rejected based on size
      let hasFilesTooBig = false;
      if (rejectedFiles.length > 0) {
          let fileNames = "";
          rejectedFiles.forEach(file => {
              if (file.size > Number(2.2e+7)) {
                  
                  console.log('file size too big');
                  console.log(file.size);
                  rejectedFileNames += file.name + ' ';
                  hasFilesTooBig = true;
                  return;
              }
          });
          //fileNames = fileNames.substr(0, fileNames.length - 2);
          //this.rejectedFiles = fileNames;
          //rejectedFileNames = rejectedFileNames.substr(0, rejectedFileNames.length - 2);
          this.uploadErrorMessageBody = 'Selected file exceeds file size limit for files:';
          this.rejectedFiles = rejectedFileNames;
          this.setState({ isUploadErrorModalOpen: true });
          //return;
          
      }

      //process old way
      if (!hasFilesTooBig) {
         
          this.uploadErrorMessageBody = 'Error uploading following file(s):';
          rejectedFileNames = rejectedFileNames.substr(0, rejectedFileNames.length - 2);
          this.rejectedFiles = rejectedFileNames;

          if (this.rejectedFiles) {

              this.setState({ isUploadErrorModalOpen: true });
          }
      }


    this.setState(prevState => ({
      thumbnailView: true,
      files: [...prevState.files, ...acceptedFiles]
    }));

    acceptedFiles.forEach(file => {
        let fileToUpload = file;  
      uploadFile(this.props.type, this.state.petPolicyNo, file)
        .then(response => {
          let files = [...this.state.files];
          let index = files.findIndex(x => x.name === fileToUpload.name && x.timeStamp === fileToUpload.timeStamp);
          let file = files[index];
          file.uploading = false;
          file.uploadedURL = response.data;
          files[index] = file;
          if (this.props.optional === true) {
            //Optional Steps have "Skip now" button , so after successful upload of 1 file , need to hide the skip now button
            //On every successful upload of the file , we are sending the files URL to Parent component
            let fileURLs = this.state.files.map(file => file.uploadedURL);
            this.props.setUploadFiles(fileURLs, true);
          }
          this.setState({
            files: files
          });
        })
        .catch(error => {
          let files = [...this.state.files];
          let index = files.findIndex(x => x.name === fileToUpload.name && x.timeStamp === fileToUpload.timeStamp);
          let file = files[index];
          file.uploading = false;
          file.uploadError = true;
          files[index] = file;
          if (this.props.optional === true) {
            //Optional Steps have "Skip now" button , so after successful upload of 1 file , need to hide the skip now button
            //On every successful upload of the file , we are sending the files URL to Parent component
            let fileURLs = this.state.files.map(file => file.uploadedURL);
            this.props.setUploadFiles(fileURLs, true);
          }
          this.setState({
            files: files
          });
        });
    });
  };

  handlePreviewClick = e => {
    const fileNameToZoom = e.target.parentElement.id.split(":")[0];
    const index = this.state.files.findIndex(
      file => file.name === fileNameToZoom
    );
    let files = [...this.state.files];
    this.zoomedImageIndex = index;
    this.zoomImageSrc = files[index].preview;
    this.fileType = files[index].type;
    this.index = index + 1;
    this.setState({ previewView: true });
  };

  handleNextStep = () => {
    //check if the files are uploading
    //If any of the file is uploading return false
    const fileUploading =
      this.state.files.filter(file => file.uploading === true).length > 0
        ? true
        : false;
    if (fileUploading) {
      return;
    }
    //Return only successful upload URLs
    let fileURLs = this.state.files
      .filter(file => file.uploadError === false)
      .map(file => file.uploadedURL);
    this.props.setUploadFiles(fileURLs, false);
  };

  openDeleteFileModal = e => {
    if (e && e.target.parentElement.id !== "") {
      const fileNameToDelete = e.target.parentElement.id.split(":")[0];
      this.deleteFileIndex = this.state.files.findIndex(
        file => file.name === fileNameToDelete
      );
      const fileToDelete = this.state.files[this.deleteFileIndex];
      //If the file is not been uploaded , we can delete without opening the modal
      if (fileToDelete.uploadError) {
        this.updateFiles(true);
        return;
      } else {
        this.setState(prevState => ({
          isDeleteModalOpen: !prevState.isDeleteModalOpen
        }));
        return;
      }
    } else {
      this.setState(prevState => ({
        isDeleteModalOpen: !prevState.isDeleteModalOpen
      }));
    }
  };

    openUploadErrorModal = () => {
      
    this.setState(prevState => ({
      isUploadErrorModalOpen: !prevState.isUploadErrorModalOpen
    }));
  };

    updateFiles = fromErrorFile => {
       
    const index = this.deleteFileIndex;
    let array = this.state.files;
    array.splice(index, 1);

    //This will work only for mobile
    if (Number(this.deleteFileIndex) === this.zoomedImageIndex) {
      this.zoomedImageIndex = -1;
    }
    //If delete call from error file , just return after delete
    if (fromErrorFile) {
      this.setState({
        files: array
      });
      return;
    }
    if (this.state.previewView === true) {
      this.setState(prevState => ({
        previewView: false,
        files: array,
        isDeleteModalOpen: !prevState.isDeleteModalOpen
      }));
    } else {
      this.setState(prevState => ({
        files: array,
        isDeleteModalOpen: !prevState.isDeleteModalOpen
      }));
    }

    //If user deletes the last file , parent has to be updated with files list
    if (array.length === 0) {
      this.props.setUploadFiles([], true);
    }
  };

  closePreview = () => {
    this.zoomedImageIndex = -1;
    this.setState({ previewView: false });
  };

  render() {
    return (
      <div id="file-upload-component">
        <ModalDialog
          isOpen={this.state.isDeleteModalOpen}
          toggle={this.openDeleteFileModal}
          modalHeader={"Are you sure?"}
          modalBody={<DeleteModalBody />}
          modalFooter={
            <DeleteModalFooter
              updateFiles={this.updateFiles.bind(this)}
              toggleModal={this.openDeleteFileModal}
            />
          }
        />

        <ModalDialog
          isOpen={this.state.isUploadErrorModalOpen}
          toggle={this.openUploadErrorModal}
          modalHeader={<UploadErrorModalHeader />}
                modalBody={<UploadErrorModalBody fileNames={this.rejectedFiles} uploadErrorMessage={this.uploadErrorMessageBody} />}
          modalFooter={
            <UploadErrorModalFooter toggleModal={this.openUploadErrorModal} />
          }
        />

        {this.state.show && (
          <FileUploadInfoTogglerMobile type={this.props.type} />
        )}

        {this.state.show && this.state.files.length !== 0 ? (
          <div
            className="file-upload-overlay d-none d-sm-block"
            onClick={() => this.handleNextStep()}
            
          />
        ) : null}

        {this.state.previewView ? (
          <React.Fragment>
            <FilePreview
              files={this.state.files.filter(
                file => file.uploadError === false
              )}
              zoomedImageIndex={this.zoomedImageIndex}
              deleteFile={this.openDeleteFileModal.bind(this)}
              closePreview={this.closePreview}
            />
          </React.Fragment>
        ) : null}

        {this.state.show && this.state.files.length !== 0 ? (
          <React.Fragment>
            <div id="file-upload2" style={{ width: "100%", zIndex: 100 }}>
              <FilesThumbnailView
                files={this.state.files}
                instance={this}
                type={this.props.type}
              />
            </div>
          </React.Fragment>
        ) : null}

        {this.state.show && this.state.files.length === 0 ? (
          <React.Fragment>
            <Dropzone
              // accept="image/png,image/jpg,image/jpeg,image/tiff,application/pdf"
              
              maxSize={Number(2.2e+7)}
              id="file-upload"
              style={{ width: "100%" }}
              onDrop={(acceptedFiles, rejectedFiles) => {
                this.onFileDropped(acceptedFiles, rejectedFiles);
              }}
                    >
            

              <Upload
                uploadIcon={this.uploadIcon}
                header={this.header}
                shortHeader={this.shortHeader}
              />
            </Dropzone>
            <div style={{ marginLeft: 14 }}>
              <FileUploadInfoTogglerDesktop type={this.props.type} />
            </div>

          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
export default FileUpload;

const Upload = props => {
  return (
      <div className="upload border-color-solidgreen2">
      <div className="d-none d-md-flex justify-content-center row">
        <img
                  className="upload-icon upload-icon-green"
          src={props.uploadIcon}
          alt="Invoice Icon"
        />
        <h4 className="col-12 drag-text-header text-secondary text-center p-0 mb-0">
          {props.header}
        </h4>
        <span className="col-12 drag-text text-center">
          Simply drag and drop files{" "}
        </span>
        <span
          className="col-12 text-center"
          style={{ fontSize: "16px", fontWeight: 200, marginBottom: "3px", color: "#038062" }}
        >
          or
        </span>
        <button className="btn-select">Select Files</button>
      </div>
      <div
        style={{ margin: "5px 10px" }}
        className="d-md-none d-sm-flex d-flex flex-row flex-nowrap"
      >
        <img
                  className="upload-icon d-flex align-items-center upload-icon-green"
          src={props.uploadIcon}
          alt="Invoice Icon"
        />
              <h4 className="text-secondary d-flex align-items-center text-color-solidgreen3">
          Click Here to {props.shortHeader ? props.shortHeader : props.header}
        </h4>
      </div>
    </div>
  );
};

const DeleteModalBody = () => (
  <div className="d-flex flex-column">
    <p className="mb-0">Do you really want to delete this file?</p>
  </div>
);

const DeleteModalFooter = ({ updateFiles, toggleModal }) => {
  return (
    <div>
      <button
        className="button-primary-plus close-button mr-2 mb-2"
        onClick={() => updateFiles(false)}
      >
        Yes
      </button>
      <button
        className="button-primary-plus close-button mr-1"
        onClick={toggleModal}
      >
        No
      </button>
    </div>
  );
};

const UploadErrorModalHeader = () => <div>Upload File Error</div>;

const UploadErrorModalBody = ({ fileNames, uploadErrorMessage }) => (
  <div>
    <p>
            <strong>{uploadErrorMessage}</strong>
    </p>
    <p>{fileNames}</p>
  </div>
);

const UploadErrorModalFooter = ({ toggleModal }) => {
  return (
    <div className="d-flex">
      <button
        className="file-upload-modal-button btn btn-secondary btn-block ml-10"
        onClick={toggleModal}
      >
        OK
      </button>
    </div>
  );
};
