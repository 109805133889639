import * as types from "../../constants/action-types";
import { statesNotInRAF } from "./../../constants/app-links";

const initialState = {
  userAccountDetails: null,
  topAlertMessage: [],
  error: null,
  needsRefresh: false,
  fetchingAccount: false,
  retryBillingStatus: false,
  enableRAF: true,
  canUserEdit: true,
  showTopAlertBanner: false,
};

// Handles image related actions
export default function(state = initialState, action) {
  switch (action.type) {
    case types.ACCOUNT_FETCH_REQUEST:
      return {
        ...state
      };
    case types.ACCOUNT_FETCH_SUCCESS:
      return {
        ...state,
        fetchingAccount: false,
        needsRefresh: false
      };
    case types.SET_USER_ACCOUNT_FETCHING_STATE:
      return {
        ...state,
        fetchingAccount: true
      };
    //Sets account details from login success
    case types.SET_ACCOUNT_DETAILS:
      let enableRAF = true;
      if (action.userDetails && action.userDetails.PhysicalAddress) {
        //If the user state is not participated in referal program
        //hide all RAF related UI items
        if (
          statesNotInRAF.indexOf(action.userDetails.PhysicalAddress.State) > -1
        ) {
          enableRAF = false;
        }
      }
      return {
        ...state,
        enableRAF: enableRAF,
        userAccountDetails: action.userDetails
      };
    case types.ACCOUNT_FETCH_ERROR:
      return {
        ...state,
        error: action.error.data,
        fetchingAccount: false
      };
    case types.SET_ACCOUNT_DATA_REFRESH:
      return {
        ...state,
        needsRefresh: true
      };
    case types.SET_RETRY_BILLING_STATUS:
      return {
        ...state,
        retryBillingStatus: action.status
      };

    case types.SET_USER_EDIT_STATE:
      return {
        ...state,
        canUserEdit: action.state
      };
      case types.SET_TOP_ALERT_MESSAGE:
          //enable global message if available
       
          if (action.alertMessage != null && action.alertMessage.length >= 1) {
              state.showTopAlertBanner = true;
              console.log("*****show global message*****");
          } else {
              console.log("*****do not show global message*****");
              state.showTopAlertBanner = false;
          }
           
      return {
        ...state,
        topAlertMessage: action.alertMessage
      };
      case types.DISMISS_TOP_ALERT_NOTIFICATION:
          state.showTopAlertBanner = false;
      return {
        ...state,
        showTopAlertBanner: false
      };
    default:
      return state;
  }
}
