export default class MonthList {
    static monthArray = [
        {
            displayName: "January",
            value: 0
        },
        {
            displayName: "February",
            value: 1
        },
        {
            displayName: "March",
            value: 2
        },
        {
            displayName: "April",
            value: 3
        },
        {
            displayName: "May",
            value: 4
        },
        {
            displayName: "June",
            value: 5
        },
        {
            displayName: "July",
            value: 6
        },
        {
            displayName: "August",
            value: 7
        },
        {
            displayName: "September",
            value: 8
        },
        {
            displayName: "October",
            value: 9
        },
        {
            displayName: "November",
            value: 10
        },
        {
            displayName: "December",
            value: 11
        }
    ];

    static getMonthList = (placeholder) => {
        let list = [...MonthList.monthArray];
        if (placeholder) {
            list.unshift({
                displayName: placeholder,
                value: null
            })
        }
        return list;
    }
}