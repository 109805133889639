import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppLinks } from "../../constants/app-links";
import ReferFriend from "../common/refer-friend/ReferFriend";

import { getPolicyDocs, markDocumentOpened } from "../../api/policy-docs-api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getDocuments, 
    setDocumentsDataRefresh,
    setDocumentOpened } from "../../store/actions/documents-actions";

import Loader from "../common/loader/Loader";
import ErrorView from "../common/error-view/ErrorView";
import PolicyDocLink from "./PolicyDocLink";

import './PolicyDocs.css'

class PolicyDocs extends Component {
    constructor() {
        super();
        this.state = {
            primaryDocs: [],
            additionalInfoDocs: [],
            fetchingDocuments: true
        }
        this.fetchingError = "";
    }
    _isMounted = false;


    markDocumentOpenedHandler = (docMetaDataId) => {
        if (!this.props.CCTLoggedIn) {
            markDocumentOpened(docMetaDataId);
            this.props.actions.setDocumentOpened(docMetaDataId);
            // this.props.actions.setDocumentsDataRefresh();
        }
    }
    

    componentDidMount = () => {
        let data;
        this._isMounted = true;
        window.scrollTo(0, 0);

        this.props.actions.getDocuments();
        /*
            .then(res => {
                data = res;
                let urls = data.policy_documents.filter(detail => {
                    return detail.cateogy_name !== "Additional Policy Documents" && detail.salesforce_type !== "claim" && detail.salesforce_type !== "medical record"
                })
                let additionalInfo = data.policy_documents.filter(detail => {
                    return detail.cateogy_name === "Additional Policy Documents" && detail.salesforce_type !== "claim" && detail.salesforce_type !== "medical record"
                })
                if (this._isMounted) {
                    this.setState({
                        primaryDocs: urls,
                        additionalInfoDocs: additionalInfo,
                        fetching: false
                    })
                }
            })
            .catch(error => {
                this.fetchingError = error.errorMessage ? error.errorMessage : "Unable to show the documents. Please try later.";
                this.setState({ fetching: false });  
                window.scrollTo(0,0);              
            })
        */
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        // fill docs and additionalDocs with documents from state
        let docs = this.props.documents.filter(detail => {
            return detail.cateogy_name !== "Additional Policy Documents" && detail.salesforce_type !== "claim" && detail.salesforce_type !== "medical record"
        });

        let additionalDocs = this.props.documents.filter(detail => {
            return detail.cateogy_name === "Additional Policy Documents" && detail.salesforce_type !== "claim" && detail.salesforce_type !== "medical record"
        });

        // const docs = this.state.primaryDocs;
        // const additionalDocs = this.state.additionalInfoDocs;
        return (
            <React.Fragment>
                <div className="container policy-docs">
                
                    <div className="row">

                        <div className="col-12">
                            <h1 className="mt-3 mt-lg-0"><span className="d-none d-md-inline">View </span>Policy Documents</h1>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-8">
                            {
                                this.props.fetchingDocuments ? (
                                    <div style={{height: 300}}>
                                        <Loader />
                                    </div>
                                ) : (
                                        <React.Fragment>
                                            {additionalDocs.length || docs.length ? (
                                                <React.Fragment>
                                                    <div className="row pt-3 pt-lg-0">
                                                <div className="col-12">
                                                    <strong className="d-block">Primary Policy Documents</strong>
                                                    <p>Click the links below to retrieve your Healthy Paws Pet Insurance policy documents. Please visit <Link className = "faq-text" to={AppLinks.faq.faq}>frequent questions</Link> if you have any questions about your policy.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="content-wrapper">
                                                        {docs.map((doc, i) => {
                                                            return (
                                                                //<a href={doc.is_url_resource ? doc.resource_url : "/file/" + doc.salesforce_type + "/" + doc.id} className={doc.opened ? "d-block pb-2 d-flex" : "d-block pb-2 d-flex bold"} target="_blank" key={i}> <i className="fa fa-file-pdf-o fa-2x pr-3" aria-hidden="true"></i><span className="">{doc.name}</span></a>
                                                                <PolicyDocLink thisDoc={doc} markDocumentOpened={this.markDocumentOpenedHandler} key={i}></PolicyDocLink>
                                                            )
                                                        })}
                                                        <strong className="d-none d-md-block mt-2">Note:</strong>
                                                        <p className="d-none d-md-block">Please download <a href="https://get.adobe.com/reader/" target="_blank" rel="noopener noreferrer">Adobe Acrobat Reader</a> if you are having trouble opening policy documents.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pt-3">
                                                <div className="col-12">
                                                    <strong className="d-block">Additional Information</strong>
                                                    <p>Just click the links below to retrieve additional information about our #1 customer-rated pet health plan. If there is any additional information you feel would be helpful, please visit <Link className = "faq-text" to={AppLinks.faq.faq} >frequent questions</Link>.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12  mb-4">
                                                    <div className="content-wrapper">
                                                        {additionalDocs.map((doc, i) => {
                                                            return (
                                                                //<a href={doc.is_url_resource ? doc.resource_url : "/file/" + doc.salesforce_type + "/" + doc.id} className={doc.opened ? "d-block pb-2 d-flex" : "d-block pb-2 d-flex bold"} target="_blank" rel="noopener noreferrer" key={i}> <i className="fa fa-file-pdf-o fa-2x pr-3" aria-hidden="true"></i><span className="">{doc.name} {doc.opened ? "opened" : "not opened"}</span></a>
                                                                <PolicyDocLink thisDoc={doc} markDocumentOpened={this.markDocumentOpenedHandler} key={i}></PolicyDocLink>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                                </React.Fragment>
                                            ) : (
                                                    <div style={{ height: 300 }}>
                                                        {this.fetchingError ?
                                                            <ErrorView> {this.fetchingError} </ErrorView> :
                                                            <div>No documents available to show you right now. Please try later</div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        </React.Fragment>
                                    )
                            }
                        </div>
                        {/* <div className="col-lg-4  d-none d-lg-block mb-4">
                            <ReferFriend />
                        </div> */}
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
    documents: state.documents.documents,
    fetchingDocuments: state.documents.fetchingMessages,
    documentsFetchSuccess: state.documents.documentsFetchSuccess,
    documentsFetchError: state.documents.documentsFetchError,
    CCTLoggedIn: state.login.CCTLoggedIn
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
      { getDocuments, 
        setDocumentsDataRefresh,
        setDocumentOpened },
      dispatch
    )
});  

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PolicyDocs);
  