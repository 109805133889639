import React from "react";

import "./Loader.css";

const Loader = props => {
  const loaderPosition = props.position ? props.position : "relative";
  const loaderHeight = props.height ? props.height : "30px";
  const loaderWidth = props.width ? props.width : "30px";
  const classes = props.className || "";
  const zIndex = props.zIndex ? props.zIndex : 5;
  const height = props.fullPage
    ? document.body.getBoundingClientRect().height
    : "100%";

  return (
    <div
      style={{ position: loaderPosition, zIndex: zIndex  , height:height }}
      className={"d-flex align-items-center justify-content-center container-fluid loader-container "+classes}
    >
      <div
        style={{ height: loaderHeight, width: loaderWidth }}
        className={"spinner" + (props.fullPage ? " full-page" : "")}
      />
    </div>
  );
};

export default Loader;
