import React, { Component } from "react";
import {
  isTablet,
  isIOS,
  isChrome,
  isMobile,
  isIE,
  isEdge,
  ConsoleView
} from "react-device-detect";
import { getContentDocument } from "../../../api/policy-docs-api";

import Loader from "../loader/Loader";
import OopsLostPage from "../../oops/OopsLostPage";

class SalesForceContentLink extends Component {
  constructor() {
    super();
    this.state = {
      invalidLink: false,
      fetchingDoc: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      fetchingDoc: true
    });
    
    let docType;
    docType = this.props.match.params.type;
     //alert('id: '+this.props.match.params.id);
    getContentDocument("", docType)
        .then(data => {             
        this.showDoc(data);
        this.setState({fetchingDoc:false})
      })
      .catch(err => {
        this.setState({
          invalidLink: true,
          fetchingDoc: false
        });
      });
  }

  renderMessageForIE = () => {
    return (
      <div className="container">
        <h1>Please wait, Your file is being downloaded.</h1>
      </div>
    );
  };

  getGuid =  () =>{
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  };

  showDoc = data => { 
 
    console.log('getContentDocument::showDoc - got content document api response. data:');
    console.log(data);

    if (
      (data.content_documents && data.content_documents.length === 0) ||
      (data.content_documents[0].file_type !== "application/pdf" &&
        data.content_documents[0].file_type !== "text/html")
    ) {
      console.log('getContentDocument::showDoc - invalid state of document');
      this.setState({
        invalidLink: true
      });
    }
    console.log('filetype: '+data.content_documents[0].file_type);
    if (
      data.content_documents &&
      data.content_documents.length > 0 &&
      data.content_documents[0].file_type === "application/pdf"
    ) {

      //convertbase64 at services/api 
      var file2 = data.content_documents[0].file_data;
      

      var file = this.converBase64toBlob(
        data.content_documents[0].file_data,
        "application/pdf"
      );
      //For IOS Mobile Viewing Blob has issue
      //So we are using fileReader API to show PDF
      if ((isTablet || isMobile) && isChrome && isIOS) { 

        var reader = new FileReader();
        var out = file;
        reader.onloadend = function() {
          window.location.href = reader.result;
        };

        reader.readAsDataURL(out);

      } else if (isIE) { 
        
        console.log('getContentDocument::showDoc isIE or isEdge');

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {

          console.log('isIE');
          var fileGuid = this.getGuid();
          var fileName = fileGuid+".pdf";
           
          window.navigator.msSaveOrOpenBlob(file, fileName);//todo: fix differently? this.props.match.params.id + ".pdf");   

        }else if(window.navigator && window.navigator.msSaveOrOpenBlob === undefined){

          //chromium edge
          console.log('getContentDocument::showDoc chromium Edge browser download');
          
          var fileUrl = URL.createObjectURL(file);
           
          var link = document.createElement("a");
          link.href = fileUrl;
      
          //link.click();
          window.open(fileUrl);
          //link.download = "test.pdf";
          //link.click();
          //link.target = "_blank";
          //link.click();
          setTimeout(function() {
            //     document.body.removeChild(link)
            window.URL.revokeObjectURL(fileUrl);
          }, 100);

        }
      } else {
        
        console.log('getContentDocument::showDoc chrome browser dowload');

        var fileUrl = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.href = fileUrl;
        link.click();
        window.open(fileUrl);
        setTimeout(function() {
          //     document.body.removeChild(link)
          window.URL.revokeObjectURL(fileUrl);
        }, 100);
      }
    } 
    
   if (
      data.content_documents &&
      data.content_documents.length > 0 &&
      data.content_documents[0].file_type === "text/html"
    ) {
      var decodedString = window.atob(data.content_documents[0].file_data);
      document.write(decodedString);
    }
  };

  converBase64toBlob = (content, contentType) => {
    contentType = contentType || "";
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  };



  render() {
    var componentToRender = null;
    if (this.state.fetchingDoc) {
      componentToRender = (
        <Loader position="absolute" zIndex={1000} className="op1" />
      );
    } else {
      if ((isIE || isEdge) && !this.state.invalidLink) {
        componentToRender = this.renderMessageForIE();
      } else {
          
        componentToRender = <OopsLostPage />;
      }
    }
    return <React.Fragment>{componentToRender}</React.Fragment>;
  }
}



export default SalesForceContentLink;
