import { axiosInstanceOldAPI, axiosInstanceWithAuth } from "./axios";

export const getMessagesByAccount = () => {
  let url = "/Messages/GetMessageByAccount";

  return axiosInstanceWithAuth
    .post(url, {})
    .then(response => {
      if(response && response.data && response.data.Items){

        var modifiedMessages = response.data.Items.map(message => {
          if (!(message.title && message.title.length > 0 && message.MessageType)) {

            if (message.MessageType === "claim" && message.MessageSubType) {
              
              if (message.MessageSubType === "Open")
                message.title = "Claim received."
              else if (message.MessageSubType === "Waiting for Info")
                message.title = "Your action needed!"
              else if (message.MessageSubType === "Closed With Payment" || message.MessageSubType === "Closed Without Payment - Denied" || message.MessageSubType === "Closed Without Payment - Covered")
                message.title = "Claim completed."
              else
                message.title = ""
            } else if (message.MessageType === "account")
              message.title = "Happy birthday!"
            else if (message.MessageType === "billing")
              message.title = "Update billing info!"
          }
          
          return message;
        })
        
        return modifiedMessages;
      } else 
        return new Array(0);
    })
    .catch(function(err) {
      let error = {
        errorCode: -1000,
        errorDescription: "Not able to retrieve the messages now.",
        errorMessage: "Unable to show messages right now. Please try again later."
      };
      return Promise.reject(error);
    });
};

export const getMessagesCountByAccount = () => {
  let url = "/Messages/MessagesCountsByAccount";
  return axiosInstanceWithAuth
    .post(url, {})
    .then(response => {
      return {
        TotalMessages: 6,
        UnreadMessages: 4
      };
    })
    .catch(function(err) {
      let error = {
        errorCode: -1000,
        errorDescription: "Not able to retrieve the messages now.",
        errorMessage: "Not able to retrieve the messages now.Try again later"
      };
      return Promise.reject(error);
    });
};

export const markMessageAsRead = (messageId) => {
  let url = "/Messages/MarkMessageRead";
  return axiosInstanceWithAuth
    .post(url, {id:messageId})
    .then(response => {
      return response;
    })
    .catch(function(err) {
      let error = {
        errorCode: -1000,
        errorDescription: "Not able to retrieve the messages now.",
        errorMessage: "Not able to retrieve the messages now.Try again later"
      };
      return Promise.reject(error);
    });
};

export const deleteMessageAPI = messageId => {
  let url = "/Messages/DeleteMessage";
  return axiosInstanceWithAuth
    .post(url, {id:messageId})
    .then(response => {
      return response;
    })
    .catch(function(err) {
      let error = {
        errorCode: -1000,
        errorDescription: "Not able to delete the messages now.",
        errorMessage: "Not able to delete the message now.Try again later"
      };
      return Promise.reject(error);
    });
};



export const getResponseCode = () => {
  let url = "/Messages/Reasoncodes";
  return axiosInstanceWithAuth
    .get(url, {})
    .then(response => {
      return response;
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
};

export const sendContactInfo = contactsInfo => {
  let url = "/Mail/Contact";
  return axiosInstanceWithAuth
    .post(url, contactsInfo)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      return Promise.reject(err);
    });
};