import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setLoginState,
  setChangePassword
} from "../../store/actions/login-actions";
import { authenticate, resetPassword } from "./../../api/auth-api";
import { getAccountInfo } from "./../../api/accounts-api";
import { updateUserAccountInfo } from "./../common/util/AccountsUtil";

import ModalDialog from "../common/modal-dialog/ModalDialog";
import { Login, CreateResetPassword, PasswordSent } from "./Login.component";
import "./LoginPage.css";
import { isReturnStatement } from "typescript";

export const SIGN_IN = 0;
export const FORGOT_PASSWORD = 1;
export const CREATE_PASSWORD = 2;
export const PASSWORD_SUCESSFULL = 3;

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: SIGN_IN,
      type: "password",
      fetching: false,
      loginError: false,
      showRememberMeDialog: false,
      loginErrorText: ''
    };

    if (this.props.location.state && this.props.location.state.userMailId) {
      this.userMailId = this.props.location.state.userMailId;
    }

  }
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.props.location.state && this.props.location.state.emailId) {
      this.props.history.replace({
        pathname: this.props.location.pathname,
        state: {}
      });
    }
  }

  toggleRememberMeDialog = () => {
    if (this._isMounted) {
      this.setState({
        showRememberMeDialog: !this.state.showRememberMeDialog
      });
    }
  };

  changeState = newState => {
    if (this._isMounted) {
      this.setState({
        show: newState,
        loginError: false,
        type: "password",
        fetching: false
      });
    }
  };

  signInHandler = values => {
    if (this._isMounted) {
      this.setState({
        fetching: true
      });
    }

    authenticate({
      username: values.email,
      password: values.password,
      rememberMe: values.rememberMe
    })
      .then(response => {
        const changePassword = response.change_password === "True";
        this.props.actions.setChangePassword(changePassword);
        getAccountInfo().then(accountResponse => {
          //As we depends on some properties if user account details
          //We are setting login to true only after successful useraccount fetch
          if (accountResponse) {
            updateUserAccountInfo(accountResponse);
            this.props.actions.setChangePassword(changePassword);
            this.props.actions.setLoginState(true);
          } else {
            this.props.actions.setChangePassword(false);
            this.props.actions.setLoginState(false);
          }
          if (this._isMounted) {
            this.setState({
              fetching: false
            });
          }
        });
      })
      .catch(error => {
        if (error.response != null && error.response.data.error == "user_not_found") {
          this.setState({ loginErrorText: "Could not find an account with that email address." })
        } else {
          this.setState({ loginErrorText: "Invalid email or password. Please correct." })
          this.props.actions.setChangePassword(false);
        }
        this.props.actions.setLoginState(false);
        if (this._isMounted) {
          this.setState({
            fetching: false,
            loginError: true
          });
        }
      });
  };

  createPasswordHandler = userName => {
    if (this._isMounted) {
      this.setState({
        fetching: true
      });
    }

    resetPassword(userName)
      .then(() => {
        if (this._isMounted) {
          this.setState({
            fetching: false
          });
        }
        this.changeState(PASSWORD_SUCESSFULL);
      })
      .catch(error => {
        if (this._isMounted) {
          this.setState({
            fetching: false,
            loginError: true
          });
        }
      });
  };

  resetErrorHandler = e => {
    if (this._isMounted) {
      this.setState({
        loginError: false
      });
    }
  };

  render() {
    // this is where we get the old route - from the state of the redirect
    //If the user not logged in , comes from different route
    //After successful login-in redirects to previous route
    // const { from } = this.props.location.state || {
    //   from: {
    //     pathname: "/"
    //   }
    // };
    // if (this.props.loggedIn) {
    //   return <Redirect to={from} />;
    // }
 
    //Whenever Login , Lands on home page



    var redirectUrl = "";
    //authenticated redirect urls
    var redirectUrls = new Array();
    redirectUrls.push("/content/signature");
    redirectUrls.push("/documents");
    redirectUrls.push("/eob/");
    redirectUrls.push("/arn/");

    if(this.props.location.state !== undefined && this.props.location.state.from !== undefined){
      console.log("***************** this.props.location.state.from.pathname:");
      console.log(this.props.location.state.from.pathname);
    }


    var hasRedirectUrl = false;
    //check if redirect matches approved redirect urls
    for(var i=0; i < redirectUrls.length; i++){

      if(this.props.location.state !== undefined && this.props.location.state.from !== undefined){
        
      if(redirectUrls[i] === this.props.location.state.from.pathname ){

        //matching urls
        redirectUrl = this.props.location.state.from.pathname;
        hasRedirectUrl = true;
        //force break
        i = redirectUrls.length-1;
      } 
      else if(this.props.location.state.from.pathname.includes(redirectUrls[i])){
        
        //matching urls
        redirectUrl = this.props.location.state.from.pathname;
        hasRedirectUrl = true;
        //force break
        i = redirectUrls.length-1;
      }

    }

    }

    console.log("has redirect url: "+hasRedirectUrl);
    console.log("matching redirect url: "+redirectUrl);

    if (this.props.loggedIn) {
      if (this.props.changePassword) {
        return <Redirect to="/changepassword" />;
      } else if (this.props.returnURL) {
        const { from } = this.props.location.state || {
          from: {
            pathname: "/"
          }
        };
        return <Redirect to={from} />;

      }else if(hasRedirectUrl){

        return <Redirect to={redirectUrl}/>;

      }

      return <Redirect to="/" />;
    }

    return (
      <div className="login-main d-flex flex-column align-items-center justify-content-center">
        {this.rememberMeDialog()}
        <img
          alt="healthypaws"
          className="logo"
          src="/assets/images/login/logo-login.png"
        />
        <div className="main-content d-flex flex-column align-items-center justify-content-center">
          <div className="title"> Customer Center </div>{" "}
          <div className="sub-title"> Manage Your Pet's Policy</div>
          {this.state.show === SIGN_IN && (
            <Login
              userMailId={this.userMailId}
              toggleRememberMeDialog={this.toggleRememberMeDialog}
              changeState={this.changeState}
              loginError={this.state.loginError}
              loginErrorText={this.state.loginErrorText}
              fetching={this.state.fetching}
              type={this.state.type}
              signInClick={this.signInHandler.bind(this)}
              resetErrorHandler={this.resetErrorHandler.bind(this)}
            />
          )}{" "}
          {this.state.show === FORGOT_PASSWORD && (
            <CreateResetPassword
              changeState={this.changeState}
              createPasswordClick={this.createPasswordHandler}
              resetErrorHandler={this.resetErrorHandler}
              loginError={this.state.loginError}
              fetching={this.state.fetching}
              title={"Forgot Password"}
              description={
                "Enter the email address registered with your Healthy Paws account, and we will send you a temporary password."
              }
            />
          )}{" "}
          {this.state.show === CREATE_PASSWORD && (
            <CreateResetPassword
              changeState={this.changeState}
              createPasswordClick={this.createPasswordHandler}
              resetErrorHandler={this.resetErrorHandler}
              loginError={this.state.loginError}
              fetching={this.state.fetching}
              title={"Create Password"}
              description={
                "First time logging in? Enter the email address registered with your Healthy Paws account, and we will create a temporary password."
              }
            />
          )}{" "}
          {this.state.show === PASSWORD_SUCESSFULL && (
            <PasswordSent changeState={this.changeState} />
          )}{" "}
        </div>{" "}
      </div>
    );
  }

  rememberMeDialog = () => {
    return (
      <ModalDialog
        isOpen={this.state.showRememberMeDialog}
        toggle={this.toggleRememberMeDialog}
        modalHeader={"Remember Me"}
        modalBody={
          <div>
            Please turn off this option if you are using a public or shared
            computer or device.
          </div>
        }
        modalFooter={
          <button
            type="submit"
            className="button-primary-plus close-button"
            onClick={this.toggleRememberMeDialog}
          >
            Close
          </button>
        }
      />
    );
  };
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  loggedIn: state.login.loggedIn,
  changePassword: state.login.changePassword,
  returnURL: state.login.returnURL
});

//Wrap all the actions with dispatcher
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setLoginState,
      setChangePassword
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
