import React from "react";
import { Link } from "react-router-dom";
import AvatarEditor from "../../common/avatar-editor/AvatarEditor";
import { AppLinks } from "../../../constants/app-links";

export const RenderCarouselImages = ({ petsDetails, selectPetHandler }) => {
  if (petsDetails.length === 0) {
    return null;
  }

  if (petsDetails.length === 1) {
    return (
      <React.Fragment>
        <RenderActiveImage petDetail={petsDetails[0]} />
      </React.Fragment>
    );
  }

  if (petsDetails.length === 2) {
    return (
      <React.Fragment>
        <RenderSmallImage
          petDetail={petsDetails[1]}
          selectPetHandler={selectPetHandler}
        />
        <RenderActiveImage
          petDetail={petsDetails[0]}
          selectPetHandler={selectPetHandler}
        />
        <AddaPetButton petsLength={petsDetails.length} />
      </React.Fragment>
    );
  }

  if (petsDetails.length > 2) {
    return (
      <React.Fragment>
        <RenderSmallImage
          selectPetHandler={selectPetHandler}
          petDetail={petsDetails[petsDetails.length - 1]}
        />
        <RenderActiveImage petDetail={petsDetails[0]} />
        <RenderSmallImage
          petDetail={petsDetails[1]}
          selectPetHandler={selectPetHandler}
        />
      </React.Fragment>
    );
  }
};

const RenderSmallImage = ({ petDetail, selectPetHandler }) => {
  let imageClassName = "carousal-image-item";
  if (!petDetail.ImageAvailable) {
    imageClassName = "carousal-image-item no-image";
    petDetail.PetImageURL =
      petDetail.Type === "Cat"
        ? "../../assets/images/add-cat-small.svg"
        : "../../assets/images/add-dog-small.svg";
  }
  return (
    <React.Fragment>
      <div onClick={() => selectPetHandler(petDetail.PetID)}>
        <div className={imageClassName}>
          <img
            alt={petDetail.Name}
            className="carousal-image"
            src={petDetail.PetImageURL}
          />
        </div>
        <span
          className="d-none d-sm-block text-truncate"
          style={{
            color: "$header-Color",
            textTransform: "capitalize",
            width: 140,
            fontWeight: 600,
            // textShadow: "2px 2px 8px rgba(0,0,0,0.6)"
          }}
        >
          {petDetail.Name}
        </span>
      </div>
    </React.Fragment>
  );
};

let RenderActiveImage = ({ petDetail }) => {
  return (
    <React.Fragment>
      <AvatarEditor
        petDetails={petDetail}
        image={petDetail.ImageAvailable ? petDetail.PetImageURL : null}
        defaultImage={
          petDetail.Type === "Cat"
            ? "../../assets/images/add-cat.png"
            : "../../assets/images/add-dog.png"
        }
      />
    </React.Fragment>
  );
};

export const RenderPetName = ({ petsLength, activePet, rotateImages , goToPetPolicyPage }) => {
  return (
    <div className="corousal-bottom d-flex flex-row">
      {petsLength > 1 ? (
        <div>
          <i
            style={{ textShadow: "2px 2px 8px rgba(0,0,0,0.6)" }}
            className="icons fa fa-angle-left"
            onClick={() => rotateImages()}
          />
        </div>
      ) : null}

      <div className="corousal-controls">
        <div className="pet-name-carousal">
          <div onClick={(evt) => goToPetPolicyPage(activePet.PetID , evt)} className="d-flex align-items-center justify-content-center pl-3 pr-3">
            <span>See</span>
            <span
              className="text-truncate"
              style={{ textTransform: "capitalize", maxWidth: 150 }}
            >
              {activePet.Name + "'s"}
            </span>
            <span>policy</span>
          </div>
        </div>
      </div>
      {petsLength > 1 ? (
        <div>
          <i
            className="icons fa fa-angle-right"
            onClick={() => rotateImages(true)}
          />
        </div>
      ) : null}
    </div>
  );
};

export const AddaPetButton = ({ petsLength }) => {
  let classNames = "d-none d-sm-block add-pet-button";
  if (petsLength === 1) {
    classNames += " add-pet-button-one";
  }

  if (petsLength === 2) {
    classNames += " add-pet-button-two";
  }
  return (
    <React.Fragment>
      <Link to={AppLinks.policy.addPet}>
        <div className={classNames}>
          <div className="add-pet-button-text">
            Add a Pet <br />
            <i
              style={{ fontSize: 24 }}
              className="fa fa-plus"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};
