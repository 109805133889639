import React, { PureComponent } from "react";
import "./Stepper.css";

class Step extends PureComponent {
    render() {
        return (
            <React.Fragment>
                {React.Children.map(this.props.children, (step) => {
                    return React.cloneElement(step, { ...this.props })
                })}
            </React.Fragment>
        );
    };
}

const StepHeader = (props) => (
    <div className="stepper-header container-fluid">
        <div className="container">
            <div><span className="font-italic">{props.title}</span> / <span className="step-details"><span className="font-weight-bold">Step {props.step} </span>of {props.total}</span></div>
        </div>
    </div>
);

export default class Stepper extends PureComponent {
    static Step = (props) => <Step {...props} />;

    constructor(props) {
        super(props);

        const { hasSuccess, children } = props;
        this.state = {
            index: 0,
            hasSuccess: !!hasSuccess,
            totalSteps: hasSuccess ? children.length - 1 : children.length
        };
    }

    _nextStep = () => {
        if (this.state.index !== this.props.children.length - 1) {
            this.setState(prevState => (
                {
                    index: prevState.index + 1
                }
            ));
        }
        window.scrollTo(0,0);
    }

    _prevStep = () => {
        if (this.state.index !== 0) {
            this.setState(prevState => (
                {
                    index: prevState.index - 1
                }
            ));
        }
        window.scrollTo(0,0);
    }

    _goToFirst = () => {
        this.setState(prevState => ({
            index: 0
        }));
        window.scrollTo(0,0);
    }

    _showHeader = () => {
        const { hasSuccess, index, totalSteps } = this.state;
        if (hasSuccess) {
            if (index > totalSteps - 1) {
                return false;
            }
        }
        return true;
    }

    render() {
        const { index, totalSteps } = this.state;
        const { headerTitle } = this.props;
        return (
            <div className="stepper">
                {this._showHeader() ? <StepHeader step={index + 1} title={headerTitle} total={totalSteps} /> : null}
                {
                    React.Children.map(this.props.children, (step, _index) => {
                        if (_index === index) {
                            return React.cloneElement(step, {
                                stepper: {
                                    currentIndex: index,
                                    nextStep: this._nextStep,
                                    previousStep: this._prevStep,
                                    goToFirst: this._goToFirst,
                                    totalSteps: totalSteps
                                }
                            })
                        }
                        return null;
                    })
                }
            </div>
        );
    }
}