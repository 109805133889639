import React, { Component } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import { AppLinks } from "../../../constants/app-links";
import ErrorView from "./../error-view/ErrorView";
import {
  markMessageAsRead,
  deleteMessageAPI
} from "./../../../api/messages-api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getMessagesByAccount,
  setMessageAsRead,
  deleteMessage,
  setExpandMessage
} from "./../../../store/actions/messages-action";
import Loader from "./../loader/Loader";
import Pagination from "./MessagesPagination";
import "./Messages.css";
import Util from "../../common/util/util";
import MessageUtil from "./MessageUtil";

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageNo: 1,
      fetchingMessages: false,
      getMessagesSuccess: false,
      getMessagesFailed: false
    };
    this.messagesToShow = [];
    this.messagesPerPage = 5;
    this.totalPages = 1;
    this.getMessagesError = {};
    this.selectedMessage = {};
  }

  componentDidMount() {
    this.props.actions.getMessagesByAccount();
  }

  setMessageToRead = messageId => {
    const message = this.props.messages.filter(
      message => message.id === messageId
    )[0];
    if (message.unread) {
      this.props.actions.setMessageAsRead(messageId);
      // Fire and Forget
      markMessageAsRead(messageId);
    }
  };

  expandMessage = messageId => {
    this.setMessageToRead(messageId);
    this.props.actions.setExpandMessage(messageId);
  };

  deleteMessage = (messageId, currentPageMessageLength) => {
    const message = this.props.messages.filter(
      message => message.id === messageId
    )[0];

    if (message.unread) {
      this.props.actions.setMessageAsRead(messageId);
    }

    if (
      this.state.currentPageNo === this.totalPages &&
      currentPageMessageLength === 1
    ) {
      const currentPageNo = this.state.currentPageNo - 1;
      this.getCurrentMessages(currentPageNo);
    }

    this.props.actions.deleteMessage(messageId);
    // Fire and Forget
    deleteMessageAPI(messageId);
  };

  getCurrentMessages = currentPageNo => {
    this.setState({
      currentPageNo: currentPageNo
    });
  };

  previousPage = event => {
    if (this.state.currentPageNo === 1) {
      event.nativeEvent.stopImmediatePropagation();
      return;
    }
    const currentPageNo = this.state.currentPageNo - 1;
    this.getCurrentMessages(currentPageNo);
    event.nativeEvent.stopImmediatePropagation();
  };

  nextPage = event => {
    if (this.state.currentPageNo === this.totalPages) {
      event.nativeEvent.stopImmediatePropagation();
      return;
    }
    const currentPageNo = this.state.currentPageNo + 1;
    this.getCurrentMessages(currentPageNo);
    event.nativeEvent.stopImmediatePropagation();
  };

  setMessage = messageId => {
    this.setMessageToRead(messageId);
    const selectedMessage = this.props.messages.find(x => x.id === messageId);
    this.props.setSelectedMessage(selectedMessage);
    this.props.setToggleMenuFalse();
  };

  render() {
    this.messagesToShow = this.props.messages.slice(
      (this.state.currentPageNo - 1) * this.messagesPerPage,
      this.state.currentPageNo * this.messagesPerPage
    );

    if (this.props.messages.length % this.messagesPerPage === 0)
      this.totalPages = this.props.messages.length / this.messagesPerPage;
    else
      this.totalPages =
        Math.floor(this.props.messages.length / this.messagesPerPage) + 1;

    return (
      <React.Fragment>
        <div id="messages-container">
          {this.props.fetchingMessages ? (
            <div style={{ height: 300 }}>
              <Loader />
            </div>
          ) : (
            <React.Fragment>
              {this.props.messageFetchSuccess && (
                <React.Fragment>
                  {this.props.messages.length > 0 ? (
                    <React.Fragment>
                      <div className="d-none d-lg-block">
                        <MessagesListDesktop
                          messagesToShow={this.messagesToShow}
                          setMessage={this.setMessage}
                          deleteMessage={this.deleteMessage}
                          setMessageToRead={this.setMessageToRead}
                        />
                      </div>

                      <div className="d-block d-lg-none">
                        <MessagesListMobile
                          messagesToShow={this.messagesToShow}
                          expandMessage={this.expandMessage}
                          deleteMessage={this.deleteMessage}
                          sideDrawerClose={this.props.sideDrawerClose}
                          retryBillingHandler={this.props.retryBillingHandler}
                        />
                      </div>
                      <Pagination
                        previousPage={this.previousPage}
                        nextPage={this.nextPage}
                        currentPageNo={this.state.currentPageNo}
                        totalPages={this.totalPages}
                      />
                    </React.Fragment>
                  ) : (
                    <div
                      id="messages-empty"
                      className="d-flex justify-content-center align-items-center"
                    >
                      There are no messages.
                    </div>
                  )}
                </React.Fragment>
              )}

              {this.props.messagesFetchError && !this.props.fetchingMessages && (
                <div className="d-flex justify-content-center align-items-start align-items-lg-center message-error">
                  <ErrorView className=" message-error-view">
                    <p>{this.props.messagesFetchError.errorMessage}</p>
                  </ErrorView>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const MessagesListDesktop = props => {
  const messageList = props.messagesToShow;
  return (
    <React.Fragment>
      {messageList
        ? messageList.map((message, index) => {
            //const unreadMessageClass = message.unread ? "unread-message" : "";
            const messageStatusClass = message.unread
              ? "message-status-green un-read"
              : "message-status-green read";
            return (
              <div
                key={index}
                className="message-item"
                onClick={evt => props.setMessage(message.id)}
              >
                <div className={messageStatusClass} />
                <div style={{ width: 300 }} className="message-content">
                  <div className="message-header floar-right d-flex justify-content-between">
                    <span
                      className={
                        "message-modal-title " +
                        (MessageUtil.isMessageImportant(message.title)
                          ? "text-danger "
                          : "text-dark")
                      }
                    >
                      {message.title}
                    </span>
                    <span className="message-date-time">
                      {message.time_ago}
                    </span>
                  </div>
                  <div className="message-text d-flex justify-content-between">
                    <div>
                      <LinesEllipsis
                        text={message.Message}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                      />
                    </div>
                    <div
                      className="trash-icon align-self-end"
                      onClick={event => {
                        props.deleteMessage(message.id, messageList.length);
                        event.stopPropagation();
                        event.nativeEvent.stopImmediatePropagation();
                      }}
                    >
                      <span>
                        <i className="fa fa-trash" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : null}
    </React.Fragment>
  );
};

const MessageActions = props => {
  if (props.title) {
    if (props.title.toLowerCase() === "your action needed!") {
      return (
        <Link to={AppLinks.claims.claimStatus}>
          <button
            className="btn w-100 btn-secondary mr-3 message-action-btn"
            onClick={() => {
              props.sideDrawerClose(true);
            }}
          >
            View Claim
          </button>
        </Link>
      );
    } else if (props.title.toLowerCase() === "update billing info!") {
      return (
        <div>
          <Link to={AppLinks.account.updateBillingInfo}>
            <button
              className="btn w-100 btn-secondary mr-3 mb-2 message-action-btn"
              onClick={() => {
                props.sideDrawerClose(true);
              }}
            >
              Update Billing Info
            </button>
          </Link>
          <button
            className="btn w-100 btn-secondary mr-3 message-action-btn"
            onClick={() => {
              props.retryBillingHandler();
              props.sideDrawerClose(true);
            }}
          >
            Retry Billing
          </button>
        </div>
      );
    } else if (props.title.toLowerCase() === "credit card expired!") {
      return (
        <Link to={AppLinks.account.updateBillingInfo}>
          <button
            className="btn w-100 btn-secondary mr-3 message-action-btn"
            onClick={() => {
              props.sideDrawerClose(true);
            }}
          >
            Update Billing
          </button>
        </Link>
      );
    }
    return null;
  }
  return null;
};

const ExpandedMessageItem = ({
  message,
  deleteMessage,
  currentPageMessageLength,
  sideDrawerClose,
  retryBillingHandler
}) => {
  return (
    <div
      style={{ marginLeft: 7, fontWeight: 400 }}
      className="message-content-expanded"
    >
      <div
        style={{ padding: 10, paddingLeft: 15 }}
        className="message-header d-flex"
      >
        <div style={{ width: "100%" }}>
          <span className="message-modal-title d-flex justify-content-between">
            <span
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
              className={
                MessageUtil.isMessageImportant(message.title)
                  ? "text-danger "
                  : "text-dark"
              }
            >
              {message.title}
            </span>
            <span>
              <i
                style={{ marginTop: -5 }}
                className="text-secondary fa fa-angle-up fa-2x"
              />
            </span>
          </span>
          <span className="message-date-time">
            {Util.formatMessageDate(message.SendDate)}
          </span>
        </div>
      </div>

      <div style={{ paddingLeft: 0 }} className="message-text expanded d-flex">
        <div className="col-xs-12">
          <div className="message-content">
            <p>{message.Message}</p>
            <MessageActions
              messageType={message.MessageType}
              messageSubType={message.MessageSubType}
              title={message.title}
              sideDrawerClose={sideDrawerClose}
              retryBillingHandler={retryBillingHandler}
            />
          </div>
          <div
            onClick={() => deleteMessage(message.id, currentPageMessageLength)}
            style={{ textAlign: "center", padding: 10 }}
          >
            <span className="text-secondary" style={{cursor: "pointer"}}>
              <strong>Delete Message</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const MessagesListMobile = props => {
  const messageList = props.messagesToShow;
  return (
    <React.Fragment>
      {messageList
        ? messageList.map((message, index) => {
            // const unreadMessageClass = message.unread
            //   ? "unread-message message-item"
            //   : "message-item";

            const messageStatusClass = message.unread
              ? "message-status-green un-read"
              : "message-status-green read";

            return (
              <div
                id={"message" + index}
                key={index}
                onClick={() => props.expandMessage(message.id)}
                className="message-item"
              >
                {message.expanded ? (
                  <ExpandedMessageItem
                    message={message}
                    deleteMessage={props.deleteMessage}
                    currentPageMessageLength={messageList.length}
                    sideDrawerClose={props.sideDrawerClose}
                    retryBillingHandler={props.retryBillingHandler}
                  />
                ) : (
                  <React.Fragment>
                    <div className={messageStatusClass} />
                    <div style={{ width: "100%" }} className="message-content">
                      <div className="message-header justify-content-between d-flex">
                        <span
                          className={
                            "message-modal-title " +
                            (MessageUtil.isMessageImportant(message.title)
                              ? "text-danger "
                              : "text-dark")
                          }
                        >
                          {message.title}
                        </span>
                        <span
                          style={{ flexShrink: 0, paddingRight: 5 }}
                          className="message-date-time"
                        >
                          {message.time_ago}
                        </span>
                        <span>
                          <i
                            style={{ marginTop: -5 }}
                            className="text-secondary fa fa-angle-down fa-2x"
                          />
                        </span>
                      </div>
                      <div className="message-text d-flex justify-content-between">
                        <LinesEllipsis
                          text={message.Message}
                          maxLine="2"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>
            );
          })
        : null}
    </React.Fragment>
  );
};

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  messages: state.messages.messages,
  fetchingMessages: state.messages.fetchingMessages,
  messageFetchSuccess: state.messages.messageFetchSuccess,
  messagesFetchError: state.messages.messagesFetchError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { getMessagesByAccount, setMessageAsRead, deleteMessage, setExpandMessage },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages);
