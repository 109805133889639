import React from "react";
import "./FileClaimSuccessView.component.css";
import { Link } from "react-router-dom";
import { AppLinks } from "../../../constants/app-links";

const FileClaimSuccessView = props => {
  const {claimReimbursementMethod, mailingAddress} = props;
  return (
    <div className="d-flex flex-column claim-submit-success-container">
          <span className="status-description"> 
        We work hard to process your claim within 10 business days. Once we
        begin reviewing your claim documentation, we will send you a
        confirmation email. If you have any questions, please visit{" "}
        <Link className="status-description text-color-violet1" to={AppLinks.claims.claimStatus}>
          view claim status.
        </Link>
      </span>
      <div className="d-flex flex-column success-container padding">
       <h4 className="margin-bottom text-color-solidgreen3" style={{textTransform: "none"}}>Want to Get Reimbursed Faster? Set Up Direct Deposit</h4>

        <h4>Reimbursement Type:</h4>
        <span className="margin-bottom">{claimReimbursementMethod}</span>

        <h4>Mailing Address:</h4>
        <span className="clam-success-margin-bottom">
          {mailingAddress.Street} 
          <br />
          {mailingAddress.City}, {mailingAddress.State} {mailingAddress.PostalCode}
        </span>

        {/* <span className="d-none d-md-block reimbrusement-info">
          Would you like to change your reimbursement info? Please{" "}
          <Link className="status-description" to={AppLinks.account.updateReimbursement}>
            click here
          </Link>
          .
        </span> */}

              {/* <br />
              <br/> */}
        <button
          className="update-success-button button-primaryV2"
          type="submit"
          onClick={props.goToReimbursement}
          style={{backgroundColor: "#4aa574"}}
        >
          Change Direct Deposit
        </button>
      </div>

      <div className="d-flex flex-column flex-md-row">
        <button
                  className="update-success-button button-primaryV2 button-primaryv2-outlined"
          type="submit"
          onClick={props.goToFileClaim}
        >
          Submit Another Claim
        </button>



    
 
      </div>
    </div>
  );
};

export default FileClaimSuccessView;
