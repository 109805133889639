import * as actionTypes from "../../constants/action-types";

export const getAlertsByAccount = () => ({
  type: actionTypes.ALERTS_FETCH_REQUEST
});

export const setAlertsDataRefresh = () => ({
  type: actionTypes.SET_ALERTS_DATA_REFRESH
});


export const setNewRoute = (pageLocation) => ({
  type: actionTypes.SET_NEW_ROUTE,
  pageLocation
});


export const addClientAlert = (newAlert)=> ({
  type: actionTypes.CLIENT_ALERT_ADD,
  newAlert
});


export const removeClientAlert = (alertKey)=> ({
  type: actionTypes.CLIENT_ALERT_REMOVE,
  alertKey
});

export const setNotificationDismiss = (dismissedNotificationIds) => ({
  type:actionTypes.SET_NOTIFICATIONS_DISMISSED,
  dismissedNotificationIds
});

export const setCurrentPageAlerts = (currentPageAlerts) => ({
  type:actionTypes.SET_CURRENT_PAGE_ALERTS,
  currentPageAlerts
})
