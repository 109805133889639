import React from "react";

import "./ClaimsPagination.css";

const ClaimsPagination = props => {
  // console.log("pagination props: ", props)
  return (
    <div id="claim-pagination" className="text-center">
        {/* change the condition to >1 instead of >=1 */}
      {props.totalPages >= 1 && (
        <div className="text-center d-flex justify-content-center">
          <div
            onClick={evt => props.previousPage(evt)}
            className="pagination-arrows disabled"
          >
            <i className="fa fa-angle-left" />
          </div>
          <div id="page-edit-text">
            <input value={props.currentPageNo} type="text" readOnly />
          </div>
          <span id="page-numbers" >/ {props.totalPages}</span>
          <div
            onClick={evt => props.nextPage(evt)}
            className="pagination-arrows"
          >
            <i className="fa fa-angle-right" />
          </div>
        </div>
      )}

    </div>
  );
};

export default ClaimsPagination;
