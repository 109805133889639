import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import MobileAppConfirmCharges from "./MobileAppConfirmCharges.component";
import MobileAppReviewPolicy from "./MobileAppReviewPolicy.component";
//import PetForm from "./PetForm.component";
import MobileAppPetForm from "./MobileAppPetForm.component";
import WANoEnrollments from "./WANoEnrollments.component";
import CancelProRataNoEnrollments from "./CancelProRataNoEnrollments.component";
import NoAddPet from "./NoAddPet.component";
import NoAddPetCompliance from "./NoAddPetCompliance.component";
import MobileAppReviewRates from "./MobileAppReviewRates.component";
import Stepper from "../../common/stepper/Stepper";
import Loader from "../../common/loader/Loader";
import ErrorView from "../../common/error-view/ErrorView";
import ConfirmNavigationModal from "./../../common/route-prevent-dialog/ConfirmNavigationModal";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getPetsByAccount,
  setPetsDataRefresh,
} from "../../../store/actions/pets-actions";
import {
  setNewRoute,
  getAlertsByAccount,
} from "../../../store/actions/alerts-actions";
import {
  getAccountInfo,
  setAccountDataRefresh,
} from "../../../store/actions/accounts-actions";
import { getBreeds } from "../../../api/pets-api";
import { AppLinks } from "../../../constants/app-links";

class MobileAppAddPet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      petDetails: {},
      policyDetails: null,
      petAge: 0,
      selectedPlan: null,
      totalMonthlyRate: 0,
      newPetDetails: null,
      allBreeds: [],
      breedsFetchError: false,
    };
    this.catBreeds = [];
    this.dogBreeds = [];

    this.breedsFetchErrorMessage = "";
    this.WANoEnrollment = false;
    this.addPetDisabled = false;
    this.formDirty = false;

    //For google analytics
    window.dataLayer.push({
      event: "Pageview",
      url: window.location.pathname + "/Step1",
    });
  }

  setCapturedPetDetails = (formDetails, policyDetails, petAge) => {
    this.setState({
      petDetails: formDetails,
      policyDetails: policyDetails,
      petAge: petAge,
    });
  };

  reset = () => {
    this.setState({
      petDetails: {},
      policyDetails: null,
      petAge: 0,
      selectedPlan: null,
      totalMonthlyRate: 0,
      newPetDetails: null,
    });
  };

  setSelectedPlan = (selectedPlan, totalMonthlyRate) => {
    this.setState({ selectedPlan, totalMonthlyRate });
  };

  //setting the details of the newly added pet from the response
  setNewPetDetails = (newPetDetails) => {
    this.setState({ newPetDetails });
  };

  setFormDirty = () => {
    this.formDirty = true;
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.actions.getPetsByAccount();
    this.props.actions.getAccountInfo();
    this.getBreedsData();
  }

  getBreedsData() {
    getBreeds()
      .then((breedsData) => {
        this.setState({ allBreeds: breedsData });
        breedsData.map((pet) => {
          if (pet.species === "Cat") this.catBreeds.push(pet);
          else if (pet.species === "Dog") this.dogBreeds.push(pet);
          return true;
        });
      })
      .catch((error) => {
        this.setState({ breedsFetchError: true });
        this.breedsFetchErrorMessage = error ? error.errorMessage : "";
      });
  }

  render() {
    if (this.props.currentPageAlerts.length > 0) {
      if (
        this.props.currentPageAlerts.indexOf(
          "PENDING_POSTPONE_PAYMENT_NO_NEW_PETS_CHANGE_KEY"
        ) !== -1
      ) {
        this.WANoEnrollment = true;
      }

      if (
        this.props.userAccountDetails.ExemptFromAutomaticProgramRewrite == true ||
        this.props.userAccountDetails.AllowAddAPet == false
      ) {
        this.addPetDisabled = true;
      }
    }
    return (
      <div
        style={{
          position:
            this.WANoEnrollment ||
            this.addPetDisabled ||
            !this.props.canUserEdit
              ? "relative"
              : "static",
        }}
      >
        <NavigationPrompt
          when={(crntLocation, nextLocation) => {
            this.currentLocation = crntLocation;
            this.nextLocation = nextLocation;
            if (
              (Object.keys(this.state.petDetails).length ||
                this.state.selectedPlan ||
                this.formDirty) &&
              !this.state.newPetDetails
            ) {
              return true;
            }
          }}
        >
          {({ onCancel, onConfirm }) => (
            <ConfirmNavigationModal
              when={true}
              message="You have unsaved changes on this page. Do you want to leave this page and discard your changes?"
              title="Unsaved Data"
              currentLocation={this.currentLocation}
              nextLocation={this.nextLocation}
              forceUpdate={false}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </NavigationPrompt>
        { this.props.userAccountDetails.ExemptFromAutomaticProgramRewrite ? (
          <NoAddPet
            backToHome={() => {
              this.props.history.push("/");
            }}
          />
          ) : this.props.userAccountDetails.Status == "Cancel Prorata" ? (
            <CancelProRataNoEnrollments
              backToHome={() => {
                this.props.history.push("/");
              }}
            />
        ) : !this.props.userAccountDetails.AllowAddAPet ? (
          <NoAddPetCompliance
            backToHome={() => {
              this.props.history.push("/");
            }}
          />
        ) : (
          <Stepper hasSuccess={true} headerTitle="Add a Pet">
            <Stepper.Step>
              {this.state.allBreeds.length > 0 ? (
                <MobileAppPetForm
                  setNewRoute={this.props.actions.setNewRoute}
                  setDetails={this.setCapturedPetDetails}
                  allBreeds={this.state.allBreeds}
                  cats={this.catBreeds}
                  dogs={this.dogBreeds}
                  initialValues={this.state.petDetails}
                  setFormDirty={this.setFormDirty}
                  physicalState={
                    this.props.userAccountDetails.PhysicalAddress.State
                  }
                  userAccountDetails={this.props.userAccountDetails}
                />
              ) : (
                <div style={{ height: 300 }} className="container">
                  {this.state.breedsFetchError ? (
                    <ErrorView>
                      {" "}
                      {this.breedsFetchErrorMessage ? (
                        this.breedsFetchErrorMessage
                      ) : (
                        <span>
                          Unable to fetch information. Please try again or{" "}
                          <Link to={AppLinks.others.contact}>click here</Link>{" "}
                          to contact us..
                        </span>
                      )}{" "}
                    </ErrorView>
                  ) : (
                    <Loader />
                  )}
                </div>
              )}
            </Stepper.Step>
            <Stepper.Step>
              {this.props.userAccountDetails && (
                <MobileAppReviewRates
                  setNewRoute={this.props.actions.setNewRoute}
                  petDetails={this.state.petDetails}
                  policyDetails={this.state.policyDetails}
                  petAge={this.state.petAge}
                  existingPets={this.props.existingPets}
                  breedsData={this.state.allBreeds}
                  setSelectedPlan={this.setSelectedPlan}
                  physicalAddress={
                    this.props.userAccountDetails.PhysicalAddress
                  }
                  selectedPlan={this.state.selectedPlan}
                  userAccountDetails={this.props.userAccountDetails}
                />
              )}
            </Stepper.Step>
            <Stepper.Step>
              {this.props.userAccountDetails && (
                <MobileAppConfirmCharges
                  petDetails={this.state.petDetails}
                  selectedPlan={this.state.selectedPlan}
                  totalMonthlyRate={this.state.totalMonthlyRate}
                  nextBillingDate={
                    this.props.userAccountDetails.NextBillingDate
                  }
                  billingDay={this.props.userAccountDetails.BillingDay}
                  setNewPetDetails={this.setNewPetDetails}
                  setPetsDataRefresh={this.props.actions.setPetsDataRefresh}
                  getAccountInfo={this.props.actions.getAccountInfo}
                  setAccountDataRefresh={
                    this.props.actions.setAccountDataRefresh
                  }
                  getAlertsByAccount={this.props.actions.getAlertsByAccount}
                  getPetsByAccount={this.props.actions.getPetsByAccount}
                  physicalState={
                    this.props.userAccountDetails.PhysicalAddress.State
                  }
                  property={
                    this.props.userAccountDetails.Property
                  }
                />
              )}
            </Stepper.Step>
            <Stepper.Step>
              {this.props.userAccountDetails && (
                <MobileAppReviewPolicy
                  petAge={this.state.petAge}
                  existingPets={this.props.existingPets}
                  selectedPlan={this.state.selectedPlan}
                  petDetails={this.state.petDetails}
                  breedsData={this.state.allBreeds}
                  policyDetails={this.state.policyDetails}
                  newPetDetails={this.state.newPetDetails}
                  policyNumber={
                    this.props.userAccountDetails.PolicyNumber
                  }
                  physicalAddress={
                    this.props.userAccountDetails.PhysicalAddress
                  }
                  reset={this.reset}
                />
              )}
            </Stepper.Step>
          </Stepper>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  existingPets: state.pets.petsDetails.filter((pet) => pet.Status === "Active"),
  userAccountDetails: state.account.userAccountDetails,
  currentPageAlerts: state.alerts.currentPageAlerts,
  fetchingAlerts: state.alerts.fetchingAlerts,
  canUserEdit: state.account.canUserEdit,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getPetsByAccount,
      getAccountInfo,
      setPetsDataRefresh,
      setAccountDataRefresh,
      setNewRoute,
      getAlertsByAccount,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileAppAddPet);
