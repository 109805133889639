import * as actionTypes from "../constants/action-types";
import { getAlertsByAccount } from "./../api/alerts-api";
import { call, put } from "redux-saga/effects";


export function* getAlertsByAccountWorker(action) {
  try {
    let alerts;
    alerts = yield call(getAlertsByAccount);
    if (alerts) {
      yield put({ type: actionTypes.ALERTS_FETCH_SUCCESS, alerts });
    }
  } catch (error) {
    yield put({ type: actionTypes.ALERTS_FETCH_ERROR, error });
  }
}
