import axios from "axios";

export default class Util {
  static getCommaSeperatedNameString = (itemList) => {
    if (!itemList || itemList.length === 0) return "";

    if (itemList.length === 1) {
      return itemList[0] + " ";
    } else if (itemList.length > 1) {
      var itemListName = "";
      itemList.map((item, index) => {
        if (index === itemList.length - 1) {
          itemListName = itemListName + "and " + item + " ";
        } else {
          itemListName =
            itemListName + item + (index === itemList.length - 2 ? " " : ", ");
        }
        return itemListName;
      });
      return itemListName;
    }
  };

  static formatMessageDate = (dateValue) => {
    // TODO: Need to write Test Case

    var dateObject = new Date(dateValue);
    var date = dateObject.getDate();

    var month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][dateObject.getMonth()];

    var hours = dateObject.getHours();
    console.log(hours);

    var minutes = dateObject.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours === 0 ? 12 : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return (
      month +
      " " +
      date +
      Util.getDateNth(date) +
      ", " +
      dateObject.getFullYear() +
      " at " +
      hours +
      ":" +
      minutes +
      " " +
      ampm
    );
  };

  static getDateNth = (d) => {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  static getFormattedDate = (date) => {
    if (!date) return "";
    date = new Date(date);
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  };

  static toggleCruciateLigamentExamples = () => {
    let togger = document.getElementById("preexisting-cruciate-examples-container");
      if (togger.style.display === "none") {
        togger.style.display = "block";
      } else {
        togger.style.display = "none";
      }
  };
  static ToggleCoverageExample = () => {
    let togger = document.getElementById("preExistExample");
    if (togger.style.display === "none") {
      togger.style.display = "block";
    } else {
      togger.style.display = "none";
    }
  };

  static OpenFAQQuestion(questionId) {
    let FAQ = document.getElementById(questionId);
    
    if (FAQ) {
      FAQ.click();
    }
    
  }
}
export function getProtectedSinceDateV2(protectedSinceDateSource) {
     //basic validation
    if (!protectedSinceDateSource
        || protectedSinceDateSource === undefined
        || protectedSinceDateSource === null) {

        return ("Unavailable");
    } 

    const protectedSinceDate = new Date(protectedSinceDateSource);
    const formattedDate = protectedSinceDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    });

    return (
        formattedDate
    );
}
export function getProtectedSinceDate(enrollmentDateValue) {
  let enrollmentDateObject = new Date(enrollmentDateValue);
  let enrollmentDateTimeStamp = enrollmentDateObject.getTime();
  let protectedSinceTimeStamp =
    enrollmentDateTimeStamp + 15 * 24 * 60 * 60 * 1000;
  let protectedSinceDateObject = new Date(protectedSinceTimeStamp);
  return (
    ("0" + (protectedSinceDateObject.getMonth() + 1)).slice(-2) +
    "/" +
    ("0" + protectedSinceDateObject.getDate()).slice(-2) +
    "/" +
    protectedSinceDateObject.getFullYear()
  );
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatFloatNumber(number) {
  if (number % 1 === 0) {
    return number;
  } else {
    return number.toFixed(2);
  }
}

export function dateSuffix(day) {
  let j = day % 10,
    k = day % 100;
  if (j === 1 && k !== 11) {
    return day + "st";
  }
  if (j === 2 && k !== 12) {
    return day + "nd";
  }
  if (j === 3 && k !== 13) {
    return day + "rd";
  }
  return day + "th";
}

export function isValidURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + //port
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locater
  if (!pattern.test(str)) {
    return false;
  } else {
    return true;
  }
}

export function checkImageURL(url) {
  // Any valid image URL should point to amazonaws. Refer: https://www.pivotaltracker.com/story/show/163832212
  if (url && isValidURL(url) && url.includes("amazonaws")) {
    return axios
      .head(url)
      .then(() => {
        return true;
      })
      .catch(function (error) {
        //TODO:Need to re-look this logic
        if (url.includes("amazonaws")) {
          return true;
        } else {
          return false;
        }
      });
  } else {
    return Promise.reject(false);
  }
}

export function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

export function parseQueryString(str) {
  var vars = [];
  var arr = str.split("&");
  var pair;
  for (var i = 0; i < arr.length; i++) {
    pair = arr[i].split("=");
    vars[pair[0]] = unescape(pair[1]);
  }
  return vars;
}
