export default class YearList {
    static getYearList = (start, end, placeholder) => {
        let years = [];
        for (let year = start; year <= end; year++) {
            years.push({
                displayName: "" + year,
                value: "" + year
            })
        }
        years = years.reverse();
        if(placeholder){
            years.unshift({
                displayName: placeholder,
                value: null
            })
        }
        return years;
    }
}