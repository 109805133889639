import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ConfirmNavigationModal = ({
  when,
  title,
  message,
  onCancel,
  onConfirm,
  currentLocation,
  forceUpdate,
  nextLocation
}) => {
  if (currentLocation.pathname === nextLocation.pathname) {
    if (forceUpdate) {
      //If user on same route , but has to reload.
      when = false;
      onConfirm();
    } else {
      when = false;
      return null;
    }
  }

  return (
    <Modal isOpen={when}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <button className="btn-secondary" style={{backgroundColor: "#4aa574", borderColor: "#4aa574"}} onClick={onConfirm}>
          <strong > Yes</strong>
        </button>
        <button className="btn-secondary" style={{backgroundColor: "#4aa574", borderColor: "#4aa574"}} onClick={onCancel}>
          <strong>No</strong>
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmNavigationModal;
