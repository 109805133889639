import { put } from "redux-saga/effects";
import {clearSession} from "../api/auth/auth"
import * as actionTypes from "../constants/action-types";

// TODO: Move out of Sagas if possible.
export function* logOut() {
  try {
    clearSession();
    yield put({ type: actionTypes.LOGOUT_SUCCESS }); 
  } catch (error) {
  }
};