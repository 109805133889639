import * as types from "../../constants/action-types";

const initialState = {
  messages: [],
  unreadMessagesCount: 0,
  messagesFetchError: null,
  messageFetchSuccess: false,
  needsRefresh: false,
  fetchingMessages: false
};

// Handles image related actions
export default function(state = initialState, action) {
  switch (action.type) {
    case types.MESSAGES_FETCH_REQUEST:
      return {
        ...state,
        fetchingMessages: true,
        messagesFetchError: null
      };
    case types.MESSAGES_FETCH_SUCCESS:
      return {
        ...state,
        messages: action.messages.map(message => {
          return { ...message, expanded: false };
        }),
        unreadMessagesCount: (action.messages.filter(message => message.unread === true)).length,
        fetchingMessages: false,
        messageFetchSuccess: true,
        needsRefresh: false
      };
    case types.MESSAGES_COUNT_FETCH_SUCCESS:
      return {
        ...state,
        messages: action.messages,
        fetchingMessages: false,
        needsRefresh: false
      };
    case types.MESSAGES_FETCH_ERROR:
      return {
        ...state,
        messagesFetchError: action.error,
        fetchingMessages: false,
        messageFetchSuccess: false
      };
    case types.SET_MESSAGES_DATA_REFRESH:
      return {
        ...state,
        needsRefresh: true
      };
    case types.SET_MESSAGE_READ:
      return {
        ...state,
        messages: state.messages.map(message =>
          message.id === action.messageId
            ? {
                ...message,
                unread: false
              }
            : message
        ),
        unreadMessagesCount: state.unreadMessagesCount > 0 ? state.unreadMessagesCount - 1 : 0
      };
    case types.MESSAGE_EXPAND:
      return {
        ...state,
        messages: state.messages.map(message =>
          message.id === action.messageId
            ? {
                ...message,
                expanded: !message.expanded
              }
            : message
        )
      };
    case types.DELETE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter(
          message => message.id !== action.messageId
        )
      };
    default:
      return state;
  }
}
