import React from "react";
import "./MobileUpdateSuccessView.css";
import StatusHeader from "../status-header/StatusHeader";
import ReferFriend from "../../common/refer-friend/ReferFriend"
import { CSSTransitionGroup } from 'react-transition-group'

const MobileUpdateSuccessView = props => {
  return (
    <div className={"d-flex flex-column align-items-center mobile-update-success " + props.className }>
    <div  className="container-fluid status-container">
      <CSSTransitionGroup
      transitionName="header-parent"
      transitionAppearTimeout={0}
      transitionEnterTimeout={0}
      transitionLeaveTimeout={0}
      transitionAppear={true}>
        <StatusHeader className="status">
          <div className="d-inline-flex align-items-center status">
            <i className="fa fa-check-circle align-middle" />
            <div className="status-info">Success!</div>
            <div className="d-none d-md-block status-text">
              &nbsp;
              {props.statusHeader}
            </div>
          </div>
        </StatusHeader>
    </CSSTransitionGroup>
    </div>

      <div className="container content d-flex flex-column">
        {!props.children && (
          <React.Fragment>
            <h1>{props.title}</h1>
            <div className="row">
              <div className="col-12 col-lg-8">
            <label className="status-description margin-bottom">{props.description}</label>
            <br></br>
            </div>
            <div className="d-none d-lg-block col-lg-4">
                {/* <ReferFriend/> */}
              </div>
            </div>
          </React.Fragment>
        )}
        {props.children && (
          <React.Fragment>
            <h1>{props.title}</h1>
            <div className="row">
              <div className="col-12 col-lg-8">
                  {props.children}
              </div>

              <div className="d-none d-lg-block col-lg-4">
                {/* <ReferFriend/> */}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default MobileUpdateSuccessView;
