import React, { Component } from "react";

import ReferFriend from "../common/refer-friend/ReferFriend";

import {AppLinks} from "../../constants/app-links"

import "./GetMobileApp.css"

class GetMobileApp extends Component {

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="container getmobileapp">
                <div className="row">
                    <div className="col-12">
                        <h1 className="pt-4 pb-4">Healthy Paws Mobile App</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-8 video-container">
                        <iframe className="video-iframe" src="https://www.youtube.com/embed/VcrJOXWnIz0?si=yDmSspSZbdz7WsYs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                    <div className="col-lg-4 d-none d-lg-block">
                        <p>
                            Download the Healthy Paws Mobile App on your tablets and mobile phones. Simply take a picture of your invoice, submit it, and we will be processing it before you are even home from the animal hospital.
                        </p>
                        <h3>
                            Scan the QR code to download
                        </h3>
                        <div class="row">
                            <div class="col text-center">
                                <img className="img-fluid" src="/assets/images/IOS_CCAPP_page.png" alt="Apple App Store QR code" />
                                <br /><br />
                                <a href={AppLinks.externalLinks.iOSApp} target="_blank">
                                    <img src="/assets/images/btn_app_store.png" className="img-fluid" alt="appstore" style={{ borderRadius:"5px"}}></img>
                                </a>
                            </div>
                            <div class="col text-center">
                                <img className="img-fluid" src="/assets/images/Android_CCapp_page.png" alt="Google Play store QR code" />
                                <br /><br />
                                <a href={AppLinks.externalLinks.androidApp}  target="_blank">
                                    <img src="/assets/images/btn_google_play.png" className="img-fluid" alt="googleplay" style={{ borderRadius:"5px"}}></img>
                                </a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col consent-text">
                                <br/>
                                Google Play and the Google Play logo are trademarks of Google LLC.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-block d-lg-none mt-4 mb-4">
                    <div className="col">
                        <div className="gray-box p-4 text-center">
                            <h3>Download the Mobile App</h3>
                            <br/>
                            <a href={AppLinks.externalLinks.iOSApp} target="_blank">
                                <img src="/assets/images/btn_app_store.png" className="img-fluid" alt="appstore" style={{ borderRadius:"5px"}}></img>
                            </a>
                            <br/><br/>
                            <a href={AppLinks.externalLinks.androidApp}  target="_blank">
                                <img src="/assets/images/btn_google_play.png" className="img-fluid" alt="googleplay" style={{ borderRadius:"5px"}}></img>
                            </a>
                            <br/><br/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mt-1 mb-1 mobile-app-text">
                        <h4>Use the <span class="d-none d-lg-inline-block">Healthy Paws</span> mobile app to</h4>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-lg-6">
                        <ul className="app-benefits-list">
                            <li className="app-benefits-item">
                                <strong>Submit a Claim from Anywhere</strong><br/>
                                Fast, easy, hassle-free claims
                            </li>
                            <li className="app-benefits-item">
                                <strong>Update Billing Information</strong><br/>
                                Direct deposit set up for faster reimbursement
                            </li>
                            <li className="app-benefits-item">
                                <strong>Check Claim Status</strong><br/>
                                Step-by-step claim journey
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <ul className="app-benefits-list">
                            <li className="app-benefits-item">
                                <strong>Review Policy Details</strong><br/>
                                Manage your account details all in one place
                            </li>
                            <li className="app-benefits-item">
                                <strong>Upload Pet Photos</strong><br/>
                                Detailed pet account view with pet photos
                            </li>
                            {/* <li className="app-benefits-item">
                                <strong>Refer a Friend and Earn $25</strong><br/>
                                Refer a friend and earn
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default GetMobileApp;