import React, { PureComponent } from "react";
import Dropzone from "react-dropzone";

import "./PhotoControl.css";

class PhotoControl extends PureComponent {
  render() {
    const {
      toCrop,
      toFilter,
      onChangePhoto,
      upload,
      onUpload,
      onNext,
      currentIndex
    } = this.props;
    return (
      <div className="photo-control">
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="outline-btn"
              onClick={() => {
                currentIndex !== 0 && toCrop && toCrop();
              }}
            >
              <span>
                <i className="fa fa-crop" />
                Crop
              </span>
            </div>
            <div
              className="outline-btn"
              onClick={() => {
                currentIndex !== 1 && toFilter && toFilter();
              }}
            >
              <span>
                <i className="fa fa-sliders" />
                Filter
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between control-group">
          <Dropzone
            className="change-photo-dropzone"
            accept="image/png,image/jpg,image/jpeg,image/tiff"
            id="change-avatar"
            multiple={false}
            onDrop={onChangePhoto}
          >
            <button type="button" className="button-link photo-control-btn px-2 px-sm-3">
              Change Photo
            </button>
          </Dropzone>
          {upload ? (
            <button
              type="button"
              onClick={onUpload}
              className="btn btn-secondary photo-control-btn"
            >
              Upload
            </button>
          ) : (
            <button
              type="button"
              onClick={onNext}
              className="btn btn-secondary photo-control-btn"
            >
              Next
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default PhotoControl;
