import React, { Component } from "react";
import Loader from "../../common/loader/Loader";
import { Redirect } from "react-router";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { setAccountDataRefresh, getAccountInfo } from "../../../store/actions/accounts-actions";
import { getAnniversaryPolicyOptions } from "../../../store/actions/pets-actions";
import { getAlertsByAccount } from "../../../store/actions/alerts-actions";

import ChangeAnniversaryPolicyOptions from "./ChangeAnniversaryPolicyOptions.component";
import ChangeAnniversaryPolicyConfirmation from "./ChangeAnniversaryPolicyConfirmation.component";
import ErrorView from "../../common/error-view/ErrorView";
import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";

import { AppLinks } from "../../../constants/app-links";


import "./AnniversaryCoverageOptions.css";

class AnniversaryCoverageOptions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetching: false,
            updateReimbursementSuccess: false,
        }
    }

    
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.actions.getAnniversaryPolicyOptions();

        //For google analytics
        window.dataLayer.push({
            'event': 'Pageview',
            'url': window.location.pathname
        });
    }

    
    onChangePolicySubmitted = (changedPolicyDetails, monthlyPremium) => {
        this.setState({      
        policyChangeSuccess: true,
        newPolicyDetails: changedPolicyDetails,
        newTotalMonthlyPremium: monthlyPremium
        });    

        // don't show alerts after submission
        

        this.props.actions.setNewRoute("/AnniversaryOptions/Step2");
        //For google analytics
        window.dataLayer.push({
        'event': 'Pageview',
        'url': window.location.pathname + "/Confirm"
        });

        window.scrollTo(0,0);
    };

    render() {
        const {
            petDetails,
            fetchingPets,
            fetchingAnniversaryOptions,
            error,
            anniversaryPolicyOptions,
            anniversaryPolicyOptionsError
        } = this.props;

        const { policyChangeSuccess } = this.state;

        var today = new Date().getTime();
        var outsideAnniversaryNoticeWindow = false;
        var inAnniversaryPeriod = false;
        var annualRepriceChangeScheduled = this.props.userAccountDetails.HasPendingAnniversaryChange;
        var anniversaryDate = new Date(this.props.userAccountDetails.NextAnniversaryDate).getTime();

        if (new Date(this.props.userAccountDetails.NextAnniversaryDate).getTime() ==
            new Date(this.props.userAccountDetails.NextBillingDate).getTime() + (24 * 60 * 60 * 1000)) {
            inAnniversaryPeriod = true;
        }

        if (today + (this.props.userAccountDetails.DaysAnniversaryNotification * 24 * 60 * 60 * 1000) <=
            anniversaryDate - (24 * 60 * 60 * 1000) || today > anniversaryDate - (24 * 60 * 60 * 1000)) {
            outsideAnniversaryNoticeWindow = true;
        }

        if (outsideAnniversaryNoticeWindow || inAnniversaryPeriod) {
            return (
                <Redirect to={AppLinks.policy.changePolicyOptions} />
            );
        } else {

            return (
                <div style={{ position: this.props.canUserEdit ? "static" : "relative" }}>
                    {this.props.canUserEdit ? null : <div className="disable-user-edit" />}
                    {fetchingPets || fetchingAnniversaryOptions ? (
                        <div style={{ height: 300 }}>
                            <Loader />
                        </div>
                    ) : (
                        <React.Fragment>
                            {petDetails &&
                                petDetails.length > 0 &&
                                !error &&
                                !anniversaryPolicyOptionsError &&
                                anniversaryPolicyOptions ? (
                                <React.Fragment>
                                    {policyChangeSuccess ? (
                                        <UpdateSuccessView
                                            statusHeader={<div className="d-none d-md-inline-block">Your policy options have been updated.</div>}
                                            title={"Policy Options Change Confirmation"}
                                        >
                                            <ChangeAnniversaryPolicyConfirmation
                                                newPolicyDetails={this.state.newPolicyDetails}
                                                newTotalMonthlyPremium={this.state.newTotalMonthlyPremium}
                                            />
                                        </UpdateSuccessView>
                                    ) : (
                                        <ChangeAnniversaryPolicyOptions
                                            getAlertsByAccount={this.props.actions.getAlertsByAccount}
                                            setAccountDataRefresh={this.props.actions.setAccountDataRefresh}
                                            userAccountDetails={this.props.userAccountDetails}
                                            petDetails={petDetails}
                                            availablePolicies={
                                                anniversaryPolicyOptions.available_policies
                                            }
                                            policyEffectiveDate={anniversaryPolicyOptions.pending_effective_date}
                                            onPolicyChangeSuccess={this.onChangePolicySubmitted}
                                        />
                                    )}
                                </React.Fragment>
                            ) : (
                                <div className="container" style={{ height: 300 }}>
                                    <h1 className="text-primary mb-3 mb-lg-0">Anniversary Policy Options</h1>
                                    {
                                        (error || anniversaryPolicyOptionsError) ?
                                            <ErrorView>Unable to fetch policy options. Please try later.</ErrorView> :
                                            <p>No pets available to show you right now. Please try later.</p>
                                    }
                                </div>
                            )
                            }
                        </React.Fragment>
                    )}
                </div>
            );
        }
    }
}

const mapStateToProps = state => ({
    petDetails: state.pets.petsDetails.filter(pet => pet.Status === "Active"),
    canUserEdit: state.account.canUserEdit,
    error: state.pets.error,
    userAccountDetails: state.account.userAccountDetails,
    userAccountDetailsFetching: state.account.fetching,
    anniversaryPolicyOptions: state.pets.anniversaryPolicyOptions,
    fetchingAnniversaryOptions: state.pets.fetchingAnniversaryOptions,
    fetchingAlerts: state.alerts.fetchingAlerts,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(
        {
            getAccountInfo,
            getAlertsByAccount,
            setAccountDataRefresh,
            getAnniversaryPolicyOptions
        }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnniversaryCoverageOptions);
