import { fork, all } from "redux-saga/effects";
import {
  watchLogOutRequest,
  watchPetsRequest,
  watchAccountsRequest,
  watchClaimsRequest,
  watchPetPhotoUpdateReqeust,
  watchMessagesByAccountRequest,
  watchAlertsByAccountRequest,
  watchGetAvailablePolicyOptions,
  watchGetAnniversaryPolicyOptions,
  watchGetTopAlertMessage,
  watchDocumentsRequest
} from "./watcher";

// Here, we register our watcher saga(s) and export as a single generator
// function (startForeman) as our root Saga.
export default function* rootSaga() {
  yield all([
    fork(watchLogOutRequest),
    fork(watchAccountsRequest),
    fork(watchPetsRequest),
    fork(watchClaimsRequest),
    fork(watchPetPhotoUpdateReqeust),
    fork(watchMessagesByAccountRequest),
    fork(watchAlertsByAccountRequest),
    fork(watchMessagesByAccountRequest) ,
    fork(watchGetAvailablePolicyOptions),
    fork(watchGetAnniversaryPolicyOptions),
    fork(watchGetTopAlertMessage),
    fork(watchDocumentsRequest)
  ]);
}
