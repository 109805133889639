import React, { Component } from "react";

import { Link } from "react-router-dom";
import "./OopsLostPage.css";
import { AppLinks } from '../../constants/app-links';
import App from "../../App";


class OopsLostPage extends Component {


    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render() {
        const linkslist1 = [{
            "name": "Home",
            "url": AppLinks.home.home
        }, {
            "name": "View Pet Details",
            "url": AppLinks.policy.viewPetDetails
        }, {
            "name": "Add a Pet",
            "url": AppLinks.policy.addPet
        }, {
            "name": "Change Policy Options",
            "url": AppLinks.policy.changePolicyOptions
        }, {
            "name": "View Policy Documents",
            "url": AppLinks.policy.viewPolicyDocuments
        }];
        const linkslist2 = [{
            "name": "View Your Profile",
            "url": AppLinks.account.viewProfile
        }, {
            "name": "Change Physical Address",
            "url": AppLinks.account.changePhysicalAddress
        }, {
            "name": "Change Profile",
            "url": AppLinks.account.changeProfile
        }, {
            "name": "Change Password",
            "url": AppLinks.account.changePassword
        }, {
            "name": "Update Billing Info",
            "url": AppLinks.account.updateBillingInfo
        }, {
            "name": "Change Reimbursement Info",
            "url": AppLinks.account.updateReimbursement
        }];
        const linkslist3 = [{
            "name": "File a Claim",
            "url": AppLinks.claims.fileClaim
        }, {
            "name": "View Claim Status",
            "url": AppLinks.claims.claimStatus
        }, {
            "name": "Upload Medical Records",
            "url": AppLinks.claims.uploadMedicalRecords
        }, {
            "name": "Get the Mobile App",
            "url": AppLinks.claims.getMobileApp
        }];
        const linkslist4 = [{
            "name": "Frequently Asked Questions",
            "url": AppLinks.faq.faq
        }, {
            "name": "Contact Us",
            "url": AppLinks.others.contact
        }, {
            "name": "Privacy Policy",
            "url": AppLinks.externalLinks.privacyPolicy
        }, {
            "name": "CA Privacy Policy",
            "url": AppLinks.externalLinks.caPrivacyPolicy
        },{
            "name": "Terms and Conditions",
            "url": AppLinks.externalLinks.termsandconditions
        }];
        return (
                <div className="container oops">
                    {/* <div className="row"> */}
                        <div className="col-12 p-0 pl-md-3">
                        <h1 className="oopsHeading">Doggone! <span className="m-block"> </span>Looks like we got lost.</h1>

                        </div>
                    {/* </div> */}
                    <div className="row pt-4">
                        <div className="col-md-5 col-lg-3 order-2 order-md-0">
                            <img src="/assets/images/img_hero_oops.jpg" className="img-fluid" alt="heroimg"></img>
                        </div>
                        <div className="col-md-7 col-lg-9">
                            <div className="row">
                                <div className="col-12">
                                <p className="subHeadline">Where would you like to go?</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-xl-3 pt-2">
                                    {linkslist1.map((link, i) => {
                                        return <Link to={link.url} key={i} className="d-block link">{link.name}</Link>
                                    })}
                                </div>
                                <div className="col-md-6 col-xl-3 pt-4 pt-md-0">
                                    {linkslist2.map((link, i) => {
                                        return <Link to={link.url} key={i} className="d-block link">{link.name}</Link>
                                    })}
                                </div>
                                <div className="col-md-6 col-xl-3 pt-4 pt-md-4 pt-xl-0">
                                    {linkslist3.map((link, i) => {
                                        return <Link to={link.url} key={i} className="d-block link">{link.name}</Link>
                                    })}
                                </div>
                                <div className="col-md-6 col-xl-3 pt-4 pt-md-4 pt-xl-0">
                                    {linkslist4.map((link, i) => {
                                        return <a href = {link.url} className="d-block link">{link.name}</a>
                                        // <Link to={link.url} key={i} className="d-block link">{link.name}</Link>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )
    }
}

export default OopsLostPage;