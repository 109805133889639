import React from "react";
import "./UpdateProfileSuccessView.component.css";

import MaskValidateUtil from "../../common/util/MaskValidateUtil";

const UpdateProfileSuccessView = props => {

  function isEmpty(value){

    if(!value) return true;
    if(value === null) return true;
    if(value.length === 0) return true;

    return false;
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column success-container padding">
        <h4 className="title">PET PARENT(S)</h4>
        <span className="margin-bottom">
          {props.value.p1FirstName + " " + props.value.p1LastName}
          <br />
          {MaskValidateUtil.phoneNumberMask(props.value.p1PrimaryPhoneNumber) + " (Primary)"}
          <br />
          {!isEmpty(props.value.p1SecondaryPhone) &&
            <React.Fragment>
              {MaskValidateUtil.phoneNumberMask(props.value.p1SecondaryPhone) + " (Secondary)"}
          <br />
            </React.Fragment>
          }
        </span>

        <h4>ACCOUNT EMAIL ADDRESS</h4>
        <span className="margin-bottom">
          {props.value.p1Email}
        </span>
        {(props.value.p2FirstName || props.value.p2LastName || props.value.p2PrimaryPhoneNumber || props.value.p2Email) &&
          <React.Fragment>
            <h4>SECOND PET PARENT(S)</h4>
            <span className="margin-bottom">
              {(!isEmpty(props.value.p2FirstName) || !isEmpty(props.value.p2LastName)) &&
                <React.Fragment>
                  {!isEmpty(props.value.p2FirstName) && props.value.p2FirstName + " "}  
                  {!isEmpty(props.value.p2LastName) && props.value.p2LastName}
                  <br />
                </React.Fragment>
              }
              {!isEmpty(props.value.p2Email) &&
                <React.Fragment>
                  {props.value.p2Email}
              <br />
                </React.Fragment>}
              {!isEmpty(props.value.p2PrimaryPhoneNumber) &&
                <React.Fragment>
                  {MaskValidateUtil.phoneNumberMask(props.value.p2PrimaryPhoneNumber)}
                <br />
                </React.Fragment>}
            </span>
          </React.Fragment>
        }
      </div>
      <button
        className="button-secondary update-success-button"
        type="submit"
        onClick={props.backToHome}
      >
        Home
      </button>
    </div>
  );
};

export default UpdateProfileSuccessView;
