import 'babel-polyfill';
import 'nodelist-foreach-polyfill';
import objectFitImages from 'object-fit-images';
import 'classlist-polyfill';

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import configureStore from './store';

import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "font-awesome/css/font-awesome.min.css";

import App from "./App";


// Initialize store
export const store = configureStore();

//objectFitImages();

objectFitImages(null, {watchMQ: true})
const root = document.querySelector('#root') // or const root = document.body

root.addEventListener('click', (e) => {
  if (e.target.tagName === 'A' ) {
    var storeValues = store.getState();
    if(e.target.getAttribute('target') == "_blank" && storeValues.account.userAccountDetails != null)
    {
      var Email =  storeValues.account.userAccountDetails.PetParentInfo.Email;
      var checkUsers = localStorage.getItem("users");
      let userID ;
      if(checkUsers != null && Email != null)
      {
        JSON.parse(checkUsers).map(item => {
          if(item.username == Email)
          {
            userID = item.user;
          }
        });
      }
      if(userID != null)
      {
        window.open(e.target.getAttribute("href") +  "?userId=" +userID);
      }
      
    }
    
    
  }
});
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
