import React from 'react';

export default class ReferFriend {
    static participate = () => {
        return (
            <div className="faqAnswer">
                <p>
                Participation is easy! Simply visit our <a style={{fontWeight:"600"}} href="https://refer.healthypawspetinsurance.com/main_website" target="_blank" rel="noopener noreferrer">
                  referral page
                  </a>  and share with your friends
by email, Facebook or Twitter. With every completed referral, Healthy Paws will
donate $25 on your behalf to help homeless pets. This promotion is not available
in all states. Please see our <a style={{fontWeight:"600"}} href="https://refer.healthypawspetinsurance.com/main_website" target="_blank" rel="noopener noreferrer">
                  Refer a Friend page
                  </a>  for more information.
              </p>
            </div>
          );
    }
  
    static earnRewards = () => {
        return (
            <div className="faqAnswer">
              <p>
               When a friend you have referred completes our online application process, you
               will receive an email letting you know that the referral has resulted in a $25
               donation to help homeless pets.
              </p>
            </div>
          );
    }
    static referralReward = () => {
        return (
            <div className="faqAnswer">
              <p>
                When a friend you have referred completes our online application process (called a "Completed Submitted Application"), you will receive an email letting you know that your referral has resulted in a $25 donation to help homeless pets. 
              </p>
            </div>
          );
    }

    
    static nominateShelter = () => {
        return (
            <div className="faqAnswer">
              <p>
               Yes, we'd like you to be involved
               in choosing who receives donations! You can nominate your favorite shelter or
               rescue organization to receive grants to help more homeless pets by&nbsp; 
               <a style={{fontWeight:"600"}} href="https://woobox.com/3vtugt" target="_blank" rel="noopener noreferrer">
                applying here.
               </a>
              </p>
            </div>
          );
    }
}