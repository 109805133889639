import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { AppLinks } from "../../../constants/app-links";
import {
    dismissTopAlertBanner,
    getTopAlertMessage
  } from "../../../store/actions/accounts-actions";
  
import "./TopAlertBanner.css";

class TopAlertBanner extends Component {
    constructor(props) {
        super(props);
    }

    componentDidUpdate(){
        this.positionBanner();  
    }
        
    componentDidMount() {
         
        //gets the top alert message from SF via .Net service
        this.props.actions.getTopAlertMessage();
        this.positionBanner(); 
        //this.renderCloseButton();
        window.addEventListener("resize", this.positionBanner.bind(this));  
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.positionBanner.bind(this));
        this.closeBanner();
    }

    positionBanner = () => {
        var banner = document.getElementById("topAlertBanner");
        var root = document.getElementById("root");
        var mobileHeader = document.getElementById("mobile-header-sticky");
        var sideDrawer = document.getElementById("side-drawer-container");

        if (banner) {
            root.style.paddingTop = banner.offsetHeight + 'px';

            if (mobileHeader) {
                mobileHeader.style.marginTop = banner.offsetHeight + 'px';
            }

            if (sideDrawer) {
                sideDrawer.style.marginTop =  banner.offsetHeight + 'px';
            }
        }
    }

    closeBanner = () => {
        var banner = document.getElementById("topAlertBanner");
        var root = document.getElementById("root");
        var mobileHeader = document.getElementById("mobile-header-sticky");
        var sideDrawer = document.getElementById("side-drawer-container");

       
        // mobileHeader.style.marginTop = '';
        if (this.props.topAlertMessage !== undefined && this.props.topAlertMessage !== null) {  
            if(document.getElementsByClassName("zeromargin")[0]) {          
                document.getElementsByClassName("zeromargin")[0].style.marginTop = '10px';  
            }
            if(banner) {
                root.style.paddingTop = banner.offsetHeight + 'px';

                if (mobileHeader) {
                    mobileHeader.style.marginTop = banner.offsetHeight + 'px';
                }

                if (sideDrawer) {
                    sideDrawer.style.marginTop =  banner.offsetHeight + 'px';
                }
            }        
        }else{
            if(document.getElementById("topAlertBanner")!== null)
                document.getElementById("topAlertBanner").className = "hidden";

            root.style.paddingTop = '';

            if (mobileHeader) {
                mobileHeader.style.marginTop = '';
            }

            if (sideDrawer) {
                sideDrawer.style.marginTop = '';
            }
        }

        //desktop
        root.style.paddingTop = '0px';
        //mobile
        if (mobileHeader) {
            mobileHeader.style.marginTop = '0px';
        }
        if (sideDrawer) {
            sideDrawer.style.marginTop = '0px';
        }
    }

    dismissBanner = () => {
        this.closeBanner();
        this.props.actions.dismissTopAlertBanner();  
    }

    isValidBannerPage = () => {
        
        if (this.props.path.pathname.indexOf('mobile') < 0) {
            return true;
        } else {
            return false;
        }

        /*
        if (document.location.pathname.search('/contact') === 0) {
             return false;
        } else {
            return true;
        }
        */
    }

    renderCloseButton = () => {
         
        
        if (this.isValidBannerPage()) {
            return <div className={this.props.showTopAlertBanner ? 'topAlertBannerButton text-left align-middle' : 'hidden'}>
                <i className="fa fa-times xButton" onClick={this.closeBanner} aria-hidden="true"></i>
            </div>
        } else {
            return null
        }
    }

    render() {        
        if (this.isValidBannerPage()) {
            return (
                this.props.showTopAlertBanner ? 
                <div id="topAlertBanner" className="topAlertBanner open text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col topAlertBannerContainer">
                                <div className="topAlertBannerText align-middle d-inline-block">
                                    {(this.props.topAlertMessage !== undefined && this.props.topAlertMessage !== null) ?
                                            this.props.topAlertMessage.map(object => {
                                                return <p className="zeromargin text-left"><b className="lighterWeight" dangerouslySetInnerHTML={{ __html: object.message }}></b></p>
                                            })
                                        //<p className="zeromargin"><b className="lighterWeight">{this.props.topAlertMessage}</b>                                        </p>
                                            :  null
                                            // <p className="nomargin">
                                            // <b>bat signal service not working</b>
                                            // </p>
                                        }
                                </div>       
                                <button className="button-secondaryV3 button-container dismissButton" onClick={this.dismissBanner}>
                                    Dismiss
                                </button>
                            </div>
                        </div>
                    </div>
                </div> : null
            );
        } else {
            this.closeBanner();
            return null
        }                                
    }

}

const mapStateToProps = state => ({
    showTopAlertBanner: state.account.showTopAlertBanner,
    topAlertMessage:state.account.topAlertMessage
  });
  
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ dismissTopAlertBanner,getTopAlertMessage }, dispatch)
});
  
  

export default connect(
    mapStateToProps,
    mapDispatchToProps  
)(TopAlertBanner);