let BlobBuilder, MozBlobBuilder, MSBlobBuilder, WebKitBlobBuilder;
const loadImage = (src, width, height, crossOrigin) => {
  return new Promise(function(resolve, reject) {
    let img = new Image();
    img.width = width;
    img.height = height;

    if (crossOrigin) {
      img.crossOrigin = crossOrigin;
    }
    img.onload = function() {
      resolve(img);
    };
    img.onerror = reject;
    img.src = src;
  });
};

const imgToCanvas = img => {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  // copy the image contents to the canvas
  var context = canvas.getContext("2d");
  context.drawImage(img, 0, 0, img.width, img.height);

  return canvas;
};

const binaryStringToArrayBuffer = binary => {
  var length = binary.length;
  var buf = new ArrayBuffer(length);
  var arr = new Uint8Array(buf);
  var i = -1;
  while (++i < length) {
    arr[i] = binary.charCodeAt(i);
  }
  return buf;
};

const createBlob = (parts, properties) => {
  parts = parts || [];
  properties = properties || {};
  if (typeof properties === "string") {
    properties = { type: properties }; // infer content type
  }
  try {
    return new Blob(parts, properties);
  } catch (e) {
    if (e.name !== "TypeError") {
      throw e;
    }
    var Builder =
      typeof BlobBuilder !== "undefined"
        ? BlobBuilder
        : typeof MSBlobBuilder !== "undefined"
        ? MSBlobBuilder
        : typeof MozBlobBuilder !== "undefined"
        ? MozBlobBuilder
        : WebKitBlobBuilder;
    var builder = new Builder();
    for (var i = 0; i < parts.length; i += 1) {
      builder.append(parts[i]);
    }
    return builder.getBlob(properties.type);
  }
};

const dataURLToBlob = dataURL => {
  console.log(dataURL);
  var type = dataURL.match(/data:([^;]+)/)[1];
  var base64 = dataURL.replace(/^[^,]+,/, "");

  var buff = binaryStringToArrayBuffer(atob(base64));
  return createBlob([buff], { type: type });
};

const canvasToBlob = (canvas, type) => {
  if (typeof canvas.toBlob === "function") {
    return new Promise(function(resolve) {
      canvas.toBlob(resolve, type);
    });
  }
  return Promise.resolve(dataURLToBlob(canvas.toDataURL(type)));
};

const imgSrcToBlob = (src, type, width, height, crossOrigin) => {
  type = type || "image/png";
  width = width || 200;
  height = height || 200;

  return loadImage(src, width, height, crossOrigin)
    .then(imgToCanvas)
    .then(function(canvas) {
      return canvasToBlob(canvas, type);
    });
};

export default imgSrcToBlob;
