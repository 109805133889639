import React from "react";

export default class PostPonepaymentQuestion {
  static whenYouPostPonePayment = () => {
    return (
      <div className="faqAnswer">
        <ul className="blackdisc">
          <li>Claims with an invoice date prior to your regularly scheduled billing date will be processed immediately.</li>
          <li>Claims with an invoice date after your regularly scheduled billing date will not be processed until after your premium is received.</li>
          <li>New pets cannot be added to your account.</li>
          <li>If you update your billing information between your regular billing date and the date of the</li>
          <li>postponed payment, your account will revert to the original billing date and your premium will be collected accordingly.</li>
          <li>If your premium is not collected on the postponed date our cancellation process begins on the billing day the premium was due. Information on policy cancellations can be found in your Policy Documents.</li>
        </ul>
      </div>
    );
  };
}
