import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPetsByAccount } from "../../../store/actions/pets-actions";
import { AppLinks } from "../../../constants/app-links";
import { Link } from "react-router-dom";
import "./HomeBanner.css";


class HomeBanner extends Component {
  today = null;
  constructor(props) {
    super(props);

    this.activePet = {};
    this.AddVetClinicBg = "../../assets/images/AddVetClinic.png";
    this.ctaLink = AppLinks.policy.viewPetDetails;
    this.fetchingError = "";
  }

  
  

  render() {
    var bgImage = {
      backgroundImage: "url(" + this.AddVetClinicBg + ")"
    };

    return (
      <div className="container homeBanner">
        <div className="row">
          <div className="col-xl-9 col-md-11 mx-auto main-content" style={bgImage}>
             <div className="row">
                <div className="col-md-12 align-self-center" >
                  <h2 className="heading">Add Your Vet Information</h2>
                  <p className="subHeading">To help us process your claims smoothly</p>
                  <Link to={{
    pathname: this.ctaLink,
    state: {
      showForm: true,
    },
  }} className="CTA">Add a Vet/Clinic</Link>
                  {/* <a href={this.ctaLink} className="CTA">Add a Vet/Clinic</a> */}
                </div>
                <div className="col-md-12 d-block d-md-none">
                  <img className="AddVetClinicImg" src={this.AddVetClinicBg} />
                </div>
                
             </div>
          </div>
        </div>
      </div>
    );
  }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  petState: ((state.pets.petsDetails.filter(pet => pet.Status =="Active").length !=0) ? state.pets.petsDetails.filter(pet => pet.Status === "Active") : state.pets.petsDetails)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getPetsByAccount }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeBanner);
