import React, { Component } from "react";
import { Link } from "react-router-dom";
import ModalDialog from "./../modal-dialog/ModalDialog";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { deleteMessageAPI } from "./../../../api/messages-api";
import { deleteMessage } from "./../../../store/actions/messages-action";
import { AppLinks } from "../../../constants/app-links";
import Util from "../../common/util/util";
import "./Messages.css";
import MessageUtil from "./MessageUtil";

class MessageModal extends Component {
  deleteMessageClick = () => {
    const messageId = this.props.selectedMessage.id;

    this.props.toggleMessageModal();

    this.props.actions.deleteMessage(messageId);
    // Fire and Forget
    deleteMessageAPI(messageId);
  };

  MessageModalHeader = () => {
    return <h1 className="pt-lg-0 pb-lg-0">Message</h1>;
  };

  ModalMessageType = (title, retryBillingHandler, toggleMessageModal) => {
    if (title) {
      if (title.toLowerCase() === "your action needed!") {
        return (
          <Link to={AppLinks.claims.claimStatus}>
            <button
              onClick={toggleMessageModal}
              className="btn btn-secondary mr-3 message-action-btn"
            >
              View Claim
            </button>
          </Link>
        );
      } else if (title.toLowerCase() === "update billing info!") {
        return (
          <div>
            <Link to={AppLinks.account.updateBillingInfo}>
              <button
                onClick={toggleMessageModal}
                className="btn btn-secondary mr-3 message-action-btn"
              >
                Update Billing Info
              </button>
            </Link>
            <button
              className="btn btn-secondary mr-3 message-action-btn"
              onClick={() => retryBillingHandler()}
            >
              Retry Billing
            </button>
          </div>
        );
      } else if (title.toLowerCase() === "credit card expired!") {
        return (
          <Link to={AppLinks.account.updateBillingInfo}>
            <button className="btn btn-secondary mr-3 message-action-btn">
              Update Billing
            </button>
          </Link>
        );
      }
      return null;
    }
    return null;
  };

  MessageModalBody = (
    selectedMessage,
    retryBillingHandler,
    toggleMessageModal
  ) => {
    return (
      <div id="message-modal-body-container">
        <div
          className={
            "text-capitalize modal-body-messsage-header message-alert-modal-title " +
            (MessageUtil.isMessageImportant(selectedMessage.title)
              ? "text-danger "
              : "text-dark")
          }
        >
          <strong>{selectedMessage.title}</strong>
        </div>
        <div className="modal-body-message-datetime">
          {Util.formatMessageDate(selectedMessage.SendDate)}
        </div>
        <div className="text-dark modal-body-message">
          {selectedMessage.Message}
        </div>
        {this.ModalMessageType(
          selectedMessage.title,
          retryBillingHandler,
          toggleMessageModal
        )}
      </div>
    );
  };

  MessageModalFooter = () => {
    return (
      <div
        onClick={() => this.deleteMessageClick()}
        style={{ margin: "0 auto", marginTop: -10 }}
      >
        <div className="text-secondary">
          <strong>Delete Message</strong>
        </div>{" "}
      </div>
    );
  };

  render() {
    return this.props.isMessageModalOpen ? (
      <ModalDialog
        isOpen={this.props.isMessageModalOpen}
        toggle={this.props.toggleMessageModal}
        modalHeader={this.MessageModalHeader()}
        modalBody={this.MessageModalBody(
          this.props.selectedMessage,
          this.props.retryBillingHandler,
          this.props.toggleMessageModal
        )}
        modalFooter={this.MessageModalFooter()}
      />
    ) : null;
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ deleteMessage }, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(MessageModal);
