import React, { Component } from "react";

import Stepper from "../common/stepper/Stepper";
import NewAddress from "./new-address/NewAddress";
import ApproveAddress from "./approve-address/ApproveAddress";
import SuccessView from "./success-view/SuccessView";

import { CreateAddressUpdateAlert } from "./../common/alerts-notifications/AlertsConfigurationList";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addClientAlert,
  getAlertsByAccount
} from "./../../store/actions/alerts-actions";
import { getAccountInfo, setAccountDataRefresh } from "./../../store/actions/accounts-actions.js";

import "./ChangeAddress.css";

class ChangeAddress extends Component {
  constructor() {
    super();
    this.state = {
      formData: null,
      premiumUpdateVerifyData: null,
      responseData: null
    };
  }

  componentDidMount() {
    this.props.actions.getAccountInfo();
    window.scrollTo(0, 0)
  }  

  createClientAlert = newPremium => {
    //On success of Address change add a Alert with new premium rate
    //Added span tags in message to mimic the response structure of server.
    //So that handling of bold and URL styles would be easy in Alert Component.
    const alertObj = CreateAddressUpdateAlert(newPremium);
    this.props.actions.addClientAlert(alertObj);

    //Need to refetch Alerts on successful address change
    this.props.actions.getAlertsByAccount();
  };

  setResponseData = responseData => {
    this.setState({ responseData: responseData });
  };

  setUpdatedPremiumData = (formData, premiumUpdateVerifyData) => {
    var existingPetPolicies = premiumUpdateVerifyData.existing_pet_policies;
    var newPetPolicies = premiumUpdateVerifyData.new_pet_policies;

    var petComparisonArray = [];

    var currentPremiumTotal = 0;
    var newPremiumTotal = 0;

    if (newPetPolicies.length > 0) {
      for (
        var policyIndex = 0;
        policyIndex < newPetPolicies.length;
        policyIndex++
      ) {
        var newPolicyDetail = newPetPolicies[policyIndex];
        var oldPolicyDetail = existingPetPolicies.find(
          policy => policy.pet.pet_id === newPolicyDetail.pet.pet_id
        );
        var petDetail = {};

        if (oldPolicyDetail && oldPolicyDetail.pet && oldPolicyDetail.policy) {
          petDetail.petName = oldPolicyDetail.pet.pet_name;

          petDetail.oldMonthlyPremium = oldPolicyDetail.policy.monthly_premium;
          petDetail.oldReimbursement = oldPolicyDetail.policy.reimbursement;
          petDetail.oldDeductible = oldPolicyDetail.policy.deductible;

          petDetail.newMonthlyPremium = newPolicyDetail.policy.monthly_premium;
          petDetail.newReimbursement = newPolicyDetail.policy.reimbursement;
          petDetail.newDeductible = newPolicyDetail.policy.deductible;

          petComparisonArray.push(petDetail);

          currentPremiumTotal += parseFloat(
            oldPolicyDetail.policy.monthly_premium
          );
          newPremiumTotal += parseFloat(newPolicyDetail.policy.monthly_premium);
        }
      }
    }

    premiumUpdateVerifyData.petComparisonArray = petComparisonArray;
    premiumUpdateVerifyData.currentPremiumTotal = currentPremiumTotal.toFixed(
      2
    );
    premiumUpdateVerifyData.newPremiumTotal = newPremiumTotal.toFixed(2);

    this.setState({
      formData: formData,
      premiumUpdateVerifyData: premiumUpdateVerifyData
    });
  };

  render() {
    return (
      <div style={{ position: this.props.canUserEdit ? "static" : "relative" }}>
        {this.props.canUserEdit ? null : <div className="disable-user-edit" />}
        <Stepper hasSuccess={true} headerTitle="Change Physical Address">
          <Stepper.Step>
          <NewAddress setUpdatedPremiumData={this.setUpdatedPremiumData}
              userAccountDetails={this.props.userAccountDetails}
              userAccountDetailsFetching={this.props.userAccountDetailsFetching}
              formData={this.state.formData} /> 
          </Stepper.Step>
          <Stepper.Step>
            <ApproveAddress
              setAccountDataRefresh={this.props.actions.setAccountDataRefresh}
              createClientAlert={this.createClientAlert}
              formData={this.state.formData}
              premiumUpdateVerifyData={this.state.premiumUpdateVerifyData}
              setResponseData={this.setResponseData}
            />
          </Stepper.Step>
          <Stepper.Step>
            <SuccessView
              responseData={this.state.responseData}
              backToHome={() => {
                this.props.history.push("/");
              }}
            />
          </Stepper.Step>
        </Stepper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  canUserEdit: state.account.canUserEdit,
  userAccountDetailsFetching: state.account.fetchingAccount,
  userAccountDetails: state.account.userAccountDetails,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { getAccountInfo, addClientAlert, getAlertsByAccount, setAccountDataRefresh },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeAddress);
