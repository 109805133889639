import React from 'react';
import {FAQLinks} from "../../../constants/app-links"
import Util from "../../common/util/util";


export default class PriorMedicalTreatment {
    static lumps = (policyFormUrl, questionOpen) => {
        return (
            <div className="faqAnswer" >
              <p>
               If a {" "}
               <span ref={element => { 
         if (element) element.style.setProperty('color', 'black', 'important');}} style={{fontStyle:"italic",textDecoration:"underline",cursor:"pointer"}} 
         onClick={(e) => {questionOpen('pre-existing_condition');}} rel="noopener noreferrer">
               pre-existing growth
               </span> is malignant or cancerous, your Healthy Paws plan will
               be unable to provide coverage. If a pre-existing growth is benign, it would be
               unable to provide coverage for the pre-existing growth (for example the
               removal). However, new growths including cancer that occur after enrollment
               and applicable waiting period(s) would be eligible for coverage.
              </p>
              <p>
               Some common benign growths include lipomas (fatty deposits), sebaceous cysts,
               papilloma (wart-like growths) and histiocytomas (firm inflammations on the
               skin). 
              </p>
              <p>
              { policyFormUrl ? (
                <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                To review your policy, please click here.
                </a>
              ) : (
                <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                </a>
              )}
              </p>
            </div>
          );
    }

    static intestinalParasites = (policyFormUrl) => {
        return (

            <div className="faqAnswer">
              <p>
                    If your pet is diagnosed with an intestinal parasite prior to enrollment or during any applicable waiting period, the plan would not cover any follow-up care to this condition, including the recheck of the fecal sample or refill of medications.
              </p>
              <p>
                    However, as long as the parasite has been cleared up, your pet has had a negative fecal exam, and an exam where the stools were normal, then future instances of this condition would be eligible for coverage.
              </p>
              <p>
              { policyFormUrl ? (
                <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                To review your policy, please click here.
                </a>
              ) : (
                <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                </a>
              )}
              </p>
            </div>
          );
    }

    static earInfections = (policyFormUrl) => {
        return (

            <div className="faqAnswer">
              <p>
                If your pet is being treated for an ear infection prior to your coverage taking
                effect, the plan would be unable to provide coverage for any follow-up care to this condition. If your pet has a history of recurring ear infections, the plan would
                not be able to cover ear infections in the future.
              </p>
              <p>
                If your pet had an ear infection prior to your coverage taking effect but does not
                have a chronic history of such infections or an underlying condition such as
                allergies suspected of causing infections, coverage may be available for future ear
                infections.
              </p>
              <p>
              { policyFormUrl ? (
                <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                To review your policy, please click here.
                </a>
              ) : (
                <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                </a>
              )}
              </p>
            </div>
          );
    }

    static urinaryBlockage = (policyFormUrl) => {
        return (

            <div className="faqAnswer" >
              <p>
                  Since urinary blockages have a high propensity to recur and are usually caused
                  by underlying urinary issues, this would be considered a pre-existing condition if
                  your pet was diagnosed with the blockage before enrolling. In that event, it
                  would be unlikely the Healthy Paws plan would be able to provide coverage for
                  urinary issues for your pet.
              </p>
              <p>
              { policyFormUrl ? (
                <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                To review your policy, please click here.
                </a>
              ) : (
                <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                </a>
              )}
              </p>
            </div>
          );
    }

    static urinaryInfection = (policyFormUrl) => {
        return (

            <div className="faqAnswer" >
              <p>
                If your cat had a bacterial urinary tract infection (UTI) when he/she was a kitten
                and it was resolved with antibiotics and without a special urinary diet, then the
                pet insurance plan may cover urinary tract infections in the future.
              </p>
              <p>
                If your cat had a urinary tract infection with crystals prior to your enrollment
                (urinary crystals have a high propensity to recur and need further treatment or
                maintenance with a special urinary diet), the plan would be unable to cover
                urinary issues for your cat in the future.
              </p>
              <p>
              { policyFormUrl ? (
                <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                To review your policy, please click here.
                </a>
              ) : (
                <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                </a>
              )}
              </p>
            </div>
          );
    }
    static urinaryDogs = (policyFormUrl) => {
        return (

            <div className="faqAnswer" >
              <p>
                 If the urinary tract infection (UTI) was an acute infection that resolved
                 completely with antibiotics, the plan may provide coverage. If there are
                 underlying urinary issues or your dog continues to experience chronic UTIs, this
                 would likely be considered pre-existing. The pet's medical history would be
                 reviewed to determine coverage eligibility.
              </p>
              <p>
              { policyFormUrl ? (
                <a style={{fontWeight:"600"}} href={policyFormUrl} target="_blank" rel="noopener noreferrer">
                To review your policy, please click here.
                </a>
              ) : (
                <a style={{fontWeight:"600"}} href={FAQLinks.FAQPicker} target="_blank" rel="noopener noreferrer">
                                To review your policy, please click here.
                </a>
              )}
              </p>
            </div>
          );
    }
}