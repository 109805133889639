import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getPetsByAccount } from "../../store/actions/pets-actions";
import { getAccountInfo } from "../../store/actions/accounts-actions";
import { setNewRoute } from "../../store/actions/alerts-actions";

import Stepper from "../common/stepper/Stepper";
import Loader from "../common/loader/Loader";

import CancelComponent from "./cancelComponent/cancel-component";
import CancelConfirm from "./cancelConfirm/CancelConfirm";

import Util from "../common/util/util";

import "./cancel-policy.css";

class CancelPolicy extends Component {
  constructor() {
    super();
    this.state = {
      formData: null,
      expiryDate: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.actions.getPetsByAccount();
  }

  setData = (formData, expiryDate) => {
    this.setState({
      formData: formData,
      expiryDate: expiryDate
    });
  };

  render() {
    let petDetailsText =
      this.props.petDetails && this.props.petDetails.length > 0
        ? Util.getCommaSeperatedNameString(
            this.props.petDetails.map(pet => {
              return pet.Name;
            })
          )
        : "your pets ";

    return (
      <div
        style={{ position: this.props.canUserEdit ? "static" : "relative" }}
        className="cancel-parent-container"
      >
        {this.props.canUserEdit ? null : <div className="disable-user-edit" />}
        {this.props.fetchingPets || this.props.userAccountDetailsFetching ? (
          <Loader position="absolute" fullPage={true}/>
        ) : (
          <Stepper hasSuccess={false} headerTitle="Cancel Account">
            <Stepper.Step>
              <CancelComponent
                {...this.props}
                setNewRoute={this.props.actions.setNewRoute}
                petDetailsText={petDetailsText}
                setData={this.setData}
              />
            </Stepper.Step>
            <Stepper.Step>
              <CancelConfirm
                {...this.props}
                petDetailsText={petDetailsText}
                formData={this.state.formData}
                expiryDate={this.state.expiryDate}
                setNewRoute={this.props.actions.setNewRoute}
              />
            </Stepper.Step>
          </Stepper>
        )}
      </div>
    );
  }
}

// TODO: Bind PetDetail Fetching from Redux
const mapStateToProps = state => ({
  fetchingPets: state.pets.fetchingPets,
  petDetails: state.pets.petsDetails.filter(pet => pet.Status === "Active"),
  canUserEdit: state.account.canUserEdit,
  userAccountDetails: state.account.userAccountDetails,
  userAccountDetailsFetching: state.account.fetching
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { getPetsByAccount, getAccountInfo, setNewRoute },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelPolicy);
