import React from "react";
import { Link } from "react-router-dom";
import { AppLinks } from "../../../constants/app-links";
import { dateSuffix } from "../../common/util/util";

const BillingInfo = ({creditCard ,billingInfo, status, premium , billingDay, newMonthlyPremium, changeEffectiveDate}) => {
    let mm = '00';
    let dd = '00';
    if(newMonthlyPremium) {
        const date = new Date(changeEffectiveDate);
        mm = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
        dd = (date.getDate() < 10 ? '0' : '') + (date.getDate());
    }
    return (
        <div className="d-md-flex flex-column justify-content-between" >
            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading" >Billing Information</span>
                    { status.toLowerCase().trim() === "cancel prorata" ? null :<Link to={AppLinks.account.updateBillingInfo} className="text-secondary" >Edit</Link>}
                    {/* { status.toLowerCase().trim() === "cancel prorata" ? null :<Link to={AppLinks.account.updateBillingInfo} className=" d-block d-lg-none" ><button className="btn btn-outline-secondary pt-0 pb-0">Edit</button></Link> } */}
                </div>
                <span>Card ending in {creditCard} </span> <br />
                <span> {billingInfo.Name} </span> <br />
                <span> {billingInfo.Street} </span> <br />
                <span> {billingInfo.City}, {billingInfo.State} {billingInfo.PostalCode} </span>
            </div>

            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading" >Billing Status</span>                    
                </div>
                { 
                    status === "Active" || status === "Transfer in Progress" ? <span className="text-primary"> Current </span>
                        : status === "Postpone Payment" ? <span className="text-danger"> Postponed Payment </span>
                            : status === "Suspended" ? <span className="text-danger">Suspended</span>
                            : status.toLowerCase().trim() === "cancel prorata" ? <span className="text-danger">Pending Cancel</span>
                                : <span className="text-danger">Past Due</span>
                    //Note: extra information displayed as inline alert boxes in existing rcc are shown in the general alerts.
                }
            </div>

            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading" >
                        { newMonthlyPremium
                            ? "Current "
                            : ""
                        }Monthly Premium
                    </span>                  
                </div>
                <span> {premium} <i style={{fontWeight: 200}}>(on the {dateSuffix(billingDay)} of each month)</i></span>
            </div>

            {newMonthlyPremium &&
             <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading text-primary" >New Monthly Premium</span>                    
                </div>
                <div> <span className="text-primary"> ${Number(newMonthlyPremium).toFixed(2)} </span> <i>({mm + "/" + dd})</i></div>
            </div>}
        </div>
    )
}

export default BillingInfo;