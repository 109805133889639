export const filters = [
  {
    filter: [
      1, 0, 0, 0, 0,
      0, 1, 0, 0, 0,
      0, 0, 1, 0, 0,
      0, 0, 0, 1, 0
    ],
    name: "Original"
  },
  {
    filter: [
      1, 0, 0, 0, -0.1,
      0, 1, 0, 0, -0.1,
      0, 0, 1, 0, -0.1,
      0, 0, 0, 1, 0
    ],
    name: "Lola"
  },
  {
    filter: [
      1.2, 0, 0, 0, 0,
      0, 1.2, 0, 0, 0,
      0, 0, 1.2, 0, 0,
      0, 0, 0, 1, 0
    ],
    name: "Buddy"
  },
  {
    filter: [
      1.2, 0, 0, 0, 0,
      0.2, 1, 0, 0, 0,
      0.2, 0, 1, 0, 0,
      0, 0, 0, 1, 0
    ],
    name: "Tucker"
  },
  {
    filter: [
      1, 0, 0, 0, 0,
      0, 1, 0, 0, 0.1,
      0, 0, 1, 0.2, 0,
      0, 0, 0, 1, 0
    ],
    name: "Boots"
  },
  {
    filter: [
      1.2, 0, 0, 0, 0,
      0, 1, 0, 0, 0,
      0, 0, 1, 0, 0,
      0, 0, 0, 1, 0
    ],
    name: "Bella"
  },
  {
    filter: "grayscale",
    name: "Luna"
  }
];
