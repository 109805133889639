export default class StateList {
    static selectYourState = "Select Your State";
    static stateArray = [{
            displayName: StateList.selectYourState,
            value: StateList.selectYourState
        }, {
            displayName: "Alabama",
            value: "AL"
        },
        {
            displayName: "Alaska",
            value: "AK"
        },
        {
            displayName: "Arizona",
            value: "AZ"
        },
        {
            displayName: "Arkansas",
            value: "AR"
        },
        {
            displayName: "California",
            value: "CA"
        },
        {
            displayName: "Colorado",
            value: "CO"
        },
        {
            displayName: "Connecticut",
            value: "CT"
        },
        {
            displayName: "Delaware",
            value: "DE"
        },
        {
            displayName: "District Of Columbia",
            value: "DC"
        },
        {
            displayName: "Florida",
            value: "FL"
        },
        {
            displayName: "Georgia",
            value: "GA"
        },
        {
            displayName: "Hawaii",
            value: "HI"
        },
        {
            displayName: "Idaho",
            value: "ID"
        },
        {
            displayName: "Illinois",
            value: "IL"
        },
        {
            displayName: "Indiana",
            value: "IN"
        },
        {
            displayName: "Iowa",
            value: "IA"
        },
        {
            displayName: "Kansas",
            value: "KS"
        },
        {
            displayName: "Kentucky",
            value: "KY"
        },
        {
            displayName: "Louisiana",
            value: "LA"
        },
        {
            displayName: "Maine",
            value: "ME"
        },
        {
            displayName: "Maryland",
            value: "MD"
        },
        {
            displayName: "Massachusetts",
            value: "MA"
        },
        {
            displayName: "Michigan",
            value: "MI"
        },
        {
            displayName: "Minnesota",
            value: "MN"
        },
        {
            displayName: "Mississippi",
            value: "MS"
        },
        {
            displayName: "Missouri",
            value: "MO"
        },
        {
            displayName: "Montana",
            value: "MT"
        },
        {
            displayName: "Nebraska",
            value: "NE"
        },
        {
            displayName: "Nevada",
            value: "NV"
        },
        {
            displayName: "New Hampshire",
            value: "NH"
        },
        {
            displayName: "New Jersey",
            value: "NJ"
        },
        {
            displayName: "New Mexico",
            value: "NM"
        },
        {
            displayName: "New York",
            value: "NY"
        },
        {
            displayName: "North Carolina",
            value: "NC"
        },
        {
            displayName: "North Dakota",
            value: "ND"
        },
        {
            displayName: "Ohio",
            value: "OH"
        },
        {
            displayName: "Oklahoma",
            value: "OK"
        },
        {
            displayName: "Oregon",
            value: "OR"
        },
        {
            displayName: "Pennsylvania",
            value: "PA"
        },
        {
            displayName: "Rhode Island",
            value: "RI"
        },
        {
            displayName: "South Carolina",
            value: "SC"
        },
        {
            displayName: "South Dakota",
            value: "SD"
        },
        {
            displayName: "Tennessee",
            value: "TN"
        },
        {
            displayName: "Texas",
            value: "TX"
        },
        {
            displayName: "Utah",
            value: "UT"
        },
        {
            displayName: "Vermont",
            value: "VT"
        },
        {
            displayName: "Virginia",
            value: "VA"
        },
        {
            displayName: "Washington",
            value: "WA"
        },
        {
            displayName: "West Virginia",
            value: "WV"
        },
        {
            displayName: "Wisconsin",
            value: "WI"
        },
        {
            displayName: "Wyoming",
            value: "WY"
        }
    ];

    static getStateList = () => {
        if (window.innerWidth > 576) {
            return StateList.stateArray;
        } else {
            return StateList.stateArray.map(item => {
                item.displayName = item.value;
                return item;
            });
        }
    }

}