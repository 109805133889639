import React, { Component } from "react";


import "./RaFTermsConditions.css";

export default class RaFTermsConditions  extends Component {


    componentDidMount(){
        window.scrollTo(0, 0);
    }


    render() {
        return (
            <div className="container mt-3 mb-3 mt-lg-0 terms-conditions">
                <div className="row">
                    <div className="col-12">
                        <h1 className="mb-3 mb-lg-0">Refer-A-Friend Terms and Conditions</h1>
                        <p> By participating in the Healthy Paws Pet Insurance LLC ("Healthy Paws," "Company") Refer a Friend program ("Program"), you agree to receive communications from Healthy Paws with regards to your participation. The following Terms & Conditions shall apply to participation in the Program:</p>
                        <ol>
                            <li>
                                <p>
                                    <strong className="d-block mt-3 mb-3">How the Program Works.</strong>
                                    <span className="d-block">To participate, visit the Refer a Friend web page and follow the on-screen instructions to refer friends, family members or colleagues to Healthy Paws by entering their names and email addresses in the "Refer a Friend" box or by sharing through Facebook and Twitter.</span>

                                    <span className="d-block">Users who refer are called "Referrers." Individuals who receive a referral are called "Referred Friends." An "eligible" Referrer who is fully compliant with these Terms &amp; Conditions may qualify for "Donation(s)" for every "Completed Referral."</span>
                                </p>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Eligible Referrer</strong>
                                    To be "eligible," a Referrer must:
                                </p>
                                <ol>
                                    <li>be a legal resident of the United States of America; and</li>
                                    <li>be at least 18 years old; and</li>
                                    <li>be a resident of a state where the Program is not prohibited by applicable law or regulation.</li>
                                </ol>
                                <p />
                                <p>
                                    <strong className="d-block mt-3 mb-3">Making a Referral</strong>
                                    <span className="d-block">Once an individual refers a friend, family member, or colleague, that individual becomes a Referrer and will be provided with a unique referral link ("Personal Link") that allows the Referrer to receive a donation credit for a Completed Referral ("Donation Credit"). Personal Links are only issued to individuals.</span>
                                    <span className="d-block">Referrers must respect the spirit and rules of the Program by only referring real individuals who meet the requirements of these Terms &amp; Conditions. Referrers cannot refer themselves. For example, a Referrer may not create multiple or fake accounts with Healthy Paws or participate in the Program using multiple or fake email addresses or identities. Referrers may not participate in the Program where doing so would be prohibited by any applicable law. Referrers may not use Spam to obtain referrals (See the section below Bulk Referrals – "Spam" for more details), or through message board postings, where the postings are unrelated to the topic of discussion or otherwise violate the terms of the site.</span>
                                </p>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Completed Referral</strong>
                                    A Completed Referral means that all the following conditions are met:
                                </p><ol>
                                    <li>The Referred Friend completed the Healthy Paws online application process ("Completed Submitted Application") using the Referrer’s "Personal Link," or provides the Referrer’s policy number or full name while completing the application process by phone. A Completed Submitted Application is defined as a person that receives a pet insurance quote and completes the application information including all required pet underwriting information.</li>
                                    <li>If a Referred Friend completes the application process with Healthy Paws using any other link or method other than what is defined above, the registration will not count as a Completed Referral and the Referrer will not earn a Donation Credit;</li>
                                    <li>The Referred Friend is not currently or previously a customer with Healthy Paws</li>
                                    <li>The Referred Friend is a) a legal resident of the United States of America and b) at least 18 years old; and</li>
                                    <li>Only one Completed Referral qualifies for a Donation Credit for each Referred Friend.</li>
                                    <li>You may not participate in the Program where doing so would be prohibited by any applicable law or regulations.</li>
                                </ol>
                                <p />
                                <p>
                                    <strong className="d-block mt-3 mb-3">Credit for Completed Referrals</strong>
                                    Donation Credit can only be awarded for Completed Referrals.
                                </p>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Earning Donations Credits</strong>
                                    <span className="d-block">Referrer shall receive one (1) Donation Credit valued at $25 that is directly donated to the Healthy Paws Foundation for each verified Completed Referral generated by Referrer.</span>
                                    <span className="d-block">Healthy Paws Foundation may then utilize that donation to further donate to animal rescue and shelters as per Healthy Paws Foundation's sole discretion. Check your Healthy Paws Refer a Friend account for details. Restrictions may apply.</span>
                                </p>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Verified Completed Referrals</strong>
                                    <span className="d-block">Donation Credits are subject to verification. The Company may delay a Donation Credit for the purposes of investigation. It may also refuse to verify and process any transaction Company deems, in its sole discretion, to be fraudulent, suspicious, in violation of these Terms &amp; Conditions, or likely to impose potential liability on Company, its subsidiaries, affiliates, or any of their respective officers, directors, employees, representatives, and agents.</span>
                                    <span className="d-block">All of the Company's decisions are final and binding, including decisions as to whether a Completed Referral or Donation Credit is verified.</span>
                                </p>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Transfer and Value of Donation Credits </strong>
                                    Donation Credits are not transferable and may not be auctioned, traded, bartered or sold. Upon termination of the Program or any portion thereof for any reason, any unredeemed Donation Credits accumulated by the Referrer may be forfeited.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Privacy</strong>
                            
                                    <span className="d-block"> Individuals may participate in the Program to recommend Healthy Paws or content made available by Company to their friends, family or colleagues ("Users"). To do this, Users must necessarily submit personal information about themselves and their friends, family members or colleagues, such as name and e-mail address information, so that the Company can send these recommendations on their behalf. The personal information will be collected, processed and used in accordance with Company’s Privacy Statement, which can be found at <a href="http://www.healthypawspetinsurance.com/PrivacyPolicy" rel="noopener noreferrer" target="_blank">http://www.healthypawspetinsurance.com<span className="d-block d-md-inline">/PrivacyPolicy</span></a></span>

                                    <span className="d-block"> In addition, personal information may be used by Company to contact Users with regards to their participation in the Program and to receive communications from Company. Where a User provides personal information about its friends, family members or colleagues to receive communications via the Program, the provided personal information will be used by the Company for sending these communications on behalf of the User and User understands that Company may send out additional follow-up communications on behalf of the User to encourage or remind the friends, family members or colleagues to complete the purchase or registration process.</span>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Liability</strong>
                                    By participating in the Program, Users agree to:
                                </p>
                                <ol>
                                    <li>Be bound by these Terms &amp; Conditions, the decisions of the Company and their designees, and the Privacy Policy of the Company;</li>
                                    <li>Defend, indemnify, release, and hold harmless the Company or their respective parent companies, affiliates, and subsidiaries, together with their respective employees, directors, officers, licensees, licensors, shareholders, attorneys, and agents including, without limitation, their respective advertising and promotion entities and any person or entity associated with the production, operation, or administration of the Program (collectively, the "Released Parties"), from any and all claims, actions, demands, damages, losses, liabilities, costs or expenses caused by, arising out of, in connection with, or related to their participation in the Program; and</li>
                                    <li>be contacted by Healthy Paws via email.</li>
                                </ol>

                                <span className="d-block"> Company shall not be liable for:</span>
                                <ol>
                                    <li>late, lost, delayed, stolen, misdirected, incomplete, unreadable, inaccurate, unreliable, garbled or unintelligible entries, communications or affidavits, regardless of the method of transmission;</li>
                                    <li>telephone system, telephone or computer hardware, software or other technical or computer malfunctions, lost connections, disconnections, delays or transmission errors;</li>
                                    <li>data corruption, theft, destruction, unauthorized access to or alteration of entry or other materials;</li>
                                    <li>any injuries, losses or damages of any kind resulting from acceptance, possession or use of a Donation Credit, or from participation in the Program, that were not reasonably foreseeable to the Company at the relevant time;</li>
                                    <li>any printing, typographical, administrative or technological errors in any websites or materials associated with the Program; or</li>
                                    <li>claims, demands, and damages in disputes among Users of the Program.</li>
                                </ol>

                                <span className="d-block">The Company disclaims any liability for damage to any computer system resulting from participating in, or accessing or downloading information in connection with the Program, and reserve the right, in their sole discretion, to cancel, modify or suspend the Program should a virus, bug, computer problem, unauthorized intervention or other causes beyond the Company's control, corrupt the administration, security or proper play of the Program.</span>
                                <span className="d-block">The Company shall not be liable to any Users for failure to provide Donation Credits or any part thereof, by reason of any acts of God, any action(s), regulation(s), order(s) or request(s) by any governmental or quasi-governmental entity (whether or not the action(s), regulations(s), order(s) or request(s) prove(s) to be invalid), equipment failure, threatened terrorist acts, terrorist acts, air raid, blackout, act of public enemy, earthquake, tornado, tsunami, war (declared or undeclared), fire, flood, epidemic, explosion, unusually severe weather, hurricane, embargo, labor dispute or strike (whether legal or illegal), labor or material shortage, transportation interruption of any kind, work slow-down, civil disturbance, insurrection, riot, or any other similar or dissimilar cause beyond any of the Released Parties’ control.</span>
                                <span className="d-block">The Company reserves the right to cancel or suspend the Program should it determine, in its sole discretion, that the administration, security or fairness of the Program has been compromised in any way.</span>
                                
                                <p>
                                    <strong className="d-block mt-3 mb-3">Disclaimer of Warranties</strong>
                                    USERS EXPRESSLY UNDERSTAND AND AGREE THAT: (A) YOUR USE OF THE PROGRAM IS AT YOUR SOLE RISK, THE PROGRAM IS PROVIDED ON AN "AS-IS" AND "AS-AVAILABLE" BASIS AND THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES, CONDITIONS, AND TERMS (COLLECTIVELY, "PROMISES") OF ANY KIND, WHETHER EXPRESS OR IMPLIED BY STATUTE, COMMON LAW, OR CUSTOM, INCLUDING, BUT NOT LIMITED TO, WARRANTIES AS TO PRODUCTS OF HEALTHY PAWS OFFERED THROUGH THE USE OF THE PROGRAM, IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT; (B) THE COMPANY MAKES AND GIVES NO WARRANTY THAT (i) THE PROGRAM WILL MEET YOUR REQUIREMENTS, (ii) THE PROGRAM WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS OBTAINED FROM THE USE OF THE PROGRAM WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU THROUGH THE PROGRAM WILL MEET YOUR EXPECTATIONS, AND (v) ANY ERRORS IN THE PROGRAM WILL BE CORRECTED; AND (C) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE PROGRAM IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY SUCH MATERIAL.
                                    </p>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Limitation of Liability and Indemnification</strong>
                                    <span className="d-block">YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE COMPANY, INCLUDING ANY VENDORS AND HEALTHY PAWS PROVIDERS ASSOCIATED WITH OR ASSISTING IN PROVIDING THE PROGRAM, SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF THE COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE THE PROGRAM; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS RESULTING FROM ANY GOODS, DATA OR INFORMATION OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH, FROM, OR AS A RESULT OF THE PROGRAM; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OR THROUGH THE PROGRAM; OR (v) ANY OTHER MATTER RELATING TO THE PROGRAM. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS IN THIS PARAGRAPH MAY NOT APPLY TO YOU.</span>
                                    <span className="d-block">TO THE FULLEST EXTENT POSSIBLE BY LAW, THE COMPANY’S (INCLUDING ANY VENDORS AND PROVIDERS) MAXIMUM LIABILITY ARISING OUT OF OR IN CONNECTION WITH THE PROGRAM, REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY, OR OTHERWISE), WILL NOT EXCEED $100.</span>
                                    <span className="d-block">Users should use the Program at their own risk.</span>
                                </p>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Bulk Distribution ("Spam")</strong>
                                    <span className="d-block">If a Referrer provides a Personal Link to a Referred Friend by email, the email must be created and distributed in a personal manner that is appropriate and customary for communications with friends, colleagues, and family members. By submitting the email addresses, the Referrer represents that he/she has the prior consent of a Referred Friend to submit their email address.</span>
                                    <span className="d-block">Bulk email distribution, distribution to strangers, or any other promotion of a Personal Link in a manner that would constitute or appear to constitute unsolicited commercial email or "spam" in the Company's sole discretion is expressly prohibited and may be grounds for immediate termination of the Referrer’s account and deactivation of the Personal Link. We have a no tolerance spam policy.</span>
                                    <span className="d-block">The Company has no obligation to monitor the content provided by Users; however, the Company may choose to do so and block any email messages, remove any such content, or prohibit any use of the Program.</span>
                                    <span className="d-block">Each Referrer is the actual sender of the emails and must comply with applicable law. Referrers who do not comply with the law, including anti-spam laws, are obligated to indemnify the Company and Program vendors against any liabilities, costs, and expenses it incurs as a result of such spam.</span>
                                </p>
                                <p>
                                    <strong className="d-block mt-3 mb-3">Fraudulent and Suspicious Behavior</strong>
                                    <span className="d-block">The Company may prohibit a User from participating in the Program or receiving a Donation Credit, in their sole discretion, if they determine such User is attempting to undermine the fairness, integrity or legitimate operation of the Program in any way by cheating, hacking, deception, or any other unfair playing practices of intending to annoy, abuse, threaten or harass any other users or representatives of the Company.</span>
                                    <span className="d-block">Use of any automated system, script, or macro to participate is strictly prohibited and will result in disqualification.</span>
                                    <span className="d-block">Users may not enter with multiple or fake emails addresses or accounts, use fictitious identities or use any system, bot or other device or artifice to participate in the Program or receive a Donation Credit.</span>
                                    <span className="d-block">The Company reserves the right to disqualify any User and/or cancel any Donation Credits(s) if they find a User to be tampering with the entry process or the operation of the Program or violating these Terms and Conditions.</span>
                                    <span className="d-block">CAUTION: ANY ATTEMPT TO DELIBERATELY DAMAGE OR UNDERMINE THE LEGITIMATE OPERATION OF THE PROGRAM MAY BE IN VIOLATION OF CRIMINAL AND CIVIL LAWS AND WILL RESULT IN DISQUALIFICATION FROM PARTICIPATION IN THE PROGRAM. SHOULD SUCH AN ATTEMPT BE MADE, THE COMPANY RESERVES THE RIGHT TO SEEK REMEDIES AND DAMAGES (INCLUDING ATTORNEY FEES) TO THE FULLEST EXTENT OF THE LAW, INCLUDING CRIMINAL PROSECUTION.</span>
                                </p>
                            </li>
                            <li>
                                <strong className="d-block mt-3 mb-3">General Terms</strong>
                                These terms constitute the entire agreement between Users and the Company concerning Users’ use of the Program. Healthy Paws reserves the right to modify or amend at any time these guidelines and the methods through which Completed Submitted Applications are credited. We reserve the right to disqualify you at any time from participation in the Program if you do not comply with any of these terms and conditions. The failure of the Company to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect. The section titles in these Terms are for convenience only and have no legal or contractual effect. A person who is not a party to these Terms shall have no right to enforce or receive the benefit of any of these Terms.
                            </li>
                        </ol>

                    </div>
                </div>
            </div>
        )
    }
}