import React from "react";
import "./DropDownV2.css";
import { asField } from "informed";
import Select from "react-select";

class DropDownSearchV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.selectedOption || null,
      petType: props.petType,
      inputValue: "",
      allBreeds: []
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.petType !== state.petType) {
      return {
        selectedOption: null,
        petType: props.petType
      };
    }

    return null;
  };

  handleChange = selectedOption => {   
    this.setState({ selectedOption });
  };

  handleInputChange = newValue => {
    const inputValue = newValue;
    this.setState({ inputValue }, () => this.handleSort());
  };

  handleSort = () => {
    const inputValue = this.state.inputValue;
    if (inputValue) {
      var data = [...this.props.data];
      // this will copy everything from array without creating reference to avoid
      // default filter highlighting behaviour of react-select package.
      var breeds = JSON.parse(JSON.stringify(data));
      var updatedBreeds = [];
      var breedOptions = [];
      for (let i = 0; i < breeds.length; i++) {
        var sortObj = {};
        breedOptions = breeds[i].options.filter(breed =>
          breed.label.toLowerCase().includes(inputValue.toLowerCase())
        );
        sortObj.label = breeds[i].label;
        sortObj.options = breedOptions.map(breed => {
          let position = breed.label
            .toLowerCase()
            .indexOf(inputValue.toLowerCase());
          breed.position = position;
          return breed;
        });
        sortObj.options.sort((a, b) => a.position - b.position);
        updatedBreeds.push(sortObj);
      }
    }
    const allBreeds = inputValue ? updatedBreeds : data;
    this.setState({ allBreeds });
  };

  render() {
    const {
      fieldApi,
      className,
      forwardedRef,
      label,
      onChange,
      styles,
      onBlur,
      placeholder
    } = this.props;
    const { setValue, setTouched } = fieldApi;

    return (
      <Select
        style={this.props.style}
            className={"dropdown-containerV2"}
        placeholder={placeholder}
        ref={forwardedRef}
        label={label}
        styles={styles}
        onInputChange={this.handleInputChange}
        value={this.state.selectedOption}
        onChange={e => {
          setValue(e.value);
          this.handleChange(e);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={e => {
          setTouched();
          if (onBlur) {
            onBlur(e);
          }
        }}
        options={this.state.inputValue ? this.state.allBreeds : this.props.data}
        components={{
          DropdownIndicator: () => (
            <span style={{ marginRight: 10, opacity: 0.5 }}>
              <i className="fa fa-search"></i>
            </span>
          ),
          IndicatorSeparator: () => null
        }}
      />
    );
  }
}

export default asField(DropDownSearchV2);
