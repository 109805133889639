import React from "react";
import Loader from "../../../common/loader/Loader";

import "./ClaimForm.css";

const ClaimForm = ({ title, petClaimForms, policyDocsFetchError, policyDocsFetchErrorMessage, subtitle, Emailaddress }) => {
    return (
        <div className="claim-form-box" >
            <div className="claim-form-header" > {title} </div>
            <div className="claim-form-body" >
                <div className="form-links" >
                    {
                        policyDocsFetchError ? (
                            <div>
                                {policyDocsFetchErrorMessage}
                            </div>
                        ) : (
                            petClaimForms.length > 0 ? petClaimForms.map((form) => (
                                <div key={form.pet_id} className="text-secondary mb-2 d-flex" >
                                    <span className="col-1 pl-0">
                                        <i className="fa fa-file-o" />
                                    </span>
                                    <a className="col-11 pl-1 pr-0" href={"/file/" + form.salesforce_type + "/" + form.id} target="_blank">
                                        <span className="truncate text-capitalize"> {form.petName} </span>'s {form.salesforce_type === "claim" ? "Claim" : "MR"} Form
                                    </a>
                                </div>
                            )) : (<div style={{ height: 300 }}>
                                    <Loader />
                                </div>)
                        )
                    }
                </div>
                <div className="mt-1" >
                    <div className="form-info" > <span className="subtitle-heading" > {subtitle} </span> </div>
                    <div className="form-info" > <span className="subtitle" >FAX: </span><span>888-228-4129</span> </div>
                    <div className="form-info" > <span className="subtitle" >EMAIL: </span><a href="mailto:{Emailaddress}">{Emailaddress}</a> </div>
                    <div className="form-info" >
                        <span className="subtitle" >MAIL</span> <br />
                        <span>Healthy Paws Pet Insurance</span> <br />
                        <span>P.O. Box 50034</span> <br />
                        <span>Bellevue, WA 98015</span>
                    </div>
                </div>
            </div>
            <div className="claim-form-footer" ></div>
        </div>
    )
}

export default ClaimForm;