import React, { Component } from "react";

import { Form, TextArea } from "informed";
import { AppLinks } from "../../constants/app-links";
import { Link } from "react-router-dom";

import { sendContactInfo } from "../../api/messages-api";

import MaskValidateUtil from "../common/util/MaskValidateUtil";
import ErrorView from "../common/error-view/ErrorView";
import Loader from "../common/loader/Loader";
import EditText from "../common/form-fields/edit-text/EditText";
import DropDownSelect from "../common/form-fields/drop-down/DropDownSelect";

import { UncontrolledCollapse } from "reactstrap";

import {
  coverageFaqLinks,
  policyFaqLinks,
  claimFaqLinks,
  billingFaqLinks
} from "./FaqLink";

import "./contact.view.component.css";

export const contactInformation = () => {
  return (
    <React.Fragment>
      <h4 className="d-flex align-items-center margin-bottom">
        Contact us by phone, fax or mail:
      </h4>
      <div className="margin-left-right margin-bottom">
        <div className="mt-3">
          <img className="contact-image-item" 
            alt = "phonenumber" 
            src="/assets/images/icon_phonenumber.svg" 
          />
          <div className="text-container">
            <strong> Phone </strong> 
            <div> 855-898-8991 </div>
            <div> Monday-Friday from 7:00am to 5:00pm PT </div>
            <div> Saturday from 8:00am to 5:00pm PT </div>
            <div>Closed Sunday</div>
          </div>
        </div>
        <div className="mt-4">
          <img className="contact-image-item" 
            alt = "phonenumber" 
            src="/assets/images/icon_fax.svg" 
          />
          <div className="text-container">
            <strong> FAX </strong>
            <p> 888 - 228 - 4129 </p>
          </div>
        </div>
        <div className="mt-4">
          <img className="contact-image-item" 
            alt = "phonenumber" 
            src="/assets/images/icon_mail.svg"
          />
          <div className="text-container">
            <strong> Mail </strong>
            <p> Healthy Paws</p>
            <p> P.O.Box 50034 </p>
            <p className="margin-bottom"> Bellevue, WA 98015 </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export class ContactForm extends Component {
  constructor() {
    super();
    this.errorText = "";
    this.state = {
      sendingToServer: false,
      serverError: false,
    }
  }

  getFaqLink = value => {
    switch (value) {
      case "PlanQuestionsCode":
        return coverageFaqLinks;
      case "ClaimsProcessingCode":
        return claimFaqLinks;
      case "ManagePolicyCode":
        return policyFaqLinks;
      case "BillingCode":
        return billingFaqLinks;
      default:
        return [];
    }
  };

  sendContactInfo = values => {

    this.setState({
      sendingToServer: true
    });

    var contactInfo = {
      contact_email: values.email,
      full_name: values.name,
      message: values.message,
      phone_number: values.primaryPhoneNumber.replace(/\D/g, ""),
      return_url: ""
    };

    var index = values.name.indexOf(" ");

    if (index > 0) {
      contactInfo.first_name = values.name.substr(0, index);
      contactInfo.last_name = values.name.substr(index + 1);
    } else {
      contactInfo.first_name = values.name;
      contactInfo.last_name = "";
    }

    if (this.props.showDropDown) {
      if (!values.reason || values.reason === "n/a") {
        this.errorText = "Please select a valid reason.";
        this.setState({
          sendingToServer: false,
          serverError: true
        });
        window.scrollTo(0, 0);
        return;
      }
      contactInfo.subject = values.reason;
      contactInfo.reason_code = values.reason;
    }

    sendContactInfo(contactInfo)
      .then(response => {
        this.setState({
          sendingToServer: false
        });
        this.props.setShowContactThankYouPage();
        window.scrollTo(0, 0);
      })
      .catch(error => {
        this.errorText = error.errorMessage ? error.errorMessage : "Unable to send information. Please try again later or call us at 855-898-8991.";
        this.setState({
          sendingToServer: false,
          serverError: true
        });
        if (this.props.showDropDown) window.scrollTo(0, 0);
      });
  };

  render() {
    return (
      <div className="contact-form-container position-relative">
        {this.state.serverError && <ErrorView>{this.errorText}</ErrorView>}
        <Form
          className="contact-form margin-left-right"
          getApi={api => {
            this.props.setContactFormFieldAPI(api);
          }}
          onSubmit={values => {
            this.sendContactInfo(values);
          }}
        >
          {({ formState }) => (
            <React.Fragment>
              <EditText
                required
                className="input-container margin-bottom"
                id="name"
                field="name"
                type="text"
                title="Name"
                floatingTitle={true}
              />
              <EditText
                required
                email
                className="input-container margin-bottom"
                id="email_address"
                field="email"
                type="text"
                title="Email Address"
                validate={MaskValidateUtil.validateEmail}
                validateOnBlur
                validateOnChange
                floatingTitle={true}
              />
              <EditText
                required
                className="phone-input-container margin-bottom"
                id="primary-phone-number"
                field="primaryPhoneNumber"
                type="text"
                title="Primary Phone #"
                floatingTitle={true}
                mask={MaskValidateUtil.phoneNumberMask}
              />
              {this.props.showDropDown && this.props.responseArray && (
                <React.Fragment>
                  <h4>I have a question regarding…</h4>
                  <DropDownSelect
                    field="reason"
                    className="address-input-container margin-bottom"
                    id="select_faq"
                    data={this.props.responseArray}
                  />

                  {formState.values.reason &&
                    formState.values.reason !== "n/a" &&
                    formState.values.reason !== "Other" && (
                      <div className="d-flex flex-column">
                        <hr className="margin-bottom" />
                        <span className="faq-container related-faq">
                          Top Related FAQs
                        </span>
                        <div className="faq-container">
                          {this.getFaqLink(formState.values.reason).map(
                            (questionItem, index) => (
                              <React.Fragment key={index}>
                                <h4
                                  id={"Toggle" + index}
                                  className="faqQuestion"
                                >
                                  {questionItem.text}
                                </h4>
                                <UncontrolledCollapse
                                  toggler={"#Toggle" + index}
                                >
                                  {questionItem.answer()}
                                </UncontrolledCollapse>
                              </React.Fragment>
                            )
                          )}
                          <Link
                            to={AppLinks.faq.faq}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <h4 className="faqQuestion">Find more FAQs</h4>
                          </Link>
                        </div>
                        <hr className="margin-bottom" />
                      </div>
                    )}
                </React.Fragment>
              )}

              <TextArea
                placeholder="Message"
                required
                rows="6"
                className="input-container text-area-input margin-bottom"
                id="message"
                field="message"
              />

              <button className="input-container button-primary" type="submit">
                Send
              </button>
            </React.Fragment>
          )}
        </Form>
        {(this.state.sendingToServer || this.props.fetching) && <Loader position="absolute" />}
      </div>
    );
  }
}
