import React from "react";
import "./Login.component.css";
import { Form, Checkbox } from "informed";
import EditText from "../common/form-fields/edit-text/EditText";
import EditPassword from "../common/form-fields/edit-text/EditPassword";
import { SIGN_IN, FORGOT_PASSWORD, CREATE_PASSWORD } from "./LoginPage";
import Loader from "./../common/loader/Loader";


const validate = value => {
  return value && value.length > 0 && !validateEmail(value)
    ? "Please enter a valid email address"
    : null;
};

const validateEmail = email => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const Login = props => {
  const signIn = values => {
    props.signInClick(values);
  };

  return (
    <div className="sign-in-box">
      <div className="title-green margin-bottom">Sign-In</div>
      <Form onSubmit={values => signIn(values)} initialValues = {{ email : props.userMailId }}>
        {({ formState }) => (
          <React.Fragment>
            <EditText              
              required
              email
              id="login_email_address"
              className="mb-2"
              field="email"
              type="text"
              showError={props.loginError ? true : false}
              title="Email Address"
              validate={validate}
              validateOnBlur
              validateOnChange={formState.errors.email}
              floatingTitle={true}
              onClick={e => {
                props.resetErrorHandler(e);
              }}
              disabled={props.fetching}
            />
            <EditPassword
              required
              showError={props.loginError ? true : false}
              showErrorText={props.loginErrorText}
              id="login_password"
              className="mb-2 password-view"
              field="password"
              title="Password"
              secured={true}
              floatingTitle={true}
              onClick={e => {
                props.resetErrorHandler(e);
              }}
              onValueChange={e => {
                props.resetErrorHandler(e);
              }}
              disabled={props.fetching}
            />

            <button
              id="login_button"
              type="submit"
              className="button-primary login-button-container"
              disabled={props.fetching || props.loginError || formState.invalid}
            >
              <i
                className="fa fa-lock login-lock-icon"
                style={{ color: "#FFFFFF", fontSize: "25px" }}
              />

              <span className="login-button-text">Sign-In</span>
            </button>
            <div className="remember-me-forgot-password-continer d-flex row flex-row justify-content-center align-item-center">
              {/* NOTE: Disabled Remember Me, Adjust styling when eenabled. */}
              {/* <div className="col-xs-12 col-sm-6 no-padding d-flex remember-me-container">
                <Checkbox
                  id="login_remember_me_checkbox"
                  field="rememberMe"
                  className="ios8-switch"
                  onChange={(e,v)=>{
                    if(formState.values.rememberMe) props.toggleRememberMeDialog()
                  }}
                />
                <label
                  htmlFor="login_remember_me_checkbox"
                  className="remember-me"
                >
                  Remember Me
                </label>
              </div> */}
              <label
                id="login_forgot_password"
                onClick={() => {
                  props.changeState(FORGOT_PASSWORD);
                }}
                className="forgot-password col-xs-12 col-sm-6 no-padding"
              >
                Forgot Password?
              </label>
            </div>
            <hr className="ruler" />
            <div className="create-password-container d-flex flex-row align-items-center justify-content-center">
              <label className="enrolled-by-phone">Enrolled by phone?</label>
              <label
                id="login_create_password"
                onClick={() => {
                  props.changeState(CREATE_PASSWORD);
                }}
                className="create-password"
              >
                Create Password
              </label>
            </div>
          </React.Fragment>
        )}
      </Form>

      <LoadingOverlay fetching={props.fetching} />
    </div>
  );
};

export const CreateResetPassword = props => {
  const createPassword = values => {
    props.createPasswordClick(values.email);
  };

  return (
    <div className="sign-in-box rounded">
      <div className="title-green">{props.title}</div>
      <label className="title-description">{props.description}</label>
      <Form onSubmit={values => createPassword(values)}>
        {({ formState }) => (
          <React.Fragment>
            <EditText
              required
              email
              id="create_reset_email_address"
              showError={props.loginError ? true : false}
              showErrorText={"Invalid email. Please correct."}
              className="margin-bottom"
              field="email"
              type="text"
              title="Email Address"
              validate={validate}
              validateOnBlur
              validateOnChange
              floatingTitle={true}
              disabled={props.fetching}
              onClick={e => {
                props.resetErrorHandler(e);
              }}
            />

            <button
              type="submit"
              id="create_reset_button"
              className="button-primary send-Password-container"
              disabled={props.fetching || props.loginError || formState.invalid}
            >
              Send Password
            </button>
            <button
              type="submit"
              id="back_to_signin_button"
              onClick={() => {
                props.changeState(SIGN_IN);
              }}
              className="button-secondary back-to-signin-container"
              disabled={props.fetching}
            >
              Back to Sign-In
            </button>

            <div className="create-password-container" />
          </React.Fragment>
        )}
      </Form>

      <LoadingOverlay fetching={props.fetching} />
    </div>
  );
};

export const PasswordSent = props => {
  return (
    <div className="sign-in-box password-sent-box rounded  d-flex flex-column justify-content-center align-items-center">
      <img
        alt="success"
        className="success_logo"
        src="/assets/images/login/success.png"
      />
      <div className="title-green title-green-center">Success!</div>
      <label className="title-description w-100">
        Your temporary password has been sent. Please check your inbox.
      </label>

      <div
        id="back_to_signin_button"
        onClick={() => {
          props.changeState(SIGN_IN);
        }}
        className="back-to-signin-container d-flex flex-row align-items-center justify-content-center"
      >
        <div className="back-to-signin-button">Back to Sign-In</div>
      </div>
    </div>
  );
};

const LoadingOverlay = props => {
  if (props.fetching)
    return (
      <Loader position="absolute" height="50px" width="50px"/>
    );
  else return null;
};
