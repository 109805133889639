import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form } from "informed";
import CheckboxControl from "../../common/form-fields/checkbox/Checkbox";
import Loader from "../../common/loader/Loader";
import { cancelPolicy } from "../../../api/accounts-api";
import {AppLinks} from "../../../constants/app-links"
import ErrorView from "../../common/error-view/ErrorView";
import Util from  "../../common/util/util"

import "./CancelConfirm.css";

export default class CancelConfirm extends Component {
  constructor() {
    super();
    this.errorText = null;
    this.state = {
      showInfo: false,
      showBtn: false,
      sendingToServer: false,
      serverError: false
    };
  }

  componentDidMount(){
    this.props.setNewRoute("CancelPolicy/Step2");
     //For google analytics
     window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname + '/Step2'
    });
  }


  showInfo = () => {
    this.setState({ showInfo: !this.state.showInfo });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      showBtn: value
    });
  }

  cancelPolicy = () => {
    this.setState({
      sendingToServer: true
    });

    cancelPolicy(this.props.formData, false)
      .then(response => {
        this.props.history.push(
          {
            pathname: AppLinks.others.postCancelPolicy,
            state: {
              isCancelled : response.is_cancel_policy_successful
            }
          });
      })
      .catch(error => {

        this.errorText = error.errorMessage ? error.errorMessage : "";
        this.setState({
          sendingToServer: false,
          serverError: true
        });
        window.scrollTo(0, 0);
      });
  };

  render() {
    return (
      <div className="container cancel-confirm">
        <div className="row">
          <div className="col-12">
            <h1 className="title-container d-flex align-items-center">
              Confirm Your Policy Cancellation
            </h1>
          </div>
        </div>
        {this.state.serverError && <ErrorView>
        {this.errorText? this.errorText :
        <span>Unable to cancel your policy. Please <Link to={AppLinks.others.contact}>click here</Link> to contact us.</span>
        }
        </ErrorView>}
        <div className="row">
          <div className="col-12">
            <p>
              <strong>Please review the following information </strong>regarding
              the cancellation of your policy. If you wish to proceed with your
              cancellation, select{" "}
              <span>Cancel My Policy</span> below.
            </p>
          </div>
        </div>
        <div className="row">
          <div className=" col-12">
            <div className=" content-wrapper">
              <div>
                <span className="sub-heading">Policy Number</span>
                <span>{this.props.userAccountDetails.AccountNo}</span>
              </div>
              <div className="mt-3">
                <span className="sub-heading">Cancel Effective Date</span>
                <span className="mr-3">
                  {Util.getFormattedDate(this.props.expiryDate)}
                </span>
                <Link to="#" onClick={() => this.showInfo()}>
                  <i
                    className="fa fa-question-circle mr-1"
                    aria-hidden="true"
                  />
                  Why?
                </Link>
                {this.state.showInfo && (
                  <div>
                    <p>
                      The effective date of your policy cancellation is the end
                      of your current payment period. Your policy is in effect,
                      and your pets are covered, until this date.
                    </p>
                    <p>
                      If your pet has passed away, or you no longer own your
                      pet, the cancellation date is the date your pet passed or
                      moved to another home. You may receive a pro-rated refund
                      of your policy premium back to that date (provided you
                      have notified us in a timely manner).
                    </p>
                    <p>
                      If you are in the 30 day trial period, the cancellation
                      date is the date you opened your pet insurance policy. No
                      claims can be filed for your pets.
                    </p>
                  </div>
                )}
              </div>
              <div className="mt-3">
                <span className="sub-heading">Pet on Policy</span>
                <span>{this.props.petDetailsText}</span>
              </div>
            </div>
          </div>
          <p className="ml-3 mt-3 mb-0 d-sm-none">
            <strong>
              By selecting Cancel My Policy, your policy will be cancelled.
            </strong>
          </p>
        </div>
        <Form onSubmit={values => this.cancelPolicy()}>
          {({ formState }) => (
            <React.Fragment>
              <div className="row terms-and-condition">
                <div className="col-12">
                  <h2 className="mt-3 mb-3">
                    <strong>Terms & Conditions for Cancellation</strong>
                  </h2>
                  <textarea
                    readOnly
                    className="form-control bg-color"
                    rows="2"
                    defaultValue="By checking this box and clicking Cancel My Policy, I certify that the information provided is accurate to the best of my knowledge. I authorize any veterinary hospital or veterinarian to provide additional information about my pet to Healthy Paws Pet Insurance. I understand that missing information or delays in delivering the pet's medical records may delay the processing of my claim. I understand that Healthy Paws Pet Insurance appreciates having claims submitted within 90 days of invoice date."
                  />

                  <CheckboxControl
                    className=" mt-4 mb-2"
                    id="agreement"
                    checked={this.state.isGoing}
                    field="agreement"
                    label="I agree to these Terms and Conditions"
                    onChange={this.handleInputChange.bind(this)}
                  />
                </div>
              </div>
              <div className="d-flex flex-column flex-md-row mt-2 mt-lg-3 mb-4">
                  <button
                    className="button-primary-plus button-container"
                    onClick={() => {
                      this.props.stepper.nextStep();
                    }}
                    disabled={!formState.values.agreement}
                  >
                    {" "}
                    Cancel My Policy
                  </button>
                  {/* <button className="button-secondary button-keep button-container ml-0 ml-md-4 mt-2 mt-md-0"
                  onClick={() => {
                    this.props.history.push("/");
                  }}>
                    No, Keep My Policy
                  </button> */}
              </div>
            </React.Fragment>
          )}
        </Form>
        {this.state.sendingToServer && <Loader position="absolute" fullPage={true} />}
      </div>
    );
  }
}
