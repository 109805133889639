import React, { Component } from "react";
import "./FilePreview.css";

class FilePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoomedImageIndex: this.props.zoomedImageIndex
    };
  }

  componentDidMount() {
    //window.scrollTo(0, 0);
  }

  nextClickHandler = evt => {
    this.setState(prevState => ({
      zoomedImageIndex: prevState.zoomedImageIndex + 1
    }));
    evt.stopPropagation();
  };

  previousClickHandler = evt => {
    this.setState(prevState => ({
      zoomedImageIndex: prevState.zoomedImageIndex - 1
    }));
    evt.stopPropagation();
  };

  render() {
    const previewFile = this.props.files[this.state.zoomedImageIndex];

    return (
      <React.Fragment>
        <div
          id="file-upload-preview-overlay"
          className="d-none  d-sm-flex flex-row"
          onClick={this.props.closePreview}
          style={{
            height: document.body.getBoundingClientRect().height,
            margin: "0 auto",
            color: "white",
            zIndex: 105
          }}
        >
          <div
            onClick={evt => evt.stopPropagation()}
            className="d-flex zoom-view"
          >
            <div className="preview-arrow-button">
              {this.state.zoomedImageIndex < 1 ? null : (
                <span
                  onClick={e => this.previousClickHandler(e)}
                  style={{ padding: 10, color: "black" }}
                >
                  <i className="fa fa-angle-left fa-2x" />
                </span>
              )}
            </div>
            <div
              id={
                this.props.files[this.state.zoomedImageIndex].name +
                ":zoom-file"
              }
              className="flex-grow-1"
              style={{ width: "100%" }}
            >
              <img
                className="delete-icon-zoom align-self-end"
                alt="delete icon"
                onClick={this.props.closePreview}
                src="../../assets/images/delete.svg"
              />
              <div className="row zoomed-preview">
                <p className="image-number-zoom d-flex justify-content-center">
                  {this.state.zoomedImageIndex + 1}
                </p>
                <div
                  style={{ width: "100%" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  {previewFile.type.startsWith("image") ? (
                    <img
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={previewFile.preview}
                      className="flex-shrink-0"
                      alt="zoom preview"
                    />
                  ) : (
                    <React.Fragment>
                      <object
                        data={previewFile.preview}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                      >
                        <div className="preview-fallback">
                          <i
                            className="fa fa-download download-icon fa-5x"
                            aria-hidden="true"
                          />
                          <p>
                            Preview is not supported for this file.{" "}
                            <a href={previewFile.uploadedURL}>
                              Click here to download the file.
                            </a>
                          </p>
                        </div>
                      </object>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <button
                className="btn btn-outline-secondary btn-close"
                onClick={this.props.closePreview}
              >
                Close
              </button>
              <img
                className="trash-icon-zoom align-self-end"
                src="../../assets/images/garbage.svg"
                alt="delete icon"
                onClick={e => this.props.deleteFile(e)}
              />
            </div>

            <div className="preview-arrow-button">
              {this.state.zoomedImageIndex >=
              this.props.files.length - 1 ? null : (
                <span
                  onClick={e => this.nextClickHandler(e)}
                  style={{ padding: 10, color: "black" }}
                >
                  <i className="fa fa-angle-right fa-2x" />
                </span>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FilePreview;
