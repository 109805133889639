import React, { Component } from "react";
import { Form } from "informed";
import { Link } from "react-router-dom";
import { policyChanges } from "../../../api/pets-api";

import ErrorView from "../../common/error-view/ErrorView";
import Loader from "../../common/loader/Loader";
import CheckboxControl from "../../common/form-fields/checkbox/Checkbox";
import PetPolicyRate from "./PetPolicyRate.component";

import "./ChangePolicyOptions.component.css";
import Util from '../../common/util/util';

class ChangePolicyOptions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedPlans: this.getCurrentPlans(),
      submitError: false,
      submitLoader: false,
      resetAll: false
    }
    this.submitErrorMessage = "";
    this.resetRef = [];
  }

  resetAll = (event) => {
    this.resetRef && this.resetRef.length && this.resetRef.forEach(ref => ref && ref.reset());
    event.preventDefault();
  }

  setRef = (instance) => {
    if (instance && !this.resetRef.find(ref => ref.props.id === instance.props.id)) {
      this.resetRef.push(instance);
    }
  }

  getCurrentPlans = () => {
    return this.props.availablePolicies.map(options => {
      const currentPlan = options.policies.find(policy => policy.is_current_plan);
      return { policy: currentPlan, pet: { pet_id: options.pet.pet_id } }
    });
  }

  getTotalMonthlyRate = () => {
    const monthlyRate = this.state.selectedPlans.reduce((accumulator, plan) => (accumulator + plan.policy.monthly_total_payment), 0)
    return monthlyRate.toFixed(2);
  }

  updateSelectedPlan = (changedPlan, pet_id) => {
    const selectedPlans = this.state.selectedPlans;
    selectedPlans.map(plan => {
      if (plan.pet.pet_id === pet_id) {
        plan.policy = changedPlan;
      }
    });
    this.setState({ selectedPlans: selectedPlans });
  }

  // sends PUT request to update pet policies
  changePolicyOptions = () => {
    this.setState({ submitLoader: true });
    const changedPolicyOptions = this.state.selectedPlans.filter(plan => !plan.policy.is_current_plan);
    changedPolicyOptions.length > 0 && policyChanges(changedPolicyOptions, "false")
      .then(response => {
        this.setState({ submitLoader: false });
        this.props.onPolicyChangeSuccess(response, this.getTotalMonthlyRate());
      })
      .catch(error => {
        this.submitErrorMessage = error.errorMessage ? error.errorMessage : "Could not change your policy options. Please try later.";
        this.setState({ submitError: true, submitLoader: false });
        window.scrollTo(0,0);
      });
  }

  petPolicyOptionSelector = (availablePolicies) => {
    const { petDetails } = this.props;
    return (
      <div className="pet-policy-rates-content">
        {petDetails.map((pet, index) => {
          const policyOptionsForEachPet = availablePolicies.find(options => options.pet["pet_id"] === pet.PetID);
          return <PetPolicyRate
            id={"petPolicyRate-" + index}
            ref={(instance) => { this.setRef(instance) }}
            key={index}
            pet={pet}
            policyOptions={policyOptionsForEachPet}
            updateSelectedPlan={this.updateSelectedPlan}
            effectiveDate={this.props.policyEffectiveDate}
          />
        })}
      </div>
    );
  };

  render() {
    let checkboxLabel;
    const { availablePolicies, petDetails, policyEffectiveDate, userAccountDetails } = this.props;
    const singlePolicyList = availablePolicies.filter(policyOptions => policyOptions.policies.length === 1);
    const termsConditionText = `By checking this box and clicking "Change Policy Options", I certify that the information provided is accurate to the best of my knowledge. I authorize any veterinary hospital or veterinarian to provide additional information about my pet to Healthy Paws Pet Insurance. I understand that missing information or delays in delivering the pet's medical records may delay the processing of my claim. Claims must be submitted for processing within 90 days of invoice date.`;
    if (window.matchMedia("(min-width: 768px)").matches) {
      checkboxLabel = "I agree to these terms and conditions";
    } 
    else {
      checkboxLabel = "I agree to these terms";
    }
    return (
      <div className="container change-policy-options">
        {this.state.submitLoader && <Loader position="absolute" fullPage={true} />}
      
        <h1 className="text-primary mb-3 mb-lg-0">Policy Options</h1>
        {this.state.submitError && <ErrorView>{this.submitErrorMessage}</ErrorView>}
        <React.Fragment>
          <div className="row">
            <div className="col-lg-8">
              {singlePolicyList.length === availablePolicies.length ?
                <div className="mb-4">Your policy options are limited.</div>
                :
                (
                  <React.Fragment>
                    <p className="d-block d-md-none d-lg-block subDescription">
                      You may raise your pet's deductible level and/or lower your
                      pet's reimbursement level at any time. If you have not
                      previously filed a claim for your pet, you may lower your
                      pet's deductible and/or raise your pet's reimbursement
                      level.
                    </p>
                    <p className="d-none d-md-block d-lg-none subDescription">
                      You may raise your pet's deductible level and/or lower your
                      pet's reimbursement level at any time. You may lower your
                      pet's deductible and/or raise your pet's reimbursement
                      level, provided that you have not previously filed a calim
                      for your pet.
                    </p>
                  </React.Fragment>
                )}
              <div>{this.petPolicyOptionSelector(availablePolicies)}</div>
            </div>
            <div className="col-lg-4">
              <div className="monthly-rate-container">
                <Form id="confirmChargesForm" onSubmit={this.changePolicyOptions}>
                  {({ formState, formApi }) => (

                    singlePolicyList.length === availablePolicies.length ?
                      (
                        <div className="rate-container text-center" >
                          <h2 className="font-weight-bold">
                            Total Monthly Rate For Your Pet(s)
                          </h2>
                          <div className="rate-text-large mb-3" style={{ color: "#999999" }}>
                            ${availablePolicies[0].policies[0].monthly_total_payment.toFixed(2)}
                            <small>/month</small>
                          </div>
                          <Link to="/">
                            <button className="button-secondary">
                              Home
                          </button>
                          </Link>
{/* Hiding this as per:  https://www.pivotaltracker.com/story/show/163799900 */}
                          {/* <div className="d-flex mt-3">
                            <div className="mr-2 mr-md-3">
                              <i className="fa fa-info-circle" style={{ fontSize: "23px" }}></i>
                            </div>
                            <div className="text-left" >
                              <strong><span className="truncate">{petDetails[0].Name}</span>'s policy option is limited.</strong>
                              <p>Pets who are 8 years and older are eligible for 60% Reimbursement and $750 Annual Deductible options.</p>
                            </div>
                          </div> */}
                        </div>
                      ) :
                      (
                        <React.Fragment>
                          <div className="rate-container text-center">
                            <h2 className="font-weight-bold d-none d-md-block">
                              Total Monthly Rate For Your Pet(s)
                            </h2>
                            <h4 className="font-weight-bold d-md-none text-primary">
                              Total Monthly Rate For Your Pets
                            </h4>
                            <div className="rate-text-large mb-3">
                              ${this.getTotalMonthlyRate()}
                              <small>/month</small>
                            </div>
                            <button className="d-md-none btn btn-outline-secondary mb-3" onClick={(event) => this.resetAll(event)}>
                              Reset to my current policy
                            </button>
                            <div className="text-left">
                              <textarea
                                className="terms-conditions w-100 mb-1"
                                readOnly
                                value={termsConditionText}
                              />
                              <CheckboxControl
                                className="mb-3 labelStyle"
                                id="checkbox"
                                field="checkbox"
                                label={checkboxLabel}
                              />
                            </div>

                            <div className="row">
                              <div className="col-md-8  col-lg-12 mb-2">
                                <button
                                  className="button-primary"
                                  type="submit"
                                  disabled={!formState.values.checkbox || this.state.selectedPlans.filter(plan => !plan.policy.is_current_plan).length === 0}
                                >
                                  Change Policy Options
                                  </button>
                              </div>
                              <div className="col-md-4 col-lg-12 mb-2">
                                <Link to="/">
                                  <button
                                    className="button-link"
                                    type="button"
                                  >
                                    Cancel
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="effective-details d-flex">
                            <i className="fa fa-calendar calendar-icon" />
                            <div>
                              <p className="font-weight-bold mb-0 d-md-none">
                                Policy Change effective {Util.getFormattedDate(policyEffectiveDate)}
                              </p>
                              <p className="font-weight-bold mb-0 d-none d-md-block">
                                Your new policy effective {Util.getFormattedDate(policyEffectiveDate)}
                              </p>
                              <p>
                                 Changes to your pet's policy will become effective on the next
                                 billing date. Premium adjustments will be applied for this 
                                 change and are subject to approval by Healthy Paws.
                        </p>
                            </div>

                          </div>
                          {this.props.userAccountDetails.MailingAddress.State == "CA" ? 
                            <div className="CAFraudWarning">
                              For your protection California law requires the following to appear
                              on this form. Any person who knowingly presents false or fraudulent
                              information to obtain or amend insurance coverage or to make a claim
                              for the payment of a loss is guilty of a crime and may be subject to
                              fines and confinement in state prison.
                            </div> : 
                            <div></div>
                          }
                        </React.Fragment>
                      )

                  )}
                </Form>
              </div>
            </div>
          </div>
        </React.Fragment>

      </div>
    );
  }
}

export default ChangePolicyOptions;