import { axiosInstanceWithAuth } from "./axios";

async function getPresignedURL(incomingURL) {
  let url = "Upload/GetPresignedURL?url=" + incomingURL;
  let response = await axiosInstanceWithAuth.get(url);

  console.log("getPresignedURL response: " + response);

  return response.data;
}

export const uploadFile = async (fileType, petPolicyNo, file, fileName) => {
  let nowTimeStamp = Date.now() + Math.random()*1000;
  let url = "Upload/Send?t="+nowTimeStamp;
// Added a timestamp to differentiate requests. Refer: https://www.pivotaltracker.com/story/show/164024291/comments/199642282

  let data = new FormData();
  data.append("ImageType", fileType);
  data.append("PetPolicyNo", petPolicyNo);
  data.append("Attachment", file, fileName);

  // Temporarily resolving to avoid unnecessory uploads.
  // return Promise.resolve({
  //       isSuccess: true,
  //       data: "https://s3-us-west-2.amazonaws.com/hptest-claims/Claim-0013B00000Ux42qQAB-09242018/Claim-1-1_full.png"
  //     });

  // TODO : Check if response is URL, else reject.

  let response = await axiosInstanceWithAuth.post(url, data);
  let presignedURL = await getPresignedURL(response.data);
  
  return {
    isSuccess: true,
    presignedURL: presignedURL,
    data: response.data
  };
};
