// General Error Codes
export const ERROR_401 = -1

// Login Relatedd Erro Codes
export const ERROR_LOGIN_NO_SAVED_TOKEN = -1001;
export const ERROR_LOGIN_INVALID_CREDS = -1002;



// Using Typescript like Enumeration Syntax.
export let APIStatus;
(function (APIStatus) {
    APIStatus[APIStatus["Successful"] = 0] = "Successful";
    APIStatus[APIStatus["ItemNotFound"] = 1] = "ItemNotFound";
    APIStatus[APIStatus["NoItemsFound"] = 2] = "NoItemsFound";
    APIStatus[APIStatus["MissingCountry"] = -35] = "MissingCountry";
    APIStatus[APIStatus["MissingPostalCode"] = -34] = "MissingPostalCode";
    APIStatus[APIStatus["MissingState"] = -33] = "MissingState";
    APIStatus[APIStatus["MissingCity"] = -32] = "MissingCity";
    APIStatus[APIStatus["MissingStreetAddress"] = -31] = "MissingStreetAddress";
    APIStatus[APIStatus["MissingName"] = -30] = "MissingName";
    APIStatus[APIStatus["InvalidCreditCardCCV"] = -29] = "InvalidCreditCardCCV";
    APIStatus[APIStatus["InvalidCreditCardDate"] = -28] = "InvalidCreditCardDate";
    APIStatus[APIStatus["InvalidCreditCardNumber"] = -27] = "InvalidCreditCardNumber";
    APIStatus[APIStatus["InvalidBankAccountType"] = -26] = "InvalidBankAccountType";
    APIStatus[APIStatus["InvalidPhoneNumber"] = -25] = "InvalidPhoneNumber";
    APIStatus[APIStatus["InvalidAccountNumber"] = -24] = "InvalidAccountNumber";
    APIStatus[APIStatus["InvalidRoutingNumber"] = -23] = "InvalidRoutingNumber";
    APIStatus[APIStatus["InvalidState"] = -22] = "InvalidState";
    APIStatus[APIStatus["InvalidCity"] = -21] = "InvalidCity";
    APIStatus[APIStatus["InvalidStreetAddress"] = -20] = "InvalidStreetAddress";
    APIStatus[APIStatus["InvalidZipCode"] = -19] = "InvalidZipCode";
    APIStatus[APIStatus["UpdatePetError"] = -18] = "UpdatePetError";
    APIStatus[APIStatus["AccountNotFound"] = -17] = "AccountNotFound";
    APIStatus[APIStatus["MultipleAccountsFound"] = -16] = "MultipleAccountsFound";
    APIStatus[APIStatus["AttachmentRequired"] = -15] = "AttachmentRequired";
    APIStatus[APIStatus["S3Unreachable"] = -14] = "S3Unreachable";
    APIStatus[APIStatus["CaseDeleteError"] = -13] = "CaseDeleteError";
    APIStatus[APIStatus["InvalidFileType"] = -12] = "InvalidFileType";
    APIStatus[APIStatus["UpdatePasswordFailed"] = -11] = "UpdatePasswordFailed";
    APIStatus[APIStatus["ResetFailed"] = -10] = "ResetFailed";
    APIStatus[APIStatus["UpdatePhotoError"] = -9] = "UpdatePhotoError";
    APIStatus[APIStatus["ImageSendError"] = -8] = "ImageSendError";
    APIStatus[APIStatus["UpdateNotificationError"] = -7] = "UpdateNotificationError";
    APIStatus[APIStatus["UpdateBillingError"] = -6] = "UpdateBillingError";
    APIStatus[APIStatus["CaseUpdateError"] = -5] = "CaseUpdateError";
    APIStatus[APIStatus["CaseCreateError"] = -4] = "CaseCreateError";
    APIStatus[APIStatus["PermissionDeniedUser"] = -3] = "PermissionDeniedUser";
    APIStatus[APIStatus["PermissionDeniedApp"] = -2] = "PermissionDeniedApp";
    APIStatus[APIStatus["ExceptionOccured"] = -1] = "ExceptionOccured";
})(APIStatus || (APIStatus = {}));

// Error Messages Mapped to ErrorCode. 
const ErrorMessages = {
};
ErrorMessages[APIStatus.MissingCountry] = "Please enter a country.";
ErrorMessages[APIStatus.MissingPostalCode] = "Please enter a postal code.";
ErrorMessages[APIStatus.MissingState] = "Please enter a state.";
ErrorMessages[APIStatus.MissingCity] = "Please enter a city.";
ErrorMessages[APIStatus.MissingStreetAddress] = "Please enter a street address.";
ErrorMessages[APIStatus.MissingName] = "Please enter a name.";
ErrorMessages[APIStatus.InvalidCreditCardCCV] = "Please enter a valid credit card CCV.";
ErrorMessages[APIStatus.InvalidCreditCardDate] = "Please enter a valid credit card expiration date.";
ErrorMessages[APIStatus.InvalidCreditCardNumber] = "Please enter a valid credit card number.";
ErrorMessages[APIStatus.InvalidBankAccountType] = "Please enter a valid bank account type.";
ErrorMessages[APIStatus.InvalidPhoneNumber] = "Please enter a valid phone number.";
ErrorMessages[APIStatus.InvalidAccountNumber] = "Please enter a valid account number.";
ErrorMessages[APIStatus.InvalidRoutingNumber] = "Please enter a valid routing number.";
ErrorMessages[APIStatus.InvalidState] = "Please enter a valid state.";
ErrorMessages[APIStatus.InvalidCity] = "Please enter a valid city.";
ErrorMessages[APIStatus.InvalidStreetAddress] = "Please enter a valid street address.";
ErrorMessages[APIStatus.InvalidZipCode] = "Please enter a valid zip code.";
ErrorMessages[APIStatus.UpdatePetError] = "We encountered an error updating your pet.";
ErrorMessages[APIStatus.AccountNotFound] = "Customer account could not be found.";
ErrorMessages[APIStatus.MultipleAccountsFound] = "Multiple customer accounts were found.";
ErrorMessages[APIStatus.AttachmentRequired] = "Please include a file attachment.";
ErrorMessages[APIStatus.S3Unreachable] = "Amazon S3 could not be reached.";
ErrorMessages[APIStatus.CaseDeleteError] = "We encountered an error deleting the case.";
ErrorMessages[APIStatus.InvalidFileType] = "Please use a valid file type.";
ErrorMessages[APIStatus.UpdatePasswordFailed] = "We could not update the password.";
ErrorMessages[APIStatus.ResetFailed] = "We could not reset the password.";
ErrorMessages[APIStatus.UpdatePhotoError] = "We encountered an error uploading the photo.";
ErrorMessages[APIStatus.ImageSendError] = "We encountered an error sending the image.";
ErrorMessages[APIStatus.UpdateNotificationError] = "We encountered an error updating the notification.";
ErrorMessages[APIStatus.UpdateBillingError] = "We encountered an error updating the billing information. Please verify your credit card information is correct or contact your bank.";
ErrorMessages[APIStatus.CaseUpdateError] = "We encountered an error updating the case.";
ErrorMessages[APIStatus.CaseCreateError] = "We encountered an error creating the case.";
ErrorMessages[APIStatus.PermissionDeniedUser] = "Permission is denied for this user.";
ErrorMessages[APIStatus.PermissionDeniedApp] = "Permission is denied for this application.";
ErrorMessages[APIStatus.ExceptionOccured] = "An unspecified error occurred.";
ErrorMessages[APIStatus.Successful] = "The command was successfully completed.";
ErrorMessages[APIStatus.ItemNotFound] = "The item could not be found.";
ErrorMessages[APIStatus.NoItemsFound] = "No items could be found.";

export const errorMessageForStatusCode = (code) => {
    return ErrorMessages[code];
};

export const errorMessageForStatusString = (string) => {
    return ErrorMessages[APIStatus[string]];
};