import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  setAccountDataRefresh,
  getAccountInfo
} from "../../../store/actions/accounts-actions";

import { updateProfile } from "../../../api/accounts-api";
import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";
import { UpdateProfileView } from "./UpdateProfileView.component";
import UpdateProfileSuccessView from "./UpdateProfileSuccessView.component"

class UpdateProfile extends Component {
  constructor(props) {
    super(props);
    this.errorText = null;
    this.state = {
      updateProfileSuccess: false,
      serverError: false,
      sendingToServer: false,
      successValue: null,
    };

    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.actions.getAccountInfo();
  }

  updateProfile = values => {
    this.setState({
      sendingToServer: true
    });
    console.log(values.p1Email);
    console.log(values.p1ConfirmEmail);


    if (values.p1Email !== values.p1ConfirmEmail) {
      this.errorText = "Please check confirm email address.";
      this.setState({
        serverError: true,
        sendingToServer: false
      });
      window.scrollTo(0, 0);
      return;
    }

    updateProfile(
      {
        first_name: values.p1FirstName,
        last_name: values.p1LastName,
        primary_phone: values.p1PrimaryPhoneNumber,
        secondary_phone: values.p1SecondaryPhone,
        email: values.p1Email
      },
      {
        first_name: values.p2FirstName,
        last_name: values.p2LastName,
        primary_phone: values.p2PrimaryPhoneNumber,
        email: values.p2Email
      }
    )
      .then(response => {
        if(!response.data.is_update_successful)
        {
       
          this.errorText = response.data.error_messages[0] ? response.data.error_messages[0] : "Unable to update profile. Please try later.";
 
          this.setState({
            sendingToServer: false,
            serverError: true
          });
          window.scrollTo(0, 0);
        }
        else{
          setTimeout(() => {
            this.setState({
              updateProfileSuccess: true,
              sendingToServer: false,
              successValue: values
            });
            this.props.actions.setAccountDataRefresh();
            window.scrollTo(0, 0);
            //For google analytics
            window.dataLayer.push({
              'event': 'Pageview',
              'url': window.location.pathname + '/Confirm'
            });
          }, process.env.REACT_APP_UPDATE_API_DELAY);
        }
        
      })
      .catch(error => {
        this.errorText = error.errorMessage ? error.errorMessage : "Unable to update profile. Please try later.";
        this.setState({
          sendingToServer: false,
          serverError: true
        });
        window.scrollTo(0, 0);
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.updateProfileSuccess ? (
          <UpdateSuccessView
            statusHeader="Your profile has been successfully updated."
            title="Your new profile"
            backToHome={() => {
              this.props.history.push("/");
            }}
          >

            <UpdateProfileSuccessView
              value={this.state.successValue}
              backToHome={() => {
                this.props.history.push("/");
              }}
            />

          </UpdateSuccessView>
        ) : (
            <UpdateProfileView
              userAccountDetails={this.props.userAccountDetails}
              userAccountDetailsFetching={this.props.userAccountDetailsFetching}
              userAccountFetchError={this.props.userDetailsFetchError}
              canUserEdit={this.props.canUserEdit}
              updateProfile={this.updateProfile}
              errorText={this.errorText}
              sendingToServer={this.state.sendingToServer}
              serverError={this.state.serverError}
              backToHome={() => {
                this.props.history.push("/");
              }}
            />
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userAccountDetails: state.account.userAccountDetails,
  canUserEdit: state.account.canUserEdit,
  userAccountDetailsFetching: state.account.fetching,
  userDetailsFetchError: state.account.error
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { getAccountInfo, setAccountDataRefresh },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateProfile);
