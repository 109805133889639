import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logOut } from "../../store/actions/login-actions";

import "./PostCancelPolicy.css";

/**
 * TODO : 
 * 1. Clear Stack History and State on navigating to home screen.
 * 2. Resize iFrame on Submit
 */
class PostCancelPolicy extends Component {
  constructor(props) {
    super(props);
    
    if(props.location.state && props.location.state.isCancelled !== undefined) {
      this.isCancelled = props.location.state.isCancelled;
      this.props.actions.logOut();
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="post-cancellation-container container-fluid">
        <div className="container-fluid header-group">
          <div>
            <div className="d-flex align-items-center container header-top">
              <a href={process.env.REACT_APP_MAINSITE_URL} rel="noopener noreferrer">
                <div>
                  <img
                    className="logo"
                    alt="Healthy Paws Pet Insurance"
                    src="../../assets/images/logo_hppi.png"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="status-bar" />
        <div className="container d-flex flex-column justify-content-start">
          {this.isCancelled ? (
            <div>
              <h1 className="title-container d-flex align-items-center">
                We're sorry to see you leave us.
              </h1>
              <h4 className="mb-4">
                Thank you for the opportunity to serve you and we hope to
                welcome you back in the future!
              </h4>
              <h4 className="mb-1">
                You have requested to cancel your policy.
              </h4>
              <ul className="mb-4">
                <li>
                  Any pending policy changes will be canceled upon completion of
                  this process.
                </li>
                <li>
                  Once you sign out, you will no longer have access to this
                  Customer Center.
                </li>
                <li>
                  Feel free to <a href={"mailto:"+ process.env.REACT_APP_CARE_MAILID}>email</a> us if you have any questions or concerns
                  regarding your cancellation.
                </li>
              </ul>
            </div>
          ) : (
            <div>
              <h1 className="title-container d-flex align-items-center">
                Your request to cancel has been received.
              </h1>
              <h4 className="m-0">
                Your request to cancel cannot be completed through our system.
              </h4>
              <span>
                A Customer Care Representative will be in touch with you
                shortly. We appreciate your patience.
              </span>
              <h4 className="mb-0 mt-3">
                You have requested to cancel your policy.
              </h4>
              <ul className="mb-4">
                <li>
                  Any pending policy changes will be canceled upon completion of
                  this process.
                </li>
                <li>
                  Once you sign out, you will no longer have access to this
                  Customer Center.
                </li>
                <li>
                  Feel free to <a href={"mailto:"+ process.env.REACT_APP_CARE_MAILID}>email</a> us if you have any questions or concerns
                  regarding your cancellation.
                </li>
              </ul>
            </div>
          )}

          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSe5BGfTAbWNYu4mJ5oOW0GX0A20Da7fWuUemNqqrPAw-ya9pw/viewform?embedded=true"
            className="form-box"
            title="feedback-form"
            id="message"
            frameBorder="0"
            scrolling="no"
          >
            Loading...
          </iframe>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ logOut}, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(PostCancelPolicy);
