import { axiosInstanceOldAPI, axiosInstanceWithAuth } from "./axios";

export const log = (payload) => {
    let url = "/logentry";

    return axiosInstanceWithAuth
        .post(url, { "level": payload.level, "message": payload.message })
        .then(response => {
      return response;
    })
    .catch(function(err) {
      let error = {
        errorCode: -1000,
        errorDescription: "Not able to write to the log now.",
        errorMessage: "Not able to write to logging now.Try again later"
      };
      return Promise.reject(error);
    });

}