/**
 *  CCTLogin componenet
 *  This component is invoked by the route
 *  /cctlogin/:userId/:accountId.
 *  The userId is the username of the account
 *  The accountId is the password of the account
 *
 */

import React, { Component } from "react";

import { Redirect } from "react-router";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setLoginState, setCCTLoginState } from "../../store/actions/login-actions";

import { updateUserAccountInfo } from "./../common/util/AccountsUtil";

import { authenticate } from "./../../api/auth-api";
import { getAccountInfo } from "./../../api/accounts-api";

import Loader from "../common/loader/Loader";

import { AppLinks } from "../../constants/app-links";

class CCTLogin extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      authenticated: false
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const accountId = params.get("accountId");
    const userId = params.get("userId");
    authenticate({
      username: accountId,
      password: userId
    })
      .then(response => {
        getAccountInfo().then(accountResponse => {
          //As we depends on some properties if user account details
          //We are setting login to true only after successful useraccount fetch
          if (accountResponse) {
            updateUserAccountInfo(accountResponse);
            this.props.actions.setLoginState(true);
            this.props.actions.setCCTLoginState(true);
            this.setState({
              loading: false,
              authenticated: true
            });
          } else {
            this.props.actions.setLoginState(false);
            this.setState({
              loading: false,
              authenticated: false
            });
          }
        });
      })
      .catch(error => {
        this.props.actions.setLoginState(false);
        this.setState({
          loading: false,
          authenticated: false
        });
        window.scrollTo(0,0);
      });
  }

  render() {
    return this.state.loading ? (
      <Loader position="absolute" className="op1" />
    ) : this.state.authenticated ? (
      <Redirect to={AppLinks.home.home} />
    ) : (
      <Redirect to={AppLinks.others.login} />
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.login.loggedIn
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setLoginState,
      setCCTLoginState
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CCTLogin);
