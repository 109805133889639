import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../../common/loader/Loader";

import "./modal-dialog.css";

const modalDialog = props => {
  const InnerBody = props.modalBody;
  const InnerFooter = props.modalFooter;
  const innerHeader = props.modalHeader;
  const innerClassName = props.className;

  return ( 
    <div>
      <Modal
        className={innerClassName +" message-modal"}
        centered
        isOpen={props.isOpen}
        toggle={props.toggle}
      >
        {props.showLoader && <Loader position="absolute" zIndex="2000"/>}
        <ModalHeader className="message-header" toggle={props.toggle}>
          <div className="message-header-container">{innerHeader}</div>
        </ModalHeader>
        <ModalBody className="message-body">{InnerBody}</ModalBody>
        <ModalFooter className="message-footer justify-content-start">
          {InnerFooter}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default modalDialog;
