import React, { Component } from "react";
import "./LiveChat.css";
import {getChatEnabled} from "../../../api/chat-api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class LiveChat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isChatEnabled: true
        };

        getChatEnabled()
        .then(res => {
            this.setState({
                isChatEnabled: res
            });
        });
    }

    showChatButtons = () => {
        document.getElementById("chatButtons").style.display = "block";
    }

    componentDidMount() {
        if (this.state.isChatEnabled) {
            this.showChatButtons();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.getElementById("chatButtons").style.display = "none";
    }
    
    render() {
        return(<div></div>);
    }
}
    
export default LiveChat;
  