import CoverageQuestions from "../faq/detail/CoverageQuestions";
import GettingReimbursed from "../faq/detail/GettingReimbursed";
import filingClaims from '../faq/detail/FilingClaims';
import Billing from '../faq/detail/Billing';
import ManagingPolicy from '../faq/detail/ManagingPolicy';

export const coverageFaqLinks = [{
    text: "What is covered?",
    answer: () => {
        return CoverageQuestions.whatIsCoveredAnswer()
    }
}, {
    text: "What is not covered?",
    answer: () => {
        return CoverageQuestions.whatIsNotCovered()
    }
}, {
    text: "Is there a waiting period?",
    answer: () => {
        return filingClaims.waitingPeriod()
    }
}, ]

export const policyFaqLinks = [{
    text: "How do I add a new pet?",
    answer: () => {
        return ManagingPolicy.addPet();
    }
}, {
    text: "How does the annual deductible work?",
    answer: () => {
        return ManagingPolicy.annualDeductible();
    }
}, {
    text: "After I enroll can I change my deductible or reimbursement level?",
    answer: () => {
        return ManagingPolicy.afterEnroll();
    }
}, ]

export const claimFaqLinks = [{
    text: "How do I file a claim?",
    answer: () => {
        return filingClaims.fileClaim();
    }
}, {
    text: "What medical records do you need for my pet?",
    answer: () => {
        return filingClaims.medicalRecords();
    }
}, {
    text: "What are my options for reimbursement?",
    answer: () => {
        return GettingReimbursed.optionReimbrushment();
    }
}, 
]

export const billingFaqLinks = [{
    text: "How do I update my account or billing information?",
    answer: () => {
        return Billing.updateProfile();
    }
}, {
    text: "Transfer of pet ownership",
    answer: () => {
        return Billing.ownershipTransfer();
    }
}, {
    text: "What is the 30-day Free Look Period?",
    answer: () => {
        return Billing.freeLookPeriod();
    }
},  
]