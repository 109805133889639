import * as actionTypes from "../../constants/action-types";

export const getPetsByAccount = () => ({
  type: actionTypes.PETS_FETCH_REQUEST
});

export const setPetsDataRefresh = () => ({
  type: actionTypes.SET_PETDETAILS_DATA_REFRESH
});

export const petUpdateRequest = payload => ({
  type: actionTypes.PET_PHOTO_UPDATE_REQUEST,
  payload
});

export const petUpdateModal = status => ({
  type: actionTypes.PET_PHOTO_UPDATE_MODAL,
  status
});

export const petUpdateLoader = payload => ({
  type: actionTypes.PET_PHOTO_UPDATE_LOADER,
  payload
});

export const getAvailablePolicyOptions = () => ({
  type: actionTypes.GET_AVAILABLE_POLICY_OPTIONS
});

export const getAvailablePolicyOptionsSuccess = payload => ({
  type: actionTypes.GET_AVAILABLE_POLICY_OPTIONS_SUCCESS,
  payload
});

export const getAvailablePolicyOptionsError = payload => ({
  type: actionTypes.GET_AVAILABLE_POLICY_OPTIONS_ERROR,
  payload
});


export const getAnniversaryPolicyOptions = (state) => ({
  type: actionTypes.GET_ANNIVERSARY_POLICY_OPTIONS,
  state
});

export const getAnniversaryPolicyOptionsSuccess = payload => ({
  type: actionTypes.GET_ANNIVERSARY_POLICY_OPTIONS_SUCCESS,
  payload
});

export const getAnniversaryPolicyOptionsError = payload => ({
  type: actionTypes.GET_ANNIVERSARY_POLICY_OPTIONS_ERROR,
  payload
});
// export const updatePetImageURL = payload => ({
//   type: actionTypes.PET_PHOTO_UPDATE,
//   payload
// });
