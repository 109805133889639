import React from "react";
import { Link } from "react-router-dom";


export const CreateAlertHeader = ({
  alertsCount,
  notificationsCount,
  alertsFetchError,
  canUserEdit,
  isAlertExpanded
}) => {
  var message =  "";
  if (alertsFetchError && canUserEdit) {
    message = "Unable to load Alert(s) or notification(s).";
  } else {
    if (alertsCount > 0 && notificationsCount > 0) {
      const alertMessage =
        alertsCount === 1
          ? "You have a new account alert and "
          : alertsCount + " account alerts and ";
      const notificationMessage =
        notificationsCount === 1
          ? "a new notification:"
          : notificationsCount + " notifications:";
      message += alertMessage + notificationMessage;
    } else {
      if (alertsCount > 0) {
        const alertMessage =
          alertsCount === 1
            ? "You have a new account alert:"
            : "You have " + alertsCount + " account alerts:";
        message = alertMessage;
      } else if (notificationsCount > 0) {
        const notificationMessage =
          notificationsCount === 1
            ? "You have a new notification:"
            : "You have " + notificationsCount + " notifications:";
        message = notificationMessage;
      }
    }
  }

  return (
    <React.Fragment>
      {alertsCount > 0 ? (
        <i className="fa fa-exclamation-triangle" />
      ) : (
        <i className="fa fa-bell" />
      )}
      <span className={isAlertExpanded ? "" : "alert-header-ellipse"} style={{ paddingLeft: 10}}>
        {message +" "}<span className="read-more">Read More</span>{" "}
      </span>
    </React.Fragment>
  );
};

export const CreateAlertNotificationList = ({
  alertBgClass,
  alerts,
  notifications,
  show,
  retryBillingHandler,
  dismissNotificationsHandler
}) => {
  const totalAlerts = alerts.length + notifications.length;

  if (totalAlerts === 1) {
    var listStyle = {
      listStyle: "none",
      marginLeft: -18
    };
  }
  return (
    <div
      className={
        "alerts-content container-fluid " + (show ? "open " : "") + alertBgClass
      }
    >
      <div style={{marginBottom:10}} className="container">
        <ul style={listStyle}>
          {alerts.map((alert, index) => (
            <li key={"alerts" + index} style={{ padding: "5px 0px" }}>
              <CreateAlertContent
                alertContent={alert}
                retryBillingHandler={retryBillingHandler}
              />
            </li>
          ))}
          {notifications.map((notification, index) => (
            <li key={"notifications" + index} style={{ padding: "5px 0px" }}>
              <CreateNotificationContent
                notificationContent={notification}
              />
            </li>
          ))}
        </ul>
        {alerts.length === 0 && notifications.length > 0 && (
          <button
            onClick={dismissNotificationsHandler}
            className="btn action-button action-button-notification"
          >
            Dismiss
          </button>
        )}
      </div>
    </div>
  );
};

const CreateAlertContent = ({
  alertContent,
  retryBillingHandler,
  metadata
}) => {
  return (
    <div>
      <CreateFormattedMessage message={alertContent.message} />
      {
        <div>
          {alertContent.actions.map((action, index) => (
            <CreateActionButton
              key={index}
              type={action}
              retryBillingHandler={retryBillingHandler}
              metadata={alertContent.metadata}
            />
          ))}
        </div>
      }
    </div>
  );
};

const CreateNotificationContent = ({
  notificationContent,
  retryBillingHandler,
  metadata
}) => {
  return (
    <div>
      <CreateFormattedMessage message={notificationContent.message} />
      {
        <div>
          {notificationContent.actions.map((action, index) => (
            <CreateActionButton
              key={index}
              type={action}
              retryBillingHandler={retryBillingHandler}
              metadata={notificationContent.metadata}
            />
          ))}
        </div>
      }
    </div>
  );
};

const createHtmlElement = (htmlString , index) => {
  let htmlElement;
  var htmlObject = document.createElement("div");
  htmlObject.innerHTML = htmlString;
  let classAttributeValue = htmlObject.childNodes[0].getAttribute("class");
  const innerText = htmlObject.childNodes[0].innerText;

  //For now action button also send with <span> tag in Message string
  //So we are removing only the span tags and adding the inner content
  //For now we have handled for only two action button cases
  if (
    innerText.includes("Please retry") ||
    innerText.includes("update your billing")
  ) {
    htmlElement = <span key={innerText + index}>{innerText}</span>;
  } else {
    if (classAttributeValue.includes("Bold")) {
      //Adds Bold style to the string
      htmlElement = (
        <span key={innerText + index}>
          <strong>{innerText}</strong>
        </span>
      );
    } else {
      // Adds Link and URL
      //Assumption is that , for now we support only strong and link tags
      //Links provided by API , should be internal react links
      classAttributeValue = classAttributeValue === "alertUrl" ? "contact" : classAttributeValue;
      htmlElement = (
        <Link key={innerText + index} to={classAttributeValue}>
          <span>{innerText}</span>
        </Link>
      );
    }
  }

  return htmlElement;
};

const CreateFormattedMessage = ({ message }) => {
  let indexOfOccurance,
    messageElementArray = [],
    remainingString = "";
  //As some links and bold texts are mentioned in message text by API using span tags
  //From Client has to extract it and create corresponding HTML elements for that
  //As React render only child elements from array format. FYI
  const htmlTags = message.match(/(<span class(.*?)<\/span>)/gim);

  if (htmlTags) {
    for (var i = 0; i < htmlTags.length; i++) {
      if (i === 0) {
        remainingString = message;
      }
      indexOfOccurance = remainingString.indexOf(htmlTags[i]);
      messageElementArray.push(
        <span key={indexOfOccurance}>{remainingString.substr(0, indexOfOccurance)}</span>
      );
      messageElementArray.push(createHtmlElement(htmlTags[i],i));
      const nextStringStarts = indexOfOccurance + htmlTags[i].length;
      remainingString = remainingString.substr(
        nextStringStarts,
        remainingString.length - 1
      );
    }
    messageElementArray.push(<span key={"remainingString" + i}>{remainingString}</span>);
    return <p className="mb-0">{messageElementArray}</p>;
  } else if(message.indexOf("click here") !== -1){
    //If the message string has click here
    //But doesn't have any span tag will be handled here
    indexOfOccurance = message.indexOf("click here");
    remainingString  = message;
    messageElementArray.push(
      <span>{remainingString.substr(0, indexOfOccurance)}</span>
    );
    const htmlElement = (
      <Link to="/contactus">
        <span>Click here</span>
      </Link>
    );
    messageElementArray.push(htmlElement);
    const nextStringStarts = indexOfOccurance + 10; //"Click here" - length
    remainingString = remainingString.substr(
      nextStringStarts,
      remainingString.length - 1
    );
    messageElementArray.push(<span >{remainingString}</span>);
    return <p key={"formattedMessage"} className="mb-0">{messageElementArray}</p>;
  } else {
    return <p className="mb-0"> {message}</p>;
  }
};

const CreateActionButton = ({ type, metadata, retryBillingHandler }) => {
  let actionElement = null;
  switch (type) {
    case "UpdateBillingInfo":
      actionElement = (
        <Link to="/updatebillinginfo">
          <button className="btn action-button">Update Billing Info</button>
        </Link>
      );
      break;
    case "RetryBilling":
      actionElement = (
        <button onClick={retryBillingHandler} className="btn retry-billing-button action-button">
          Retry Billing Info
        </button>
      );
      break;
    case "UpdateClaim":
      const missingDocURL =
        "/UpdateClaim?claimId=" +
        metadata.claim_id +
        "&petId=" +
        metadata.pet_id;
      actionElement = (
        <Link to={missingDocURL}>
          <button className="btn action-button">Update Claim</button>
        </Link>
      );
      break;
    case "AnniversaryCoverageOptions":
      actionElement = (
        <Link to="/anniversaryoptions">
          <button className="btn action-button action-button-notification">Annual Policy Renewal Options</button>
        </Link>
      );
      break;
    //We are commenting out for now as we are not handling this any more.
    //We may need in future

    // case "ContactUs":
    //   actionElement = (
    //     <Link to={AppLinks.others.contact}>
    //       <span style={{ color: "white", textDecoration: "underline" }}>
    //         {" "}
    //         click here{" "}
    //       </span>
    //     </Link>
    //   );
    //break;
    default:
      actionElement = null;
  }
  return actionElement;
};

