import React from "react";
import { Link } from "react-router-dom";
import { AppLinks } from "../../../constants/app-links";
import "./styles.css";

const PetParentInfo = ({parentInfo, address, reimbursement, policyEffectiveDate, status}) => {
    return (
        <div className="d-md-flex flex-column justify-content-between" >
            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading" style={{ marginBottom: 0 }} >Contact Information</span>
                    {status == "Cancel Prorata"? null :<Link to={AppLinks.account.changeProfile} className="text-secondary d-md-block" >Edit</Link>}
                    
                </div>
                <span> {parentInfo.FirstName} {parentInfo.LastName} </span> <br />
                <span> {parentInfo.PrimaryPhone} </span> <br />
                <span> {parentInfo.Email} </span>
            </div>

            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading" style={{ marginBottom: 0 }} >Physical Address</span>
                    {status == "Cancel Prorata"? null :<Link to={AppLinks.account.changePhysicalAddress} className="text-secondary d-md-block" >Edit</Link>}
                    
                </div>
                <span> {address.Street} </span> <br />
                <span> {address.City}, {address.State} {address.PostalCode} </span>
            </div>

            <div className="mt-3">
                <div className="d-flex flex-row justify-content-between" >
                    <span className="heading" style={{ marginBottom: 0 }} >Reimbursement Info</span>
                    <Link to={AppLinks.account.updateReimbursement} className="text-secondary d-md-block" >Edit</Link>
                    
                </div>
                <span> {reimbursement} </span>
            </div>

            <div className="mt-3 d-flex consent-text">
                <div className="mr-1"> <i className="fa fa-check-square-o"></i> </div>
                <div>Consent to <a href={AppLinks.externalLinks.legal} className="tos-privacy-link" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and <a href={AppLinks.externalLinks.privacyPolicy} className="tos-privacy-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a> provided on {new Date(policyEffectiveDate).toLocaleDateString('en-US')}.</div>
            </div>
        </div>
    )
}

export default PetParentInfo;