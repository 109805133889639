import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import enhanceWithClickOutside from 'react-click-outside';
import injectExtole from "../../../extole-hoc/extole-hoc";
import "./DropdownMenu.css";
import { menuItems } from "../../menu";
import { AppLinks } from "../../../../../constants/app-links";

class DropdownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedMenuHeader: "",
      pathname: props.location.pathname,
      newMenuItems: menuItems
    };
    this.toggle = this.toggle.bind(this);
    let menuToHideForCancelledAccounts = ["View Policy Options","Change Physical Address","Change Profile","Update Billing Info","Cancel Account"];
    if(this.props.userAccountDetails?.Status.toLowerCase().trim() === "cancel prorata") {
      this.state.newMenuItems = menuItems?.map((element) => {
        return {...element, menu_items: element.menu_items?.filter(x=>!menuToHideForCancelledAccounts.includes(x.subtitle))}})
    }
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({ pathname: location.pathname });
    });
    this.updateActiveMenu(this.props.location.pathname);
  }

  componentDidUpdate() {
    this.updateActiveMenu(this.state.pathname);
  }

  updateActiveMenu(pathname) {
    let keys = Object.keys(AppLinks);
    let selectedElement;
    if (pathname !== "/") {
      keys.map(key => {
        if (
          JSON.stringify(AppLinks[key])
            .toLowerCase()
            .includes(pathname.toLowerCase())
        ) {
          selectedElement = document.getElementById("menu-" + key);
          if (selectedElement) {
            selectedElement.classList.add("active-navmenu");
          }
        }
        return selectedElement;
      });
    }
    if (pathname === "/") {
      let element = document.getElementById("menu-home");
      if (element) {
        element.classList.add("active-navmenu");
      }
    }
    //For URLs that are not part of the main menu, active menu item will not be shown
  }

  componentWillUnmount() {
    this.unlisten();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.history.location.pathname !== prevState.pathname) {
      return { pathname: nextProps.history.location.pathname };
    }
    return null;
  }

  handleClickOutside = evt => {
    this.setState({ selectedMenuHeader: "", isOpen: false });
  };

  toggle(evt) {
    let identifier = evt.target.id;
    if (this.state.selectedMenuHeader !== identifier) {
      this.setState({ selectedMenuHeader: identifier, isOpen: true });
    } else {
      this.setState({ selectedMenuHeader: "", isOpen: false });
    }
  }

  render() {
    const RenderMenuWithSubMenu = ({ header, identifier, menu_items, enableRAF }) => {
      return (
        <div key={identifier} onClick={event => this.toggle(event)} className="nav-item">
          <div
            id={identifier}
            className={
              "dropdown-navmenu-header " +
              (this.state.isOpen && this.state.selectedMenuHeader === identifier
                ? "header-active"
                : "")
            }
          >
            {header}
            <i class="fa fa-caret-down"></i>
          </div>
          <div
            className={
              "dropdown-navmenu-items " +
              (this.state.isOpen && this.state.selectedMenuHeader === identifier
                ? "header-menu-active"
                : "")
            }
          >
            {menu_items.map((subItem, index) => (
              <div key={index}>
                {(identifier === "menu-faq" && subItem.subtitle === "Refer a Friend") ? (
                  enableRAF && <Link to={subItem.link}>{subItem.subtitle}</Link>
                ) :
                  (<Link to={subItem.link}>{subItem.subtitle}</Link>)
                }
              </div>
            ))}
          </div>
        </div>
      );
    };

    /* for ReferAFriend, which should trigger extole. Link overrides the behaviour.
     */
    const RenderWithoutSubMenu = ({ header, identifier, link, icon, enableRAF }) => {
      if (identifier === "menu-refer-friend") {
        if (enableRAF) {
          return (
            <ReferAFrienMenuItemWithExtole
              header={header}
              identifier={identifier}
            />
          );
        } else {
          return null;
        }

      } else {
        return (
          <div key={identifier} className="nav-item">
            {link === undefined ? (
              <div id={identifier} className="dropdown-navmenu-header">
                {header}
              </div>
            ) : (
                <Link to={link}>
                  <div id={identifier} className="dropdown-navmenu-header">
                    {!icon ? (
                      ""
                    ) : (
                        <i className="header-navmenu-icons fa fa-home mr-1" />
                      )}
                    {header}
                  </div>
                </Link>
              )}
          </div>
        );
      }
    };
    return (
      <div id="dropdown-navmenu" className="container-fluid">
        <div className="dropdown-navmenu-group d-flex container">
          <div className="logo-container">
            <img
              id="logo"
              alt="Healthy Paws Pet Insurance"
              src="../../assets/images/login/logo_main.png"
            />
          </div>
          <div className="menu-container">
            {this.state.newMenuItems?.map(
                (
                  { header, identifier, menu_items, link, hideLink, mobile, icon },
                  index
                ) =>
                  mobile ? (
                    ""
                  ) : menu_items !== undefined && !link ? (
                    <RenderMenuWithSubMenu
                      key={header}
                      header={header}
                      identifier={identifier}
                      menu_items={menu_items}
                      enableRAF={this.props.enableRAF}
                    />
                  ) : (
                        <RenderWithoutSubMenu
                          key={header}
                          header={header}
                          link={link}
                          identifier={identifier}
                          icon={icon}
                          enableRAF={this.props.enableRAF}
                        />
                      )
              )}
          </div>
          <div id="header-right-container">
            <div className="headertop-image-group d-inline-block">
              <div
                id="header-need-help"
                onClick={e => this.props.toggleMenu(e, "need-help")}
              >
                <img
                  className="headertop-image-item"
                  alt="Contact information"
                  src="/assets/images/icon_help.png"
                />
                
              </div>
            </div>

            <div className="headertop-image-group d-inline-block">
              <div
                id="message"
                onClick={e => this.props.toggleMenu(e, "messages")}
              >
                <img
                  className="headertop-image-item "
                  alt="Email to us"
                  src="/assets/images/icon_messages.png"
                />
                {this.props.unreadMessageCount > 0 &&
                  <div id="message-count" className={this.props.unreadMessageCount > 99 ? "message-count-large" : "message-count-small"}>
                    <strong>
                      <span id="message-text" className="w-100 h-100 d-flex align-items-center justify-content-center">{this.props.unreadMessageCount}</span>
                    </strong>
                  </div>
                }
                
              </div>
            </div>

            <div className="headertop-image-group d-inline-block">
              <div
                onClick={e => this.props.toggleMenu(e, "my-account")}
                id="header-account"
              >
                <div>
                  <img
                    className="headertop-image-item"
                    alt="account information"
                    src="/assets/images/icon_account.png"
                  />
                  
                </div>
              </div>
            </div>
      </div>
        </div>
      </div>
    );
  }
}

const ReferAFrienMenuItem = props => {
  return (
    <div id={props.identifier} className="nav-item">
      <div className="dropdown-navmenu-header">
        {props.header}
      </div>
    </div>
  );
};

const ReferAFrienMenuItemWithExtole = injectExtole(ReferAFrienMenuItem, {
  ctaID: "menu-refer-friend"
});

export default withRouter(enhanceWithClickOutside(DropdownMenu));
