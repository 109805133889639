import React from "react";
import { Link } from "react-router-dom";
import { AppLinks } from "../../../constants/app-links";

const ClaimRebursementInfo = ({
  claimReimbursementMethod,
  mailingAddress,
  accountDetails
}) => {
  return (
    <div className="d-md-flex flex-column justify-content-between">
      <div className="mt-3">
        <div className="d-flex flex-row justify-content-between">
          <span className="heading">Reimbusement Type</span>
          <Link
            to={AppLinks.account.updateReimbursement}
            className="text-secondary"
          >
            Edit
          </Link>
          {/* <Link
            to={AppLinks.account.updateReimbursement}
            className="d-block d-lg-none"
          >
            <button className="btn btn-outline-secondary pt-0 pb-0">
              Edit
            </button>
          </Link> */}
        </div>
        <span>
          {claimReimbursementMethod === "Direct Deposit" ? "" : "Mail"}{" "}
          {claimReimbursementMethod}{" "}
        </span>
      </div>

      {claimReimbursementMethod === "Direct Deposit" ? (
        <React.Fragment>
        <div className="mt-3">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <span className="heading">Account Type </span>
            </div>
          </div>
          <span> {accountDetails.AccountType} </span>
        </div>
        <div className="mt-3">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <span className="heading">Account </span>
            </div>
          </div>
          <span> {accountDetails.AccountNumberMasked} </span>
        </div>
        </React.Fragment>
      ) : (
        <div className="mt-3">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <span className="heading">Mailing Address </span>
              <span className="d-none d-md-inline-block d-lg-none">
                <i> for reimbursement check</i>
              </span>
            </div>
          </div>
          <span> {mailingAddress.Street} </span>{" "}
          <br className="d-block d-md-none d-lg-block" />
          <span>
            {" "}
            {mailingAddress.City}, {mailingAddress.State}{" "}
            {mailingAddress.PostalCode}{" "}
          </span>
        </div>
      )}
    </div>
  );
};

export default ClaimRebursementInfo;
