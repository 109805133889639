import React from "react";

import "./MessagesPagination.css";

const MessagesPagination = props => {
  return (
    <div id="message-pagination" className="text-center">
      {props.totalPages > 1 && (
        <div className="text-center d-flex justify-content-center">
          <div
            onClick={evt => props.previousPage(evt)}
            className="pagination-arrows disabled"
          >
            <i className="fa fa-angle-left" />
          </div>
          <div id="page-edit-text">
            <input value={props.currentPageNo} type="text" readOnly />
          </div>
          <span id="page-numbers">/ {props.totalPages}</span>
          <div
            onClick={evt => props.nextPage(evt)}
            className="pagination-arrows"
          >
            <i className="fa fa-angle-right" />
          </div>
        </div>
      )}

      <div id="messages-status-change" className="d-none">
        <span>
          <a href="/">Mark all as read </a> |{" "}
        </span>
        <span>
          <a href="/">Delete messages</a>
        </span>
      </div>
    </div>
  );
};

export default MessagesPagination;
