import { APIStatus, errorMessageForStatusCode } from "../constants/error-code";

// Standard Format for All API's

// // Error.
// {
//   errorCode:
//   errorDescription:
//   errorMessage: // Optional
// }

export const validateResponse = response => {

  // Some API's have different response structure :(
  if (response.config.url.includes("Upload/Send")) {
    // Plain Text Response API's
    return validatePlainResponse(response);

  } else {
    if (response.data.ErrorCode < 0) {
      return Promise.reject({
        errorCode: response.data.ErrorCode,
        errorDescription: response.data.ErrorDescription,
        errorMessage: errorMessageForStatusCode(response.data.ErrorCode)
      });
    } else {
      return response;
    }
  }
};

const validatePlainResponse = (response) => {
  // Check if response matches some Standard Error codes
  const status = APIStatus[response.data];
  if (status && status < 0) {
    return Promise.reject({
      errorCode: status,
      errorDescription: APIStatus[status],
      errorMessage: errorMessageForStatusCode(status)
    });
  } else { // TODO: Handle any other cases if needed.
    return response;
  }
};