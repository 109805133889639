import React from "react";
import { Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import classes from "./noMedComp.module.css";
import { Link } from "react-router-dom";
import { AppLinks } from "../../../../constants/app-links";

export const NoMedComp = ({isAnyPetUnderWaitingPeriod}) => {  
  const DesktopTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: "#666666",
      height: "220px",
      fontSize: "14px"
    }
  }))(Tooltip);

  const MobileTooltip = withStyles(() => ({
    tooltip: {
      top: "-12px",
      backgroundColor: "#666666",
      width: "500px",
      height: "190px",
      fontSize: "13px"
    },
    popper:{
      zIndex: "100001"
    },
    arrow: {
      opacity: "0"
    }
  }))(Tooltip);

  const [desktopTooltipOpen, setDesktopTooltipOpen] = React.useState(false);
  const [mobileTooltipOpen, setmobileTooltipOpen] = React.useState(false);

  const handleDesktopTooltipClose = () => {
    setDesktopTooltipOpen(false);
  };
  const toggleDesktopTooltip = () => {
    // setDesktopTooltipOpen(!desktopTooltipOpen);
    window.open('https://www.healthypawspetinsurance.com/waiting-periods-and-veterinary-exam','_blank');
  };
  const hanldeMobileTooltipClose = () => {
    setmobileTooltipOpen(false);
  };
  const toggleMobileTooltip = () => {
    // setmobileTooltipOpen(!mobileTooltipOpen);
    window.open('https://www.healthypawspetinsurance.com/waiting-periods-and-veterinary-exam','_blank');
  };
    var carousalBgStyle = {
        backgroundImage: "url(../../assets/images/noMedicalRecords.jpg)"
      };
      var tooltipBgStyle = {
        backgroundImage: "url(../../assets/images/toolTip.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100%"
      };
      var tooltipMobStyle = {
        backgroundImage: "url(../../assets/images/toolTipMobile.svg)"
      };
    var Modal = document.getElementById("Modal");
    var classVar = classes.display;
    var toggleModal = () =>{        
      document.getElementById("Modal").classList.toggle(classVar);
    }   
    return (
      <div>
      <div  className={classes.background}>
        <div style={carousalBgStyle} className={classes.desktopView}>

          <div id="Modal" className={classes.modal + ' ' + classes.display}>
            <div className={classes.modalBox}>
              <div className={classes.modalHeading}>Last Veterinarian Exam <i className="fa fa-times" aria-hidden="true" onClick={toggleModal}></i></div>
              <div className={classes.modalContent}>
              <p>We use your pet's medical records to process claims. This information tells us that your pet has been examined by a veterinarian within the time frame required by our policy.</p>
              <p>For pets younger than 6 years of age, a veterinarian exam is required in the 12 months  prior to enrollment. For pets older than 6 years of age, a veterinarian exam is required within 30 days of enrollment.</p>
              <p>If this information is not available, an exam is required within 15 days of enrollment for the policy to take effect.</p>
              </div>
              <div className={classes.closeButton} onClick={() => toggleModal()}>I understand</div>
            </div>
          </div>
          <div>
          <div className={classes.gif}>
          <img  className = {classes.gifImg} src="../../assets/gif/Pet.gif" alt="petGif"/>
          {isAnyPetUnderWaitingPeriod?
            (
            <div className={classes.text1}>
            During your <span className={classes.bolder}>waiting period…
            <DesktopTooltip interactive placement="bottom" 
                open={desktopTooltipOpen}
                disableHoverListener
                title = {
              <div>
              <div><i onClick={handleDesktopTooltipClose} className={"fa fa-times"} style={{float:"right", cursor:"pointer"}} aria-hidden="true"></i> <br /></div>
              <div>
               Your policy will be effective at 12:01 am the day after you enroll your pets. 
               Your coverage will then begin after a 15-day waiting period. You can submit claims for
               accidents &amp; illnesses that occur after the 15-day waiting period. <br /><br />
               Hip dysplasia coverage is provided for pets 5 years of age and younger and is subject to a 
               12-month waiting period.
              </div>
              </div> } arrow>
              <i onClick={toggleDesktopTooltip} style={{cursor:"pointer"}} className={classes.orangeIcon + " fa fa-question-circle"}></i>
            </DesktopTooltip>
            </span>
          </div>
            ):(<div />)
          }
          <div className={classes.text1}><span className={classes.greenText}>Complete these steps to ensure fast</span></div>
          <div className={classes.text1}><span className={classes.greenText}>claim filing &amp; reimbursement</span></div>
          <div className={classes.text2}>
          <div className = {classes.numbers}>1.</div> 
          <div className={classes.number1Content}>
            <p>Complete Enrollment Examination</p>
            {/* <p>haven't already done so</p> */}
             <p className={classes.specialP}><a href={'https://www.healthypawspetinsurance.com/waiting-periods-and-veterinary-exam'} target={'_blank'}>View important requirements here</a></p></div>
             </div>
          {/* <div className={classes.text2}>
          <div className = {classes.numbers}>2.</div> 
          <Link to="/submitmr"><div className={classes.number2Content}>Upload Medical Records<i className="fa fa-angle-right fa-2x text-secondary "></i>
          </div></Link>
          </div> */}
          <div className={classes.text2}>
          <div className = {classes.numbers}>2.</div> 
          <Link to="/updatereimbursement"><div className={classes.number2Content}>Choose Reimbursement Type<i className="fa fa-angle-right fa-2x text-secondary "></i>
          </div></Link>
          </div>   
          </div>     
          </div>
          <div className={classes.footer}>
            <div className={classes.arrowUp}> 
            <i className="fa fa-lg fa-arrow-up"></i>
            <div className={classes.arrowText}> Download our mobile app &amp; file a claim in seconds</div>            
            </div>
            <div className={classes.appStore}><a href={AppLinks.externalLinks.iOSApp} target="_blank"><img className={classes.appStoreImg} alt="text" src="../../assets/images/btn_app_store.png" style={{ borderRadius:"5px"}}></img></a>
            </div>
            <div className={classes.appGoogle}><a href={AppLinks.externalLinks.androidApp} target="_blank"><img className={classes.appGoogleImg} alt="text" src="../../assets/images/btn_google_play.png" style={{ borderRadius:"5px"}}></img></a>
            </div>
          </div>
          </div>
          <div className={classes.mobileView}>
          <div className={classes.padding}>
          {isAnyPetUnderWaitingPeriod?
            (<div>
              During your <span className={classes.bolder}>waiting period…
             <MobileTooltip interactive placement="bottom"
                open={mobileTooltipOpen}
                style={{ zIndex: '100000000' }}
                disableHoverListener
                title = {
              <div>
              <div><i onClick={hanldeMobileTooltipClose} className={"fa fa-times"} style={{float:"right", cursor:"pointer"}} aria-hidden="true"></i> <br /></div>
              <div>
               Your policy will be effective at 12:01 am the day after you enroll your pets. 
               Your coverage will then begin after a 15-day waiting period. You can submit claims for
               accidents &amp; illnesses that occur after the 15-day waiting period. <br /><br />
               Hip dysplasia coverage is provided for pets 5 years of age and younger and is subject to a 
               12-month waiting period.
              </div>
              </div> } arrow>
              <i onClick={toggleMobileTooltip} style={{cursor:"pointer"}} className={classes.orangeIcon + " fa fa-question-circle"}></i>
            </MobileTooltip>
            </span>
            </div>)
            :(<div />)
          }
          <p ><span className={classes.greenText}>Complete these steps to ensure fast claim filing &amp; reimbursement</span></p>
          </div>
          <div className={classes.mobNavtext}>
            <div className={classes.numbers}>1.</div>
            <div className={classes.number2Content} style={{paddingBottom:'5px'}}>Complete Enrollment Examination
            </div>
            <div className={classes.number2Content} style={{paddingTop:'0px'}}><a style={{textDecoration:'underline'}} href={'https://www.healthypawspetinsurance.com/waiting-periods-and-veterinary-exam'} target={'_blank'}>View important requirements here</a></div>
          </div>
          <div>
            
            {/* <Link to="/submitmr">
              <div className={classes.mobNavtext}>
              <div className = {classes.numbers}>2.</div> 

                <div className={classes.number2Content}>Upload Medical Records
              </div>
              <i className="fa fa-angle-right fa-2x text-secondary "></i>
              </div>
            </Link> */}
            <Link to="/updatereimbursement">
              <div className={classes.mobNavtext}>
              <div className = {classes.numbers}>2.</div> 

                <div className={classes.number2Content}>Choose Reimbursement Type
              </div>
              <i className="fa fa-angle-right fa-2x text-secondary "></i>
              </div>
            </Link>
            <Link to="/mobileapp">
              <div className={classes.mobNavtext}>
              <div className = {classes.numbers}>3.</div> 

                <div className={classes.number2Content}>Get the Mobile App
              </div>
              <i className="fa fa-angle-right fa-2x text-secondary "></i>
              </div>
            </Link>
            <div className={classes.mobNavtext}>
              <div className = {classes.greenPadding}></div> 
              </div>
          </div>
          </div>
      </div>
      </div>
    );
  };