import React from "react";
import "./PetSelector.css";

const PetSelector = ({ pets, selectionHandler, selectedPet }) => {
  return (
    <div>
      {pets && pets.length <= 4 ? (
        <div className="d-flex pet-selector flex-wrap justify-content-center">
          {pets.map(pet => (
            <div
              key={pet.PetID}
              className={selectedPet === pet.PetID ? "pet" : "pet disabled"}
              onClick={() => selectionHandler(pet.PetID, pet.DateJoinedFamily)}
            >
              <div
                className="pet-img-container"
              >
                <img
                  src={
                    pet.ImageAvailable
                      ? pet.PetImageURL
                      : pet.Type === "Dog"
                        ? "/assets/images/add-dog-small.svg"
                        : "/assets/images/add-cat-small.svg"
                  }
                  alt={pet.Name}
                />
              </div>
              <p className="truncate"> {pet.Name} </p>
              {selectedPet === pet.PetID ? (
                <div className="image-selected-tick">
                  <i
                    className="fa fa-check"
                    style={{ fontSize: 17, color: "white" }}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      ) : (
          <div className="d-flex pet-selector flex-wrap">
            {pets.map(pet => (
              <div 
              key={pet.PetID}
              className={selectedPet === pet.PetID ? "pet-chip chip-selected" : "pet-chip"}
              onClick={() => selectionHandler(pet.PetID, pet.DateJoinedFamily)}
              >
                <div className="pet-name" >{pet.Name}</div>
                {selectedPet === pet.PetID ? (
                <div className="chip-selected-tick">
                  <i
                    className="fa fa-check"
                    style={{ fontSize: 17, color: "white" }}
                  />
                </div>
              ) : null}
              </div>
            ))}
          </div>
        )}
    </div>
  );
};

export default PetSelector;
