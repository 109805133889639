import React, { Component } from "react";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { Form } from "informed";
import CheckboxControl from "./../../common/form-fields/checkbox/Checkbox";
import UploadVeterinaryInvoice from "./../../common/file-upload/FileUpload";
import UploadMedicalRecords from "./../../common/file-upload/FileUpload";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPetsByAccount } from "../../../store/actions/pets-actions";
import { getAccountInfo } from "../../../store/actions/accounts-actions";
import {
  getClaimsByAccount,
  setClaimsDataRefresh
} from "../../../store/actions/claims-actions";
import { getAlertsByAccount } from "../../../store/actions/alerts-actions";
import Loader from "./../../common/loader/Loader";
import ErrorView from "./../../common/error-view/ErrorView";
import { updateClaim } from "../../../api/claims-api";
import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";
import BillingInfo from "./../components/billing-info/BillingInfo";

import "./UploadMissingDocs.css";
class UploadMissingDocs extends Component {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    this.state = {
      showUploadVeterinaryInvoices: false,
      veterinaryInvoicesList: [],
      showUploadMedicalRecords: true,
      medicalRecordsList: [],
      uploading: false,
      uploadDocsSuccess: false,
      uploadDocsError: false
    };

    this.missingClaimData = [];
    this.receivedClaimData = [];

    this.veterinaryUploadRef = React.createRef();
    this.medicalUploadRef = React.createRef();

    this.needsInvoice = false;
    this.renderDataSetup = false;

    let petId, claimId;
    if (props.location.search) {
      var input = props.location.search.trim().replace(/^[?#&]/, "");
      for (const param of input.split("&")) {
        let [key, value] = param.split("=");
        if (key === "petId") petId = value;
        if (key === "claimId") claimId = value;
      }
    }

    if (petId && claimId) {
      this.petId = petId;
      this.claimId = claimId;
    } else {
      //If the page doesn't route properly
      this.props.history.push("/");
    }

    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.actions.getAccountInfo();
    this.props.actions.getPetsByAccount();
    this.props.actions.getClaimsByAccount();
  }

  uploadVeterinaryEdithandler = () => {
    this.setState(prevState => ({
      showUploadVeterinaryInvoices: !prevState.showUploadVeterinaryInvoices
    }));
  };

  uploadMedicalEdithandler = () => {
    this.setState(prevState => ({
      showUploadMedicalRecords: !prevState.showUploadMedicalRecords
    }));
  };

  setVeterinaryFilesList = (files, status) => {
    this.setState({
      veterinaryInvoicesList: files,
      showUploadVeterinaryInvoices: status,
      showUploadMedicalRecords: !status
    });
  };

  setMedicalFilesList = (files, status) => {
    this.setState({
      medicalRecordsList: files,
      showUploadMedicalRecords: status
    });
  };

  setupDataForRender = () => {
    const petDetail = this.props.claimsDetails.find(
      claim => claim.ClaimID === this.claimId && claim.PetID === this.petId
    );
    this.missingClaimData =
      petDetail && petDetail.missing_claim_information ? petDetail.missing_claim_information : [];
    this.receivedClaimData =
      petDetail && petDetail.received_claim_information
        ? petDetail.received_claim_information
        : [];
    this.claimNumber =
      petDetail && petDetail.ClaimNumber ? "#" + petDetail.ClaimNumber : null;

    //If missing claim data array has word "Invoice"
    //Assuming that user has to upload vetineriary incoice
    for (var i = 0; i < this.missingClaimData.length; i++) {
      if (this.missingClaimData[i].data.toLowerCase().includes("invoice")) {
        this.needsInvoice = true;
        this.setState({
          showUploadMedicalRecords: false,
          showUploadVeterinaryInvoices: true
        });
        break;
      }
    }

    this.renderDataSetup = true;
  };

  uploadDocuments = () => {
    if (
      (this.state.medicalRecordsList.length > 0 ||
        this.state.veterinaryInvoicesList.length > 0) &&
      this.claimId
    ) {
      let docType = "MR";
      if (
        this.state.medicalRecordsList.length > 0 &&
        this.state.veterinaryInvoicesList.length > 0
      ) {
        docType = "Both";
      } else {
        if (this.state.medicalRecordsList.length > 0) {
          docType = "MR";
        } else {
          docType = "VI";
        }
      }

      this.setState({ uploading: true });
      const uploadDocsList = [
        ...this.state.veterinaryInvoicesList,
        ...this.state.medicalRecordsList
      ];

      window.scrollTo(0, 0);
      updateClaim(this.claimId, uploadDocsList, docType)
        .then(response => {
          this.setState({
            uploadDocsSuccess: true,
            uploading: false
          });
          //Need to fetch the alerts on success of missing docs upload
          this.props.actions.getAlertsByAccount();
          this.props.actions.setClaimsDataRefresh();
          window.scrollTo(0, 0);
          //For google analytics
          window.dataLayer.push({
            'event': 'Pageview',
            'url': window.location.pathname + '/Confirm'
          });
        })
        .catch(error => {
          this.setState({
            uploadDocsError: true,
            uploading: false
          });
          window.scrollTo(0, 0);
        });
    }
  };

  reset = () => {
    if (this.needsInvoice) {
      this.veterinaryUploadRef &&
        this.veterinaryUploadRef.current &&
        this.veterinaryUploadRef.current.resetFiles();
    }
    this.medicalUploadRef &&
      this.medicalUploadRef.current &&
      this.medicalUploadRef.current.resetFiles();
    this.renderDataSetup = false;
    this.needsInvoice = false;
    this.setState({
      showUploadVeterinaryInvoices: false,
      showUploadMedicalRecords: true,
      veterinaryInvoicesList: [],
      medicalRecordsList: [],
      uploadDocsSuccess: false
    });
  };

  render() {
    let policyNo;
    
    if (this.props.claimsDetails.length > 0 && !this.renderDataSetup) {
      this.setupDataForRender();
    }

    if (this.props.fetchingPets || this.props.fetchingClaims || this.props.fetchingUser) {
      return (
        <div style={{ height: 300 }}>
          <Loader />
        </div>
      );
    } else {
      try {
        policyNo = this.props.petDetails.find(pet => pet.PetID === this.petId)
          .PolicyNo;
      } catch (error) {
        return (
          <div className="container d-flex flex-column h-400">
            <h1 className="text-danger mt-4 mb-2 d-sm-block m-lg-0">
              Your Action Needed
            </h1>
            <ErrorView>
              Unable to retrieve your pet's information. Please try
              later.
            </ErrorView>
          </div>
        );
      }
    }

    return (
      <React.Fragment>
        {/* <NavigationPrompt
          when={(crntLocation, nextLocation) => {
            this.currentLocation = crntLocation;
            this.nextLocation = nextLocation;
            if (this.state.veterinaryInvoiceList.length > 0) {
              return true;
            }
          }}
        >
          {({ onCancel, onConfirm }) => (
            <ConfirmNavigationModal
              when={true}
              message="You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?"
              title="Unsaved Data"
              currentLocation={this.currentLocation}
              nextLocation={this.nextLocation}
              forceUpdate={false}
              onCancel={onCancel}
              onConfirm={onConfirm}
            />
          )}
        </NavigationPrompt> */}

        {this.state.uploadDocsError && (
          <ErrorView>Unable to upload files. Please try later.</ErrorView>
        )}

        {this.state.uploadDocsSuccess ? (
          <MissingUploadDocsSuccess
            goTouploadMissingRecords={this.reset.bind(this)}
            goTohome={() => this.props.history.push("/")}
          />
        ) : (
            <React.Fragment>
              <div className="container">
                <div className="col-12 col-lg-8 d-flex justify-content-between align-items-center mb-3 m-lg-0">
                  <h1 className="text-danger d-none mt-3 d-sm-block m-lg-0">
                    {" "}
                    Your Action Needed{" "}
                  </h1>
                  <h1 className="text-primary  mb-2 mt-2  d-block d-sm-none">
                    {" "}
                    <strong>Upload Missing Claim Records </strong>
                  </h1>
                </div>
                <div className="d-none col-lg-4" />
              </div>

              <div className="d-lg-flex container">
                {(this.props.petFetchingError || this.props.claimsFetchingError || this.props.userAccountFetchingError) ?
                  (
                    <div className="col-12 col-lg-8" style={{ height: 300 }}><ErrorView> Unable to fetch information right now. </ErrorView></div>
                  )
                  : (<div
                    id="upload-missing-docs"
                    className="col-12 col-lg-8 position-static"
                  >
                    <p className="upload-claim-info">
                      We need the following information before we can continue
                  processing your <span>claim {this.claimNumber}:</span>
                </p>
                    {(this.missingClaimData.length > 0 ||
                      this.receivedClaimData.length > 0) && (
                        <div className="missing-doc-info-container">
                          {this.missingClaimData.length > 0 && (
                            <React.Fragment>
                              <span>
                                <strong>Missing Claim Document(s)</strong>
                              </span>
                              <ul style={{ marginLeft: -22 }}>
                                {this.missingClaimData.map((missingInfo, index) => {
                                  return <li key={index}>{ReactHtmlParser(missingInfo.data)}</li>;
                                })}
                              </ul>
                            </React.Fragment>
                          )}

                          {this.receivedClaimData.length > 0 && (
                            <React.Fragment>
                              <span>
                                <strong>Received Claim Document(s)</strong>
                              </span>
                              <ul style={{ marginLeft: -22 }}>
                                {this.receivedClaimData.map((receivedInfo, index) => {
                                  return <li key={index}>{ReactHtmlParser(receivedInfo.data)}</li>;
                                })}
                              </ul>
                            </React.Fragment>
                          )}
                        </div>
                      )}

                    <div className="claim-collapse-box">
                      {this.needsInvoice && (
                        <div className="collapse-box-item">
                          <div
                            className="d-flex justify-content-between align-items-center"
                            onClick={this.uploadVeterinaryEdithandler}
                          >
                            <div className="box-title d-block d-sm-none mb-1">
                              1. Upload Your Veterinary Invoice
                        </div>
                            <div className="box-title d-none mb-1 d-sm-block">
                              {this.state.veterinaryInvoicesList.length > 0 &&
                                !this.state.showUploadVeterinaryInvoices ? (
                                  <span className="mr-1">
                                    <SectionStatus />
                                  </span>
                                ) : (
                                  " 1. "
                                )}
                              Upload Your Veterinary Invoice
                        </div>
                            <div>
                              {this.state.showUploadVeterinaryInvoices ? (
                                <i className="text-secondary fa fa-angle-up fa-2x" />
                              ) : this.state.veterinaryInvoicesList.length > 0 ? (
                                <React.Fragment>
                                  <span className="upload-file-edit d-none d-sm-block">
                                    Edit
                              </span>
                                  <i className="text-secondary fa fa-angle-down fa-2x d-block d-sm-none" />
                                </React.Fragment>
                              ) : (
                                    <i className="text-secondary fa fa-angle-down fa-2x" />
                                  )}
                            </div>
                          </div>

                          <UploadVeterinaryInvoice
                            ref={this.veterinaryUploadRef}
                            type="Claim"
                            show={this.state.showUploadVeterinaryInvoices}
                            petPolicyNo={policyNo}
                            classification="MissingDocs"
                            setUploadFiles={this.setVeterinaryFilesList}
                          />
                        </div>
                      )}

                      <div className="collapse-box-item">
                        <div
                          className="d-flex justify-content-between align-items-center"
                          onClick={this.uploadMedicalEdithandler}
                        >
                          <div className="box-title d-block d-sm-none mb-1">
                            {(this.needsInvoice ? "2. " : "1. ") +
                              "Upload your missing document(s)"}
                          </div>
                          <div className="box-title d-none mb-1 d-sm-block">
                            {this.state.medicalRecordsList.length > 0 &&
                              !this.state.showUploadMedicalRecords ? (
                                <span className="mr-1">
                                  <SectionStatus />
                                </span>
                              ) : this.needsInvoice ? (
                                "2. "
                              ) : (
                                  "1. "
                                )}
                            Upload Missing Claim Documents
                      </div>
                          <div>
                            {this.state.showUploadMedicalRecords ? (
                              <i className="text-secondary fa fa-angle-up fa-2x" />
                            ) : this.state.medicalRecordsList.length > 0 ? (
                              <React.Fragment>
                                <span className="upload-file-edit d-none d-sm-block">
                                  Edit
                            </span>
                                <i className="text-secondary fa fa-angle-down fa-2x d-block d-sm-none" />
                              </React.Fragment>
                            ) : (
                                  <i className="text-secondary fa fa-angle-down fa-2x" />
                                )}
                          </div>
                        </div>

                        <UploadMedicalRecords
                          ref={this.medicalUploadRef}
                          type="MR"
                          show={this.state.showUploadMedicalRecords}
                          petPolicyNo={policyNo}
                          classification="MissingDocs"
                          setUploadFiles={this.setMedicalFilesList}
                        />
                      </div>

                      <div
                        className={
                          //either of the list has any item , enable terms and conditions
                          this.state.veterinaryInvoicesList.length > 0 ||
                            this.state.medicalRecordsList.length > 0
                            ? "collapse-box-item"
                            : "collapse-box-item disable"
                        }
                      >
                        <div className="box-title">
                          {(this.needsInvoice ? "3. " : "2. ") +
                            "Terms for Upload Documents"}
                        </div>
                        {(this.state.veterinaryInvoicesList.length > 0 ||
                          this.state.medicalRecordsList.length > 0) && (
                            <div className="tc-box">
                              <textarea
                                readOnly
                                value='By checking this box and clicking "Upload Documents!", I certify that the information provided is accurate to the best of my knowledge. I authorize any veterinary hospital or veterinarian to provide additional information about my pet to Healthy Paws Pet Insurance.'
                              />

                              <Form onSubmit={() => this.uploadDocuments()}>
                                {({ formState }) => (
                                  <div>
                                    <CheckboxControl
                                      field="checkbox"
                                      id="submit-checkbox"
                                      label="I agree to these terms"
                                    />

                                    <div className="d-block d-md-flex justify-content space-between mt-2 mb-2">
                                      <button
                                        className={
                                          formState.values.checkbox
                                            ? "btn btn-secondary"
                                            : "btn btn-secondary disabled-btn"
                                        }
                                        type="submit"
                                      >
                                        {" "}
                                        Upload Documents{" "}
                                      </button>
                                      <button
                                        className="cancel-btn text-blue d-none d-md-block"
                                        onClick={() => this.reset()}
                                      >
                                        {" "}
                                        Cancel{" "}
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </Form>
                            </div>
                          )}
                      </div>
                    </div>

                    <div>
                      <p className="mb-0">
                        <strong>Documents are not available?</strong>
                      </p>
                      <p>
                        Your veterinarian may also email these to{" "}
                        <a
                          style={{ textDecoration: "none" }}
                          href={"mailto:records@healthypaws.com"}
                        >
                          records@healthypaws.com
                    </a>{" "}
                        or fax them to 844-333-0739.
                  </p>
                    </div>
                  </div>)}

                <div className="d-none col-lg-4 d-lg-flex side-box flex-column ">
                  {this.props.userAccountDetails ? (
                    <BillingInfo
                      creditCard={this.props.userAccountDetails.Last4CreditCard}
                      billingInfo={this.props.userAccountDetails.BillingAddress}
                      status={this.props.userAccountDetails.Status}
                      premium={this.props.userAccountDetails.MonthlyPremium}
                      newMonthlyPremium={this.props.userAccountDetails.NewMonthlyPremium}
                      billingDay={this.props.userAccountDetails.BillingDay}
                      changeEffectiveDate={this.props.userAccountDetails.ChangeEffectiveDate}
                    />
                  ) : (
                      <div style={{ height: 300 }}>
                        <Loader />
                      </div>
                    )}
                </div>
              </div>
            </React.Fragment>
          )}

        {this.state.uploading && <Loader position="absolute" />}
      </React.Fragment>
    );
  }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  petDetails: state.pets.petsDetails,
  claimsDetails: state.claims.claimsDetails,
  userAccountDetails: state.account.userAccountDetails,
  fetchingPets: state.pets.fetchingPets,
  petFetchingError: state.pets.error,
  fetchingClaims: state.claims.fetching,
  claimsFetchingError: state.claims.error,
  fetchingUser: state.account.fetchingAccount,
  userAccountFetchingError: state.account.error
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPetsByAccount,
      getAccountInfo,
      getClaimsByAccount,
      setClaimsDataRefresh,
      getAlertsByAccount
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadMissingDocs);

const SectionStatus = () => {
  return (
    <span className="section-check-status">
      <i
        className="fa fa-check-circle"
        style={{ fontSize: 22, color: "#34A250" }}
      />
    </span>
  );
};

const MissingUploadDocsSuccess = props => {
  return (
    <React.Fragment>
      <div className="d-none d-md-block">
        <UpdateSuccessView
          statusHeader="Your documents have successful uploaded."
          title="We'll review your documents shortly."
        >
          <div>
            <p>
              We will continue to process your claim once we review your claim
              documents
            </p>
            <Link to="/">
              <button className="button-secondary update-success-button">
                Home
              </button>
            </Link>
          </div>
        </UpdateSuccessView>
      </div>
      <UpdateSuccessView
        className="d-md-none"
        title="We'll review your documents shortly."
      >
        <div>
          <p>
            We will continue to process your claim once we review your claim
            documents.
          </p>
          <button
            className="button-primary update-success-button"
            onClick={props.goTouploadMissingRecords}
          >
            Upload Another Record
          </button>
          <button
            className="button-secondary update-success-button"
            onClick={props.goTohome}
          >
            Home
          </button>
        </div>
      </UpdateSuccessView>
    </React.Fragment>
  );
};