import React from 'react';
import "./StatusHeader.css";

const StatusHeader = (props) => {
    return (
    <div className="status-header-container container-fluid">
    <div id="status-header"  className="container d-flex align-items-center">
        {props.children}
    </div>
    </div>
  )
}

export default StatusHeader;