import React from "react";
import ModalDialog from "./../modal-dialog/ModalDialog";

const SessionExpiryModalDialog = (props) => {
  return (
    <ModalDialog
      isOpen={props.showSessionExpiryModal}
      toggle={props.toggleSessionExpiryModal}
      modalHeader={"Your Session has Expired"}
      modalBody={
        <div className="d-flex flex-column" style="position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%);">
          Unfortunately, your session has expired and we require you to sign-in
          again. You will be redirected to the sign-in page.
        </div>
      }
      modalFooter={
        <button
          type="submit"
          className="button-primary-plus close-button"
          onClick={props.toggleSessionExpiryModal}
        >
          Okay
        </button>
      }
      className="modal-popup"
    />
  );
};

export default SessionExpiryModalDialog;
