import * as actionTypes from "../../constants/action-types";

export const getDocuments = () => ({
    type: actionTypes.DOCUMENTS_FETCH_REQUEST
  });
  
export const setDocumentsDataRefresh = () => ({
    type: actionTypes.SET_DOCUMENTS_DATA_REFRESH
});
  
export const setDocumentOpened = docId => ({
    type: actionTypes.SET_DOCUMENT_OPENED,
    docId
});
  