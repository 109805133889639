import React, { Component } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAccountInfo } from "./../../store/actions/accounts-actions";

import BillingInfo from "../claims/components/billing-info/BillingInfo";
import PetParentInfo from "../view-profile/components/PetParentInfo";
import { Link } from "react-router-dom";

import UpdateSuccessView from "../common/update-success-view/UpdateSuccessView";
import LiveChat from "../common/live-chat/LiveChat.js";
import { contactInformation, ContactForm } from "./contact.view.component";
import { AppLinks } from "../../constants/app-links";
import { getResponseCode } from "../../api/messages-api";

import "./contact.css";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactFormFieldApi: undefined,
      responseArray: undefined,
      showContactThankYouPage: false
    };

    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname
    });
  }
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.props.actions.getAccountInfo();
    this.getResponseCode();
    window.scrollTo(0, 0)
  }

  getResponseCode = () => {
    getResponseCode()
      .then(response => {
        let responseCodesFromServer = response.data.reason_codes.sort(function (
          a,
          b
        ) {
          return a.display_index - b.display_index;
        });

        let responseCodes = [];

        responseCodes.push({
          displayName: "- Please Select One -",
          value: "n/a"
        });

        for (var code of responseCodesFromServer) {
          responseCodes.push({ displayName: code.name, value: code.code });
        }
        if (this._isMounted === true) {
          this.setState({
            responseArray: responseCodes
          });
        }

      })
      .catch(error => {
        let responseCodes = [];

        responseCodes.push({
          displayName: "- Please Select One -",
          value: "n/a"
        });

        responseCodes.push({
          displayName: "Other",
          value: "Other"
        });

        this.setState({
          responseArray: responseCodes
        });
      });
  };

  setShowContactThankYouPage = () => {

    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname + '/Confirm'
    });

    this.setState({
      showContactThankYouPage: true
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      prevState.contactFormFieldApi &&
      nextProps.userAccountDetails &&
      nextProps.userAccountDetails !== prevState.userAccountDetails
    ) {
      let PetParentInfo = nextProps.userAccountDetails.PetParentInfo;

      prevState.contactFormFieldApi.setValue("email", PetParentInfo.Email);
      prevState.contactFormFieldApi.setValue(
        "name",
        PetParentInfo.FirstName + " " + PetParentInfo.LastName
      );
      prevState.contactFormFieldApi.setValue(
        "primaryPhoneNumber",
        PetParentInfo.PrimaryPhone
      );
    }
    return null;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <React.Fragment>
        {this.state.showContactThankYouPage ? (
          <UpdateSuccessView
            statusHeader="Your message has been successfully submitted."
            title="Thanks for getting in touch!"
          >
            <div>
              We’ve received your message and will respond to you as soon as we
              can.
            </div>
            <button
              className="button-secondary home-button-thank-you"
              type="submit"
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              Home
            </button>

            {contactInformation()}
          </UpdateSuccessView>
        ) : (
            <div
              className="container contact-container "
              onClick={this.blurSearch}
            >
              <h1 className="d-flex align-items-center title">
                Contact Customer Care
            </h1>

              <div className="row">
                <div className="col-12 col-lg-8 container-view position-static">
                  <div>
                    <span className="d-none d-md-block margin-bottom">
                      Please check our{" "}
                      <Link
                        to={AppLinks.faq.faq}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Frequently Asked Questions
                    </Link>{" "}
                      section to find an immediate answer to our most commonly
                      asked questions. If you can’t find your answer, please
                      complete the form below.
                  </span>
                    <span className="d-md-none margin-bottom">
                      You can also check our{" "}
                      <Link
                        to={AppLinks.faq.faq}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Frequently Asked Questions
                    </Link>{" "}
                      section for help in finding an immediate answer to our most
                      commonly asked questions. If you can’t find your answer,
                      please complete the form below.
                  </span>
                    <ContactForm
                      setContactFormFieldAPI={this.setContactFormFieldAPI}
                      showDropDown={true}
                      responseArray={this.state.responseArray}
                      setShowContactThankYouPage={this.setShowContactThankYouPage}
                      fetching={
                        this.props.userAccountDetailsFetching ||
                        !this.state.responseArray
                      }
                    />
                  </div>

                  {contactInformation()}
                </div>

                {this.rightColumn(this.props.userAccountDetails)}
              </div>
              <LiveChat
                userAccountDetails={this.props.userAccountDetails}
              />
            </div>
          )}
      </React.Fragment>
    );
  }

  setContactFormFieldAPI = api => {
    this.setState({
      contactFormFieldApi: api
    });
  };

  rightColumn = userAccountDetails => {
    return (
      <div className="rightColumn d-none d-lg-block col-lg-4 ">
        {userAccountDetails &&
          userAccountDetails.PetParentInfo &&
          userAccountDetails.PhysicalAddress &&
          userAccountDetails.MailingAddress && (
            <React.Fragment>
              <BillingInfo
                creditCard={userAccountDetails.Last4CreditCard}
                billingInfo={userAccountDetails.BillingAddress}
                status={userAccountDetails.Status}
                billingDay={userAccountDetails.BillingDay}
                premium={userAccountDetails.MonthlyPremium}
                newMonthlyPremium={userAccountDetails.NewMonthlyPremium}
                changeEffectiveDate={userAccountDetails.ChangeEffectiveDate}
              />

              <div className="billing-info mt-4">
                <div className="info-header text-primary">
                  Contact Information
                </div>
                <div className="info-body info-body-padding d-flex flex-column justify-content-between">
                  <PetParentInfo
                    parentInfo={userAccountDetails.PetParentInfo}
                    address={userAccountDetails.PhysicalAddress}
                    mailingAddress={userAccountDetails.MailingAddress}
                    hidePassword={true}
                    policyEffectiveDate={userAccountDetails.PolicyEffectiveDate}
                    status={userAccountDetails.Status}
                  />
                </div>
                <div className="info-footer" />
              </div>
            </React.Fragment>
          )}
      </div>
    );
  };
}

const mapStateToProps = state => ({
  userAccountDetails: state.account.userAccountDetails,
  userAccountDetailsFetching: state.account.fetching
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAccountInfo
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
