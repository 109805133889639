import moment from "moment-timezone";
import { store } from "./../../../index";
import {
  addClientAlert,
  removeClientAlert,
  getAlertsByAccount
} from "./../../../store/actions/alerts-actions";
import {
  setUserEditState,
  setAccountInfo
} from "./../../../store/actions/accounts-actions";

let disableAlertCreated = false,
  transferAlertCreated = false;

export function checkCanUserEdit(billDay) {
  try {   
    let currentTimePST = new Date(
      moment()
        .tz("America/Los_Angeles")
        .format("MM/DD/YYYY h:mm a")
    );

    billDay = Number(billDay);

    let applyToday = false;

    if (
      billDay === currentTimePST.getDate() ||
      billDay === currentTimePST.getDate() + 1
    ) {
      applyToday = true;
    }

    if (applyToday) {
      let dtStart = new Date(
        currentTimePST.getFullYear(),
        currentTimePST.getMonth(),
        currentTimePST.getDate(),
        23,
        30,
        0
      );
      let dtEnd = new Date(
        currentTimePST.getFullYear(),
        currentTimePST.getMonth(),
        currentTimePST.getDate(),
        2,
        0,
        0
      );

      if (currentTimePST <= dtEnd) {
        //Subtract one day to date object
        dtStart = dtStart.getTime() - 1440 * 60 * 1000;
      } else if (currentTimePST < dtStart.getTime() + 30 * 60 * 60 * 1000) {
        //Add one day to date object
        dtEnd = dtEnd.getTime() + 1440 * 60 * 1000;
      }
      if (currentTimePST >= dtStart && currentTimePST <= dtEnd) {
        if (!disableAlertCreated) {
          const newAlert = {
            alertKey: "POLICIY_CHANGES_APPLIED_KEY"
          };
          store.dispatch(addClientAlert(newAlert));
          store.dispatch(setUserEditState(false));
          store.dispatch(getAlertsByAccount);
          disableAlertCreated = true;
        }
      } else {
        removeDisableAlert();
      }
    } else {
      removeDisableAlert();
    }
  } catch (error) {
    console.log(error);
  }
}

//Removes disable user alert if already created
//Change user can edit flag to true
function removeDisableAlert() {
  if (disableAlertCreated) {
    const alertKey = "POLICIY_CHANGES_APPLIED_KEY";
    store.dispatch(removeClientAlert(alertKey));
    store.dispatch(setUserEditState(true));
    store.dispatch(getAlertsByAccount);
    disableAlertCreated = false;
  }
}

export function updateUserAccountInfo(userDetails) {
  //Sets User Details
  store.dispatch(setAccountInfo(userDetails));

  //Checks for billing day and login time
  //Disable some features if the login time falls on Sales Force script run time
  checkCanUserEdit(userDetails.BillingDay);

  //If the user account status is Transferring , we need add a alert
  //This alert will be shown till the account status changed to Active
  /*
  if (userDetails.Status === "Transferring") {
    const newAlert = {
      alertKey: "PENDING_ACCOUNT_ALMOST_READY_CHANGE_KEY"
    };

    store.dispatch(addClientAlert(newAlert));
    transferAlertCreated = true;
  } else if (transferAlertCreated) {
    //We need to remove this alert , if already added
    const alertKey = "PENDING_ACCOUNT_ALMOST_READY_CHANGE_KEY";
    store.dispatch(removeClientAlert(alertKey));
    transferAlertCreated = false;
  }
  */
}
