import React, { Component } from "react";
import { Form } from "informed";
import { Link } from "react-router-dom";
import CheckboxControl from "../../common/form-fields/checkbox/Checkbox";
import { addPet } from "../../../api/pets-api";
import Loader from "../../common/loader/Loader";
import { dateSuffix } from "../../common/util/util";
import ErrorView from "../../common/error-view/ErrorView";
import { AppLinks } from "../../../constants/app-links";

import "./ConfirmCharges.component.css";

class ConfirmCharges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitLoader: false,
      submitError: false,
    };
    this.submitErrorMessage = "";
    this.errorData = null;

    //For google analytics
    window.dataLayer.push({
      event: "Pageview",
      url: window.location.pathname + "/Step3",
    });
  }

  addPetConfirm = () => {
    this.setState({ submitLoader: true });
    addPet(this.props.petDetails, this.props.selectedPlan)
      .then((response) => {
        this.setState({ submitLoader: false });
        this.props.setNewPetDetails(response);
        this.props.setPetsDataRefresh();
        this.props.getPetsByAccount();
        //As the SalesForce takes some time to refresh the data in API
        //We are setting the refresh flag after 2 mins
        //So if the user goes particular route after this time, it will fetch new data from API
        setTimeout(() => {
          this.props.setAccountDataRefresh();
          this.props.getAlertsByAccount();
        }, 120000);
        //For google analytics
        window.dataLayer.push({
          event: "Pageview",
          url: window.location.pathname + "/Confirm",
        });

        this.props.stepper.nextStep();
      })
      .catch((error) => {
        console.log(error);
        this.errorData = error;
        this.submitErrorMessage = error.errorMessage ? error.errorMessage : "";
        this.setState({ submitError: true, submitLoader: false });
        window.scrollTo(0, 0);
      });
  };

  render() {
    const {
      selectedPlan,
      petDetails,
      totalMonthlyRate,
      nextBillingDate,
      billingDay,
      physicalState,
      property,
    } = this.props;
    const billingDate = new Date(nextBillingDate).toLocaleDateString("en-US");
    const termsConditionText = `By checking this box and clicking "Add This Pet To My Policy", I certify that the information provided is accurate to the best of my knowledge. I authorize any veterinary hospital or veterinarian to provide additional information about my pet to Healthy Paws Pet Insurance. I understand that missing information or delays in delivering the pet's medical records may delay the processing of my claim. I understand that Healthy Paws Pet Insurance appreciates having claims submitted within 90 days of invoice date.`;
    const MO_termsConditionText = `By checking this box, you will be agreeing to apply for insurance through the Healthy Paws electronic platform, and to receive your policy and all documents electronically, as set out in the Terms and Conditions. Your clicking on the ‘I accept’ box constitutes your electronic signature.`;
    return (
      <div className="container confirm-charges">
        <h1 className="text-primary mb-3 mb-lg-0">
          3. Confirm your charges{" "}
          <span className="d-lg-none">and complete</span>
        </h1>
        {this.errorData &&
          this.errorData.data &&
          this.errorData.data.response &&
          this.errorData.data.response.data.code === "11008" && (
            <ErrorView>
              {
                <span>
                  Transaction Unsuccessful. Could not add your pet! Please re-enter your payment information{" "}
                  <Link to={AppLinks.account.updateBillingInfo}>here</Link>, then retry adding your pet.
                </span>
              }
            </ErrorView>
          )}

        {this.errorData &&
          this.errorData.data &&
          this.errorData.data.response &&
          this.errorData.data.response.data.code === "11011" && (
            <ErrorView>
              {
                <span>
                  Could not add your pet. An active pet with this name and birth date already exists for this account. If you'd like to add a new pet to your account, please use that pet's name and birth date.
                </span>
              }
            </ErrorView>
          )}

        {this.errorData &&
          this.errorData.data &&
          this.errorData.data.response &&
          this.errorData.data.response.data.code !== "11008" &&
          this.errorData.data.response.data.code !== "11011" &&
          this.state.submitError && (
            <ErrorView>
              {this.submitErrorMessage ? (
                this.submitErrorMessage
              ) : (
                <span>
                  Could not add your pet! Please try again or{" "}
                  <Link to={AppLinks.others.contact}>click here</Link> to
                  contact us.
                </span>
              )}
            </ErrorView>
          )}
        <Form id="confirmChargesForm" onSubmit={this.addPetConfirm}>
          {({ formState }) => (
            <React.Fragment>
              <p className="mb-3 summary-content">
                <span className="font-weight-bold">Almost done!</span> Review
                your billing summary and click the button below.
              </p>
              <div className="billing-summary">
                <p className="mb-0 font-weight-bold">Billing Summary:</p>
                <ul className="summary-list">
                  <li>
                    <span className="truncate">{petDetails.petName}</span>'s
                    monthly premium: ${selectedPlan.monthly_premium}
                    <span
                      className="link-text ml-2"
                      style={{ fontSize: "14px" }}
                      onClick={() => this.props.stepper.previousStep()}
                    >
                      Edit
                    </span>
                  </li>
                  <li>
                    Pro-rated charge today: $
                    {Number(selectedPlan.prorated_first_month_payment).toFixed(
                      2
                    )}
                    *
                  </li>
                  <li>New total monthly premium: ${totalMonthlyRate}</li>
                  <li>Your next billing date: {billingDate}</li>
                </ul>
                <p className="mb-0 font-weight-bold">*Keep in mind</p>
                <p className="mb-0">
                  A pro-rated charge $
                  {Number(selectedPlan.prorated_first_month_payment).toFixed(2)}{" "}
                  will be billed to your credit card today.
                </p>
                <p className="mb-0">
                  You will be billed for{" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {petDetails.petName}
                  </span>{" "}
                  as apart of your regular billing cycle on the{" "}
                  {dateSuffix(billingDay)} day of each month. Your next bill
                  date is {billingDate}.
                </p>
              </div>
              <h2 className="mb-md-4 font-weight-bold terms">
                Agreement to Add a Pet
              </h2>
              <textarea
                className="terms-conditions"
                readOnly
                value={termsConditionText}
              ></textarea>
              <CheckboxControl
                className="agreement"
                id="agreement"
                field="checkbox"
                label="I agree"
              />
              {physicalState === "MO" && property === "Indemnity Insurance Company of North America" && (
                <div>
                  <textarea
                    className="terms-conditions"
                    readOnly
                    value={MO_termsConditionText}
                  ></textarea>
                  <CheckboxControl
                    className="agreement"
                    id="mo_agreement"
                    field="mo_checkbox"
                    label="I agree"
                  />
                </div>
              )}
              <div className="sticky-bottom">
                <div className="col-md-8 col-lg-4 p-0">
                  <div className="d-md-none text-center mb-1 text-uppercase">
                    <span className="font-weight-bold">Step 3</span> of 3
                  </div>
                  <button
                    className={
                      physicalState === "MO" && property === "Indemnity Insurance Company of North America"
                        ? formState.values.checkbox &&
                          formState.values.mo_checkbox
                          ? "button-primary"
                          : "button-primary disabled-btn"
                        : formState.values.checkbox
                        ? "button-primary"
                        : "button-primary disabled-btn"
                    }
                    type="submit"
                  >
                    Add This Pet To My Policy
                  </button>
                </div>
              </div>
            </React.Fragment>
          )}
        </Form>
        {this.state.submitLoader && (
          <Loader position="absolute" fullPage={true} />
        )}
      </div>
    );
  }
}

export default ConfirmCharges;
