import React, { Component } from "react";

import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";
import Util from "../../common/util/util";

import "./SuccessView.css";
import { style } from "@material-ui/system";

export default class SuccessView extends Component {
  constructor() {
    super();
    this.mobAppRoute= window.location.pathname.indexOf('mobile')> -1 ? 'true':'false';
  }

  render() {
    const { responseData } = this.props;
    return (
      <div className="success-view">
        <div>
          <UpdateSuccessView
            statusHeader="Your new physical address has been updated."
            title= {this.mobAppRoute==='false'? "Your new physical address" : ""}
          >
            {this.mobAppRoute==='false'? null: 
            <h1><div className="h1_v2">Your new physical address</div></h1>
            }
             <div style={this.mobAppRoute==='false'?null:{marginTop:'-30px'}} className="container pl-0">
              <div className="row">
                <div className="col-md-12">
                  <span className="status-description">
                    {this.mobAppRoute==='false'? 'Please review your new physical address and billing information.' :
                    <div style={{paddingBottom:'10px'}} className= {this.mobAppRoute==='false'? "":"mobileTextV2"}>
                   Please review your new physical address and billing
                    information.
                  </div> 
                  }
                   
                  </span>
                  <div className="d-flex flex-column success-container padding">
                    <h4 className={this.mobAppRoute==='false'? "successHeader":"unBoldv2 successHeader"}>
                      {this.mobAppRoute==='false'?
                      'New Physical Address': 'NEW PHYSICAL ADDRESS'
                      }
                      </h4>
                    {this.mobAppRoute==='false'?
                    <div>
                    <span className="d-block">{responseData.address1}</span>
                    {responseData.city_name && (
                      <span className="d-block ">{responseData.city_name}</span>
                    )}
                    <span className="d-block margin-bottom">
                      {responseData.state_abbreviation +
                        " " +
                        responseData.postal_code}
                    </span>
                    </div>
                    :
                    <strong>
                      <span className="d-block">{responseData.address1}</span>
                    {responseData.city_name && (
                      <span className="d-block">{responseData.city_name}</span>
                    )}
                    <span className="d-block margin-bottom">
                      {responseData.state_abbreviation +
                        " " +
                        responseData.postal_code}
                    </span>
                    </strong>
                    }
                    <h4 className={this.mobAppRoute==='false'? "":"unBoldv2"}>
                    {this.mobAppRoute==='false'?
                      'Billing Summary': 'BILLING SUMMARY'
                    }
                      </h4>
                    { this.mobAppRoute==='false'? 
                    <ul className="pl-3">
                    <li>
                      New total monthly premium: ${Number(responseData.new_premium).toFixed(2)}
                    </li>
                    <li>
                      Next coverage period:{" "}
                      {Util.getFormattedDate(
                        responseData.change_effective_date
                      )}
                    </li>
                    <li>
                      Your next billing date:{" "}
                      {Util.getFormattedDate(responseData.next_billing_date)}*
                    </li>
                  </ul>
                    :
                    <strong>
                      <ul className="pl-3">
                      <li>
                        New total monthly premium: ${Number(responseData.new_premium).toFixed(2)}
                      </li>
                      <li>
                        Next coverage period:{" "}
                        {Util.getFormattedDate(
                          responseData.change_effective_date
                        )}
                      </li>
                      <li>
                        Your next billing date:{" "}
                        {Util.getFormattedDate(responseData.next_billing_date)}*
                      </li>
                    </ul>
                    </strong>
                    }
                    
                    <h4 className="keep-in-mind">Keep in mind*</h4>
                    <span className="keep-in-mind">
                      Please note that changes will not be reflected in your
                      Healthy Paws customer profile until that time. Your
                      monthly premium will be adjusted on your next billing
                      date.
                    </span>
                  </div>
                  {this.mobAppRoute ==='false' ? 
                  <button
                    className="button-secondary update-success-button margin-bottom"
                    onClick={this.props.backToHome}
                  >
                    Home
                  </button>
                  : null}
                </div>
              </div>
            </div>
          </UpdateSuccessView>
        </div>
      </div>
    );
  }
}
