import React, { Component } from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPetsByAccount } from "../../../store/actions/pets-actions";
import { getAccountInfo } from "../../../store/actions/accounts-actions";
import {
  setClaimsDataRefresh,
  getClaimsByAccount,
} from "../../../store/actions/claims-actions";
import { Form } from "informed";
import PetSelector from "../components/PetSelector";
import AddClaimDetails from "../components/AddClaim";
import FaqWidget from "../../common/faq/FaqWidget";
import { submitClaimFaqLinks } from "../../common/faq/FaqLink";
import ClaimForm from "../components/claim-form/ClaimForm";
import UploadVeterinaryInvoice from "./../../common/file-upload/FileUpload";
import UploadMedicalRecords from "./../../common/file-upload/FileUpload";
import CheckboxControl from "./../../common/form-fields/checkbox/Checkbox";
import { submitClaim } from "../../../api/claims-api";
import { getPolicyDocs } from "../../../api/policy-docs-api";
import ErrorView from "./../../common/error-view/ErrorView";
import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";
import ClaimSubmissionMaintenance from "./ClaimSubmissionMaintenance.component";

import { AppLinks } from "../../../constants/app-links";

import "./FileClaim.css";
import { ClaimInfo } from "../../../model/Claims";
import UpdateDateJoinedFamilyModal from "../components/UpdateDateJoinedFamilyModal";
import { getVetClinics } from "../../../api/vet-clinics-api";
import FileClaimSuccessView from "../components/FileClaimSuccessView.component";
import ConfirmNavigationModal from "./../../common/route-prevent-dialog/ConfirmNavigationModal";

import Loader from "./../../common/loader/Loader";

class FileClaimPage extends Component {
  constructor(props) {
    super(props);
    //For google analytics
    window.dataLayer.push({
      event: "Pageview",
      url: window.location.pathname,
    });

    this.state = {
      selectedPet: "",
      showUploadVeterinaryInvoice: false,
      showUploadMedicalRecords: false,
      showClaimDetailsForm: false,
      veterinaryInvoiceList: [],
      medicalRecordsList: [],
      policyDocs: [],
      policyDocsFetchError: false,
      dateJoinFamilyModal: false,
      claimFormStatus: false,
      claimSubmitSuccess: false,
      claimSubmissionError: false,
      showSubmitLoader: false,
      vetClinics: {
        fetchingData: false,
        errorFetchingData: false,
        data: null,
      },
    };
    this.policyDocsFetchErrorMessage = "";
    this.claimFormDetails = {};
    this.petSelectionHandler = this.petSelectionHandler.bind(this);
    this.reset = this.reset.bind(this);
    this.dateJoinedFamilyToggler = this.dateJoinedFamilyToggler.bind(this);
    this.veterinaryUploadRef = React.createRef();
    this.medicalUploadRef = React.createRef();
    this.dateOfServiceError = false;
  }
  _isMounted = false;

  componentDidUpdate(prevProps, prevState) {
    // only update chart if the data has changed
    if (
      prevProps.location.key !== this.props.location.key &&
      this.state.claimSubmitSuccess
    ) {
      this.reset();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this._isMounted = true;
    console.log('state did mount: ',this.props)
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.PetID
    ){
      console.log('state did mount:- ',this.props)
    
      this.setState({
        selectedPet: this.props.location.state.PetID,
        showUploadVeterinaryInvoice: true,
      });
    }
    if(this.props.petDetails.length === 1){
      this.setState({
        selectedPet: this.props.petDetails[0].PetID,
        showUploadVeterinaryInvoice: true,
      });
    }
    this.props.actions.getAccountInfo();

    this.props.actions.getClaimsByAccount();

    getPolicyDocs()
      .then((res) => {
        if (this._isMounted) {
          this.setState({
            policyDocs: res.policy_documents,
          });
        }
      })
      .catch((error) => {
        this.policyDocsFetchErrorMessage = error.errorMessage
          ? error.errorMessage
          : "Unable show claim form(s).Please try later.";
        if (this._isMounted) {
          this.setState({
            policyDocsFetchError: true,
          });
        }
      });
    // this.getVetClinicsList();
    this.props.actions.getPetsByAccount();
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.petDetails !== state.petDetails) {
      //If the user has only one pet , it has to be selected by default
      const selectedPet =
        props.petDetails.length === 1 ? props.petDetails[0].PetID : "";
      const showUploadVeterinaryInvoice = selectedPet === "" ? false : true;
      const dateJoinFamilyModal = selectedPet === "" ? false : true;
      // if(selectedPet === "") {
      //   localStorage.removeItem("dateJoinedFamilyModalSubmitted");
      // }
      return {
        petDetails: props.petDetails,
        selectedPet,
        showUploadVeterinaryInvoice,
        dateJoinFamilyModal
      };
    }

    return null;
  }

  // Submit Handler
  submitClaim() {
    // TODO: Pass proper Data and handle error.
    // const selectedPetDetails = this.props.petDetails ? this.props.petDetails.find(x => x.PetID === this.state.selectedPet) : null;
    if (
      this.state.selectedPet &&
      this.state.veterinaryInvoiceList &&
      this.state.veterinaryInvoiceList.length > 0
    ) {
      let fileURLs = [...this.state.veterinaryInvoiceList];
      this.setState({ showSubmitLoader: true });
      if (
        this.state.medicalRecordsList &&
        this.state.medicalRecordsList.length > 0
      ) {
        fileURLs.push(...this.state.medicalRecordsList);
      }
      let claimDetails = null;
      if (this.claimFormDetails) {
        // TODO: Handle date format. and clinic ID.
        claimDetails = new ClaimInfo(
          this.claimFormDetails.clinicID,
          this.claimFormDetails.invoiceNumber,
          this.claimFormDetails.invoiceTotal,
          this.claimFormDetails.dateOfService,
          this.claimFormDetails.examinationFee,
          this.claimFormDetails.relatedClaimId
        );
      }
      submitClaim(this.state.selectedPet, fileURLs, claimDetails)
        .then((response) => {
          // TODO: Handle Submit Success
          //
          this.setState({
            claimSubmissionError: false,
            claimSubmitSuccess: true,
            showSubmitLoader: false,
          });
          //For google analytics
          window.dataLayer.push({
            event: "Pageview",
            url: window.location.pathname + "/Confirm",
          });

          this.props.actions.setClaimsDataRefresh();
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          // TODO: Handle Error.
          this.setState({
            claimSubmissionError: true,
            showSubmitLoader: false,
          });
          window.scrollTo(0, 0);
        });
    }
  }

  petSelectionHandler(id, doj) {
    if (this.state.selectedPet !== id) {
      this.setState({
        dateJoinFamilyModal: doj ? false : true,
        selectedPet: id,
        showUploadVeterinaryInvoice: true,
        veterinaryInvoiceList: [],
        medicalRecordsList: [],
      });
      this.claimFormDetails = {};
      this.dateOfServiceError = false;
    }
    this.setRelatedClaims();
  }

  setRelatedClaims = () => {
    this.relatedClaims = this.props.claimsDetails.filter(
      (claim) =>
        claim.StatusDetail === "Closed With Payment" ||
        claim.StatusDetail === "Closed Without Payment - Covered"
    );
  };

  setVeterinaryInvoiceList = (files, status) => {
    this.setState({
      veterinaryInvoiceList: files,
      showUploadVeterinaryInvoice: status,
      showUploadMedicalRecords: true,
    });
    this.scrollStepIntoView("upload-veterinary-invoice-toggler");
  };

  setMedicalRecordsList = (files, status) => {
    this.setState({
      medicalRecordsList: files,
      showUploadMedicalRecords: status,
    });
    this.scrollStepIntoView("upload-medical-records-toggler");
  };

  editMedicalRecordsHandler = () => {
    this.setState((prevState) => ({
      showUploadMedicalRecords: !prevState.showUploadMedicalRecords,
    }));
  };

  reset() {
    this.setState({
      selectedPet: "",
      showUploadVeterinaryInvoice: false,
      showUploadMedicalRecords: false,
      dateJoinFamilyModal: false,
      claimFormStatus: false,
      veterinaryInvoiceList: [],
      medicalRecordsList: [],
      claimSubmitSuccess: false,
      claimSubmissionError: false,
    });
    this.veterinaryUploadRef &&
      this.veterinaryUploadRef.current &&
      this.veterinaryUploadRef.current.resetFiles();
    this.medicalUploadRef &&
      this.medicalUploadRef.current &&
      this.medicalUploadRef.current.resetFiles();
    this.claimFormDetails = {};
    this.dateOfServiceError = false;
    window.scrollTo(0, 0);
  }

  veterinaryInvoiceEditHandler = () => {
    this.setState((prevState) => ({
      showUploadVeterinaryInvoice: !prevState.showUploadVeterinaryInvoice,
    }));
  };

  getVetClinicsList = () => {
    let vetClinics = {
      fetchingData: true,
      errorFetchingData: false,
      data: null,
    };
    if (this._isMounted) {
      this.setState({
        vetClinics: vetClinics,
      });
    }

    const selectOptions = [
      {
        value: "select_a_clinic",
        displayName: "Select a Clinic",
      },
    ];

    const otherOptions = [
      {
        value: "other_clinic",
        displayName: "Other",
      },
    ];

    getVetClinics()
      .then((response) => {
        // process response.
        vetClinics.fetchingData = false;
        // Bind Data
        response.Items.map((clinic) => {
          clinic.value = clinic.Sfid;
          clinic.displayName = clinic.Name;
        });
        let data = [...selectOptions, ...response.Items, ...otherOptions];
        vetClinics.data = data;
        if (this._isMounted) {
          this.setState({
            vetClinics: vetClinics,
          });
        }
      })
      .catch((error) => {
        vetClinics.fetchingData = false;
        vetClinics.errorFetchingData = true;
        vetClinics.data = [...selectOptions, ...otherOptions];
        if (this._isMounted) {
          this.setState({
            vetClinics: vetClinics,
          });
        }
      });
  };

  // TODO: remove unecessory data  Data.
  addClaimDetails = (claimFormDetails, dateOfServiceError) => {
    let { clinicID, ...rest } = claimFormDetails;
    let claimData = rest;
    if (!(clinicID === "select_a_clinic" || clinicID === "other_clinic")) {
      claimData.clinicID = clinicID;
    }
    if (claimData.dateOfService && dateOfServiceError) {
      this.dateOfServiceError = true;
    } else {
      this.dateOfServiceError = false;
    }
    this.claimFormDetails = claimData;
  };

  scrollStepIntoView = (id) => {
    const claimForm = document.getElementById(id);
    if (claimForm) {
      claimForm.scrollIntoView();
    }
    if (window.innerWidth > 960) window.scrollBy(0, -30);
    else window.scrollBy(0, -84);
  };

  addClaimDetailsNextStep = (evt) => {
    !this.dateOfServiceError &&
      this.setState({ claimFormStatus: true, showClaimDetailsForm: false });
    this.scrollStepIntoView("claim-details-toggler");
    evt.preventDefault();
  };

  setRelatedClaimId = (claimId) => {
    this.claimFormDetails.relatedClaimId = claimId;
  };

  addClaimDetailsSkip = (evt) => {
    this.claimFormDetails = {};
    !this.dateOfServiceError &&
      this.setState({ claimFormStatus: false, showClaimDetailsForm: false });
    this.scrollStepIntoView("claim-details-toggler");
    evt.preventDefault();
  };

  medicalRecordsSkip = (evt) => {
    this.setState({ medicalRecordsList: [], showUploadMedicalRecords: false });
    evt.preventDefault();
  };

  dateJoinedFamilyToggler() {
    var obj = { ...this.state };
    obj.dateJoinFamilyModal = !obj.dateJoinFamilyModal;
    this.setState({ ...obj });
  }

  updateDateJoinedFamily = (joinedDate) => {
    let petsDetails = this.props.petDetails;
    let selectedPetIndex = petsDetails.findIndex(
      (pet) => pet.PetID === this.state.selectedPet
    );
    petsDetails[selectedPetIndex].DateJoinedFamily = joinedDate;
    this.setState({ petsDetails: petsDetails });
    localStorage.setItem("dateJoinedFamilyModalSubmitted", true);
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
      const selectedPetDetails = this.props.petDetails
          ? this.props.petDetails.find(x => x.PetID === this.state.selectedPet)
          : null;

      if (this.props.petDetails.length === 1) {
          this.setRelatedClaims();
      }
      let stateName = this.props.userDetails.PhysicalAddress.State;
      let claimDisclaimerText = "By checking this box and clicking  \"Submit Claim!\", I certify that the information provided is accurate to the best of my knowledge. I authorize any veterinary hospital or veterinarian to provide additional information about my pet to Healthy Paws Pet Insurance.  I understand that missing information or delays in delivering the pet's medical records may delay the processing of my claim. Claims must be submitted for processing within 90 days of invoice date.";

      if (stateName == "AL") {
          claimDisclaimerText += "\r\n\nAny person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution, fines, or confinement in prison, or any combination thereof.";

      } else if (stateName == "AK") {
          claimDisclaimerText += "\r\n\nA person who knowingly and with intent to injure, defraud, or deceive an insurance company files a claim containing false, incomplete, or misleading information may be prosecuted under state law.";

      } else if (stateName == "AZ") {
          claimDisclaimerText += "\r\n\nFor your protection Arizona law requires the following statement to appear on this form. Any person who knowingly presents a false or fraudulent claim for payment of a loss is subject to criminal and civil penalties.";

      } else if (stateName == "AR") {
          claimDisclaimerText += "\r\n\nAny person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.";

      } else if (stateName == "CA") {
          claimDisclaimerText += "\r\n\nFor your protection California law requires the following to appear on this form. Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.";

      } else if (stateName == "CO") {
          claimDisclaimerText += "\r\n\nIt is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance, and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado division of insurance within the department of regulatory agencies.";

      } else if (stateName == "DC") {
          claimDisclaimerText += "\r\n\nWARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.";

      } else if (stateName == "DE") {
          claimDisclaimerText += "\r\n\nAny person who knowingly, and with intent to injure, defraud or deceive any insurer, files a statement of claim containing any false, incomplete or misleading information is guilty of a felony.";

      } else if (stateName == "FL") {
          claimDisclaimerText += "\r\n\nAny person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.";

      } else if (stateName == "ID") {
          claimDisclaimerText += "\r\n\nAny person who knowingly, and with intent to defraud or deceive any insurance company, files a statement of claim containing any false, incomplete, or misleading information is guilty of a felony.";

      } else if (stateName == "IN") {
          claimDisclaimerText += "\r\n\nA person who knowingly and with intent to defraud an insurer files a statement of claim containing any false, incomplete, or misleading information commits a felony.";

      } else if (stateName == "KY") {
          claimDisclaimerText += "\r\n\nAny person who knowingly and with intent to defraud any insurance company or other person files a statement of claim containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime.";

      } else if (stateName == "LA") {
          claimDisclaimerText += "\r\n\nAny person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.";

      } else if (stateName == "ME") {
          claimDisclaimerText += "\r\n\nIt is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or a denial of insurance benefits.";

      } else if (stateName == "MD") {
          claimDisclaimerText += "\r\n\nAny person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.";

      } else if (stateName == "MN") {
          claimDisclaimerText += "\r\n\nA person who files a claim with intent to defraud or helps commit a fraud against an insurer is guilty of a crime.";

      } else if (stateName == "NH") {
          claimDisclaimerText += "\r\n\nAny person who, with a purpose to injure, defraud, or deceive any insurance company, files a statement of claim containing any false, incomplete, or misleading information is subject to prosecution and punishment for insurance fraud, as provided in RSA 638:20.";

      } else if (stateName == "NJ") {
          claimDisclaimerText += "\r\n\nAny person who knowingly files a statement of claim containing any false or misleading information is subject to criminal and civil penalties.";

      } else if (stateName == "NM") {
          claimDisclaimerText += "\r\n\nANY PERSON WHO KNOWINGLY PRESENTS A FALSE OR FRAUDULENT CLAIM FOR PAYMENT OF A LOSS OR BENEFIT OR KNOWINGLY PRESENTS FALSE INFORMATION IN AN APPLICATION FOR INSURANCE IS GUILTY OF A CRIME AND MAY BE SUBJECT TO CIVIL FINES AND CRIMINAL PENALTIES.";

      } else if (stateName == "NY") {
          claimDisclaimerText += "\r\n\nAny person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation.";

      } else if (stateName == "OH") {
          claimDisclaimerText += "\r\n\nAny person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.";

      } else if (stateName == "OK") {
          claimDisclaimerText += "\r\n\nWARNING: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.";

      } else if (stateName == "PA") {
          claimDisclaimerText += "\r\n\nAny person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information or conceals for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime and subjects such person to criminal and civil penalties.";

      } else if (stateName == "RI") {
          claimDisclaimerText += "\r\n\nAny person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.";

      } else if (stateName == "TN") {
          claimDisclaimerText += "\r\n\nIt is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.";

      } else if (stateName == "VA") {
          claimDisclaimerText += "\r\n\nIt is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.";

      } else if (stateName == "WA") {
          claimDisclaimerText += "\r\n\nIt is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of insurance benefits.";

      } else if (stateName == "WV") {
          claimDisclaimerText += "\r\n\nAny person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.";

      } else {
          claimDisclaimerText += "\r\n\nAny person who knowingly presents a false or fraudulent claim for the payment of a loss may be guilty of a crime and may be subject to fines and confinement in state prison.";
      }

      const petsWithClaimForms =
        this.props.petDetails && this.state.policyDocs
          ? this.state.policyDocs.filter((doc) => {
              return this.props.petDetails.some((pet) => {
                if (
                  pet.PetID === doc.pet_id &&
                  doc.salesforce_type === "claim"
                ) {
                  doc.petName = pet.Name;

                  return doc;
                }
              });
            })
          : [];

      let petName = "";

      let petPolicyNo = "";
      if (selectedPetDetails) {
        petName = selectedPetDetails.Name;
        petPolicyNo = selectedPetDetails.PetPolicyNo;
      }
    
    if (false) {
      return (
        <ClaimSubmissionMaintenance
            backToHome={() => {
              this.props.history.push("/");
            }}
          />
      )
    }
     

    if (!this.state.claimSubmitSuccess) {
      return (
        <div
          style={{ position: this.props.canUserEdit ? "static" : "relative" }}
          className="container file-claim p-0"
        >
          {this.props.canUserEdit ? null : (
            <div className="disable-user-edit" />
          )}
          <div className="col-12">
                  <h1 className="text-primary-heading m-lg-0"> File a Claim </h1>
          </div>
          <div className="d-flex">
            <div className="col-lg-8 position-static">
              <NavigationPrompt
                when={(crntLocation, nextLocation) => {
                  this.currentLocation = crntLocation;
                  this.nextLocation = nextLocation;
                  if (this.state.veterinaryInvoiceList.length > 0) {
                    return true;
                  }
                }}
              >
                {({ onCancel, onConfirm }) => (
                  <ConfirmNavigationModal
                    when={true}
                    message="You have unsaved changes on this page. Do you want to leave this page and discard your changes?"
                    title="Unsaved Data"
                    currentLocation={this.currentLocation}
                    nextLocation={this.nextLocation}
                    forceUpdate={false}
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                  />
                )}
              </NavigationPrompt>
              {this.props.petDetails.length > 0 ? (
                <div className="claims">
                  <div>
                    <p>
                      If you have multiple invoices, please submit each as a
                      separate claim.
                    </p>
                  </div>
                  {this.state.claimSubmissionError && (
                    <ErrorView>
                      Unable to submit claim. Please try later.
                    </ErrorView>
                  )}
                   <div className="claim-collapse-box background-color-light-bluegrey">
                    <div
                      className={
                        this.state.selectedPet
                          ? "collapse-box-item selected"
                          : "collapse-box-item"
                      }
                    >
                      <div className="box-title d-block d-sm-none">
                        1.Select your pet
                      </div>

                    <div className="box-title d-none d-sm-block">
                        {this.state.selectedPet ? <SectionStatus /> : " 1."}
                        Select your pet
                      </div>

                      {this.props.petDetails && (
                        <PetSelector
                          pets={this.props.petDetails}
                          selectionHandler={this.petSelectionHandler}
                          selectedPet={this.state.selectedPet}
                        />
                      )}
                      {(this.props.petDetails != undefined && this.state.selectedPet != undefined && this.state.selectedPet != "" && this.props.petDetails != [] && this.props.petDetails.filter(x=>x.PetID == this.state.selectedPet)[0].DateJoinedFamily === null) ? <div>
                      {this.state.dateJoinFamilyModal &&
                        this.state.selectedPet && (
                          <UpdateDateJoinedFamilyModal
                            isOpen={this.state.dateJoinFamilyModal}
                            toggleHandler={this.dateJoinedFamilyToggler}
                            petDetails={selectedPetDetails}
                            updateDateJoinedFamily={this.updateDateJoinedFamily}
                          />
                        )}</div>:null}
                    </div>
                    <div
                      className={
                        this.state.selectedPet
                          ? "collapse-box-item"
                          : "collapse-box-item disable"
                      }
                    >
                      <div
                        onClick={() => {
                          this.setState({ showUploadVeterinaryInvoice: true });
                        }}
                        id="upload-veterinary-invoice-toggler"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div className="box-title d-block d-sm-none">
                          2.Upload Veterinary Invoice
                        </div>
                        <div className="box-title d-none d-sm-block">
                          {this.state.veterinaryInvoiceList.length > 0 &&
                          !this.state.showUploadVeterinaryInvoice ? (
                            <SectionStatus />
                          ) : (
                            " 2. "
                          )}
                          Upload{" "}
                          {petName !== "" ? (
                            <span
                              className="truncate"
                              style={{ textTransform: "capitalize" }}
                            >
                              <strong>
                                {petName}
                                's
                              </strong>
                            </span>
                          ) : null}{" "}
                          Veterinary Invoice
                        </div>
                        <div>
                          {" "}
                          {this.state.veterinaryInvoiceList.length > 0 &&
                          !this.state.showUploadVeterinaryInvoice ? (
                            <span
                              onClick={this.veterinaryInvoiceEditHandler}
                              className="upload-file-edit d-none d-sm-block text-color-violet1"
                            >
                              Edit
                            </span>
                          ) : null}
                          {this.state.showUploadVeterinaryInvoice ? (
                            <i className="text-secondary fa fa-angle-up fa-2x d-block d-sm-none" />
                          ) : (
                            this.state.selectedPet && (
                              <i className="text-secondary fa fa-angle-down fa-2x d-block d-sm-none" />
                            )
                          )}{" "}
                        </div>
                      </div>

                      <UploadVeterinaryInvoice
                        ref={this.veterinaryUploadRef}
                        type="Claim"
                        show={this.state.showUploadVeterinaryInvoice}
                        petPolicyNo={petPolicyNo}
                        setUploadFiles={this.setVeterinaryInvoiceList}
                      />
                    </div>

                    <div
                      onClick={() => {
                        !this.dateOfServiceError &&
                          this.setState((prevState) => ({
                            showClaimDetailsForm: !prevState.showClaimDetailsForm,
                          }));
                      }}
                      className={
                        this.state.veterinaryInvoiceList.length > 0
                          ? "collapse-box-item"
                          : "collapse-box-item disable"
                      }
                    >
                      <div
                        onClick={() => {
                          this.setState({ showUploadMedicalRecords: true });
                        }}
                        id="upload-medical-records-toggler"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div className="box-title d-block d-sm-none">
                          3.Upload Medical Records (recommended)
                        </div>
                        <div className="box-title d-none d-sm-block">
                          {this.state.medicalRecordsList.length > 0 &&
                          !this.state.showUploadMedicalRecords ? (
                            <SectionStatus />
                          ) : (
                            " 3. "
                          )}
                          Upload{" "}
                          {petName !== "" ? (
                            <div
                              className="truncate"
                              style={{ textTransform: "capitalize" }}
                            >
                              <strong >
                                {petName}
                                's
                              </strong>
                            </div>
                          ) : null}{" "}
                          Medical Records (recommended)
                        </div>
                        <div>
                          {this.state.showUploadMedicalRecords ? (
                            <i className="text-secondary fa fa-angle-up fa-2x d-block d-sm-none" />
                          ) : (
                            <React.Fragment>
                              {this.state.medicalRecordsList.length > 0 &&
                              this.state.veterinaryInvoiceList.length > 0 ? (
                                <React.Fragment>
                                  <i className="text-secondary fa fa-angle-down fa-2x d-block d-sm-none" />
                                  <span className="upload-file-edit d-none d-sm-block text-color-violet1">
                                    Edit
                                  </span>
                                </React.Fragment>
                              ) : (
                                <i className="text-secondary fa fa-angle-down fa-2x" />
                              )}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <UploadMedicalRecords
                        optional={true}
                        ref={this.medicalUploadRef}
                        type="MR"
                        show={
                          this.state.showUploadMedicalRecords &&
                          this.state.veterinaryInvoiceList.length > 0
                        }
                        petPolicyNo={petPolicyNo}
                        setUploadFiles={this.setMedicalRecordsList}
                      />
                      {this.state.showUploadMedicalRecords &&
                        this.state.medicalRecordsList.length === 0 && (
                          <button
                            className="text-secondary w-100 text-right cancel-btn text-color-violet1"
                            id="claim-details-toggler"
                            onClick={() => {
                              this.setState({
                                showUploadMedicalRecords: false,
                              });
                            }}
                          >
                            Skip This Step
                          </button>
                        )}
                    </div>
                    <div
                      className={
                        this.state.veterinaryInvoiceList.length > 0
                          ? "collapse-box-item"
                          : "collapse-box-item disable"
                      }
                    >
                      <div className="box-title">4. Submit Claim</div>
                      {this.state.veterinaryInvoiceList.length > 0 && (
                        <div className="claimform-submit-box">
                          <textarea readOnly value={claimDisclaimerText} />

                          <Form onSubmit={() => this.submitClaim()}>
                            {({ formState }) => (
                              <div>
                                <CheckboxControl
                                  field="checkbox"
                                  id="submit-checkbox"
                                  label="I agree to these terms"
                                />

                                <div className="d-block d-md-flex justify-content space-between mt-2 mb-2">
                                  <button
                                    className={
                                      formState.values.checkbox
                                        ? "button-primaryV2"
                                        : "button-primaryV2 disabled-btn"
                                    }
                                    type="submit"
                                  >
                                    {" "}
                                    Submit Claim{" "}
                                  </button>
                                  <button
                                    className="cancel-btn text-secondary d-none d-md-block"
                                    onClick={this.reset}
                                  >
                                    {" "}
                                    Cancel{" "}
                                  </button>
                                </div>
                              </div>
                            )}
                          </Form>
                        </div>
                      )}
                    </div>
                  </div>

                  {this.state.showSubmitLoader && (
                    <Loader position="absolute" fullPage={true} />
                  )}
                </div>
              ) : (
                <div style={{ height: 300 }} className="container">
                  {this.props.fetchingPets && this.props.fetchingClaims ? (
                    <Loader />
                  ) : (
                    this.props.error && (
                      <ErrorView>
                        {" "}
                        {this.props.error.message
                          ? this.props.error.errorMessage
                          : "Unable to fetch information right now. Please try later."}{" "}
                      </ErrorView>
                    )
                  )}
                </div>
              )}
            </div>
            <div className="d-none col-lg-4 d-lg-flex flex-column justify-content-start align-items-center side-box">
              {this.props.petDetails && (
                <ClaimForm
                  title="Do you prefer to use a claim form?"
                  petClaimForms={petsWithClaimForms}
                  policyDocsError={this.state.policyDocsFetchError}
                  policyDocsFetchErrorMessage={this.policyDocsFetchErrorMessage}
                  subtitle="Send your claim form and itemized invoice:"
                  Emailaddress = "claims@healthypaws.com"
                />
              )}


            </div>
          </div>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div className="d-none d-md-block">
            <UpdateSuccessView
              statusHeader={
                <div className="d-inline">
                  <div
                    className="d-inline"
                    style={{ textTransform: "capitalize" }}
                  >
                    <span className="truncate">{petName}</span>
                  </div>
                  's claim has been successfully submitted.
                </div>
              }
              title={
                <div className="d-inline">
                  <div
                    className="d-inline"
                    style={{ textTransform: "capitalize" }}
                  >
                    <span className="truncate">{petName}</span>
                  </div>
                  's claim
                </div>
              }
            >
              {this.props.userDetails && (
                <FileClaimSuccessView
                  goToFileClaim={() => this.reset()}
                  backToHome={() => {
                    this.props.history.push("/");
                  }}
                  goToReimbursement={() => {
                    this.props.history.push(
                      AppLinks.account.updateReimbursement
                    );
                  }}
                  claimReimbursementMethod={
                    this.props.userDetails.ClaimReimbursementMethod
                  }
                  mailingAddress={this.props.userDetails.MailingAddress}
                />
              )}
            </UpdateSuccessView>
          </div>
          <UpdateSuccessView
            className="d-md-none"
            statusHeader={
              <div className="d-md-inline">
                <div
                  className="d-inline"
                  style={{ textTransform: "capitalize" }}
                >
                  <span className="truncate">{petName}</span>
                </div>
                's claim has been successfully submitted.
              </div>
            }
            title={
              <div className="d-md-inline py-3">
                Success!{" "}
                <div
                  className="d-inline"
                  style={{ textTransform: "capitalize" }}
                >
                  <span className="truncate">{petName}</span>
                </div>
                's claim has been submitted.
              </div>
            }
          >
            {this.props.userDetails && (
              <FileClaimSuccessView
                goToFileClaim={() => this.reset()}
                backToHome={() => {
                  this.props.history.push("/");
                }}
                goToReimbursement={() => {
                  this.props.history.push(AppLinks.account.updateReimbursement);
                }}
                claimReimbursementMethod={
                  this.props.userDetails.ClaimReimbursementMethod
                }
                mailingAddress={this.props.userDetails.MailingAddress}
              />
            )}
          </UpdateSuccessView>
        </React.Fragment>
      );
    }
  }
}

const SectionStatus = () => {
  return (
    <span className="section-check-status">
      <i
        className="fa fa-check-circle"
        style={{ fontSize: 22, color: "#34A250" }}
      />
    </span>
  );
};

const mapStateToProps = (state) => ({
  petDetails: state.pets.petsDetails,
  fetchingPets: state.pets.fetchingPets,
  claimsDetails: state.claims.claimsDetails,
  fetchingClaims: state.claims.fetchingClaims,
  error: state.pets.error,
  canUserEdit: state.account.canUserEdit,
  userDetails: state.account.userAccountDetails,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getPetsByAccount,
      setClaimsDataRefresh,
      getClaimsByAccount,
      getAccountInfo,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileClaimPage);
