import React from "react";
import { BasicText, asField } from "informed";
import PropTypes from "prop-types";
import "./EditText.css";

const EditText = asField(({ fieldState, id, ...props }) => {
  const {
    className,
    floatingTitle,
    type,
    email,
    showError,
    showErrorText,
    secured,
    title,
    note,
    onBlur,
    ...rest
  } = props;

  return (
    <div id={id} className={"d-flex flex-column edit-text-container " + className}>
      <div
        className={
          "demo-form-group d-flex justify-content-between " +
          (floatingTitle
            ? " demo-form-group-float "
            : " demo-form-group-normal ")
        }
        style={
          fieldState.error || showError ? { border: "solid 2px red" } : null
        }
      >
        {!secured && (
          /**
           * onBlur() prevents onSubmit() while submitting form
           * Adding setTimeout() will delay onBlur() and Submit will work
           */
          <BasicText
            className="informed-text-field"
            type={type}
            placeholder={floatingTitle ? "" : title}
            fieldState={fieldState}
            email={email ? 1 : 0} 
            {...rest}
          />
        )}
        {secured && (
          <PasswordField
            type={type}
            placeholder={floatingTitle ? "" : title}
            fieldState={fieldState}
            onBlur={onBlur}
            {...rest}
          />
        )}
        {floatingTitle && (
          <label
            className={
              "floating-label " +
              ((fieldState.error || props.showError) && "red-label") +
              (props.fieldApi.getValue() &&
                props.fieldApi.getValue().length > 0 &&
                " floating-label-with-value floating-label-with-value-font ")
            }
          >
            {title}
          </label>
        )}
      </div>

      {fieldState.error || (showError && showErrorText) ? (
        <label className="error">
          {fieldState.error ? fieldState.error : showErrorText}
        </label>
      ) : null}

      {note && (
        <div id="btnClick" className="note-view d-flex flex-row">
          {note}
        </div>
      )}
    </div>
  );
});

// This is do done to remove value attribut in input tag. which displays password.

const PasswordField = asField(({ fieldState, fieldApi, ...props }) => {
  const { setValue, setTouched } = fieldApi;
  const {
    onChange,
    onBlur,
    forwardedRef,
    className,
    ...rest
  } = props;

  return (
    <React.Fragment>
      <input
        {...rest}
        className={className + " secure-field"}
        ref={forwardedRef}
        onChange={e => {
          setValue(e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={e => {
          setTimeout(() => {
            setTouched();
            if (onBlur) {
              onBlur(e);
            }
          }, 150);
        }}
      />
    </React.Fragment>
  );
});

EditText.propTypes = {
  floatingTitle: PropTypes.bool,
  className: PropTypes.string,
  secured: PropTypes.bool,
  title: PropTypes.string,
  noteText: PropTypes.string,
  email: PropTypes.bool,
  showError: PropTypes.bool,
  showErrorText: PropTypes.string
};

export default EditText;
