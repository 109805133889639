import React from "react";
import { AppLinks, FAQLinks } from "../../../constants/app-links";
export default class Billing {
    static freeLookPeriod = (policyFormUrl) => {
        return (

            <div className="faqAnswer" >
                <p>As helpful as pet health coverage may be, especially to your peace of mind, we realize it's not always for everyone. That's why we invite you to take 30 days to see how good it feels to know that your pet is protected. If pet insurance isn't for you, we'll give you a complete refund as long as you cancel during the first 30 days of your policy and you <span style={{ textDecoration: 'underline' }}>have not submitted a claim</span>. (Void where prohibited by law.)  </p>
              <p>
                For additional information please <a href={policyFormUrl} target="_blank" rel="noopener noreferrer">click here</a> to see policy specific provisions related to this FAQ.
              </p>
            </div>
          );
    }
    static updateProfile = () => {
        return (
            <div className="faqAnswer">
                <p>
                   To update your profile or billing information, please visit our website and
                   select&nbsp;  
                   <a style={{fontWeight:"600"}} href={AppLinks.others.login} target="_blank">Sign In.</a>
                </p>
                <p>
                   Log in with your email address and Healthy Paws password. There is assistance
                   if you've forgotten your password.
                </p>
                <p>
                Once you have logged in, choose&nbsp;
                 <a style={{fontWeight:"600"}} href={AppLinks.home.home} target="_blank" rel="noopener noreferrer">My Account</a> 
                 &nbsp;and select the information you would like to update.</p>
            </div>
        )
    };
    static charged = () => {
        return (
            <div className="faqAnswer">
                <p>Your monthly premium will be charged automatically to your credit or debit card each month on the same date that you enrolled. For example, if you enrolled on the 15th of the month, all payments moving forward will be deducted from the card on file on the 15th of each month.</p>
                <p style={{ fontStyle: 'italic' }}>For Pet Parents that enroll on or during the 28th to 31st of the month, the billing date defaults to the 28th because of February.</p>
            </div>
        )
    }
    static paymentMethod = () => {
        return (
            <div className="faqAnswer">
                <p>
                  We currently accept any debit or credit cards issued by VISA, MasterCard,
                  American Express, and Discover.                      
                </p>
            </div>

        )
    }
    
    static cancelPolicy = (policyFormUrl) => {
        return (
            <div className="faqAnswer">
                <p>
                   We'd sure be sorry to see you go, but if you still want to cancel your policy, you
                   can let us know via regular mail, fax, email or verbally. Once the account is
                   canceled, you will no longer have access to the online Customer Center. Learn
                   more about canceling your Healthy Paws plan <a style={{fontWeight:"600"}} href={FAQLinks.HowtoCancelPageURL} target="_blank" rel="noopener noreferrer">here</a>.
                </p>
            </div>
        );
    }
    static premiumIncrease = (policyFormUrl) => {
        return (
            <div className="faqAnswer">
                <p>
                 In accordance with the terms of the Pet Health Insurance Policy and the
                 associated rating rules, monthly premiums may change for all
                 policyholders. Premiums are determined based on the rates and rating rules
                 filed with and approved by (as applicable), each state’s insurance regulator,
                 which reflect the cost of treatment advances in veterinary medicine, individual
                 pet’s breed, gender, age, and other factors, in addition to the overall claims
                 experience for the program within the region where the pet resides. Premium
                 increases are not based on your individual claim submissions.
                </p>
            </div>

        )
    }

    static ownershipTransfer = () => {
        return (

            <div className="faqAnswer" >
              <p>If your furry family member will be cared for by another pet parent, we are able to transfer the policy with seamless coverage.  In order to process this request, we would need notification in writing from both the current and new pet parents.  This information can be emailed to our transfer department, and should include:</p>
              <ul className="blackdisc">
                <li>Confirmation of new ownership</li>
                <li>Full Name</li>
                <li>Address</li>
                <li>Phone Number</li>
                <li>Email Address</li>
              </ul>
              <p>We will also need to reach out to the new pet parent once the transfer is complete to confirm their preferred billing information. </p>
            </div>
          );
    }
    
    
}