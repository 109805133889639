import React, { Component } from "react";
import { Form } from "informed";

import NavigationPrompt from "react-router-navigation-prompt";
import ConfirmNavigationModal from "./../../common/route-prevent-dialog/ConfirmNavigationModal";
import CheckboxControl from "./../../common/form-fields/checkbox/Checkbox";
import ErrorView from "../../common/error-view/ErrorView";
import Loader from "../../common/loader/Loader";
import Util from "../../common/util/util";
import CATermsConditions from "./CATermsConditions";

import { updatePhysicalAddress } from "../../../api/accounts-api";

import "./ApproveAddress.css";

export default class ApproveAddress extends Component {
  constructor() {
    super();
    this.errorText = null;
    this.state = {
      sendingDataToServer: false,
      serverError: false
    };
    this.mobAppRoute= window.location.pathname.indexOf('mobile')> -1 ? 'true':'false';
  }

  updatePhysicalAddress = values => {
    this.setState({
      sendingDataToServer: true,
      serverError: false
    });

    updatePhysicalAddress(this.props.formData, false)
      .then(response => {
        this.setState({
          sendingDataToServer: false
        });

        this.props.createClientAlert(response.new_premium);

        this.props.setResponseData(response);

        this.props.setAccountDataRefresh();

        //For google analytics
        window.dataLayer.push({
          'event': 'Pageview',
          'url': window.location.pathname + '/Confirm'
        });

        this.props.stepper.nextStep();
      })
      .catch(error => {
        this.errorText =
          error && error.errorMessage
            ? error.errorMessage
            : "Unable to update physical address due to an error. Please try later";
        this.setState({
          sendingDataToServer: false,
          serverError: true
        });
        window.scrollTo(0, 0);
      });
  };

  render() {
    const { premiumUpdateVerifyData } = this.props;

    return (
      <div className="container approve-address">
        <Form
          onSubmit={values => {
            this.updatePhysicalAddress(values);
          }}>
          {
            ({ formState }) => (
              <React.Fragment>
                <NavigationPrompt
                  when={(crntLocation, nextLocation) => {
                    this.currentLocation = crntLocation;
                    this.nextLocation = nextLocation;
                    return true;
                  }}
                >
                  {({ onCancel, onConfirm }) => (
                    <ConfirmNavigationModal
                      when={true}
                      message="You have unsaved changes on this page. Do you want to leave this page and discard your changes?"
                      title="Unsaved Data"
                      currentLocation={this.currentLocation}
                      nextLocation={this.nextLocation}
                      forceUpdate={false}
                      onCancel={onCancel}
                      onConfirm={onConfirm}
                    />
                  )}
                </NavigationPrompt>
                <div className="row">
                  <div className="col-12">
                    <h1 className={this.mobAppRoute === 'false' ? "title-container mt-3 mb-3 pt-0 pb-0" : "h1_v2 mt-3 mb-3 pt-0 pb-0"}>
                      2. Approve your new address and monthly premium.
            </h1>
                    {this.state.serverError && <ErrorView>{this.errorText}</ErrorView>}
                    <p className= {this.mobAppRoute==='false'? "mb-3 d-md-none": "mb-3 d-md-none mobileTextV2"}>
                      A physical address change may result in policy adjustments.
                      Because we reimburse based on the actual veterinary bill, your
                      monthly premium reflects the cost of veterinary care in your area.
                      Due to the change in residence your current monthly premium may
                      have to be adjusted.
            </p>
                    <p className= {this.mobAppRoute==='false'? "mb-3 d-none d-md-block": "mb-3 d-none d-md-block mobileTextV2"}>
                      Changing your physical address may result in policy adjustments.
                      We reimburse you based on your actual veterinary bill and your
                      monthly premium reflects the cost of veterinary care in your area.
                      Due to the change in residence your current monthly premium may
                      have been adjusted.
            </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="content-wrapper content-wrapper-top">
                      <div className="d-flex mb-3">
                        <div className="flex-1 flex-row">
                          <h4 className="">New Physical Address</h4>
                          <p className="address-text ">
                            {premiumUpdateVerifyData.address1}
                          </p>

                          {premiumUpdateVerifyData.city_name && (
                            <p className="address-text ">
                              {premiumUpdateVerifyData.city_name}
                            </p>
                          )}
                          <p className="address-text ">
                            {premiumUpdateVerifyData.state_abbreviation +
                              " " +
                              premiumUpdateVerifyData.postal_code}
                          </p>
                        </div>

                        <button
                          onClick={() => this.props.stepper.goToFirst()}
                          type="submit"
                          className={"btn-w-14 button-secondaryV2 d-block d-lg-none"} 
                        >
                         {<img className="" src="../../assets/images/edit_icon.svg" alt="Edit" />}
                </button>
                        <button
                          onClick={() => this.props.stepper.goToFirst()}
                          className={"button-link btn-w-14 button-secondaryV2 d-none d-lg-inline"}
                        >
                          {<img className="editButton" src="../../assets/images/edit_icon.svg" alt="Edit" />}
                </button>
                      </div>

                      <p className="mb-0 fs-12">
                        <strong>*Keep in Mind</strong>
                      </p>
                      <p className="fs-12 fs-md-14 mb-md-2 mb-lg-3">
                        Your policy or premium may change due to your new address.
                        Please review your monthly premium in the column to the right before changing your physical address.
              </p>
                      <p className="pr-3 d-none d-md-flex calendar-text">
                        <i className="fa fa-calendar pr-3 calendar-icon" />
                        Your physical address change effective{" "}
                        {Util.getFormattedDate(
                          premiumUpdateVerifyData.change_effective_date
                        )}
                        . Changes to your pet's policy will become effective on the next
                        coverage period.
              </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="content-wrapper-bottom">
                      <p className="pl-3 mb-lg-0">
                        <strong>New Monthly Premium*</strong>
                      </p>
                      {premiumUpdateVerifyData.petComparisonArray.length > 0 && (
                        <React.Fragment>
                          <div className="row rate-table-head-row">
                            <span align="center" className="col-4 rate-table-column" />
                            <span align="center" className="col-4 rate-table-column">
                              <em className="fw-300">Current</em>
                            </span>
                            <span align="center" className="col-4 rate-table-column">
                              <strong>
                                <em>New</em>
                              </strong>
                            </span>
                          </div>

                          {premiumUpdateVerifyData.petComparisonArray.map(
                            (petData, index) => (
                              <div key={index} className={this.mobAppRoute==='false'? "row rate-table-row bg-row":"row rate-table-row bg-rowv2"}>
                                <span
                                  align="center"
                                  className="col-4 rate-table-column justify-content-lg-start pl-lg-3 text-truncate d-block pt-2"
                                >
                                  {petData.petName}
                                </span>
                                <div
                                  align="center"
                                  className="col-4 flex-column flex-lg-row rate-table-column"
                                >
                                  <span className="d-block ">
                                    ${Number(petData.oldMonthlyPremium).toFixed(2)}
                                  </span>
                                  <span className="d-none d-lg-block">&nbsp;</span>
                                  <span>
                                    {" (" +
                                      petData.oldReimbursement * 100 +
                                      "%/$" +
                                      petData.oldDeductible +
                                      ")"}
                                  </span>
                                </div>
                                <div
                                  align="center"
                                  className={this.mobAppRoute==='false'? "col-4 flex-column flex-lg-row rate-table-column bg-dark-row":"col-4 flex-column flex-lg-row rate-table-column bg-dark-rowv2"}
                                >
                                  <span className="d-block ">
                                    <strong>
                                      ${Number(petData.newMonthlyPremium).toFixed(2)}
                                    </strong>
                                  </span>
                                  <span className="d-none d-lg-block">&nbsp;</span>
                                  <span>
                                    {" (" +
                                      petData.newReimbursement * 100 +
                                      "%/$" +
                                      petData.newDeductible +
                                      ")"}
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                          <div className={this.mobAppRoute==='false'? "row rate-table-row bg-row-success": "row rate-table-row bg-dark-rowv2"}>
                            <span align="center" className="col-4 rate-table-column">
                              Total
                    </span>
                            <span align="center" className="col-4 rate-table-column">
                              {"$" + premiumUpdateVerifyData.currentPremiumTotal}
                            </span>
                            <span align="center" className="col-4 rate-table-column">
                              <strong>
                                {"$" + premiumUpdateVerifyData.newPremiumTotal}
                              </strong>
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                      <p className="margin d-flex d-md-none">
                        <i className="fa fa-calendar pr-3 calendar-icon" />
                        Your physical address change effective{" "}
                        {Util.getFormattedDate(
                          premiumUpdateVerifyData.change_effective_date
                        )}
                        . Changes to your pet's policy will become effective on the
                        next coverage period.
              </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h3 className={this.mobAppRoute==='false'? "term-text mt-3 mb-3" : "h3_v2 term-text mt-3 mb-3"} >
                      Terms{" "}
                      <span className="d-none d-md-inline"> & Conditions</span>{" "}
                      for Change Physical Address
                  </h3>
                    <CATermsConditions />

                    <CheckboxControl
                      field="agreement"
                      id="submit-checkbox"
                      label="I agree to these terms"
                      className="d-block d-lg-none mb-3"
                    />
                    <CheckboxControl
                      field="agreement"
                      id="submit-checkbox"
                      label="I agree to these terms and conditions"
                      className="d-none d-lg-block mb-3"
                    />
                  </div>
                </div>
                <div className={this.mobAppRoute==='false'? "sticky-bottom mb-3": "sticky-bottomv2 mb-3"}>
                  <div className="col-md-7 p-0 col-lg-4">
                    <div className="d-md-none text-center mb-1 text-uppercase">
                      <span className="font-weight-bold">Step 2</span> of 2
                  </div>
                    <button
                      className={this.mobAppRoute==='false'?"button-primary":"button-primaryV2"} 
                      type="submit"
                      disabled={!formState.values.agreement}
                    >
                      Change Physical Address
                  </button>
                  </div>
                </div>
                {this.state.sendingDataToServer && <Loader position="absolute" fullPage={true} />}
              </React.Fragment>
            )
          }
        </Form>
      </div>
    );
  }
}


