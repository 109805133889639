import React, { Component } from "react";
import Tabs from "../components/Tabs";
import {
  isTablet,
  isIOS,
  isChrome,
  isMobile,
  isIE,
  isEdge,
} from "react-device-detect";
import { getPolicyDoc } from "../../../api/policy-docs-api";
import { UncontrolledCollapse } from "reactstrap";
import ClaimStatus from "../components/ClaimStatus";
import ClaimStatusMessage from "../components/claim-status-message/ClaimStatusMessage";
import "./ClaimsTable.css";
import { StatusTypes } from "../status-model";
import Pagination from "./ClaimsPagination";
import { updatePageHeader } from "../../../api/axios";


export default class ClaimsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPageNo: 0
    };
    this.totalPages = 1;
    this.limit = 20;
  }

  TableHeader = [
    {
      header1: "Submitted",
      header: "Date",
      id: "DateSubmitted",
    },
    {
      header: "Claim #",
      id: "ClaimID",
      mobile: true,
    },
    {
      header: "Pet Name",
      id: "PetName",
    },
    {
      header: "Status",
      id: "Status",
    },
  ];

  converBase64toBlob = (content, contentType) => {
    contentType = contentType || "";
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType,
    }); //statement which creates the blob
    return blob;
  };

  viewOrDownloadAttachment = (attachment, action) => {
    var blob = null;
    var fileId = "";

    if (attachment.salesforce_type == "aws_s3") {
      fileId = attachment.resource_url;
    } else {
      fileId = attachment.id;
    }

    getPolicyDoc(attachment.resource_url, attachment.salesforce_type)
      .then((data) => {
        if (
          data.policy_documents &&
          data.policy_documents.length > 0 &&
          (data.policy_documents[0].file_type === "application/pdf" ||
            data.policy_documents[0].file_type === "image/png" ||
            data.policy_documents[0].file_type === "image/jpeg" ||
            data.policy_documents[0].file_type === "image/jpg" ||
            data.policy_documents[0].file_type === "image/tiff" ||
            data.policy_documents[0].file_type === "application/html")
        ) {
          blob = this.converBase64toBlob(
            data.policy_documents[0].file_data,
            data.policy_documents[0].file_type
          );
          return blob;
        }
      })
      .then((blob) => {
        this.viewOrDownloadBlob(blob, attachment, action);
      });

  };

  viewOrDownloadBlob = (blob, attachment, action) => {
    //For IOS Mobile Viewing Blob has issue
    //So we are using fileReader API to show PDF
    if (action === "view" && (isTablet || isMobile) && isChrome && isIOS) {
      var reader = new FileReader();
      var out = blob;
      reader.onloadend = function () {
        window.location.href = reader.result;
      };
      reader.readAsDataURL(out);
    } else {
      var fileUrl = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = fileUrl;
      if (action === "view") {
        link.target = "_blank";
        link.click();
        //window.open(fileUrl);
      } else {
        link.download = attachment.name;
        link.click();
      }
      setTimeout(function () {
        window.URL.revokeObjectURL(fileUrl);
      }, 100);
    }
  };

  getCurrentPageClaim = currentPageNo => {
    this.setState({
      currentPageNo: currentPageNo
    });
    updatePageHeader(currentPageNo*this.limit);
    // this.props.actions.getClaimsByAccount();
  };

  previousPage = event => {
    if (this.state.currentPageNo === 0) {
      // event.nativeEvent.stopImmediatePropagation();
      return;
    }
    const currentPageNo = this.state.currentPageNo - 1;
    this.getCurrentPageClaim(currentPageNo);
    this.props.componentReMount(this.state.currentPageNo)
    // event.nativeEvent.stopImmediatePropagation();
  };
  

  nextPage = event => {
    if (this.state.currentPageNo === this.totalPages - 1) {
      // event.nativeEvent.stopImmediatePropagation();
      return;
    }
    const currentPageNo = this.state.currentPageNo + 1;
    this.getCurrentPageClaim(currentPageNo);
    this.props.componentReMount(this.state.currentPageNo)
  };

  render() {
    this.totalPages = Math.ceil(this.props.totalCount/this.limit);
    return (
      <div className="claims-table">
        <div className="claims-table-header">
          {this.TableHeader.map(({ header1, header, id, mobile }) => (
            <div
              key={id}
              className={
                mobile
                  ? "d-none d-md-block col-md-3 col-lg-3 text-center"
                  : "col-sm-4 col-md-3 col-lg-3 text-center"
              }
              id={id}
              onClick={() => this.props.toggleSort(id)}
            >
              <span className="d-none d-md-inline-block">{header1}</span>{" "}
              {header}
              <i
                className={
                  this.props.sortColumn === id &&
                  this.props.sortOrder === "ascending"
                    ? "ml-1 fa fa-sort-down"
                    : this.props.sortColumn === id &&
                      this.props.sortOrder === "descending"
                    ? "ml-1 fa fa-sort-up"
                    : "ml-1 fa fa-sort"
                }
              />
            </div>
          ))}
        </div>
        {this.props.claimsDetails.length > 0 ? (
          <div className="claims-striped-table">
            {this.props.claimsDetails.map((claim) => (
              <div className="claim-item" key={claim.ClaimID}>
                <div className="d-flex">
                  <div className="col-sm-4 col-md-3 col-lg-3 text-center">
                    {" "}
                    {claim.DateSubmitted}{" "}
                  </div>
                  <div className="d-none d-md-block col-md-3 col-lg-3 text-center">
                    {" "}
                    {claim.ClaimNumber ? claim.ClaimNumber : "n/a"}{" "}
                  </div>
                  <div className="col-sm-4 col-md-3 col-lg-3 text-truncate text-center">
                    {" "}
                    {claim.PetName}{" "}
                  </div>
                  <div className="col-sm-4 col-md-3 col-lg-3 text-center">
                    <div onClick={this.props.disableAutoExpand}>
                      <ClaimStatus
                        status={claim.StatusDetail}
                        id={"toggle-" + claim.ClaimID}
                      />
                    </div>
                  </div>
                </div>
                <Tabs>
                  <div label="Invoice(s)">
                    {claim.claim_documents.filter(
                      (document) =>
                        document.is_claim_invoice_resource && document.name &&
                        (document.name.endsWith("pdf") ||
                          document.name.endsWith("jpeg") ||
                          document.name.endsWith("jpg") ||
                          document.name.endsWith("png") ||
                          document.name.endsWith("tiff"))
                    ).length != 0 ? (
                      <div className="scrollable-div">
                        {claim.claim_documents
                          .filter(
                            (document) =>
                              document.is_claim_invoice_resource && document.name &&
                              (document.name.endsWith("pdf") ||
                                document.name.endsWith("jpeg") ||
                                document.name.endsWith("jpg") ||
                                document.name.endsWith("png") ||
                                document.name.endsWith("tiff"))
                          )
                          .map((document) => (
                            <div key={document.name}>
                              <span className="case-doc" href="">
                                {window.innerWidth < 400 &&
                                document.name.length > 10
                                  ? document.name.substr(0, 4) +
                                    "..." +
                                    document.name.substr(
                                      document.name.length - 4,
                                      document.name.length
                                    )
                                  : window.innerWidth < 500 &&
                                    document.name.length > 15
                                  ? document.name.substr(0, 8) +
                                    "..." +
                                    document.name.substr(
                                      document.name.length - 4,
                                      document.name.length
                                    )
                                  : document.name.length > 20
                                  ? document.name.substr(0, 14) +
                                    "..." +
                                    document.name.substr(
                                      document.name.length - 4,
                                      document.name.length
                                    )
                                  : document.name}
                              </span>
                              <a
                                className="case-doc-download"
                                onClick={() =>
                                  this.viewOrDownloadAttachment(
                                    document,
                                    "view"
                                  )
                                }
                                style={{
                                  float: "right",
                                  marginRight: 10 + "px",
                                  color: "black",
                                  cursor: "pointer",
                                  fontWeight:600
                                }}
                              >
                                &#10094; View
                              </a>
                              <a
                                className="case-doc-download"
                                onClick={() =>
                                  this.viewOrDownloadAttachment(
                                    document,
                                    "download"
                                  )
                                }
                                style={{
                                  float: "right",
                                  marginRight: 10 + "px",
                                  color: "black",
                                  cursor: "pointer",
                                  fontWeight:600
                                }}
                              >
                                &#10094; Download
                              </a>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="scrollable-div">
                        <span href="" style={{ marginLeft: 21 + "px" }}>
                          No invoices attached with this claim.
                        </span>
                      </div>
                    )}
                  </div>
                  <div label="Medical Records">
                    {claim.claim_documents.filter(
                      (document) =>
                        document.is_claim_medical_record_resource && document.name &&
                        (document.name.endsWith("pdf") ||
                          document.name.endsWith("jpeg") ||
                          document.name.endsWith("jpg") ||
                          document.name.endsWith("png") ||
                          document.name.endsWith("tiff"))
                    ).length != 0 ? (
                      <div className="scrollable-div">
                        {claim.claim_documents
                          .filter(
                            (document) =>
                              document.is_claim_medical_record_resource && document.name &&
                              (document.name.endsWith("pdf") ||
                                document.name.endsWith("jpeg") ||
                                document.name.endsWith("jpg") ||
                                document.name.endsWith("png") ||
                                document.name.endsWith("tiff"))
                          )
                          .map((document) => (
                            <div key={document.id}>
                              <span className="case-doc">
                                {" "}
                                {window.innerWidth < 400 &&
                                document.name.length > 10
                                  ? document.name.substr(0, 4) +
                                    "..." +
                                    document.name.substr(
                                      document.name.length - 4,
                                      document.name.length
                                    )
                                  : window.innerWidth < 500 &&
                                    document.name.length > 15
                                  ? document.name.substr(0, 8) +
                                    "..." +
                                    document.name.substr(
                                      document.name.length - 4,
                                      document.name.length
                                    )
                                  : document.name.length > 20
                                  ? document.name.substr(0, 14) +
                                    "..." +
                                    document.name.substr(
                                      document.name.length - 4,
                                      document.name.length
                                    )
                                  : document.name}
                              </span>
                              <a
                                className="case-doc-download"
                                onClick={() =>
                                  this.viewOrDownloadAttachment(
                                    document,
                                    "view"
                                  )
                                }
                                style={{
                                  float: "right",
                                  marginRight: 10 + "px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              >
                                &#10094; View
                              </a>
                              <a
                                className="case-doc-download"
                                onClick={() =>
                                  this.viewOrDownloadAttachment(
                                    document,
                                    "download"
                                  )
                                }
                                style={{
                                  float: "right",
                                  marginRight: 10 + "px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              >
                                &#10094; Download
                              </a>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="scrollable-div">
                        <span href="" style={{ marginLeft: 21 + "px" }}>
                          No Medical records attached with this claim.
                        </span>
                      </div>
                    )}
                  </div>
                  <div label="Other Documents">
                    {claim.claim_documents.filter(
                      (document) =>
                        document.is_claim_cct_attachment_resource && document.name &&
                        (document.name.endsWith("pdf") ||
                          document.name.endsWith("jpeg") ||
                          document.name.endsWith("jpg") ||
                          document.name.endsWith("png") ||
                          document.name.endsWith("tiff"))
                    ).length != 0 ? (
                      <div className="scrollable-div">
                        {claim.claim_documents
                          .filter(
                            (document) =>
                              document.is_claim_cct_attachment_resource && document.name &&
                              (document.name.endsWith("pdf") ||
                                document.name.endsWith("jpeg") ||
                                document.name.endsWith("jpg") ||
                                document.name.endsWith("png") ||
                                document.name.endsWith("tiff"))
                          )
                          .map((document) => (
                            <div key={document.id}>
                              <span className="case-doc">
                                {" "}
                                {window.innerWidth < 400 &&
                                document.name.length > 10
                                  ? document.name.substr(0, 4) +
                                    "..." +
                                    document.name.substr(
                                      document.name.length - 4,
                                      document.name.length
                                    )
                                  : window.innerWidth < 500 &&
                                    document.name.length > 15
                                  ? document.name.substr(0, 8) +
                                    "..." +
                                    document.name.substr(
                                      document.name.length - 4,
                                      document.name.length
                                    )
                                  : document.name.length > 20
                                  ? document.name.substr(0, 14) +
                                    "..." +
                                    document.name.substr(
                                      document.name.length - 4,
                                      document.name.length
                                    )
                                  : document.name}
                              </span>
                              <a
                                className="case-doc-download"
                                onClick={() =>
                                  this.viewOrDownloadAttachment(
                                    document,
                                    "view"
                                  )
                                }
                                style={{
                                  float: "right",
                                  marginRight: 10 + "px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              >
                                &#10094; View
                              </a>
                              <a
                                className="case-doc-download"
                                onClick={() =>
                                  this.viewOrDownloadAttachment(
                                    document,
                                    "download"
                                  )
                                }
                                style={{
                                  float: "right",
                                  marginRight: 10 + "px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              >
                                &#10094; Download
                              </a>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <div className="scrollable-div">
                        <span href="" style={{ marginLeft: 21 + "px" }}>
                          No other documents attached with this claim.
                        </span>
                      </div>
                    )}
                  </div>
                  <div label="EOB">
                    {claim.EOBURL != null && (claim.StatusDetail.toLowerCase() == StatusTypes.closedWithPayment || claim.StatusDetail.toLowerCase() == StatusTypes.closedWithoutPaymentCovered || claim.StatusDetail.toLowerCase() == StatusTypes.closedWithoutPaymentDenied || claim.StatusDetail.toLowerCase() == StatusTypes.closedWithoutPaymentDuplicate || claim.StatusDetail.toLowerCase() == StatusTypes.reissuingCheck) ? (
                      <div>
                        <div className="d-none d-md-flex justify-content-start align-items-center">
                          <div
                            className="col-md-1 text-primary pr-0"
                            style={{ marginLeft: 25 + "px" }}
                          ></div>
                          <div className="col-md-5 pl-0">
                            Your claim process has been completed. Please check
                            your EOB.
                          </div>
                          <div className="col-md-5 p-0">
                            <a
                              href={claim.EOBURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="btn btn-outline-secondary">
                                {" "}
                                View Explanation of Benefits
                              </button>
                            </a>
                          </div>
                        </div>
                        <div
                          className="d-flex d-md-none flex-column"
                          style={{ padding: 15 + "px" }}
                        >
                          <div className="mt-2">
                            <a
                              href={claim.EOBURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <button className="btn btn-outline-secondary">
                                {" "}
                                View Explanation of Benefits
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-10 pl-5 ">EOB document not generated for this claim yet.</div>
                    )}
                  </div>
                </Tabs>
                <UncontrolledCollapse toggler={"toggle-" + claim.ClaimID}>
                  <ClaimStatusMessage
                    status={claim.StatusDetail}
                    claimID={claim.ClaimID}
                    claimNumber={claim.ClaimNumber}
                    petId={claim.PetID}
                    petName={claim.PetName}
                    receivedData={claim.received_claim_information}
                    missingData={claim.missing_claim_information}
                  />
                </UncontrolledCollapse>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-claims text-center">
            {this.props.searchKey
              ? "No matching records found."
              : "There is no claim data to show you right now."}
          </div>
        )}
        <Pagination
          previousPage={this.previousPage}
          nextPage={this.nextPage}
          currentPageNo={this.state.currentPageNo + 1}
          totalPages={this.totalPages}
        />
      </div>
    );
  }
}
