import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalDialog from "../../common/modal-dialog/ModalDialog";
import { Form } from "informed";
import EditText from "../../common/form-fields/edit-text/EditText";
import {AppLinks} from "../../../constants/app-links"
import ErrorView from "../../common/error-view/ErrorView";
import { setPetsDataRefresh } from "./../../../store/actions/pets-actions";
import { updatePetJoinedFamily } from "../../../api/pets-api";
import { getFormattedDate } from '../../common/util/util';
import MaskValidateUtil from "../../common/util/MaskValidateUtil";
import moment from "moment-timezone";
import "./UpdateDateJoinedFamilyModal.scss"

class UpdateDateJoinedFamilyModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      showError: false,
      showErrorWithContactUs: false
    };
  }

  errorString = "";

  updateDateJoinedFamily = values => {

    if(this.props.petDetails.BirthDate && values.dateJoined) {
      
      var userProvidedPetJoinedDate = moment(values.dateJoined,'MM/DD/YYYY');
      var petProtectedSinceDate = moment(this.props.petDetails.EnrollmentStartDate).format('MM/DD/YYYY');
      var petBirthDate = new Date(this.props.petDetails.BirthDate).toLocaleDateString('en-US');
             
      if(!(userProvidedPetJoinedDate.isBetween(petBirthDate,petProtectedSinceDate,'month','[]'))) {
        this.errorString = "This date should be after your pet was born and prior to the day their insurance policy went into effect.";
        this.setState({
            showError: false,
            showErrorWithContactUs: true,
            fetching: false
        });
        return;
      }
    }
  
      const petJoinedDate = userProvidedPetJoinedDate.startOf('month').format("MM/DD/YYYY");
      this.setState({
        fetching: true
      });
      updatePetJoinedFamily(
        this.props.petDetails.PetID,
        petJoinedDate
      )
        .then(response => {
          this.setState({
            showError: false,
            showErrorWithContactUs: false,
            fetching: false
          });
          this.props.toggleHandler();
          //Updates pets list in parent component
          let dateString = getFormattedDate(petJoinedDate)
          this.props.updateDateJoinedFamily(dateString);
          this.props.actions.setPetsDataRefresh();
        })
        .catch(error => {
          this.errorString = error.errorMessage ? error.errorMessage : "Unable to update date joined family. Please try later.";
          this.setState({
            showError: true,
            showErrorWithContactUs: false,
            fetching: false
          });
        });
   
  };

  render() {
    return (
      <ModalDialog
        isOpen={this.props.isOpen}
        toggle={this.props.toggleHandler}
        showLoader={this.state.fetching}
        modalHeader={<ModalHeader />}
        modalBody={
          <div className="body-container update-pet-joined-date">
            {/* TODO: Get proper error message. */}
            {this.state.showError && (
              <ErrorView>
                {this.errorString}
              </ErrorView>
            )}
            {this.state.showErrorWithContactUs && (
              <ErrorView>
                <span>{this.errorString}, If you have questions, please <Link to={AppLinks.others.contact}>Contact Us</Link>.</span>
              </ErrorView>
            )}
            <p>
              To more rapidly process your claim, please tell us approximately
              when{" "}
              {this.props.petDetails.Name !== "" ? (
                <span className="truncate" style={{ textTransform: "capitalize" }}>
                  <strong>{this.props.petDetails.Name}</strong>
                </span>
              ) : null} joined your family.
            </p>
            <Form onSubmit={values => this.updateDateJoinedFamily(values)}>
            {({ formState }) => (
                <React.Fragment>
                <EditText
                  required
                  className="pet-joined-date"
                  field="dateJoined"
                  type="text"
                  title="Date Pet Joined Your Family (MM/YYYY)"
                  floatingTitle={true}
                  mask={MaskValidateUtil.monthFullYearMask}
                  validate={MaskValidateUtil.validateDateJoinedFamily}
                  validateOnChange={formState.errors.dateJoined}
                  validateOnBlur
                />
                <div className="d-flex justify-content-start modal-buttons">
                  <button className="btn btn-secondary" type="submit">
                    Submit
                  </button>
                  <button
                    className="btn-skip"
                    onClick={() => this.props.toggleHandler()}
                  >
                    Skip Now
                  </button>
                </div>
              </React.Fragment>
            )}
            </Form>
          </div>
        }
      />
    );
  }
}

const ModalHeader = props => (
  <div className="title-container">
    <span className="modal-title">Additional Information Required</span>{" "}
    <span>(Optional)</span>
  </div>
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setPetsDataRefresh }, dispatch)
});

export default connect(
  null,
  mapDispatchToProps
)(UpdateDateJoinedFamilyModal);
