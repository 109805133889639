import * as actionTypes from "../../constants/action-types";

export const getClaimsByAccount = () => ({
  type: actionTypes.CLAIMS_FETCH_REQUEST
});


export const setClaimsDataRefresh = () => ({
  type: actionTypes.SET_CLAIMS_DATA_REFRESH
});
