import React, { Component } from "react";
import Loader from "./../../common/loader/Loader";
import { retryBilling } from "./../../../api/accounts-api";
import { setRetryBillingStatus } from "./../../../store/actions/accounts-actions";
import { getAlertsByAccount } from "../../../store/actions/alerts-actions";
import { RetryBillingErrorModal } from "./RetryBillingErrorModal";

import "./RetryBilling.css";

class RetryBilling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retryBilling: false,
      showRetryBillingErrorModal: false
    };
  }

  retryBillingHandler = () => {
    this.setState({ retryBilling: true, showRetryBillingErrorModal: false });
    retryBilling()
      .then(() => {
        //We have status for retry billing to prevent user from directly navingating to the page ( URL in addressbar)
        setRetryBillingStatus(true);
        getAlertsByAccount();

        setTimeout(function() {
          window.location='/RetryBillingSuccess';
        }, 3000);
      })
      .catch(err => {
        this.retryBillingErrorMessage = err.errorMessage;
        this.setState({
          showRetryBillingErrorModal: true,
          retryBilling: false
        });
      });
  };

  toggleRetryBillingErrorModal = () => {
    this.setState(prevState => ({
      showRetryBillingErrorModal: !prevState.showRetryBillingErrorModal
    }));
  };

  render() {
    return (
      <React.Fragment>
        {this.state.retryBilling && (
          <Loader position="absolute" fullPage={true} zIndex="100" />
        )}
        <RetryBillingErrorModal
          retryBillingHandler={this.retryBillingHandler}
          showRetryBillingErrorModal={this.state.showRetryBillingErrorModal}
          toggleRetryBillingErrorModal={this.toggleRetryBillingErrorModal}
          errorMessage={this.retryBillingErrorMessage}
        />
      </React.Fragment>
    );
  }
}

export default RetryBilling;
