export default class BreedList {
    static selectYourBreed = "What breed is your pet?";
    static breedArray = [{
        displayName: BreedList.selectYourBreed,
        value: BreedList.selectYourBreed
    }
    ];

    static getBreedList = () => {
        return BreedList.breedArray;
    }
}