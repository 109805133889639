import "./Radio.css";
import React from "react";
import { Radio  } from "informed";

export default class RadioButton extends React.Component {
    render() {
      return (
        <label className={"radio_container " + this.props.className}>
          <Radio className="radio-button" value={this.props.value} />
          <span className="checkmark"></span>
          {this.props.title}
        </label>   
      );
    }
  }

  