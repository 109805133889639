import React from "react";

const getGeneralECTC = () => {
    let content = (
        <div id="legalContents" className="w-100 mb-3 textarea-border" style={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 5 }}>
            <h3 class="TandC-sectionHeader">Consent to Electronic Transactions</h3>
            <p>
                Healthy Paws, a Chubb Company, is a collection of U.S.-based Chubb company affiliates (collectively, "Healthy Paws",
                "Chubb", "we", "us", or "our"). To streamline how you do business with us, we are providing you with the option of
                receiving certain documents related to any policy you may have with us electronically by posting them to our secure
                website, the Healthy Paws Internet Customer Center ("Customer Center"), or we may send them via text or e-mail. We
                are also providing you with the option to receive payments related to any policy you may have with us electronically.
            </p>
            <br />
            <p>
                This consent electronically through email to your email address on record or via posting to your Customer Center.
            </p>
            <br />
            <p>
                By clicking "I agree", you agree and consent to receiving electronically all documents that we are required to provide
                in writing in connection with your pet health insurance transactions, including but not limited to any application for
                insurance made or insurance policy issued through the Customer Center and to use your electronic signature in conjunction
                with such insurance transactions. This will not preclude you from receiving physical mail when required by law.
            </p>
            <br />
            <p>
                You consent that all your pet insurance documentation will be delivered by electronic means, if permitted by your state.
                These documents may include, but not limited to, your insurance policy documents, forms, and endorsements; policyholder
                notices; notices of cancellation and non-renewal; signature pages; premium increase or conditional renewal notices;
                applications; alerts and communications relating to your policy; invoices; claims acknowledgements; claims notices,
                including explanation of benefits, proof of loss, claims documentation, releases, authorizations, affidavits,
                disclosures and all other communication and documents related to an insurance claim, to the extent permitted by law.
            </p>
            <br />
            <p>
                This consent for electronic delivery is effective immediately and will remain in effect until you withdraw it.
                Importantly, if you choose to access your documents electronically, this does not affect the validity, legal effect,
                or enforceability of such insurance related documents.
            </p>
            <br />
            <p>
                While we reserve the right to modify the terms of this consent, we will not do so without first providing you with
                notice of any changes. The modified terms will apply to you and will be binding unless you withdraw your consent to
                electronic delivery.
            </p>
            <br />
            <p>
                We may also use your Electronic Signature for documents that require your legal signature. You consent that your
                Electronic Signature has the same legal effect as if you affixed your signature by hand and will be deemed valid,
                authenticated and binding. For purpose of this consent, "Electronic Signature" means an electronic sound, symbol or
                process, attached to or logically associated with a contract or other record and executed or adopted by a person with
                the intent to be bound by such contract or record.
            </p>
            <br />
            <p>
                We may make electronic documents available to you by posting them to the Customer Center at
                <a style={{ fontSize: 12 }} href="https://myaccount.healthypawspetinsurance.com/" target="_blank" title="https://myaccount.healthypawspetinsurance.com/"><span> https://myaccount.healthypawspetinsurance.com/ </span></a>,
                or we may send them via text or   e-mail whether as text in, attachment to, and/or hyperlinks embedded within such messages.
                Such messages will be sent to the current e-mail address or cellular telephone number that you provided to us. For text
                messages, standard message and data rates may apply.
            </p>
            <br />
            <p>
                In order to obtain, download and view all electronic documentation you must have the following hardware or software
                system requirements in place.
            </p>
            <br />
            <ul class="TandC-ul">
                <li>An electronic device capable of receiving, accessing and displaying or printing or storing communications
                    received from us in an electronic form. </li>
                <li>Adobe PDF Reader v11.0</li>
                <li>System Requirements:
                    <br /><br />
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ padding: 8, borderWidth: 1, borderStyle: "solid", borderColor: "black" }}>-OS: Windows 7 or higher<br />
                                    Internet Explorer v11 or higher<br />
                                    Firefox v45.7 or higher<br />
                                    Chrome v40 or higher</td>
                                <td>&nbsp;</td>
                                <td style={{ padding: 8, borderWidth: 1, borderStyle: "solid", borderColor: "black" }}>-OS: Mac OS X 10.9 or higher<br />
                                    Safari 9.0 or higher<br />
                                    Firefox v45.7 or higher<br />
                                    Chrome v40 or higher</td>
                            </tr>
                        </tbody>
                    </table>
                </li>
            </ul>
            <br />
            <p>
                By clicking "I agree", you affirm that your computer system meets the hardware and software requirements for receiving
                policies, endorsements and other related documents.
            </p>
            <br />
            <p>
                It is your responsibility to download and store records and all documents made available to you electronically. We may
                at our sole discretion discontinue availability of the Customer Center at any time, without further notice to you.
            </p>
            <br />
            <p>
                You agree to notify us promptly if your mailing address, e-mail address, cellular telephone number or other delivery
                information changes by contacting <a style={{ fontSize: 12 }} href="mailto:help@healthypawspetinsurance.com"><span> help@healthypawspetinsurance.com </span></a>
                or via our toll-free number at 1-855-898-8991. We
                will notify you in the event of any changes regarding hardware or software requirements necessary to receive documents
                and other related documents electronically. However, it is your duty to notify us if you are unable to access the
                documentation made electronically available to you.
            </p>
            <br />
            <p>
                While you can choose to print or save any of your electronic insurance documents, you may at no additional charge at
                any time request a print copy of your documents. You may withdraw your consent to electronic transactions at any time
                by sending a request in writing to <a style={{ fontSize: 12 }} href="mailto:help@healthypawspetinsurance.com"><span> help@healthypawspetinsurance.com </span></a>
                or via our toll-free number at 1-855-898-8991. All
                other requests, notices and other communications from you under this consent must be made to us in writing by contacting
                <a style={{ fontSize: 12 }} href="mailto:help@healthypawspetinsurance.com"><span> help@healthypawspetinsurance.com </span></a> or via our toll-free
                number at 1-855-898-8991. You have the option to receive all covered claim payments as an electronic payment via automated
                clearing house (direct) deposit into your checking or savings account. We will not impose any fees on you for choosing to
                accept your payments electronically, but your financial institution may impose a fee or charge. By checking the "I agree",
                you are accepting this offer and consenting to accept your claim payments electronically. Agreeing to this method of
                receiving your claim payments is voluntary. Your payments received through electronic transfer may be subject to attachment
                or garnishment if your account is subject to the same.
            </p>
            <br />
            <p>
                You may change your payment account at any time by notifying us at
                <a style={{ fontSize: 12 }} href="mailto:help@healthypawspetinsurance.com"><span> help@healthypawspetinsurance.com </span></a>
                or via our toll-free number at 1-855-898-8991.
            </p>
            <br />
            <p>
                Selecting "I agree" constitutes your electronic signature.
            </p>
            <br />
            <p>
                By selecting the "I agree" box I confirm that:
                <br /><br />
            </p>
            <ul class="TandC-ul">
                <li>I have the requisite authority to provide such consent.</li>
                <li>I have read and consent to these terms and conditions.</li>
                <li>I have access including the ability to print and save documents for future reference.</li>
                <li>I agree to receiving documents electronically including all notices, alerts, and communications.</li>
                <li>I AGREE TO RECEIVE ALL MAILINGS AND COMMUNICATIONS ELECTRONICALLY.  SUCH ELECTRONIC MAILING OR
                    COMMUNICATIONS MAY, TO THE EXTENT PERMITTED BY LAW, EVEN INCLUDE CANCELLATION OR NONRENEWAL NOTICES.</li>
            </ul>


            <br />
            <h3 id="conditions-for-use">Conditions for Use</h3>
            <p>Welcome to HealthyPawsPetInsurance.com. Healthy Paws, a Chubb company, and/or one or more of its affiliates within the Chubb group (the "Owner", "we", "us", "our" or "Healthy Paws") maintains this web site (the "Site") for your personal education, information, and communication. Chubb is composed of insurance and reinsurance companies that are licensed or otherwise approved to transact insurance and/or reinsurance business in various countries and jurisdictions around the world. Chubb also includes companies that provide services to the insurance industry and other customers.</p>
            <br />
            <p>You may download only material displayed on the Site specifically identified as available for downloading. Such material is for noncommercial, personal use only provided you also retain all copyright and other proprietary notices contained on the materials. You may not, however, distribute, modify, transmit, reuse, repost or use the content of the Site for public or commercial purposes, including the text, images, audio and video without Healthy Paws' written permission.</p>
            <br />
            <p>Your access to and use of the Site is also subject to the following terms and conditions ("Terms and Conditions") and all applicable laws. By accessing and browsing the Site, you accept, without limitation or qualification, the Terms and Conditions and acknowledge that any other agreements between you and Healthy Paws regarding this site are superseded and of no force or effect.  These Terms and Conditions may be changed, modified, supplemented or updated by the Owner from time to time without advance notice. Unless otherwise indicated, any new products and services, content and software added to this Web Site will also be subject to these Terms and Conditions effective upon the date of any such addition. You are encouraged to review the Web Site periodically for updates and changes.</p>
            <br /><br />
            <h3>
                <span style={{}}>TERMS AND CONDITIONS</span>
            </h3>
            <p>You should assume that everything you see or read on the Site is copyrighted unless otherwise noted and may not be used except as provided in these Terms and Conditions or in the text on the Site without the written permission of Healthy Paws. Healthy Paws neither warrants nor represents that your use of materials displayed on the Site will not infringe rights of third parties not owned by or affiliated with Healthy Paws.</p>
            <br />
            <ol style={{ marginLeft: "40px" }} className="blackdisc">
                <li style={{ listStyleType: "decimal", marginBottom: "15px" }}>
                    While Healthy Paws uses reasonable efforts to include accurate and up-to-date information in the Site, Healthy Paws makes no warranties or representations as to its accuracy. Healthy Paws assumes no liability or responsibility for any errors or omissions in the content of the Site.
                </li>
                <li style={{ listStyleType: "decimal", marginBottom: "15px" }}>
                    Your use of and browsing in the Site are at your risk. Neither Healthy Paws nor any other party involved in creating, producing or delivering the Site is liable for any direct, incidental, consequential, indirect or punitive damages arising out of your access to, or use of, the Site. Without limiting the foregoing, everything on the Site is provided to you "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. Please note that some jurisdictions may not allow the exclusion of implied warranties, so some of the above exclusions may not apply to you. Check your local laws for any restrictions or limitations regarding the exclusion of implied warranties. Healthy Paws also assumes no responsibility, and shall not be liable for, any damages to, or viruses that may infect, your computer equipment or other property on account of your access to, use of, or browsing in the Site or your downloading of any materials, data, text, images, video or audio from the Site.
                </li>
                <li style={{ listStyleType: "decimal", marginBottom: "15px" }}>
                    Any communication or material you transmit or post to the Site by electronic mail or otherwise, including any data, questions, comments, suggestions, stories, homepages, or the like is, and will be treated as, non-confidential and non-proprietary. Healthy Paws, or its affiliates, at its sole discretion, and without compensation to you, may use, not use, edit, adapt, modify or discontinue the use of any communication or material you post to the site for any purpose whatsoever including, but not limited to, disclosure, transmission, reproduction, distribution, publishing, broadcasting, or posting in any medium. Furthermore, Healthy Paws, or its affiliates, without compensation to you, is free to use, not use, edit, adapt, modify, or discontinue the use of any ideas, concepts, know-how, or techniques contained in any communication or material you transmit or post to the Site for any purpose whatsoever including, but not limited to, developing, manufacturing and marketing products.
                </li>
                <li style={{ listStyleType: "decimal", marginBottom: "15px" }}>
                    Images of people, animals, or places displayed on the Site are either the property of, or used with permission by, Healthy Paws. The use of these images by you, or anyone else authorized by you, is prohibited unless specifically permitted by these Terms and Conditions or specific permission provided elsewhere on the Site. Any unauthorized use of the images may violate copyright laws, trademark laws, the laws of privacy and publicity and communications regulations and statutes.
                </li>
                <li style={{ listStyleType: "decimal", marginBottom: "15px" }}>
                    The trademarks, logos and service marks (collectively the "Trademarks") displayed on the Site are registered and unregistered Trademarks of Healthy Paws and others. Nothing contained on the Site should be construed as granting, by implication, estoppel or otherwise, any license or right to use any Trademark displayed on the Site without the written permission of the Trademark owner. Your misuse of the Trademarks displayed on the Site, or any other content on the Site, except as provided in these Terms and Conditions, is strictly prohibited.
                </li>
                <li style={{ listStyleType: "decimal", marginBottom: "15px" }}>
                    Healthy Paws has not reviewed all the sites linked to the Site and is not responsible for the content of any other sites linked to the Site. Your linking to any other off-site pages or other sites is at your own risk.
                </li>
                <li style={{ listStyleType: "decimal", marginBottom: "15px" }}>
                    Although Healthy Paws may from time to time monitor or review discussions, chats, postings, transmissions, bulletin boards, and the like on the Site, Healthy Paws is under no obligation to do so and assumes no responsibility or liability arising from the content of any such locations nor for any error, defamation, libel, slander, omission, falsehood, obscenity, pornography, profanity, danger or inaccuracy contained in any information within such locations on the Site. You are prohibited from posting or transmitting any unlawful, threatening, libelous, defamatory, obscene, scandalous, inflammatory, pornographic or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability or otherwise violate any law. Healthy Paws will fully cooperate with any law enforcement authorities or court order requesting or directing Healthy Paws to disclose the identity of anyone posting any such information or materials.
                </li>
                <li style={{ listStyleType: "decimal", marginBottom: "15px" }}>
                    This Site may from time to time contain information relating to various medical, health and fitness conditions of pets and their treatment. This is for informational purposes only and is not meant to be a substitute for the advice provided by your own veterinarian or other medical professional. You should not use the information contained herein for diagnosing a pet's health or fitness problem or disease. You should always consult your own veterinarian and medical advisors.
                </li>
                <li style={{ listStyleType: "decimal", marginBottom: "15px" }}>
                    Healthy Paws may at any time revise these Terms and Conditions by updating this posting. You are bound by any such revisions and should therefore periodically visit this page to review the then current Terms and Conditions to which you are bound.
                </li>
            </ol>
            <br /><br />
            <h3>
                <span style={{}}>Limitations of Liability</span>
            </h3>
            <p>YOU UNDERSTAND AND AGREE THAT THE OWNER WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PRODUCTS, USE, DATA OR OTHER INTANGIBLE LOSSES, EVEN IF THE OWNER HAS BEEN ADVISED OF THE POSSIBILITIES OF THOSE DAMAGES, RESULTING FROM YOUR USE OF OR INABILITY TO USE THE WEB SITE, PRODUCTS AND SERVICES, CONTENT OR SOFTWARE, OR ANY OTHER MATTER RELATED TO THE WEB SITE, PRODUCTS AND SERVICES, CONTENT OR SOFTWARE. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEB SITE IS PREDICATED UPON YOUR WAIVER OF ANY RIGHT TO SUE THE OWNER OR ITS AFFILIATES DIRECTLY OR TO PARTICIPATE IN A CLASS ACTION SUIT FOR ANY LOSSES OR DAMAGES RESULTING FROM YOUR USE OF THE WEB SITE.</p>
            <br />
            <p>Certain state laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations may not apply to you, and you might have additional rights.</p>
            <br /><br />
            <h3>
                <span style={{}}>Indemnification</span>
            </h3>
            <p>You agree to indemnify, defend and hold harmless the Owner, its affiliates and their officers, directors, employees, contractors, agents, licensors, service providers, subcontractors and suppliers from and against any and all losses, liabilities, expenses, damages and costs, including reasonable attorneys' fees and court costs, arising or resulting from your use of the Web Site or any violation of these Terms and Conditions. If you cause a technical disruption of the Web Site or the systems transmitting the site to you or others, you agree to be responsible for any and all losses, liabilities, expenses, damages and costs, including reasonable attorneys' fees and court costs, arising or resulting from that disruption. The Owner reserves the right, at its own expense, to assume exclusive defense and control of any matter otherwise subject to indemnification by you and, in such case, you agree to cooperate with the Owner in the defense of such matter.</p>
            <br /><br />
            <h3>
                <span style={{}}>Recurring Payment</span>
            </h3>
            <p>By clicking the Get Protected button, you authorize Healthy Paws to charge your credit card for the agreed upon amount . You understand the charge will recur monthly on the same date the policy became effective. For Pet Parents that enroll on the 28th to 31st of the month, the billing date defaults to the 28th for consistency due to the typical number of days in February. You understand that in order to cancel coverage, you need to notify Healthy Paws via regular mail, fax, email or verbally where permitted by your policy. We will return unearned premium on a pro rata basis. You understand this insurance policy constitutes a legally binding contract. Finally, you understand that policy amounts may be subject to change and if so, you will be notified via the email address you have provided.</p>
            <p>Healthy Paws, a Chubb Company, is the marketing name used to refer to the pet insurance products underwritten and provided by ACE American Insurance Company, Westchester Fire Insurance Company, Indemnity Insurance Company of North America, ACE Property and Casualty Insurance Company and Atlantic Employers Insurance Company and one or more of their U.S. based Chubb underwriting company affiliates. Chubb is the marketing name used to refer to subsidiaries of Chubb Limited providing insurance and related services. For a list of these subsidiaries, please visit our website at www.chubb.com.  This communication contains product summaries only. Coverage is subject to the language of the policies as actually issued. These pet insurance products are offered through Chubb Insurance Solutions Agency, Inc. (CISA) (California license no. 0D12120). Surplus lines insurance sold only through licensed surplus lines producers. Chubb, 202 Hall's Mill Road, Whitehouse Station, NJ 08889-1600.</p>
        </div>
    );

    return content;
}

const CATermsConditions = () => {
    //TODO: ACCEPT STATE AS PARAMETER, DIFFERENT EC/TC  FOR CA STATE ETC
    //if(state in ['CA'])
    //return californiaECTC, etc
    //else
    //return generalECTC
    let content = getGeneralECTC();
    return content; 
                       
}

export default CATermsConditions;