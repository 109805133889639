import React, { Component } from "react";
import "./PetForm.component.css";

import { Link } from "react-router-dom";
import { Form, RadioGroup } from "informed";
import { UncontrolledCollapse } from "reactstrap";
import ErrorView from "../../common/error-view/ErrorView";
import EditText from "../../common/form-fields/edit-text/EditText";
import Radio from "../../common/form-fields/radio/Radio";
import SegmentButton from "../../common/form-fields/segment-button/SegmentButton";
import BreedList from "../../common/data/BreedList";
import MonthList from "../../common/data/MonthList";
import YearList from "../../common/data/YearList";
import DropDownSelect from "../../common/form-fields/drop-down/DropDownSelect";
import BreedSearchDropdown from "./BreedSearchDropdown";
import ModalDialogML from "../../common/modal-dialog-ml/ModalDialogML";
import { getPolicyOptions } from "../../../api/pets-api";
import Loader from "../../common/loader/Loader";
import { AppLinks } from "../../../constants/app-links";

import FaqWidget from "../../common/faq/FaqWidget";
import { addAPetFAQLink } from "../../common/faq/FaqLink";

class PetForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      validationErrors: {}, 
      petAge: 0 || this.ageCalc(this.props.initialValues.birthMonth, this.props.initialValues.birthYear),
      submitLoader: false,
      submitError: false
  }
   
    this.submitErrorMessage = "";
    this.modelLaw = this.props.userAccountDetails.ModelLawInEffect;
  }

  componentDidMount() {
    //this.props.setNewRoute("/AddPet/Step1");
  }

  petAgeinWeeks = 0;

  toggleDialog = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  radioVetExam = val => {
    if (val === "no") this.toggleDialog();
  };

  formDirtyCheck = (formValues) => {
    if(Object.keys(formValues).length) {
      this.props.setFormDirty();
    }
  }

  ageCalc(month, year) {
    if (month && year) {
      let birthDate = new Date(year, month, 1);
      let now = new Date();
      if (birthDate) {
        birthDate = new Date(birthDate.getFullYear(), birthDate.getMonth() + 1, birthDate.getDate() - 1);
      }
      if (birthDate < now) {
        let timeInMilliSec = Math.abs(now.getTime() - birthDate.getTime());
        let days = Math.ceil(timeInMilliSec / (1000 * 3600 * 24));
        let age = Math.floor(days / 365.4);
        this.petAgeinWeeks = Math.ceil(days / 7);
        return age;
      }
      else {
        this.petAgeinWeeks = -1;
        return 0;
      }
    }

  }

  setAge = (month, year) =>{
    this.setState({petAge: this.ageCalc(month, year)});
  }


  validateName = name => {
    if (!name) return "Enter a valid pet name.";

    name.trim();
    if (
      name.length === 0 ||
      name.includes("'") ||
      name.includes("%") ||
      name.includes('"') ||
      name.includes(";") ||
      name.includes("\\") ||
      name.includes("--") ||
      name.includes("*")
    ) {
      return "Enter a valid pet name.";
    }
    return null;
  };

  addPetFormValidator = form => {
    let err = {};

    if (this.petAgeinWeeks < 8) {
      err["futureBirth"] =
        "For your pet to be eligible for coverage it should be at least 8 weeks old. Please check the age of the pet you are attempting to enroll.";
    }
    if (this.state.petAge >= 14) {
      err["over14"] =
        "We provide lifetime pet health coverage for pets enrolled before the age of 14. Unfortunately, your pet's age does not meet our age requirement to qualify for enrollment.";
    }
    if (this.state.petAge > 4 && this.props.physicalState === "NY") {
      err["noQuote"] =
        "After reviewing your information, we are not in a position to provide you with a quote.";
    }
    
    if (Object.keys(err).length !== 0) {
      this.setState({ validationErrors: err });
      window.scrollTo(0, 0);
    } else {
      this.setState({ submitLoader: true, submitError: false });
      getPolicyOptions(form)
        .then(response => {
          this.setState({ submitLoader: false, validationErrors: {} });
          if(response.policies.length) {
            this.props.setDetails(form, response, this.state.petAge);
            this.props.stepper.nextStep();
          }
          else {
            this.setState({
              submitLoader: false,
              validationErrors: {},
              submitError: true
            });
            window.scrollTo(0, 0);
          }
        })
        .catch(error => {
          this.submitErrorMessage = error.errorMessage
            ? error.errorMessage
            : "";
          this.setState({
            submitLoader: false,
            validationErrors: {},
            submitError: true
          });
          window.scrollTo(0, 0);
        });
    }
  };

  resetErrors = errKeys => {
    errKeys.length && this.setState({ validationErrors: {} });
  };

  render() {
    const vetExamOptions = [
      {
        label: "Yes",
        value: "yes"
      },
      {
        label: "No",
        value: "no"
      }
    ];

    const petTypeOptions = [
      {
        label: "Dog",
        value: "dog",
        activeimage: "../../assets/images/dog_active.svg",
        image: "../../assets/images/dog.svg"
      },
      {
        label: "Cat",
        value: "cat",
        activeimage: "../../assets/images/cat_active.svg",
        image: "../../assets/images/cat.svg"
      }
    ];

    const petGenderOptions = [
      {
        label: "Boy",
        value: "Male"
      },
      {
        label: "Girl",
        value: "Female"
      }
    ];

    const errKeys = Object.keys(this.state.validationErrors);

    return (
      <div className="container pet-form">
        <h1 className="text-primary mb-3 mb-lg-0">
          1. Tell us about the pet you are adding
        </h1>
        {this.state.submitError && (
          <ErrorView>
            {this.submitErrorMessage ? (
              this.submitErrorMessage
            ) : (
              <span>
                Could not proceed to the next step! Please try again or{" "}
                <Link to={AppLinks.others.contact}>click here</Link> to contact
                us..
              </span>
            )}
          </ErrorView>
        )}
        {errKeys.length > 0 && this.state.validationErrors.futureBirth && (
          <ErrorView> {this.state.validationErrors.futureBirth} </ErrorView>
        )}
        {errKeys.length > 0 && this.state.validationErrors.over14 && (
          <ErrorView> {this.state.validationErrors.over14} </ErrorView>
        )}
        {errKeys.length > 0 && this.state.validationErrors.noQuote && (
          <p className="noQuoteMessage">{this.state.validationErrors.noQuote}</p>
        )}
        <div className="row">
          <div className="col-lg-8 d-flex flex-column flex-fill">
            <p className="order-md-1 p-0 col-md-8 text-md-center">
              You can add one pet at a time.
            </p>
            <Form className="order-md-0"
              onSubmit={(formState) => this.addPetFormValidator(formState)}
              onFocus={() => this.resetErrors(errKeys)}
              initialValues={this.props.initialValues}
              onChange={(formState) => this.formDirtyCheck(formState.values)}
            >
              {({ formState, formApi }) => {
                return <React.Fragment>
                  <EditText
                    required
                    className="mb-3 p-0 col-md-8"
                    id="pet_name"
                    field="petName"
                    type="text"
                    title="Enter Your Pet's Name"
                    floatingTitle={true}
                    validate={this.validateName}
                    validateOnBlur
                    validateOnChange={formState.errors.petName}
                  />
                  <p
                    className={
                      errKeys.length > 0 && this.state.validationErrors.name
                        ? "text-danger error"
                        : "d-none"
                    }
                  >
                    {" "}
                    {this.state.validationErrors.name}{" "}
                  </p>
                  <h4 className="mb-3">Is your pet a dog or cat?</h4>
                  <SegmentButton
                    required
                    id="pet-type-segment-control"
                    field="petType"
                    className="d-md-none mb-3"
                    data={petTypeOptions}
                  />
                  <div className="d-none d-md-flex flex-row mb-3">
                    <RadioGroup
                      required
                      field="petType"
                    >
                      <Radio value="dog" title="Dog" id="radio-dog" />
                      <Radio value="cat" title="Cat" id="radio-cat" />
                    </RadioGroup>
                  </div>
                  <p
                    className={
                      errKeys.length > 0 && this.state.validationErrors.petType
                        ? "text-danger error"
                        : "d-none"
                    }
                  >
                    {" "}
                    {this.state.validationErrors.petType}{" "}
                  </p>
                  <h4 className="mb-3">Boy or girl?</h4>
                  <SegmentButton
                    required
                    id="pet-gender-segment-control"
                    field="petGender"
                    className="d-md-none mb-3"
                    data={petGenderOptions}
                  />
                  <div className="d-none d-md-flex flex-row mb-3">
                    <RadioGroup required field="petGender">
                      <Radio value="Male" title="Boy" id="radio-boy" />
                      <Radio value="Female" title="Girl" id="radio-girl" />
                    </RadioGroup>
                  </div>
                  <BreedSearchDropdown
                    selectedBreedId={this.props.initialValues.petType !== formState.values.petType ? null : this.props.initialValues.breed}
                    // data={this.props.allBreeds}
                    disabled={formState.values.petType ? false : true}
                    source={
                      formState.values.petType === "dog" &&
                      this.props.dogs.length > 0
                        ? this.props.dogs
                        : formState.values.petType === "cat" &&
                          this.props.cats.length > 0
                        ? this.props.cats
                        : []
                    }
                    petType={formState.values.petType}
                  />
                  {/* <DropDownSelect
                                        required
                                        field="breed"
                                        className="mb-3"
                                        id="select_breed"
                                        data={formState.values.petType === "dog" && this.props.dogs.length > 0 ? this.props.dogs : formState.values.petType === "cat" && this.props.cats.length > 0 ? this.props.cats : BreedList.breedArray}
                                    /> */}
                  <p
                    className={
                      errKeys.length > 0 && this.state.validationErrors.breed
                        ? "text-danger error"
                        : "d-none"
                    }
                  >
                    {" "}
                    {this.state.validationErrors.breed}{" "}
                  </p>
                  <div className="row">
                    <div className="col-md-4  mb-4">
                      <DropDownSelect
                        required
                        field="birthMonth"
                        className=""
                        id="select_birth_month"
                        data={MonthList.getMonthList("Pet's birth month?")}
                        onValueChange={val =>
                          {
                            formApi.getValue("vetExam") && formApi.setValue("vetExam", null);
                            this.setAge(val, formState.values.birthYear);
                          }
                        }
                      />
                      <p
                        className={
                          errKeys.length > 0 &&
                          this.state.validationErrors.birthMonth
                            ? "text-danger error mt-2"
                            : "d-none"
                        }
                      >
                        {" "}
                        {this.state.validationErrors.birthMonth}{" "}
                      </p>
                    </div>
                    <div className="col-md-4 pr-md-1  mb-3">
                      <DropDownSelect
                        required
                        field="birthYear"
                        className=""
                        id="select_birth_year"
                        data={YearList.getYearList(
                          new Date().getFullYear() - 14,
                          new Date().getFullYear(),
                          "Pet's birth year?"
                        )}
                        onValueChange={val =>
                          {
                            formApi.getValue("vetExam") && formApi.setValue("vetExam", null);
                            this.setAge(formState.values.birthMonth, val);
                          }
                        }
                      />
                      <p
                        className={
                          errKeys.length > 0 &&
                          this.state.validationErrors.birthYear
                            ? "text-danger error mt-2"
                            : "d-none"
                        }
                      >
                        {" "}
                        {this.state.validationErrors.birthYear}{" "}
                      </p>
                    </div>
                    <div className="col-md-4 d-md-flex align-items-md-center pl-md-1">
                      <p>(best guess is ok)</p>
                    </div>
                    {this.petAgeinWeeks === -1 && (
                      <p className="text-danger error ml-3">
                        Please double check the age of your pet.
                      </p>
                    )}
                  </div>
                  {(this.state.petAge > 0 || this.petAgeinWeeks > 0) && (
                    <React.Fragment>
                      <div className="d-flex">
                        <h4 className="mb-3 order-md-0">
                          <span style={{ fontWeight: "normal" }}>
                            Last Vet Exam within
                          </span>{" "}
                          {(this.state.petAge > 0 || this.petAgeinWeeks > 0) &&
                          this.state.petAge < 6
                            ? "12 months?"
                            : this.state.petAge >= 6
                            ? "30 days?"
                            : null}
                        </h4>
                        <div
                          className="why-important text-secondary font-weight-bold mb-3 mb-0 ml-1 d-none d-md-inline"
                          onClick={this.toggleDialog}
                          style={{ cursor: "pointer" }}
                        >
                          <i className="mr-1 ml-1 fa fa-question-circle" />
                          Why is this important?
                        </div>
                      </div>

                      <div className="w-100 d-none order-md-2 d-md-block" />
                      <SegmentButton
                        required
                        id="vet-exam-segment-control"
                        field="vetExam"
                        className="d-md-none mb-3 order-md-4"
                        data={vetExamOptions}
                      />
                      <div className="d-none d-md-flex flex-row mb-3 order-md-3">
                        <RadioGroup
                          required
                          field="vetExam"
                          onValueChange={val => this.radioVetExam(val)}
                        >
                          <Radio value="yes" title="Yes" id="radio-yes" />
                          <Radio value="no" title="No" id="radio-no" />
                        </RadioGroup>
                      </div>
                      <p
                        className={
                          errKeys.length > 0 &&
                          this.state.validationErrors.vetExam
                            ? "text-danger error mt-2"
                            : "d-none"
                        }
                      >
                        {" "}
                        {this.state.validationErrors.vetExam}{" "}
                      </p>

                      <div
                        className="why-important text-secondary font-weight-bold mb-3 d-md-none"
                        id="vet-exam-toggler"
                      >
                        Why is this important?
                        <i className="d-md-none ml-1 fa fa-angle-down" />
                      </div>
                      <UncontrolledCollapse
                        className="d-md-none"
                        toggler="vet-exam-toggler"
                      >
                        <ul style={{ fontSize: "12px" }}>
                          <li>
                            {" "}
                            All pets { this.modelLaw == true ? "should" : "must" } have undergone a complete <span style={{ fontWeight: "bold" }}>clinical examination</span> to speed up processing your claims. Your pet's age at enrollment determines when your pet { this.modelLaw  == true ? "should" : "must" } have the exam.
                          </li>
                          {" "}
                               <span style={{ fontWeight: "bold" }}>Pets {((this.state.petAge > 0 || this.petAgeinWeeks > 0) && this.state.petAge < 6 )? "5 years old and younger": this.state.petAge >= 6 ? "6 years old and older":null} </span>
                          {" "}
                          <li>Exam taken place {((this.state.petAge > 0 || this.petAgeinWeeks > 0) && this.state.petAge < 6 )? "12 months": this.state.petAge >= 6 ? "30 days":null} before the pet policy effective date <span style={{ fontWeight: "bold" }}>or</span></li>
                          <li>Within 15 days after the pet policy effective date</li>
                        </ul>
                      </UncontrolledCollapse>
                      <ModalDialogML
                        isOpen={this.state.showModal}
                        toggle={this.toggleDialog}
                        modalHeader={"Clinical Exam for Enrollment"}
                        modalBody={
                          <div className="d-flex flex-column modalBoxContent">
                             <p>
                               {" "}
                               All pets { this.modelLaw == true ? "should" : "must" } have undergone a complete <span style={{ fontWeight: "bold" }}>clinical examination</span> to speed up processing your claims. Your pet's age at enrollment determines when your pet { this.modelLaw  == true ? "should" : "must" } have the exam.
                               </p>
                               <p style={{ display: 'inline-block',verticalAlign:'top',marginRight:'5px' }}>
                               {" "}
                               <span style={{ fontWeight: "bold" }}>Pets {((this.state.petAge > 0 || this.petAgeinWeeks > 0) && this.state.petAge < 6 )? "5 years old and younger": this.state.petAge >= 6 ? "6 years old and older":null} </span>
                              
                               <ul style={{margin:'16px',padding:'0'}}>
                                <li>Exam taken place {((this.state.petAge > 0 || this.petAgeinWeeks > 0) && this.state.petAge < 6 )? "12 months": this.state.petAge >= 6 ? "30 days":null} before the pet policy effective date <span style={{ fontWeight: "bold" }}>or</span></li>
                                <li>Within 15 days after the pet policy effective date</li>
                               </ul>
                               </p>
                          </div>
                        }
                        modalFooter={
                          <button
                            className="button-primary-plus close-button"
                            style={{borderRadius:'100px',height:'48px'}}
                            type="submit"
                            onClick={this.toggleDialog}
                          >
                            I understand »
                          </button>
                        }
                      />
                    </React.Fragment>
                  )}

                  <div className="mb-3 sticky-bottom">
                    <div className="col-md-8 p-0">
                      <div className="d-md-none text-center mb-1 text-uppercase">
                        <span className="font-weight-bold">Step 1</span> of 3
                      </div>
                      <button
                        className="button-primary"
                        type="submit"
                        disabled={
                          formState.invalid ||
                          Object.keys(formState.values).length < 7 ||
                          (formState.values.breed &&
                            formState.values.breed ===
                              BreedList.selectYourBreed)
                        }
                      >
                        See My Rates
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              }}
            </Form>
          </div>

        </div>
        {this.state.submitLoader && (
          <Loader position="absolute" fullPage={true} />
        )}
      </div>
    );
  }
}

export default PetForm;