import React from "react";
import ModalDialog from "./../../common/modal-dialog/ModalDialog";
import { Link } from "react-router-dom";

export const RetryBillingErrorModal = ({
  showRetryBillingErrorModal,
  toggleRetryBillingErrorModal,
  retryBillingHandler
}) => {
  return (
    <ModalDialog
      isOpen={showRetryBillingErrorModal}
      toggle={toggleRetryBillingErrorModal}
      modalHeader={"Retry billing was unsuccessful."}
      modalBody={
        <div className="d-flex flex-column">
          We're sorry, there was a problem completing your request. Please try
          again or update your billing information with a new card
        </div>
      }
      modalFooter={
        <div style={{ width: "100%" }}>
          <button
            type="submit"
            className="button-primary-plus mr-2 mb-2 retry-modal-footer-btn"
            onClick={retryBillingHandler}
          >
            Retry Billing Info
          </button>
          <Link to="/updatebillinginfo">
            <button
              type="submit"
              className="button-primary-plus mb-2 retry-modal-footer-btn"
              onClick={toggleRetryBillingErrorModal}
            >
              Update Billing
            </button>
          </Link>
        </div>
      }
    />
  );
};
