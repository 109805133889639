import React from "react";
import ImageFilter from "react-image-filter";

import PhotoControl from "./PhotoControl";

import { filters } from "./filter-types";

import "./PhotoFilter.css";

const PhotoFilter = props => {
  const {
    petDetails,
    image,
    filter,
    onChangePhoto,
    stepper,
    onFilterChanged
  } = props;

  const updateFilter = index => {
    onFilterChanged(index);
  };

  const onNext = () => {
    stepper.nextStep();
  };

  const goToCrop = () => {
    stepper.goToStep(0);
  };

  const getFilteredImages = () => {
    let imageFilter = filters.map((type, index) => {
      return (
        <div
          className={
            filter === index
              ? "filter-image-container active"
              : "filter-image-container"
          }
          key={index + "filterImageType"}
          onClick={() => updateFilter(index)}
        >
          <ImageFilter
            className="filter-image"
            svgStyle={{ width: "70px", height: "70px" }}
            image={image}
            filter={type.filter}
          />
          <div className="filter-name">{type.name}</div>
        </div>
      );
    });
    return imageFilter;
  };

  return (
    <div className="photo-filter">
      <div className="title-container">
        <h1 className="text-center">
          Edit {petDetails.Name}
          's photo
        </h1>
      </div>
      <div className="image-container grow">
        <ImageFilter
          id="imageFilter"
          svgStyle={{ width: "200px", height: "200px" }}
          className="original-image"
          image={image}
          filter={filters[filter].filter}
        />
      </div>
      <div className="filters-holder">
        <div className="image-filters">{getFilteredImages()}</div>
      </div>
      <PhotoControl
        toCrop={goToCrop}
        onNext={onNext}
        onChangePhoto={onChangePhoto}
        {...stepper}
      />
    </div>
  );
};

export default PhotoFilter;
