import * as actionTypes from "../../constants/action-types";

export const setLoginState = (state) => ({
  type: actionTypes.SET_LOGIN_STATE,
  state
});


export const setCCTLoginState = (state) => ({
  type: actionTypes.SET_CCT_LOGIN_STATE,
  state
});


export const setLoginExpired = (state) => ({
  type: actionTypes.SET_LOGIN_EXPIRED,
  state
});

export const logOut = () => ({
  type: actionTypes.LOGOUT_REQUEST,
  payload: {}
});


export const setChangePassword = (state) => ({
  type: actionTypes.SET_CHANGE_PASSWORD,
  state
});


export const setReturnURL = (state) => ({
  type: actionTypes.SET_RETURN_URL,
  state
});

