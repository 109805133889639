import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "informed";
import StatusHeader from "../../common/status-header/StatusHeader";
import EditPassword from "../../common/form-fields/edit-text/EditPassword";
import { updatePassword } from "../../../api/auth-api";
import { store } from "./../../../../src/index";
import { setChangePassword } from "./../../../store/actions/login-actions";
import Loader from "../../common/loader/Loader";
import ErrorView from "../../common/error-view/ErrorView";

import "./UpdatePassword.css";

// TODO: Fix visibility of success message in desktop
class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordCompleted: false,
      fetching: false,
      changePasswordError: false
    };
    this.errorMessage = "";
    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  changePasswordHandler = newPassword => {
    // Validate Password
    this.setState({
      fetching: true,
      changePasswordError: false
    });

    updatePassword(newPassword)
      .then(response => {
        store.dispatch(setChangePassword(false));
        this.setState({
          fetching: false,
          changePasswordCompleted: true
        });
        //For google analytics
        window.dataLayer.push({
          'event': 'Pageview',
          'url': window.location.pathname + '/Confirm'
        });
      })
      .catch(error => {
        this.errorMessage = error.errorMessage
          ? error.errorMessage
          : "Could not change your password. Please try later.";
        this.setState({
          fetching: false,
          changePasswordError: true
        });
        window.scrollTo(0, 0);
      });
  };

  render() {
    const isCompleted = this.state.changePasswordCompleted;

    return (
      <React.Fragment>
        {!isCompleted ? (
          <UpdatePasswordForm
            fetching={this.state.fetching}
            submitHandler={newPassword => {
              this.changePasswordHandler(newPassword);
            }}
            changePasswordError={this.state.changePasswordError}
            errorMessage={this.errorMessage}
            backToHome={() => {
              this.props.history.push("/");
            }}
          />
        ) : (
            <PasswordChangeSuccess
              backToHome={() => {
                this.props.history.push("/");
              }}
            />
          )}
      </React.Fragment>
    );
  }
}

class UpdatePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNote: false,
      showError: false
    };
  }

  passwordNote = password => {
    return (
      <div className="password-note d-flex">
        <i className="fa fa-question-circle-o" />
        <div>
          <span>Password must:</span>
          <br />
          <span>
            <i
              className={
                "fa " +
                (this.validateLength(password)
                  ? "fa-check-circle"
                  : "fa-info-circle")
              }
            />{" "}
            Be at least 8 characters long
          </span>
          <br />
          <span>
            <i
              className={
                "fa " +
                (this.validateCharacter(password)
                  ? "fa-check-circle"
                  : "fa-info-circle")
              }
            />{" "}
            Have at least 1 letter
          </span>
          <br />
          <span>
            <i
              className={
                "fa " +
                (this.validateNumber(password)
                  ? "fa-check-circle"
                  : "fa-info-circle")
              }
            />{" "}
            Have at least 1 number
          </span>
        </div>
      </div>
    );
  };

  showHideNote = shouldShow => {
    this.setState({
      showNote: shouldShow
    });
  };

  showHideError = shouldShow => {
    this.setState({
      showError: shouldShow
    });
  };

  validateNewPassword = password => {
    if (!password) {
      return;
    }
    // validate length
    if (!this.validateLength(password)) {
      return "Password must be at least 8 characters long";
    }
    // contain a letter
    if (!this.validateCharacter(password)) {
      return "Password must have at least 1 letter";
    }
    // contain a number
    if (!this.validateNumber(password)) {
      return "Password must have at least 1 number";
    }
    return null;
  };

  validateLength = password => {
    return password && password.trim().length > 7;
  };

  validateCharacter = password => {
    return password && /[a-zA-Z]/.test(password);
  };

  validateNumber = password => {
    return password && /\d/.test(password);
  };

  render() {
    return (
      <div className="container">
        <div className="d-flex flex-column change-password">
          <h1 className="title-container d-flex align-items-center">
            Change Password
          </h1>
          {this.props.changePasswordError && (
            <ErrorView> {this.props.errorMessage} </ErrorView>
          )}
          <span>Enter a new password in the field below.</span>
          <Form onSubmit={values => this.props.submitHandler(values.password)}>
            {({ formState, formApi }) => (
              <React.Fragment>
                <EditPassword
                  id="change_password_field"
                  required
                  field="password"
                  type="password"
                  title="New Password"
                  floatingTitle={true}
                  secured={true}
                  validateOnBlur
                  showError={this.state.showError}
                  validate={this.validateNewPassword}
                  onFocus={() => {
                    this.showHideNote(true);
                  }}
                  onBlur={() => {
                    this.showHideNote(false);
                  }}
                  onChange={event => {
                    formApi.setError("password", null);
                  }}
                  note={
                    this.state.showNote
                      ? this.passwordNote(formState.values.password)
                      : null
                  }
                  disabled={this.props.fetching}
                  className="field-width"
                />
                <div className="d-flex flex-column flex-md-row button-container-margin">
                  <button
                    id="change_password_button"
                    className="button-primary button-margin field-width"
                    type="submit"
                    disabled={this.props.fetching}
                  >
                    Change Password
                  </button>

                  <button
                    type="submit"
                    className="button-link_cancel col-sm-12 col-md-2 button-margin"
                    id="cancel_button"
                    onClick={this.props.backToHome}
                  >
                    Cancel
                  </button>
                </div>
              </React.Fragment>
            )}
          </Form>
        </div>
        {this.props.fetching && <Loader position="absolute" fullPage={true} />}
      </div>
    );
  }
}

const PasswordChangeSuccess = props => {
  return (
    <div className="password-change-success ">
      <StatusHeader className="status">
        <div className="d-inline-flex align-items-center status">
          <i className="fa fa-check-circle align-middle" />
          <div className="status-info">Success!</div>
          <div className="d-none d-md-block status-text">
            &nbsp;Your account password has been changed.
          </div>
        </div>
      </StatusHeader>
      <div className="content container">
        <h1 className="my-3 my-lg-0">Thank you!</h1>
        <button
          className="button-secondary password-change-success-home-button mb-3 d-md-none d-block"
          type="submit"
          onClick={props.backToHome}
        >
          Home
        </button>
        <button
          className="button-secondary password-change-success-home-button mb-3 d-none d-md-block"
          type="submit"
          onClick={props.backToHome}
        >
          Home
        </button>
      </div>
    </div>
  );
};

export default withRouter(UpdatePassword);
