import React from "react";
import { Redirect } from 'react-router';
import { parseQueryString } from "../../common/util/util";

// Simple
const ARNLink = (props) => {
    let querystringParams = parseQueryString(props.location.search.substring(1));

    let filePath = "/pubfile/arn/" + encodeURIComponent(querystringParams["arnid"]);
        return (
            <Redirect to={filePath}></Redirect>
        );
};

export default ARNLink;