export const StatusTypes = {
    new: "new",
    inProcess: "in process",
    open: "open",
    waitingForInfo: "waiting for info",
    waitingOnSomeone: "waiting on someone else",
    waitingForReview: "waiting for review",
    waitingForApproval: "waiting for approval",
    approvedForPayment: "approved for payment",
    closedWithoutPaymentDuplicate: "closed without payment - duplicate",
    closedWithoutPaymentDenied: "closed without payment - denied",
    closedWithoutPaymentCovered: "closed without payment - covered",
    closedWithPayment: "closed with payment",
    reissuingCheck: "reissuing check",
    pendingClose: "pending close",
    directDepositInProgress: "direct deposit in progress",
    directDepositError: "direct deposit error",
    adjustorReview: "adjustor review",
    adjustorRejected: "adjustor rejected"
}