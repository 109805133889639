import React, { Component } from "react";
import "./UpdateReimbursementView.component.css";
import { Link } from "react-router-dom";

import { Form } from "informed";

import MaskValidateUtil from "../../common/util/MaskValidateUtil";
import StateList from "../../common/data/StateList";

import { UncontrolledCollapse } from "reactstrap";

import EditText from "../../common/form-fields/edit-text/EditText";
import DropDownSelect from "../../common/form-fields/drop-down/DropDownSelect";

import { DIRECT_DEPOSIT, MAIL_CHECK } from "./UpdateReimbursement";

export class MailCheckView extends Component {

  fetchInitialValues = userData => {
    if (userData && userData.MailingAddress) {
      return {
        streetAddress: userData.MailingAddress.Street,
        city: userData.MailingAddress.City,
        state: userData.MailingAddress.State,
        zipCode: userData.MailingAddress.PostalCode,
      };
    }

    return {};
  };

  render() {
    return (
      <div className="d-flex flex-column edit-field-reimbrusement-container">
        <h4 className="select-type">{!this.props.updateMailing && <span>2.&nbsp;</span>}Check Your Mailing Address</h4>
        <label className="select-description">
          Please review your current mailing address. Any claim reimbursement
          checks will be sent to that address. If you need to update your
          mailing address, please submit the form below.
        </label>
        <h4 className="select-type margin-bottom">Enter New Mailing Address</h4>
        {/** 
        If props.userAccountDetailsFetching is not checked, then there is a chance that 
        Form get initialized with old account data.
        */}
        {(!this.props.userAccountDetailsFetching &&
          this.props.userAccountDetails) &&
          <Form
            className="d-flex flex-column form-container"
            onSubmit={values =>
              this.props.updateReimbursement(MAIL_CHECK, values, null)
            }
            initialValues={this.fetchInitialValues(
              this.props.userAccountDetails
            )}
          >
            {({ formState }) => (
              <React.Fragment>
                <EditText
                  required
                  className="street-address-container margin-bottom"
                  id="street_address"
                  field="streetAddress"
                  type="text"
                  title="New Street Address"
                  floatingTitle={true}
                />

                <EditText
                  required
                  className="address-container margin-bottom"
                  id="city"
                  field="city"
                  type="text"
                  title="City"
                  floatingTitle={true}
                />

                <DropDownSelect
                  style={{ width: "100%" }}
                  field="state"
                  className="d-md-none address-container margin-bottom"
                  id="select_state"
                  data={StateList.getStateList()}
                />

                <DropDownSelect
                  style={{ width: "269px" }}
                  field="state"
                  className="d-none d-md-block address-container margin-bottom"
                  id="select_state"
                  data={StateList.getStateList()}
                />

                <EditText
                  required
                  className="address-container margin-bottom"
                  id="zip_code"
                  field="zipCode"
                  type="text"
                  title="New Zip Code"
                  floatingTitle={true}
                  mask={MaskValidateUtil.zipCodeMask}
                  validate={MaskValidateUtil.mailZipCodeValidate}
                  validateOnBlur
                  validateOnChange={formState.errors.zipCode}
                />
                <div className="w-100 d-flex flex-column flex-md-row my-3">
                  <button
                    className="button-primary button-mc-container margin-bottom"
                    type="submit"
                    disabled={this.props.fetching || formState.invalid}
                  >
                    Save Changes
                </button>
                  <button
                    className="button-link_cancel cancel-button margin-bottom"
                    type="submit"
                    onClick={this.props.backToHome}
                  >
                    Cancel
                </button>
                </div>
              </React.Fragment>
            )}
          </Form>
        }
      </div>
    );
  }
}

export const CHECKING = "checking";
export const SAVING = "savings";

export class DirectDepositView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAccountTab: CHECKING
    };
  }

  toggle = value => {
    this.setState({
      activeAccountTab: value
    });
  };

  link = () => {
    return (
      <React.Fragment>
        <i className="fa fa-question-circle" /> <span>&nbsp;</span> What's this
        ?
      </React.Fragment>
    );
  };

  handlePaste = e => {
    e.preventDefault();
  };

  fetchInitialValues = userData => {
    if (userData && userData.PetParentInfo) {
      return {
        primaryPhoneNumber: MaskValidateUtil.phoneNumberMask(userData.PetParentInfo.PrimaryPhone)
      };
    }

    return {};
  };

  render() {
    return (
      <div className="d-flex flex-column edit-field-reimbrusement-container">
        <h4 className="select-type">2. Setup Your Direct Deposit Account</h4>
        <label className="select-description">
          Please enter your account information below. Any reimbursements will
          be deposited directly into your account.
        </label>

        <div className="d-flex flex-column flex-md-row select-type">
          <h4>Enter Bank Account Information</h4>
          <Link
            to="#"
            id="bank-account-whats-this"
            className="d-md-none link-icon d-flex flex-row align-items-center "
          >
            {this.link()}
          </Link>
          <Link
            to="#"
            onClick={this.props.toggleSecurityDialog}
            className="d-none link-icon d-md-flex flex-row align-items-center "
          >
            &nbsp;&nbsp;
            {this.link()}
          </Link>
        </div>
        <UncontrolledCollapse
          className="toggle-container d-lg-none"
          toggler="#bank-account-whats-this"
        >
          <div className="d-flex flex-column">
            <img
              alt="Bank Account Info"
              className="toggle-image"
              src="../../assets/images/bank_account_info.png"
            />
            <h4 className="toggle-header">Routing number</h4>
            <span className="toggle-description">
              The first set of numbers on the lower left corner of a check is
              the routing number. Keep in mind, the routing numbers are 9-digit
              codes and the character symbols surrounding the numbers are not
              part of the number on the check.
            </span>
            <h4 className="toggle-header">Account number</h4>
            <span className="toggle-description">
              The second set of numbers following the character symbol
              immediately after the routing number is the account number. The
              check account number is private and unique to the bank account.
            </span>
            <span className="toggle-description mt-2">
              Please note that your bank may use a different routing number 
              for ACH (Automated Clearing House) transfers than the routing number on your 
              checks, and/or require the zeros at the beginning of your account number to be included. 
              Please confirm both the correct ACH routing number and the 
              full account number with your bank prior to enrolling.
            </span>
          </div>
        </UncontrolledCollapse>
        <h4 className="d-md-none select-type">Select an Account Type</h4>
        <div className="row tab-parent margin-bottom">
          <label
            onClick={() => {
              this.toggle(CHECKING);
            }}
            className={
              "col-6 d-flex align-items-center justify-content-center left-tab " +
              (this.state.activeAccountTab === CHECKING
                ? "selected-tab"
                : "unselected-tab")
            }
          >
            Checking
          </label>
          <label
            onClick={() => {
              this.toggle(SAVING);
            }}
            className={
              "col-6 d-flex align-items-center justify-content-center right-tab " +
              (this.state.activeAccountTab === SAVING
                ? "selected-tab"
                : "unselected-tab")
            }
          >
            Savings
          </label>
        </div>
        {/** 
        If props.userAccountDetailsFetching is not checked, then there is a chance that 
        Form get initialized with old account data.
        */}
        {(!this.props.userAccountDetailsFetching &&
          this.props.userAccountDetails) &&
          <Form
            className="d-flex flex-column form-container"
            initialValues={this.fetchInitialValues(
              this.props.userAccountDetails
            )}
            onSubmit={values =>
              this.props.updateReimbursement(
                DIRECT_DEPOSIT,
                values,
                this.state.activeAccountTab
              )
            }
          >
            {({ formState }) => (
              <React.Fragment>
                <EditText
                  required
                  className="button-container margin-bottom"
                  id="primary-phone-number"
                  field="primaryPhoneNumber"
                  type="text"
                  title="Primary Phone Number"
                  floatingTitle={true}
                  mask={MaskValidateUtil.phoneNumberMask}
                  validate={MaskValidateUtil.validatePhoneNumber}
                  validateOnBlur
                  validateOnChange={formState.errors.primaryPhoneNumber}
                />

                <EditText
                  required
                  className="button-container margin-bottom"
                  id="routing-number"
                  field="routingNumber"
                  type="text"
                  title="Routing Number"
                  floatingTitle={true}
                  mask={MaskValidateUtil.NumberMask}
                />
                <EditText
                  required
                  className="button-container margin-bottom"
                  id="account-number"
                  field="accountNumber"
                  type="text"
                  title="Account Number"
                  floatingTitle={true}
                  mask={MaskValidateUtil.NumberMask}
                  validate={MaskValidateUtil.validateAccountNumber}
                  validateOnBlur
                  validateOnChange={formState.errors.accountNumber}
                />

                <EditText
                  required
                  className="button-container margin-bottom"
                  id="verify-account-number"
                  field="verifyAccountNumber"
                  type="text"
                  title="Verify Account Number"
                  floatingTitle={true}
                  mask={MaskValidateUtil.NumberMask}
                  validate={MaskValidateUtil.validateAccountNumber}
                  validateOnBlur
                  validateOnChange={formState.errors.verifyAccountNumber}
                  onPaste={this.handlePaste}
                />

                <div className="w-100 d-flex flex-column flex-md-row">
                  <button
                    className="button-primary button-mc-container margin-bottom"
                    type="submit"
                    disabled={this.props.fetching || formState.invalid}
                  >
                    Save Changes
                </button>
                  <button
                    className="button-link cancel-button margin-bottom"
                    type="submit"
                    onClick={this.props.backToHome}
                  >
                    Cancel
                </button>
                </div>
              </React.Fragment>
            )}
          </Form>
        }
      </div>
    );
  }
}
