import * as types from "../../constants/action-types";

const initialState = {
  petsDetails: [],
  availablePolicyOptions: null,
  error: null,
  petUpdateError: false,
  needsRefresh: false,
  petPhotoUpdating: false,
  photoUpdateModelOpen: false,
  fetchingPets: false,
  fetchingAvailableOptions: false,
  availablePolicyOptionsError: null,
  fetchingAnniversaryOptions: false,
  anniversaryPolicyOptions: null,
  anniversaryPolicyOptionsError: null,
};

// Handles image related actions
export default function (state = initialState, action) {
  switch (action.type) {
    case types.PETS_FETCH_REQUEST:
      return {
        ...state
      }
    case types.PETS_FETCH_SUCCESS:
      return {
        ...state,
        petsDetails: action.petsDetails,
        fetchingPets: false,
        needsRefresh: false
      };
    case types.PETS_FETCH_ERROR:
      return {
        ...state,
        error: action.error,
        fetchingPets: false
      };
    case types.SET_PETDETAILS_DATA_REFRESH:
      return {
        ...state,
        needsRefresh: true
      };
    case types.SET_PETS_FETCHING_STATE: 
      return {
        ...state,
        fetchingPets: true
      };
    case types.PET_PHOTO_UPDATE_MODAL:
      return {
        ...state,
        photoUpdateModelOpen: !state.photoUpdateModelOpen
      };
    case types.PET_PHOTO_UPDATE_REQUEST:
      return {
        ...state,
        petPhotoUpdating: true
      };
    case types.PET_PHOTO_UPDATE_SUCCESS:
      const payload = action.newImageProps;
      return {
        ...state,
        petPhotoUpdating: false,
        petsDetails: state.petsDetails.map(
          pet =>
            pet.PetID === payload.petID
              ? {
                ...pet,
                PetImageURL: payload.petPhoto,
                ImageAvailable: payload.petPhoto === null ? false : true
              }
              : pet
        )
      };
    case types.PET_PHOTO_UPDATE_ERROR:
      return {
        ...state,
        petPhotoUpdating: false,
        petUpdateError: action.error
      };
    case types.PET_PHOTO_UPDATE_LOADER:
      return {
        ...state,
        petPhotoUpdating: action
      };
    case types.GET_AVAILABLE_POLICY_OPTIONS:
      return {
        ...state,
        fetchingAvailableOptions: true
      };
    case types.GET_AVAILABLE_POLICY_OPTIONS_SUCCESS:
      return {
        ...state,
        fetchingAvailableOptions: false,
        availablePolicyOptions: action.availablePolicyOptions
      };
    case types.GET_AVAILABLE_POLICY_OPTIONS_ERROR:
      return {
        ...state,
        fetchingAvailableOptions: false,
        availablePolicyOptionsError: action.error
      }
    case types.GET_ANNIVERSARY_POLICY_OPTIONS:
      return {
        ...state,
        fetchingAnniversaryOptions: true
      };
    case types.GET_ANNIVERSARY_POLICY_OPTIONS_SUCCESS:
      return {
        ...state,
        fetchingAnniversaryOptions: false,
        anniversaryPolicyOptions: action.anniversaryPolicyOptions
      };
    case types.GET_ANNIVERSARY_POLICY_OPTIONS_ERROR:
      return {
        ...state,
        fetchingAnniversaryOptions: false,
        anniversaryPolicyOptionsError: action.error
      }
    default:
      return state;
  }
}
