import {  axiosInstanceOldAPI } from "./axios";

export const getChatEnabled = () => {
    let url = "/LiveChat/getEnabled";

    return axiosInstanceOldAPI
    .get(url, {})
    .then(response => {
        if(response && response.data){
            return response.data.result;
          } else {
            return false;
          }
        })
    .catch(function(err) {
        return false;
    });
}

