import Helpers from "./Helpers";


export default class MaskValidateUtil {
  static cvvMask = value => {
    return value.replace(/\D/g, "").substring(0, 4);
  };

  static NumberMask = value => {
    return value.replace(/\D/g, "");
  };

  static cvvValidate = value => {
    return !value || value.length < 3 || value.length > 4
      ? "Please enter a valid CCV number."
      : null;
  };

  static validateCity = value => {
    return !value ? "Please enter a city name" : null;
  }

  static validateEmail = value => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return value && value.length > 0 && !re.test(String(value).toLowerCase())
      ? "Please enter a valid email address"
      : null;
  };

  static validateStreetAddress = value => {

    if (!value) {
      return "Please enter a street address"
    }
    
    var re = /^[a-zA-Z0-9.,_#\-\/ ]{0,60}$/;
    if (value && value.length > 0 && !re.test(String(value).toLowerCase())) {
      return "Only letters, numbers, and . , _ # - / are allowed in address"
    }

    if (value && value.length > 0 && Helpers.isNumeric(value)) {
      return "Address cannot be all numbers"
    }

    return null;
  }

  static validateStateDropdown = value => {
    if (!value || value.length == 0) {
      return "Please select a state"
    } else {
      return null;
    }
  }

  static dateMask = value => {
    if (!value || value.length === 0) return value;
    value = value.replace(/[^0-9/]/g, "");

    if (value.length > 10) {
      value = value.substring(0, 10);
    }

    return value;
  };

  static validateDate = value => {
    var today = new Date().getTime();
    var dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(20)\d{2}$/;

    if (value && value.length === 10 && dateRegex.test(value)) {
      return null;
    } else return "Please enter a valid date";
  };

  static validateDateWithNow = value => {
    var today = new Date().getTime();
    var dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(20)\d{2}$/;

    if (value && value.length === 10 && dateRegex.test(value)) {
      var splitDate = value.split("/");
      var enteredDate = new Date(
        splitDate[2],
        splitDate[0] - 1,
        splitDate[1]
      ).getTime();
      return today - enteredDate < 0 ? "Please enter a valid date" : null;
    } else return "Please enter a valid date";
  };

  static OptionalValidateDateWithNow = value => {
    var today = new Date().getTime();
    var dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(20)\d{2}$/;

    if (value) {
      if (value.length === 10 && dateRegex.test(value)) {
        var splitDate = value.split("/");
        var enteredDate = new Date(
          splitDate[2],
          splitDate[0] - 1,
          splitDate[1]
        ).getTime();
        return today - enteredDate < 0 ? "Please enter a valid date" : null;
      } else return "Please enter a valid date";
    } else return null;
  };

  static monthYearMask = value => {
    if (!value || value.length === 0) return value;
    value = value.replace(/[^0-9/]/g, "");

    // if((value.split("/").length - 1) > 1) {
    //   console.log(value);

    //   var firstIndex = value.indexOf('/')
    //   var secondIndex = value.indexOf('/', firstIndex + 1);

    //   value = value.substring(0, secondIndex) + value.substring(secondIndex + 1);
    // }

    if (value.length > 5) {
      value = value.substring(0, 5);
    }

    return value;
  };

  static monthFullYearMask = value => {
    if (!value || value.length === 0) return value;
    value = value.replace(/[^0-9/]/g, "");

    if (value.length > 7) {
        value = value.substring(0, 7);
        
    }
    
    return value;
  };

  static phoneNumberMask = value => {
    if (!value || value.length === 0) return value;

    value = value.replace(/\D/g, "");

    if (value.length > 10) {
      value = value.substring(0, 10);
    }

    var match = value.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return value;
  };

  static validatePhoneNumber = value => {
    if (value) value = value.replace(/\D/g, "");
    return !value || value.length !== 10
      ? "Please enter a valid phone number."
      : null;
  };

  static validateAccountNumber = value => {
    if (value) value = value.replace(/\D/g, "");
    return !value || value.length < 4 || value.length > 18
      ? "Please enter a valid account number."
      : null;
  };

  static monthYearValidate = value => {
    //Checks for validation to [0-12]/[2000 - 2199] (month/year)
    if (value) {
      if (value.match(/(0[1-9]|1[0-2])\/(2[0-1])\d{2}$/)) {
        let values = value.split("/");
        let currentMonth = new Date().getMonth() + 1;
        let currentFullYear = new Date().getFullYear();

          //if year is greater than current year,else if year is same as current but month is greater than
          //current month
          //values[0] = month
        //values[1] = year
        if ((values[1] > currentFullYear)
            || (values[1] == currentFullYear && values[0] >= currentMonth)) { 
          return null;
        } else {
            
            return "Please enter a valid expiry date in mm/yyyy format like for example 03/2023";
        }
      } else {
          return "Please enter a valid expiry date in mm/yyyy format like for example 03/2023";
      }
    }
  };

  static monthFullYearValidate = value => {
    return !value ||
      value.length < 5 ||
      !value.match(/(0[1-9]|1[0-2])[/][0-9]{4}/)
        ? "Please enter a valid expiry date in mm/yyyy format like for example 03/2023"
      : null;
  };

  static validateDateJoinedFamily = value => {
    return !value ||
      value.length < 5 ||
      !value.match(/(0[1-9]|1[0-2])[/][0-9]{4}/)
      ? "Invalid date format. Please enter the date in the format MM/YYYY."
      : null;
  };

  static zipCodeValidate = value => {
    return !value || value.length !== 5
      ? "Please enter a valid zip code."
      : null;
  };

  static mailZipCodeValidate = value => {
    return !value || value.length !== 5
      ? "Please enter a valid zip code."
      : null;
  };

  static zipCodeMask = value => {
    value = value.replace(/\D/g, "");
    if (value.length > 5) {
      value = value.substring(0, 5);
    }
    return value;
  };

  static creditCardNumberMask = value => {
    value = value.replace(/\D/g, "");
    var val = "";

    if (value.length > 16) {
      value = value.substring(0, 16);
    }

    for (var i = 0; i < value.length; i++) {
      if ((i + 1) % 4 === 0 && i + 1 < value.length) val = val + value[i] + " ";
      else val = val + value[i];
    }

    return val;
  };

  static
  = value => {
    if (value) {
      value = value.replace(/\D/g, "");
    }

    return !value || value.length < 15 || value.length > 16
      ? "Please enter a valid credit card number."
      : null;
  };
}
