import React, { Component } from "react";
import { Link } from "react-router-dom";
import { UncontrolledCollapse } from "reactstrap";

import ModalDialog from "../../common/modal-dialog/ModalDialog";

import "./FaqWidget.css";

export default class FaqWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false
    };
  }

  changeModalstate = (value, title, link) => {
    this.modalTitle = title;
    this.modalBody = link;
    this.setState({
      openModal: value
    });
  };

  toggleOpenModal = () => {
    this.setState({
      openModal: !this.state.openModal
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className={this.props.className + " d-lg-none faq-box-container "}>
          {this.faqMobileWidget(this.props)}
        </div>
        <div
          className={
            this.props.className + " d-none d-lg-block faq-box-container "
          }
        >
          {this.faqDesktopWidget(this.props)}
        </div>
        <ModalDialog
          isOpen={this.state.openModal}
          toggle={this.toggleOpenModal}
          modalHeader={this.modalTitle}
          modalBody={this.modalBody}
          modalFooter={
            <button
              type="submit"
              className="button-primary-plus modal-close-button"
              onClick={() => {
                this.changeModalstate(false);
              }}
            >
              Close
            </button>
          }
        />
      </React.Fragment>
    );
  }

  faqMobileWidget = props => {
    return (
      <React.Fragment>
        <Link to="#" id="toggler">
          <div className="d-flex flex-row link-text align-items-center">
            <i className="fa fa-question-circle link-icon mr-1" />
            {props.mobileTitle}
          </div>
        </Link>
        <UncontrolledCollapse toggler="#toggler" className="collapse-margin">
          <div className="d-flex flex-column faq-mobile-container faq-body">
            {props.faqLink.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    this.changeModalstate(true, item.text, item.answer);
                  }}
                  className="faq"
                >
                  {item.text}
                </div>
              );
            })}

            <Link
              to="/faq"
              target="_blank"
              rel="noopener noreferrer"
              className="faq faq-more-info"
            >
              More FAQs >
            </Link>
          </div>
        </UncontrolledCollapse>
      </React.Fragment>
    );
  };

  faqDesktopWidget = props => {
    return (
      <React.Fragment>
        <div className="faq-header d-none d-lg-block">
          <span>{props.title}</span>
        </div>
        <div className="faq-body">
          {props.faqLink.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  this.changeModalstate(true, item.text, item.answer);
                }}
                className="faq"
              >
                {item.text}
              </div>
            );
          })}
          <Link
            to="/faq"
            target="_blank"
            rel="noopener noreferrer"
            className="faq faq-more-info"
          >
            More FAQs >
          </Link>
        </div>
        <div className="faq-footer d-none d-lg-block" />
      </React.Fragment>
    );
  };
}
