import { axiosInstanceOldAPI, axiosInstance, axiosInstanceWithAuth } from "./axios";
import {
  getCredentialsIfValid,
  saveCredentials,
  updatePasswordToken,
  clearSession
} from "./auth/auth";

// Since there is no proper authentication API's GetAccountInfo is used for authentication now.
// Replace with proper authentication flow when available. (For now few API's using proper authentication flow.)
// Ref. auth.js also for more authentication related handling which should never be done :(

export const authenticate = credentials => {
  let { username, password } = credentials;
  let url = "/oauth/token";

  var params = new URLSearchParams();
  params.append("grant_type", "password");
  params.append("username", username);
  params.append("password", password);

  // let data = "grant_type=password&username=dhandapani.kgp%40gmail.com&password=Test%40123"
  
  // let config = {
  //   headers: {
  //       "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
  //   }
  // };
  
  return axiosInstanceWithAuth
    .post(url, params)
    .then(response => {
      if (response.data && response.data.access_token) {
        saveCredentials(credentials, response.data);
        return response.data;
      } else {
        return Promise.reject({});
      }
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};

export const validateSession = credentials => {
  let url = "/Accounts/authorization";

  return axiosInstanceWithAuth
    .get(url);
};

export const updatePassword = newPassword => {
  let url = "/Accounts/UpdatePassword";
  // Getting it before the call to avoid the rare case of token getting expired in mean time.
  let credentials = getCredentialsIfValid();
  return axiosInstanceWithAuth
    .post(url, { newPassword: newPassword })
    .then(response => {
      //credentials.password = newPassword;
      //updatePasswordToken(credentials);
      return response.data.Item;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};

export const resetPassword = username => {
  let url = "/Accounts/ResetPassword";
  return axiosInstance
    .post(url, { EmailAddress: username })
    .then(response => {
      return response.data.Item;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};

export const decryptOAuthToken = authToken => {
  let url = "/Accounts/DecryptOAuthToken?accessToken=" + authToken;

  return axiosInstance
    .get(url, {})
    .then(response => {
      return response.data;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
}

export const logout = () => {
  clearSession();
};
