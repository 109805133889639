import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form } from "informed";
import { Link } from "react-router-dom";
import EditText from "../../common/form-fields/edit-text/EditText";
import { getAccountInfo } from "../../../store/actions/accounts-actions";
import { getClaimsByAccount,setClaimsDataRefresh } from "../../../store/actions/claims-actions";
import ReferFriend from "../../common/refer-friend/ReferFriend";
import BillingInfo from "../components/billing-info/BillingInfo";
import Loader from "../../common/loader/Loader";
import ErrorView from "../../common/error-view/ErrorView";
import { AppLinks } from "../../../constants/app-links";
import "./ViewClaimStatus.css";
import ClaimsTable from "./ClaimsTable";
import ClaimStatusMaintenance from "./ClaimStatusMaintenance.component";

class ViewClaimStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claimsDetails: [],
      sortColumn: "",
      sortOrder: "ascending",
      searchKey: "",
      currentPage: 1,
      totalCount: 1
    };

    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname
    });

    if (props.location.search) {
      this.selectedClaimID = props.location.search.split("=")[1];
      this.shouldAutoExpand = true;
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.claimsDetails !== state.claimsDetails) {
      return {
        claimsDetails: props.claimsDetails,
        totalCount: (props.pagination && props.pagination.total ) || 1
      };
    }
    return null;
  }
  
  componentReMount = currentPage => {
    this.props.actions.getClaimsByAccount();
    this.props.actions.setClaimsDataRefresh();
    this.props.actions.getClaimsByAccount();
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.props.actions.getAccountInfo();
    this.props.actions.getClaimsByAccount();
    this.autoExpandClaimStatus();
    if (!this.selectedClaimID) {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    // this.props.actions.getClaimsByAccount();
    if (this.shouldAutoExpand) {
      this.autoExpandClaimStatus();
    }
  }

  autoExpandClaimStatus() {
    if (this.selectedClaimID) {
      let selectedClaimElement = document.getElementById(
        "toggle-" + this.selectedClaimID
      );
      if (selectedClaimElement) {
        selectedClaimElement.click();
        selectedClaimElement.parentElement.scrollIntoView();
      }
    }
  }

  disableAutoExpand() {
    this.shouldAutoExpand = false;
  }

  setSearchKey(value) {
    this.setState({ searchKey: value.search_claim });
  }

  toggleSort(id) {
    let claimsObj = this.state.claimsDetails;
    let statusSortOrder = [
      "new",
      "in process",
      "open",
      "waiting for info",
      "waiting on someone else",
      "waiting for review",
      "waiting for approval",
      "approved for payment",
      "closed without payment - denied",
      "closed without payment - covered",
      "closed with payment",
      "reissuing check",
      "pending close",
      "direct deposit in progress",
      "direct deposit error",
      "adjustor review",
      "adjustor rejected",
      "completed",
    ];
    switch (id) {
      case "DateSubmitted":
        claimsObj.sort((claim1, claim2) => {
          let date1 = new Date(claim1.DateSubmitted);
          let date2 = new Date(claim2.DateSubmitted);
          if (this.state.sortOrder === "ascending") return date1 - date2;
          return date2 - date1;
        });
        break;

      case "ClaimID":
        claimsObj.sort((claim1, claim2) => {
          let claimNo1 = claim1.ClaimNumber;
          let claimNo2 = claim2.ClaimNumber;
          if (claimNo1 === null || claimNo2 === null) {
            if (!claimNo1 && !claimNo2) return 0;
            if (this.state.sortOrder === "ascending") {
              if (claimNo1) return -1;
              if (claimNo2) return 1;
            } 
            else {
              if (claimNo1) return 1;
              if (claimNo2) return -1;
            }
          } 
          else {
            if (this.state.sortOrder === "ascending") {
              if (claimNo1 < claimNo2) return -1;
              if (claimNo1 > claimNo2) return 1;
            } else {
              if (claimNo1 < claimNo2) return 1;
              if (claimNo1 > claimNo2) return -1;
            }
            return 0;
          }
        });
        break;

      case "PetName":
        claimsObj.sort((claim1, claim2) => {
          let pet1 = claim1.PetName;
          let pet2 = claim2.PetName;
          let date1 = new Date(claim1.DateSubmitted);
          let date2 = new Date(claim2.DateSubmitted);
          if (!pet1 || !pet2) {
            if (!pet1 && !pet2) return 0;
            if (this.state.sortOrder === "ascending") {
              if (pet1) return -1;
              if (pet2) return 1;
            } 
            else {
              if (pet1) return 1;
              if (pet2) return -1;
            }
          } 
          else {
            pet1.toLowerCase();
            pet2.toLowerCase();
            if (this.state.sortOrder === "ascending") {
              if (pet1 === pet2) {
                return date1 - date2;
              }
              if (pet1 < pet2) return -1;
              if (pet1 > pet2) return 1;
            } 
            else {
              if (pet1 === pet2) {
                return date2 - date1;
              }
              if (pet1 < pet2) return 1;
              if (pet1 > pet2) return -1;
            }
            return 0;
          }
        });
        break;

      case "Status":
        claimsObj.sort((claim1, claim2) => {
          let status1 = claim1.StatusDetail.toLowerCase();
          let status2 = claim2.StatusDetail.toLowerCase();
          let date1 = new Date(claim1.DateSubmitted);
          let date2 = new Date(claim2.DateSubmitted);
          if (this.state.sortOrder === "ascending") {
            if (status1 === status2) {
              return date1 - date2;
            }
             else 
              return statusSortOrder.indexOf(status1) - statusSortOrder.indexOf(status2);
          } 
          else {
            if (status1 === status2) {
              return date2 - date1;
            } 
            else
              return statusSortOrder.indexOf(status2) - statusSortOrder.indexOf(status1);
          }
        });
        break;

      default:
        break;
    }


    this.setState({
      sortColumn: id,
      sortOrder:
        this.state.sortOrder === "ascending" ? "descending" : "ascending",
      claimsDetails: claimsObj,
      totalCount: this.state.totalCount
    });

  }

  render() {

    if (false) {
      return (
        <ClaimStatusMaintenance
            backToHome={() => {
              this.props.history.push("/");
            }}
          />
      )
    }
    
    let searchPlaceHolder;
    let filteredClaims = this.state.searchKey ?
      this.state.claimsDetails.filter(claim =>
        JSON.stringify(claim)
          .toLowerCase()
          .includes(this.state.searchKey.toLowerCase())
      ) : this.state.claimsDetails;

    if (window.matchMedia("(min-width: 768px)").matches) {
      searchPlaceHolder =
        "Search your claims by entering submitted date, claim #, status or pet name";
    } else searchPlaceHolder = "Search your claim(s)";
    return (
      <React.Fragment>
        <div className="container view-claim-status-header">
          <div className="col-12 col-lg-8 d-flex justify-content-between align-items-center">
            <h1 className="text-primary m-lg-0">View Claim Status</h1>
            <button
              className="d-none d-md-block button-secondary"
              onClick={() =>
                this.props.history.push(AppLinks.claims.fileClaim)
              }
            >
              {this.state.claimsDetails.length > 0 ?
                "Submit Another Claim"
                : "File a Claim"}
            </button>
          </div>
          <div className="d-none col-lg-4" />
        </div>
        <div className="d-lg-flex view-claim-status container">
          {
            this.props.claimsDetails && !this.props.error ? (
              <div className="col-12 col-lg-8 claim-status">
                <div>
                  <div className="d-block d-md-none d-lg-block mb-3">
                    If you have questions regarding your claims, please {" "}
                    <Link to={AppLinks.others.contact} ><span className="font-weight-bold">
                      contact us
                  </span>
                    </Link>
                    .
                </div>
                  <span className="d-none d-lg-block mb-1" style={{ fontWeight: 650 }} >Search your claim(s)</span>
                  <Form onValueChange={this.setSearchKey.bind(this)}>
                    <div className="search-container">
                      <EditText
                        type="text"
                        field="search_claim"
                        title={searchPlaceHolder}
                        floatingTitle={true}
                        id="claim-search-edit-text"
                      />
                      <i className="search-icon fa fa-search fa-lg" />
                    </div>
                  </Form>

                  <ClaimsTable
                    claimsDetails={filteredClaims}
                    toggleSort={this.toggleSort.bind(this)}
                    disableAutoExpand={this.disableAutoExpand.bind(this)}
                    sortColumn={this.state.sortColumn}
                    sortOrder={this.state.sortOrder}
                    searchKey={this.state.searchKey}
                    componentReMount = {this.componentReMount}
                    totalCount = {this.state.totalCount}
                  />
                </div>
              </div>
            ) : (
                this.props.error &&
                <div className="container" style={{ height: 300 }}>
                  <ErrorView> Unable to fetch claims. Please try later. </ErrorView>
                </div>
              )
          }

          <div className="d-none col-lg-4 d-lg-flex side-box flex-column ">
            {/* <ReferFriend /> */}
            {this.props.userAccountDetails && (
              <BillingInfo
                creditCard={this.props.userAccountDetails.Last4CreditCard}
                billingInfo={this.props.userAccountDetails.BillingAddress}
                status={this.props.userAccountDetails.Status}
                premium={this.props.userAccountDetails.MonthlyPremium}
                billingDay={this.props.userAccountDetails.BillingDay}
                newMonthlyPremium={this.props.userAccountDetails.NewMonthlyPremium}
                changeEffectiveDate={this.props.userAccountDetails.ChangeEffectiveDate}
              />
            )}
          </div>
        </div>
        <div className="mb-3 sticky-bottom d-md-none">
          <div className="col-md-8 p-0">
            <button
              className="button-primary mb-3"
              type="button"
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(AppLinks.claims.fileClaim)
              }
            >
              Submit another Claim
            </button>
            <button
              className="button-secondary"
              type="button"
              onClick={() =>
                this.props.history.push(AppLinks.home.home)
              }
            >
              Home
            </button>
          </div>
        </div>
        {this.props.fetching && <Loader position="absolute" fullPage={true} />}
      </React.Fragment>
    );
  }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => {
  return ({
  userAccountDetails: state.account.userAccountDetails,
  claimsDetails: state.claims.claimsDetails,
  pagination: state.claims.pagination,
  fetching: state.claims.fetchingClaims,
  error: state.claims.error
});
}
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getAccountInfo, getClaimsByAccount, setClaimsDataRefresh }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewClaimStatus);
