import { combineReducers } from "redux";
import login from "./reducers/login-reducer";
import account from "./reducers/accounts-reducer";
import pets from "./reducers/pets-reducer";
import claims from "./reducers/claims-reducer.js";
import messages from "./reducers/messages-reducer";
import documents from "./reducers/documents-reducer";
import alerts from "./reducers/alerts-reducer";

// Combines all reducers to a single reducer function
const appReducer = combineReducers({
  login,
  account,
  pets,
  claims,
  messages,
  alerts,
  documents
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_SUCCESS") {
    //We are clearing this here since alerts initial state depends on local storage items
    //Before the clear session gets called , the state gets re-initalized with initial values
    //So local storage items might not cleared
    localStorage.removeItem("dismissedNotifications");
    localStorage.removeItem("clientAlertsNotifications");
    localStorage.setItem('firstTimeVisit','no');
    localStorage.setItem('secondVisit', 'no');
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
