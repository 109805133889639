import * as actionTypes from "../constants/action-types";
import { getPolicyDocs } from "./../api/policy-docs-api";
import { call, put, select } from "redux-saga/effects";

const getDocumentsFromState = state => {
  return {
    documents: state.documents.documents,
    needsRefresh: state.documents.needsRefresh
  };
};

export function* getPolicyDocsWorker(action) {
  try {
    let documents;
    
    const documentsFromState = yield select(getDocumentsFromState);

    if (documentsFromState.documents.length === 0 ||
      documentsFromState.needsRefresh) {
      documents = yield call(getPolicyDocs);
    } else {
      documents = documentsFromState.documents;
    }
    
    if (documents) {
      yield put({ type: actionTypes.DOCUMENTS_FETCH_SUCCESS, documents });
    }
  } catch (error) {
    yield put({ type: actionTypes.DOCUMENTS_FETCH_ERROR, error });
  }
}
