import React, { PureComponent } from "react";
import AvatarEditor from "react-avatar-editor";
import PhotoControl from "./PhotoControl";

import {isValidURL} from "../../util/util";
import "./PhotoCropper.css";

class PhotoCropper extends PureComponent {
  handleScale = evt => {
    const { scaleHandler } = this.props;
    const scale = parseFloat(evt.target.value);
    scaleHandler(scale);
  };

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  onNext = () => {
    const { onEdited, stepper } = this.props;
    const image = this.editor.getImageScaledToCanvas().toDataURL();
    onEdited(image);
    stepper.nextStep();
  };

  goToFilter = () => {
    const { onEdited, stepper } = this.props;
    const image = this.editor.getImageScaledToCanvas().toDataURL();
    onEdited(image);
    stepper.goToStep(1);
  };

  handlePositionChange = position => {
    const { onPositionChanged } = this.props;
    onPositionChanged(position);
  };

  render() {
    const {
      petDetails,
      position,
      scale,
      rotate,
      image,
      onChangePhoto,
      stepper
    } = this.props;
    return (
      <div className="photo-cropper">
        <div className="title-container">
          <h1 className="text-center">
            Edit {petDetails.Name}
            's photo
          </h1>
        </div>
        <div className="d-flex justify-content-center cropper-image-container grow">
          <AvatarEditor
            ref={this.setEditorRef}
            border={30}
            width={200}
            height={200}
            position={position}
            onPositionChange={this.handlePositionChange}
            color={[255, 255, 255, 0.8]}
            scale={parseFloat(scale)}
            rotate={rotate}
            image={image}
            
          />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex range-container align-items-center">
            <i className="fa fa-image zoom" />
            <input
              type="range"
              onChange={this.handleScale}
              min="1"
              max="3"
              step="0.01"
              value={scale}
              id="zoom"
              className="p-0"
            />
            <i className="fa fa-image zoom zoom-max" />
          </div>
        </div>
        <PhotoControl
          toFilter={this.goToFilter}
          onNext={this.onNext}
          onChangePhoto={onChangePhoto}
          {...stepper}
        />
      </div>
    );
  }
}

export default PhotoCropper;
