import * as actionTypes from "../constants/action-types";
import { getPetsByAccount, updatePetPhoto, fetchAvailableOptions, getAnniversaryPolicyOptions } from "../api/pets-api";
import { checkImageURL } from "./../../src/modules/common/util/util";
import { uploadFile } from "./../api/upload-api";
import { call, put, all, select } from "redux-saga/effects";

const getPetsDetailsFromState = state => {
  return {
    petsDetails: state.pets.petsDetails,
    needsRefresh: state.pets.needsRefresh
  };
};

//Related to getAvailablePoliciesWorker
export function* getPetsByAccountWorker(action) {
  try {
    const petsDetailsFromState = yield select(getPetsDetailsFromState);
    if (
      !petsDetailsFromState.petsDetails || petsDetailsFromState.petsDetails.length === 0 ||
      petsDetailsFromState.needsRefresh
    ) {
      let petsDetails;
      yield put({ type: actionTypes.SET_PETS_FETCHING_STATE })
      const petsArray = yield call(getPetsByAccount);
      petsDetails =
        petsArray.length > 0
          ? yield all(petsArray.map(item => call(checkImageAvailability, item)))
          : [];

      if (petsDetails) {
        yield put({ type: actionTypes.PETS_FETCH_SUCCESS, petsDetails });
      }
      else {
        const error = {}
        yield put({ type: actionTypes.PETS_FETCH_ERROR, error });
      }
    }

  } catch (error) {
    yield put({ type: actionTypes.PETS_FETCH_ERROR, error });
  }
}

export function* petPhotoUpdateWorker({ payload }) {
  //[TO_DO] Need to change this error object based on ASP.NET code
  let error = {
    errorCode: -1000,
    errorDescription: `${payload.type} error`,
    errorMessage:
      "Unable to update your pet's photo. Please try later."
  };

  try {
    if (payload.type === "delete") {
      yield call(updatePetPhoto, payload.PetID, null);
      const newImageProps = {
        petID: payload.PetID,
        petPhoto: null
      };
      yield put({
        type: actionTypes.PET_PHOTO_UPDATE_SUCCESS,
        newImageProps
      });
      yield put({ type: actionTypes.PET_PHOTO_UPDATE_MODAL });
    } else {
      const updatePhotoResponse = yield call(
        uploadFile,
        payload.fileType,
        payload.PetPolicyNo,
        payload.file,
        payload.fileName
      );
      const updatedPhotoURL = updatePhotoResponse.data;
      const updatedSignedPhotoURL = updatePhotoResponse.presignedURL;
      const validImageURL = yield call(checkImageURL, updatedPhotoURL);
      if (validImageURL) {
        yield call(updatePetPhoto, payload.PetID, updatedPhotoURL);
        const newImageProps = {
          petID: payload.PetID,
          petPhoto: updatedSignedPhotoURL
        };
        yield put({
          type: actionTypes.PET_PHOTO_UPDATE_SUCCESS,
          newImageProps
        });
        yield put({ type: actionTypes.PET_PHOTO_UPDATE_MODAL });
      } else {
        yield put({ type: actionTypes.PET_PHOTO_UPDATE_ERROR, error });
      }
    }
  } catch (err) {
    yield put({ type: actionTypes.PET_PHOTO_UPDATE_ERROR, error });
  }
}

function checkImageAvailability(petDetails) {
  return checkImageURL(petDetails.PetImageURL)
    .then(result => {
      petDetails.ImageAvailable = result;
      return petDetails;
    })
    .catch(() => {
      petDetails.ImageAvailable = false;
      return petDetails;
    });
}

//Fetching available policy options of pets for ChangePolicyOptions
//Related to getPetsByAccountWorker - pets should be fetched before fetching availablePolicyOptions
export function* getAvailablePolicyOptionsWorker(action) {
  try {
    let petsDetails;
    const petsDetailsFromState = yield select(getPetsDetailsFromState);
    if (
      !petsDetailsFromState.petsDetails || petsDetailsFromState.petsDetails.length === 0 ||
      petsDetailsFromState.needsRefresh
    ) {
      yield put({ type: actionTypes.SET_PETS_FETCHING_STATE });
      const petsArray = yield call(getPetsByAccount);
      petsDetails =
        petsArray.length > 0
          ? yield all(petsArray.map(item => call(checkImageAvailability, item)))
          : [];

      if (petsDetails) {
        yield put({ type: actionTypes.PETS_FETCH_SUCCESS, petsDetails });
      }

      else {
        const error = {};
        yield put({ type: actionTypes.PETS_FETCH_ERROR, error });
      }
    } else {
      petsDetails = petsDetailsFromState.petsDetails;
    }
    //fetching availablePolicyOptions once petDetails are available
    try {
      const availablePolicyOptions = yield call(fetchAvailableOptions, petsDetails.map(pet => ( {"pet_id": pet.PetID} )));
      yield put({ type: actionTypes.GET_AVAILABLE_POLICY_OPTIONS_SUCCESS, availablePolicyOptions });
    }

    catch(error) {
      yield put({ type: actionTypes.GET_AVAILABLE_POLICY_OPTIONS_ERROR, error });
    }
  } catch (error) {
    yield put({ type: actionTypes.PETS_FETCH_ERROR, error });
  }
}


export function* getAnniversaryPolicyOptionsWorker(action) {
  try {
    let petsDetails;
    const petsDetailsFromState = yield select(getPetsDetailsFromState);
    if (
      !petsDetailsFromState.petsDetails || petsDetailsFromState.petsDetails.length === 0 ||
      petsDetailsFromState.needsRefresh
    ) {
      yield put({ type: actionTypes.SET_PETS_FETCHING_STATE });
      const petsArray = yield call(getPetsByAccount);
      petsDetails =
        petsArray.length > 0
          ? yield all(petsArray.map(item => call(checkImageAvailability, item)))
          : [];

      if (petsDetails) {
        yield put({ type: actionTypes.PETS_FETCH_SUCCESS, petsDetails });
      }

      else {
        const error = {};
        yield put({ type: actionTypes.PETS_FETCH_ERROR, error });
      }
    } else {
      petsDetails = petsDetailsFromState.petsDetails;
    }

    const anniversaryPolicyOptions = yield call(getAnniversaryPolicyOptions);
    yield put({ type: actionTypes.GET_ANNIVERSARY_POLICY_OPTIONS_SUCCESS, anniversaryPolicyOptions });
  }

  catch(error) {
    yield put({ type: actionTypes.GET_ANNIVERSARY_POLICY_OPTIONS_ERROR, error });
  }
}