import { axiosInstanceWithAuth } from "./axios";
import {getCredentialsIfValid } from "./auth/auth";

export const getAlertsByAccount = () => {
  let credentials = getCredentialsIfValid();  
  credentials.username = credentials.username.replace(/\+/g,'%2B');
  
  let url = "/Messages/AlertMessages?email=" + credentials.username;

  return axiosInstanceWithAuth
    .get(url, {})
    .then(response => {
      let alertsNotifications = response.data.alert_messages;       
      return alertsNotifications;
    })
    .catch(function(err) {
      let error = {
        errorCode: -1000,
        errorDescription: "Not able to retrieve the alerts now.",
        errorMessage: "Not able to retrieve the alerts now.Try again later"
      };
      return Promise.reject(error);
    });
};
