import React, { Component } from "react";
import {
  RenderCarouselImages,
  RenderPetName,
  AddaPetButton
} from "./ImageCarouselComponents";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../../common/loader/Loader";
import { getPetsByAccount } from "../../../store/actions/pets-actions";
import { getPolicyDocs } from "../../../api/policy-docs-api";
import { AppLinks } from "../../../constants/app-links";
import "./ImageCarousel.css";
import { NoMedComp } from "./noMedComp/noMedComp";

class ImageCarousel extends Component {
  today = null;
  constructor(props) {
    super(props);
    this.state = {
      prevPetDetails: [],
      petsDetails: [],
      activePetID: null,
      haveMedicalRecords: null,
      isAnyPetUnderWaitingPeriod: null,
      isFirstVisit: null
    };
    this.activePet = {};
    this.carousalBgImageDog = "../../assets/images/carousal-bg-large.jpg";
    this.carousalBgImageCat = "../../assets/images/img_bg_cat.jpg";
    this.noMedicalRecordImg = "../../assets/images/noMedicalRecords.jpg";
    this.noMedicalRecordgif = "../../assets/gif/loader.gif"
    this.imageDoubleClick = false;
    this.fetchingError = "";
  }
  _isMounted = false;
  componentDidMount() {
    this.today = new Date().getTime();
    this.checkAnyPetUnderWaitingPeriod();
    this.setState({
      isFirstVisit : false
    });
    if(localStorage.getItem('firstTimeVisit') === null) {
      this.setState({
        isFirstVisit : true
      });
      localStorage.setItem('firstTimeVisit', 'yes');
    }
    else if(localStorage.getItem('firstTimeVisit') === 'yes') {
      localStorage.setItem('firstTimeVisit', 'no');
      if(localStorage.getItem('secondVisit') === null){
        localStorage.setItem('secondVisit','yes');
      }
      this.setState({
        isFirstVisit : true
      });
    }
    let data;
    this._isMounted = true;
    this.props.actions.getPetsByAccount();
    getPolicyDocs()
    .then(res => {
        data = res;
        let urls = data.policy_documents.filter(detail => {
            return detail.salesforce_type === "medical record" && detail.has_medical_records_on_file === false
        })
        this._isMounted = true;
        if (this._isMounted && urls.length > 0) {
          this.setState({
            haveMedicalRecords: false
          });
        }else{
          this.setState({
            haveMedicalRecords: true
          });
        }
    })
    .catch(error => {
        this.fetchingError = error.errorMessage ? error.errorMessage : "Unable to show the documents. Please try later.";          
    })
  }
  componentWillUnmount() {
    this._isMounted = false;
    if(localStorage.getItem('firstTimeVisit') === 'no') {
      localStorage.setItem('firstTimeVisit', 'yes');
      //localStorage.setItem('secondVisit', 'yes');
      this.setState({
        isFirstVisit : true
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    //As this function gets called before every render ,
    //We have to check this method call is for prop change or internal state change
    //to check that, we are storing the list in one more state variable
    const prevPetDetails = state.prevPetDetails;
    let petsDetails = [],
      activePetID;
    if (prevPetDetails !== props.petState) {
      petsDetails = props.petState;
      if (petsDetails.length > 0) {
        if (state.activePetID !== null) {
          const activePetIndex = petsDetails.findIndex(
            pet => pet.PetID === state.activePetID
          );
          //Need to rotate the array to place selected pet as in index 0
          //If the image is updated or new pet added , Image carousal active pet should not change
          //We are rotating the new array , so that active pet will rotate to first.
          //First pet in the array will be kept as active.
          for (let i = 0; i < activePetIndex; i++) {
            petsDetails.push(petsDetails.shift());
          }
        }
        activePetID = petsDetails[0].PetID;
      }
    } else {
      petsDetails = state.petsDetails;
      if (petsDetails.length > 0) activePetID = petsDetails[0].PetID;
    }
    return {
      prevPetDetails: props.petState,
      petsDetails,
      activePetID
    };
    }
    checkAnyPetUnderWaitingPeriod() {
        this.isAnyPetUnderWaitingPeriod = false;
    }
  //deprecated on 01-04-2023
  checkAnyPetUnderWaitingPeriodOld(){
    for(var i = 0; i < this.state.petsDetails.length; i++)
    {
      var currentPetWaitingPeriodEndDate = Date.parse(this.state.petsDetails[i].WaitingPeriodDate);
      if(currentPetWaitingPeriodEndDate >= this.today)      
      {      
        this.isAnyPetUnderWaitingPeriod = true;
        break;
      }
      else
      { 
        this.isAnyPetUnderWaitingPeriod = false;
      }
      }

  }

  goToPetPolicyPage = (petId , evt) => {
    this.props.history.push({
      pathname: AppLinks.policy.viewPetDetails,
      state: {
        petId: petId
      }
    });
    evt.stopPropagation();
  };

  setDoubleClickFlag = () => {
    this.imageDoubleClick = false;
  };

  selectPetHandler = petID => {
    if (this.imageDoubleClick) {
      return;
    }
    //Clears the timeout
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.imageDoubleClick = true;
    const petsDetails = this.rotatePetsToActive(petID);
    this.setState({ petsDetails: petsDetails });
    this.timeout = setTimeout(() => this.setDoubleClickFlag(), 500);
  };

  rotateImages = reverse => {
    let petsDetails = [...this.state.petsDetails];
    if (reverse) petsDetails.unshift(petsDetails.pop());
    else petsDetails.push(petsDetails.shift());
    this.setState({ petsDetails: petsDetails });
  };

  rotatePetsToActive = petID => {
    let petsDetails = [...this.state.petsDetails];
    const activePetIndex = petsDetails.findIndex(pet => pet.PetID === petID);
    for (let i = 0; i < activePetIndex; i++) {
      petsDetails.push(petsDetails.shift());
    }
    return petsDetails;
  };

  render() {
    const activePet = this.state.petsDetails[0];
    const bgImage = activePet && activePet.Type && activePet.Type === "Cat"
        ? this.carousalBgImageCat
        : this.carousalBgImageDog;

    var carousalBgStyle = {
      backgroundImage: "url(" + bgImage + ")"
    };

    const centerAlignImages =
      this.state.petsDetails.length === 2
        ? "center-align-images-two"
        : "center-align-images";

    return (
      <div> { (localStorage.getItem("secondVisit") === 'yes' && this.isAnyPetUnderWaitingPeriod) ?
      ( 
        <NoMedComp isAnyPetUnderWaitingPeriod = {this.isAnyPetUnderWaitingPeriod}/>)  : (
       <div style={carousalBgStyle} className="background-container col-lg">
       {this.state.petsDetails.length !== 0 ? (
         <div className="d-flex image-slider align-items-center ">
           <div className={centerAlignImages}>
             <div>
               <div className="d-flex carousal-images-div justify-content-center">
                 <RenderCarouselImages
                   petsDetails={this.state.petsDetails}
                   selectPetHandler={this.selectPetHandler}
                 />
               </div>
             </div>
             <RenderPetName
               goToPetPolicyPage={this.goToPetPolicyPage}
               rotateImages={this.rotateImages}
               petsLength={this.state.petsDetails.length}
               activePet={activePet}
             />
           </div>
           {this.state.petsDetails.length === 2 ? null : (
             <AddaPetButton petsLength={this.state.petsDetails.length} />
           )}
         </div>
       ) : (
         <Loader position="absolute" />
       )}
     </div> 
      
    )}
      </div>
    );
  }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  petState: ((state.pets.petsDetails.filter(pet => pet.Status =="Active").length !=0) ? state.pets.petsDetails.filter(pet => pet.Status === "Active") : state.pets.petsDetails)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getPetsByAccount }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageCarousel);
