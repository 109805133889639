import React, { PureComponent } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dropzone from "react-dropzone";
import imgSrcToBlob from "./components/blobUtils";
import * as EXIF from "exif-js";

import PhotoStepper from "./components/PhotoStepper";
import PhotoCropper from "./components/PhotoCropper";
import PhotoFilter from "./components/PhotoFilter";
import PhotoPreview from "./components/PhotoPreview";

import Loader from "../../common/loader/Loader";
import {
  petUpdateRequest,
  petUpdateModal,
  petUpdateLoader
} from "../../../store/actions/pets-actions";

import "./AvatarEditor.css";

class AvatarEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.photoStepperRef = React.createRef();
    this.state = {
      scale: 1,
      filter: 0,
      rotate: 0,
      position: { x: 0.5, y: 0.5 },
      originalImage: props.image ,
      modifiedImage: props.image
    };
  }

  toggle = () => {
    const { actions, image } = this.props;
    actions.petUpdateModal();
    this.setState({
      scale: 1,
      filter: 0,
      position: { x: 0.5, y: 0.5 },
      originalImage: image || null,
      modifiedImage: image || null
    });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.props.actions.petUpdateModal();
    const image = acceptedFiles[0];
    let me = this;
    EXIF.getData(image, function() { // Not used arrow function since the getTag function "this" argument refers to EXIF context
      var orientation = EXIF.getTag(this, "Orientation");
      let rotatePic = 0;
      console.log(orientation);
      switch (orientation) {
        case 8:
          rotatePic = 270;
          break;
        case 6:
          rotatePic = 90;
          break;
        case 3:
          rotatePic = 180;
          break;
        default:
          rotatePic = 0;
      }
      me.setState({
        rotate: rotatePic,
        originalImage: image,
        modifiedImage: image
      });
    });
  };

  onImageEdited = image => {
    this.setState({
      modifiedImage: image
    });
  };

  showLoader = () => {
    this.props.actions.petUpdateLoader(true);
  };

  onUpload = image => {
    const { petDetails, actions } = this.props;
    const fileName = petDetails.PetPolicyNo + ".png";
    imgSrcToBlob(image).then(blob => {
      actions.petUpdateRequest({
        fileType: "PetPhoto",
        PetPolicyNo: petDetails.PetPolicyNo,
        PetID: petDetails.PetID,
        file: blob,
        type: "upload",
        fileName: fileName
      });
    });
  };

  onScaleChanged = scale => {
    this.setState({ scale });
  };

  onPositionChanged = position => {
    this.setState({ position });
  };

  onFilterChanged = index => {
    this.setState({ filter: index });
  };

  onChangePhoto = acceptedFiles => {
    const image = acceptedFiles[0];
    this.photoStepperRef.current._goToStep(0);
    let me = this;
    EXIF.getData(image, function() { // Not used arrow function since the getTag function "this" argument refers to EXIF context
      var orientation = EXIF.getTag(this, "Orientation");
      let rotatePic = 0;
      console.log(orientation);
      switch (orientation) {
        case 8:
          rotatePic = 270;
          break;
        case 6:
          rotatePic = 90;
          break;
        case 3:
          rotatePic = 180;
          break;
        default:
          rotatePic = 0;
      }
      me.setState({
        scale: 1,
        rotate: rotatePic,
        filter: 0,
        position: { x: 0.5, y: 0.5 },
        originalImage: image,
        modifiedImage: image
      });
    });
  };

  renderPhotoEditorModal = () => {
    const { petDetails, petUpdateError } = this.props;
    const {
      modifiedImage,
      originalImage,
      scale,
      rotate,
      position,
      filter
    } = this.state;
    return (
      <Modal
        className="photo-editor-modal"
        isOpen={this.props.photoUpdateModelOpen}
      >
        {this.props.petPhotoUpdating ? <Loader position="absolute" /> : null}
        <ModalHeader className="text-primary" toggle={this.toggle} />
        <ModalBody>
          <PhotoStepper ref={this.photoStepperRef}>
            <PhotoStepper.Step>
              <PhotoCropper
                petDetails={petDetails}
                scale={scale}
                rotate={rotate}
                position={position}
                image={originalImage}
                petUpdateError={petUpdateError}
                onEdited={this.onImageEdited}
                scaleHandler={this.onScaleChanged}
                onPositionChanged={this.onPositionChanged}
                onChangePhoto={this.onChangePhoto}
              />
            </PhotoStepper.Step>
            <PhotoStepper.Step>
              <PhotoFilter
                petDetails={petDetails}
                image={modifiedImage}
                petUpdateError={petUpdateError}
                filter={filter}
                onFilterChanged={this.onFilterChanged}
                onChangePhoto={this.onChangePhoto}
              />
            </PhotoStepper.Step>
            <PhotoStepper.Step>
              <PhotoPreview
                petDetails={petDetails}
                image={modifiedImage}
                petUpdateError={petUpdateError}
                upload={true}
                filter={filter}
                onUpload={this.onUpload}
                showLoader={this.showLoader}
                onChangePhoto={this.onChangePhoto}
              />
            </PhotoStepper.Step>
          </PhotoStepper>
        </ModalBody>
      </Modal>
    );
  };

  renderAvatarWithEdit = () => {
    const { image } = this.props;
    return (
      <div className="avatar-container">
        <img alt="avatar" src={image} />
        <div className="edit-container" onClick={this.toggle}>
          <i className="fa fa-pencil" />
        </div>
      </div>
    );
  };

  renderAvatar = () => {
    const { defaultImage } = this.props;
    return (
      <Dropzone
        className="avatar-dropzone"
        activeClassName="active-avatar-dropzone"
        accept="image/png,image/jpg,image/jpeg,image/tiff"
        id="avatar"
        multiple={false}
        onDrop={this.onDrop.bind(this)}
      >
        <div className="avatar-container no-image">
          <img alt="avatar" src={defaultImage} />
        </div>
      </Dropzone>
    );
  };

  render() {
    const { image } = this.props;

    return (
      <div
        className={
          image
            ? "avatar-editor position-relative"
            : "avatar-editor position-static"
        }
      >
        {this.renderPhotoEditorModal()}
        {image ? this.renderAvatarWithEdit() : this.renderAvatar()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  petUpdateError: state.pets.petUpdateError,
  petsDetails: state.pets.petDetails,
  petPhotoUpdating: state.pets.petPhotoUpdating,
  photoUpdateModelOpen: state.pets.photoUpdateModelOpen
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { petUpdateRequest, petUpdateModal, petUpdateLoader },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvatarEditor);
