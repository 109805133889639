import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logOut } from "../../../../../store/actions/login-actions";
import { getAccountInfo } from "../../../../../store/actions/accounts-actions";
import { getMessagesByAccount } from "../../../../../store/actions/messages-action";
import RetryBilling from "./../../../../account/retry-billing/RetryBilling";
import SideDrawer from "./SideDrawer";
import MobileMenu from "./MobileMenu";
import Messages from "./../../../messages/Messages";
import "./MobileHeader.css";

class MobileHeader extends Component {
  constructor(props) {
    super(props);
    this.scrolledYPosition = 0;
    this.retryBillingRef = React.createRef();
  }

  state = {
    sideDrawerState: "closed"
  };

  componentDidMount() {
    this.props.actions.getAccountInfo();
  }

  retryBillingHandler = () => {
    this.retryBillingRef.current.retryBillingHandler();
  };

  moveScrollToOldPosition = () => {
    if (this.state.sideDrawerState === "closed") {
      this.scrolledYPosition = window.scrollY;
    } else {
      //Scroll back to older position after side drawer closes
      //Timeout has given , as sidedrawer has the animation
      setTimeout(() => {
        window.scrollTo({ top: this.scrolledYPosition });
      }, 300);
    }
  };

  drawerToggle() {
    this.moveScrollToOldPosition();

    this.setState(prevState => {
      let newSideDrawerState =
        prevState.sideDrawerState === "left-open" ? "closed" : "left-open";
      return { sideDrawerState: newSideDrawerState };
    });
  }

  toggleMessagesHandler() {
    this.moveScrollToOldPosition();

    if (this.props.messagesFetchError)
      this.props.actions.getMessagesByAccount();

    this.setState(prevState => {
      let newSideDrawerState =
        prevState.sideDrawerState === "right-open" ? "closed" : "right-open";
      return { sideDrawerState: newSideDrawerState };
    });
  }

  menuHeaderClick(event) {
    const identifier = event.currentTarget.id;

    if (identifier === "signout") {
      this.props.actions.logOut();
    }

    // TODO: Implment a Common Login for Click instance of SideMenu Drawer items
    if (this.state.sideDrawerState !== "closed") {
      this.setState({
        sideDrawerState: "closed"
      });
      return;
    }

    // if (this.state.currentSubMenu !== identifier) {
    //   this.setState({ currentSubMenu: identifier, isSubMenuOpen: true });
    // } else {
    //   this.setState({ currentSubMenu: "", isSubMenuOpen: false });
    // }
  }

  sideDrawerClose = openNewPage => {
    //OpenNewPage will be true , if the user clicks on links in message body
    //When routes to new page need to position it top
    //Otherwise need to position current page in same old scrolled position
    if (openNewPage === true) {
      this.scrolledYPosition = 0;
    }
    this.setState({ sideDrawerState: "closed" }, () => {
      //Scroll back to older position after side drawer closes
      //Timeout has given , as sidedrawer has the animation
      setTimeout(() => {
        window.scrollTo({ top: this.scrolledYPosition });
      }, 300);
      //window.scrollTo({ top: props.scrolledYPosition, behavior: 'smooth' }); ;
    });
  };

  // TODO: Cleanup messages flow by providing content to Side Drawer.
  render() {
    return (
      <div id="mobile-header" className="mobile-header d-xl-none d-lg-none">
        <RetryBilling ref={this.retryBillingRef} />
        <div id="mobile-header-sticky">
          <div id="mobile-header-toolbar">
            <div
              id="mobile-toggle-menu-button"
              onClick={() => this.drawerToggle()}
            >
              <span id="mobile-toggle-icon">
              <img
                  id="mobile-toggle-image"
                  alt="toggle-icon"
                  src="../../assets/images/toggle-icon.svg"
                />
              </span>
              <div>
                <span id="toggle-menu-label">MENU</span>
              </div>
            </div>
            <Link to="/">
              <div id="mobile-header-logo" className="mobile-logo">
                <img
                  id="mobile-logo-image"
                  alt="Logo"
                  src="../../assets/images/login/logo_main.png"
                />
              </div>
            </Link>
            <div
              id="mobile-header-messages"
              className="mobile-header-messages"
              onClick={() => {
                this.toggleMessagesHandler();
              }}
            >
              <img
                id="mobile-header-message-image"
                alt="Email to us"
                src="../../assets/images/icon_messages.png"
              />
              {this.props.unreadMessageCount > 0 && (
                <div
                  id="mobile-message-count-container"
                  className={
                    " pull-right " +
                    (this.props.unreadMessageCount > 99
                      ? "mobile-message-count-large"
                      : "mobile-message-count-small")
                  }
                >
                  <span
                    id="mobile-message-count"
                    className="w-100 h-100 d-flex align-items-center justify-content-center"
                  >
                    {this.props.unreadMessageCount}
                  </span>
                </div>
              )}
            </div>
          </div>
          
        </div>
        {this.props.userAccountDetails &&
          this.props.userAccountDetails.PetParentInfo && (
            <SideDrawer
              sideDrawerClose={this.sideDrawerClose.bind(this)}
              state={this.state.sideDrawerState}
              leftContent={
                <MobileMenu
                  enableRAF={this.props.enableRAF}
                  menuHeaderClick={event => {
                    this.menuHeaderClick(event);
                  }}
                  userName={
                    this.props.userAccountDetails
                      ? this.props.userAccountDetails.PetParentInfo.FirstName
                      : null
                  }
                  userAccountDetails= {this.props.userAccountDetails}
                />
              }
              rightContent={
                <div>
                  <Messages
                    {...this.props}
                    sideDrawerClose={this.sideDrawerClose}
                    retryBillingHandler={this.retryBillingHandler.bind(this)}
                  />
                </div>
              }
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userAccountDetails: state.account.userAccountDetails,
  enableRAF:state.account.enableRAF,
  messagesFetchError: state.messages.messagesFetchError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      logOut,
      getAccountInfo,
      getMessagesByAccount
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHeader);
