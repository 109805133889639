import React from "react";
import "./ClaimStatus.css";
import { StatusTypes } from "../status-model";

// This code can be more generic for status indicators; since there is no proper context for
//  various status types and related indicators, there is code repetition

const ClaimStatus = ({ status, id }) => {
  let classObj =
    status === "Action needed"
      ? "primary-text mb-0 alignStatus"
      : "primary-text mb-0";
  switch (status.toLowerCase()) {
    case StatusTypes.inProcess:
    case StatusTypes.new:
    case StatusTypes.open:
      return (
        <div className="text-center status">
          <div className="d-flex justify-content-center align-items-center">
            <span className="">
              <i className="fa fa-check-circle icon primary-text"></i>
            </span>
            <div className="fillin dark" />
            <span className="">
              <i className="fa fa-check-circle icon text-dark"></i>
            </span>
            <div className="fillin dark" />
            <span className="">
              <i className="fa fa-check-circle icon text-dark"></i>
            </span>
          </div>
          <p className={classObj} id={id}>
            {" "}
            {status == "Open" ? "In Process" : "Submitted"}{" "}
          </p>
        </div>
      );

    case StatusTypes.waitingOnSomeone:
    case StatusTypes.waitingForApproval:
    case StatusTypes.pendingClose:
    case StatusTypes.directDepositError:
    case StatusTypes.directDepositInProgress:
    case StatusTypes.adjustorRejected:
    case StatusTypes.adjustorReview:
      return (
        <div className="text-center status">
          <div className="d-flex justify-content-center align-items-center">
            <span className="">
              <i className="fa fa-check-circle icon primary-text"></i>
            </span>
            <div className="fillin success" />
            <span className="">
              <i className="fa fa-check-circle icon primary-text"></i>
            </span>
            <div className="fillin dark" />
            <span className="">
              <i className="fa fa-check-circle icon text-dark"></i>
            </span>
          </div>
          <p className={classObj} id={id}>
            {" "}
            In Process{" "}
          </p>
        </div>
      );

    case StatusTypes.waitingForInfo:
    case StatusTypes.waitingForReview:
      return (
        <div className="text-center status">
          <div className="d-flex justify-content-center align-items-center">
            <span className="">
              <i className="fa fa-check-circle icon primary-text"></i>
            </span>
            <div className="fillin success" />
            <span className="">
              <i className="fa fa-check-circle icon text-warning"></i>
            </span>
            <div className="fillin dark" />
            <span className="">
              <i className="fa fa-check-circle icon text-dark"></i>
            </span>
          </div>
          <p className={classObj} id={id}>
            {" "}
            {status == "Waiting for Info"
              ? "Assistance Required"
              : "In Process"}{" "}
          </p>
        </div>
      );

    case StatusTypes.approvedForPayment:
    case StatusTypes.closedWithPayment:
    case StatusTypes.closedWithoutPaymentCovered:
    case StatusTypes.closedWithoutPaymentDenied:
    case StatusTypes.closedWithoutPaymentDuplicate:
    case StatusTypes.reissuingCheck:
      return (
        <div className="text-center status">
          <div className="d-flex justify-content-center align-items-center">
            <span className="">
              <i className="fa fa-check-circle icon primary-text"></i>
            </span>
            <div className="fillin success" />
            <span className="">
              <i className="fa fa-check-circle icon primary-text"></i>
            </span>
            <div className="fillin success" />
            <span className="">
              <i className="fa fa-check-circle icon primary-text"></i>
            </span>
          </div>
          <p className={classObj} id={id}>
            {" "}
            {status == "Approved for Payment" ? "In Process" : "Closed"}{" "}
          </p>
        </div>
      );

    default:
      return (
        <div className="text-center status">
          <div className="d-flex justify-content-center align-items-center">
            <span className="">
              <i className="fa fa-check-circle icon text-dark"></i>
            </span>
            <div className="fillin dark" />
            <span className="">
              <i className="fa fa-check-circle icon text-dark"></i>
            </span>
            <div className="fillin dark" />
            <span className="">
              <i className="fa fa-check-circle icon text-dark"></i>
            </span>
          </div>
          <p className={classObj} id={id}>
            {" "}
            {status}{" "}
          </p>
        </div>
      );
  }
};

export default ClaimStatus;
