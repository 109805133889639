import React, { PureComponent } from "react";
import { Collapse } from 'reactstrap';
import "./PetPlanCollapse.component.css";

class PetPlanCollapse extends PureComponent {
    constructor(props) {
        super(props);
        this.state = { collapse: false };
    }

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
    }
    render() {
        const { MonthlyPremium, Name, Reimbursement, Deductible, AnnualLimit } = this.props.plan
        return (
            <div onClick={this.toggle} className={ this.state.collapse? "pet-plan-collapse pt-md-4 pb-md-4": "pet-plan-collapse"}>
                <div className="pet-plan-header row mx-0 d-none d-md-flex align-items-center">
                <div className={this.state.collapse? "pet-collapse-button": "pet-collapse-button mt-1"} ><i className={"arrow-icon fa " + (this.state.collapse ? "fa-angle-up" : "fa-angle-down")}></i></div>
                    <h2 className="col-6 pl-0 mb-0 text-dark font-weight-bold text-truncate">{Name}</h2>
                    <div className="col-6 p-0 d-flex">
                        {this.state.collapse ? (<h4 className="mb-0 align-self-center rate-title"><span className="truncate">{Name}</span>'s Rate</h4>) : (<div className="rate-text">{MonthlyPremium}<small>/month</small></div>)}
                    </div>
                </div>
                <div className="pet-plan-header row mx-0 d-md-none">
                    <h2 className="col-6 pl-0 mb-0 text-dark font-weight-bold text-truncate">{Name}</h2>
                    <h2 className="col-6 p-0 mb-0 text-dark text-right font-weight-bold">{MonthlyPremium}<small className="month-text">/month</small></h2>
                </div>
                <div className="d-none d-md-block">
                    <Collapse isOpen={this.state.collapse}>
                        <div className="row mx-0">
                            <div className="col-6 p-0 mt-2">
                                <div>Reimbursement: <span className="font-weight-bold">{Reimbursement}</span></div>
                                <div>Annual Deductible: <span className="font-weight-bold">{Deductible}</span></div>
                                <div>Maximum Payouts: <span className="font-weight-bold">{AnnualLimit === "99999"? "Unlimited": AnnualLimit }</span></div>
                            </div>
                            <div className="col-6 p-0 d-flex align-items-center">
                                <div><span className="font-weight-bold rate-text">{MonthlyPremium}</span><small>/month</small></div>
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }
}

export default PetPlanCollapse;