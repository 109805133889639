import React, { Component } from "react";
import "./NoAddPetCompliance.component.css";

import { Link } from "react-router-dom";
import { AppLinks } from "../../../constants/app-links";

class NoAddPetCompliance extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return(
            <div className="container no-enrollments">
                <div className="row">
                    <div className="d-none d-sm-block col-6 col-sm-5 col-lg-4 px-0">
                        <img
                            className="img-fluid no-enrollments-image"
                            src="../../assets/images/no-enrollments.jpg"
                            alt="worth-the-wait"
                        />
                        </div>
                    <div className="col col-sm-7 col-lg-8">
                        <h1 className="text-primary mb-3 mb-lg-0 sorry">
                            Sorry
                        </h1>
                        <p class="">
                        We are unable to enroll new policies on your current account. To enroll a new pet, you can do so by creating a new account. 
                        Your new account will need to be created using a different email address and it will have a separate billing date. We apologize for the inconvenience.
                        Please <a target="_blank" href={ process.env.REACT_APP_MAINSITE_URL + "/quote"} >click here</a> to start a new quote.
                        </p>
                        <button
                            className="button-primary back-to-home"
                            type="submit"
                            onClick={this.props.backToHome}
                            >
                            Home
                            </button>
                    </div>
                </div>
            </div>
        );
    }
}
  
export default NoAddPetCompliance;