import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setRetryBillingStatus } from "./../../../store/actions/accounts-actions";
import Util from "./../../common/util/util";
import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";

class RetryBillingSuccess extends Component {



  componentWillMount() {
    //After it un-mounts user should not able to see this page by entering the URL
    //This is a success page for retry billing success
    //As this page doen't have any naviagation url from menu
    this.props.actions.setRetryBillingStatus(false);
    window.scrollTo(0, 0);
  }

  render() {
    var petDetailsText = "";

    petDetailsText =
      this.props.petDetails && this.props.petDetails.length > 0
        ? Util.getCommaSeperatedNameString(
            this.props.petDetails.map(pet => {
              return pet.Name;
            })
          )
        : "your pets ";

    return (
      <React.Fragment>
        {this.props.retryBillingStatus && this.props.petDetails.length > 0 ? (
          <div>
            <UpdateSuccessView
              statusHeader="Success! Your billing retry was successful"
              title="Your policy is active. ***retry billing test"
            >
              <div>
                <p style={{wordBreak: "break-all"}}>
                  We look forward to protecting {petDetailsText} for years to
                  come.
                </p>
                <Link to="/">
                  <button className="button-primary update-success-button">
                    Home
                  </button>
                </Link>
              </div>
            </UpdateSuccessView>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/"
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  retryBillingStatus: state.account.retryBillingStatus,
  petDetails: state.pets.petsDetails.filter(pet => pet.Status === "Active")
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ setRetryBillingStatus }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RetryBillingSuccess);
