import * as types from "../../constants/action-types";

const initialState = {
    documents: [],
    documentsFetchError: null,
    documentsFetchSuccess: false,
    needsRefresh: false,
    fetchingDocuments: false
};
  
export default function(state = initialState, action) {
    switch (action.type) {
        case types.DOCUMENTS_FETCH_REQUEST:
            return {
              ...state,
              fetchingDocuments: true,
              documentsFetchError: null
            };      
        case types.DOCUMENTS_FETCH_SUCCESS:
            return {
                ...state,
                documents: action.documents.policy_documents.map(document => {
                    return { ...document };
                }),
                fetchingDocuments: false,
                documentsFetchSuccess: true,
                needsRefresh: false
            };
        case types.DOCUMENTS_FETCH_ERROR:
            return {
                ...state,
                documentsFetchError: action.error,
                fetchingDocuments: false,
                documentsFetchSuccess: false
            };
        case types.SET_DOCUMENTS_DATA_REFRESH:
            return {
                ...state,
                needsRefresh: true
            };
        case types.SET_DOCUMENT_OPENED:
            return {
                ...state,
                documents: state.documents.map(document =>
                    document.metadata_id === action.docId
                    ? {
                        ...document,
                        opened: true
                        }
                    : document
                )
            };
        default:
            return state;
    }
}  