import React, { PureComponent } from "react";

class Step extends PureComponent {
  render() {
    return (
      <React.Fragment>
        {React.Children.map(this.props.children, step => {
          return React.cloneElement(step, { ...this.props });
        })}
      </React.Fragment>
    );
  }
}

class PhotoStepper extends PureComponent {
  static Step = props => <Step {...props} />;

  constructor(props) {
    super(props);
    const { children } = props;
    this.state = {
      index: 0,
      totalSteps: children.length
    };
  }

  _nextStep = () => {
    const { index, totalSteps } = this.state;
    if (index + 1 < totalSteps) {
      this.setState(prevState => ({
        index: prevState.index + 1
      }));
    }
  };

  _prevStep = () => {
    const { index } = this.state;
    if (index > 0) {
      this.setState(prevState => ({
        index: prevState.index - 1
      }));
    }
  };

  _goToStep = index => {
    this.setState(() => ({
      index: index
    }));
  };

  render() {
    const { index, totalSteps } = this.state;
    return (
      <React.Fragment>
        {React.Children.map(this.props.children, (step, _index) => {
          if (_index === index) {
            return React.cloneElement(step, {
              stepper: {
                currentIndex: index,
                nextStep: this._nextStep,
                previousStep: this._prevStep,
                goToStep: this._goToStep,
                totalSteps: totalSteps
              }
            });
          }
          return null;
        })}
      </React.Fragment>
    );
  }
}

export default PhotoStepper;
