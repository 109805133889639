import React from "react";

import "./Popover.css";

const popovermenu = props => {
  let popoverclasses = props.show ? "pop-over open" : "pop-over";
  let customStyle = {},
    calculateLeft = 0;
    
  if(props.parent){
    let boundingRect = props.parent.getBoundingClientRect();
    if (props.position === "left") {
      popoverclasses += " left";
      calculateLeft = boundingRect.left + boundingRect.width - props.width + 10;
    } else {
      popoverclasses += " custom-message";
      calculateLeft =
        boundingRect.left + boundingRect.width / 2 - props.width / 2 - 56;
    }
    const calculatedTop = boundingRect.height + boundingRect.top + 15;    
    customStyle = {
      left: calculateLeft,
      top: calculatedTop
    };
  }

  return (
    <div style={customStyle} className={popoverclasses}>
      <div>{props.menuItems}</div>
    </div>
  );
};

export default popovermenu;
