import * as actionTypes from "../../constants/action-types";

export const getAccountInfo = () => ({
  type: actionTypes.ACCOUNT_FETCH_REQUEST
});


export const setAccountInfo = (userDetails) => ({
  type: actionTypes.SET_ACCOUNT_DETAILS,
  userDetails
})


export const setAccountDataRefresh = () => ({
  type: actionTypes.SET_ACCOUNT_DATA_REFRESH
});


export const setRetryBillingStatus = (status) => ({
  type: actionTypes.SET_RETRY_BILLING_STATUS,
  status
});


export const setUserEditState = (state) => ({
  type:actionTypes.SET_USER_EDIT_STATE,
  state
})

export const getTopAlertMessage = () => ({
  type:actionTypes.GET_TOP_ALERT_MESSAGE  
})

export const setTopAlertMessage = (state) => ({
  type:actionTypes.SET_TOP_ALERT_MESSAGE,
  state
})

export const dismissTopAlertBanner = (state) => ({
  type:actionTypes.DISMISS_TOP_ALERT_NOTIFICATION,
  state
})
