import React from "react";
import {AppLinks} from '../../../constants/app-links';
export default class GettingReimbursed {
  static howLongItGetReimbursed = () => {
    return (
      <div className="faqAnswer">
         <p>
                If a claim is approved, any reimbursement due will be issued on average within 24 business hours.


         </p>
        <p>
                Reimbursement via a mailed check can take up to 15 days to reach you, depending on your location.


        </p>
        <p>
                Reimbursement via direct deposit may take up to 10 days to process depending on your bank or financial institution.
        </p>
        <p className="disabled-link-wrapper">
          To review or change your reimbursement method, just click&nbsp;
          <a style={{fontWeight:"600"}}
            className="disabled-link"
            href={AppLinks.home.home}
            target="_blank"
            rel="noopener noreferrer"
          >
            My Account 
          </a> and 'Change Reimbursement Info' in your Customer Center.
        </p>
      </div>
    );
  };

  static optionReimbrushment = () => {
    return (
      <div className="faqAnswer">
        <p>
          There are two options for you to receive your reimbursements - Check or
          Direct Deposit.
        </p>
        <p className="font-bold">Claim Reimbursement Method: Checks</p>
        <ul className="blackdisc">
          <li>
            Checks are mailed in an envelope with &quot;Return Service Requested&quot; and
            with this service, the USPS will not forward or hold the checks. 
            To ensure that your reimbursement check(s) arrive in a timely manner, 
            please make sure to keep your mailing address up to date. 
            If you need to make any changes to your mailing address, 
            you may do so in your online Customer Center.
          </li>
          <li>A check is valid for 6 months from the date it is issued.</li>
          <li>
            Checks that have not been cashed and are 9 months past the date they
            were issued are sent to our corporate escheatment department.
            Replacement of these reimbursements can take several months to
            process.
          </li>
        </ul>
        <br />
        <p className="font-bold">Claim Reimbursement Method: Direct Deposit</p>
        <ul className="blackdisc">
          <li>Direct Deposits are issued via Automated Clearing House (ACH).</li>
          <li>
              Many banks use a different routing number for ACH transfers than the
              routing number on your checks, and/or require the zeros at the
              beginning of your account number to be included. Please confirm both
              the correct ACH routing number and full account number with your
              bank prior to enrolling.
          </li>
          <li>
            You may enroll for direct deposit via your online Customer Center. For security purposes, 
            we are not able to enter or update your banking information.
          </li>
        </ul>
        
      </div>
    );
  };

  static cantWaitReimbrushment = () => {
    return (
      <div className="faqAnswer">
        <p>
          In situations where you cannot pay your vet at the time of treatment, you may
          contact us to request payment directly to your vet in the event of a covered claim.
          You must make your request for direct payment in advance of the treatment to
          allow our claims team time to coordinate direct payment.
           <strong> Approval for direct payment does not mean a claim will be 
            covered, and you will remain responsible for all amounts due.
           </strong>
        </p>
        <p>
           In the event of approval on a covered claim, if your veterinary hospital is willing
           to accept direct payment, we can work with them to expedite access to your pet's
           medical records and the processing of your claim. This direct payment option is
           available for any licensed vet across the United States; it is not limited to any
           specific network of vets.
        </p>
        <p>
        <strong>
          Direct payment on covered claims is available during business hours. 
        </strong> 
        &nbsp;Please call (855) 898-8991 and select the option to speak with a Claims
        Representative.
        </p>
      </div>
    );
  };
}
