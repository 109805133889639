import React, { Component } from "react";
import { Link } from "react-router-dom";
import {  AppLinks} from "../../../constants/app-links";

import "./dont-cancel-yet-component.css";

export default class DontCancelYet extends Component {
  render() {
    return (
      <div className="container dont-cancel-yet">
        <div className="row">
          <div className="col-12">
            <h1 className="title-container d-flex align-items-center">
            Dont Cancel Just Yet!
            </h1>
          </div>
        </div>
        <div className="row">
          
          <div className="col-12">
            <p className="mb-0">
              <strong>
                Pets get sick. Accidents happen. Did you know that 1 in 3 pets
                experience an unexpected accident or illness each year?
              </strong>
            </p>
            <p>
              Healthy Paws' plan{" "}
              <strong>pays the majority of your pet's medical bills </strong>
              when they are sick or injured.
            </p>
          </div>
        </div>
        <div className="row">
          <div className=" col-12">
            <div className=" content-wrapper">
              <ul className="pl-3 mb-0">
                <li>
                  Our plan has no per incident, annual or lifetime caps on
                  payouts. This means more savings for you!
                </li>
                <li>
                  {" "}
                  <strong>We protect you and your pet </strong> from the cost of
                  expensive hereditary or congenital conditions.
                </li>
                <li>
                  {" "}
                  We will never drop your pet or decrease coverage due to age or
                  claim submission.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 content-links">
            <p>
              Did you know that we can help you reduce your monthly premium and
              keep the same great coverage simply by adjusting your deductible
              or reimbursement level? If you interested in this, please visit{" "}
              <Link to={AppLinks.policy.changePolicyOptions}>change policy option</Link> or{" "}
              <a href={"mailto:"+ process.env.REACT_APP_HELPPETS_MAILID}>send us an email!</a>
            </p>
            <p>
              If you still wish to cancel, please continue by selecting Next
              below.
            </p>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row mt-2 mt-lg-3 mb-4">
          <button
            className="button-primary-plus button-container"
            onClick={() => {
              this.props.stepper.nextStep();
            }}
          >
            Next
          </button>

          <button className="button-secondary button-keep button-container ml-0 ml-md-4 mt-2 mt-md-0"
          onClick={() => {
            this.props.history.push("/");
          }}>
            No, Keep My Policy
          </button>
        </div>
      </div>
    );
  }
}
