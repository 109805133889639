
import React, { Component } from "react";
import { Checkbox } from 'informed';

import "./Checkbox.css";

class CheckboxControl extends Component {
    render() {
        const { field, id, label, className } = this.props;
        return (
            <div className={"checkbox-container " + className}>
                <Checkbox field={field} id={id}/>
                <label htmlFor={id}> <span></span>{label}</label>
            </div>
        );
    }
}

export default CheckboxControl;