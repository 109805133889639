import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAccountInfo } from "./../../store/actions/accounts-actions";

import PetParentInfo from "./components/PetParentInfo";
import BillingInfo from "./components/BillingInfo";
import ClaimReimbursementInfo from "./components/ClaimReimbursementInfo";

import Loader from "./../common/loader/Loader";
import ErrorView from "./../common/error-view/ErrorView";

import "./ViewProfile.css";

class ViewProfile extends Component {
  componentDidMount() {
    this.props.actions.getAccountInfo();
    window.scrollTo(0, 0);
  }

  render() {
    const userDetails = this.props.userAccountDetails;
    let accountDetails = null;
    if (this.props.userAccountDetails.PaymentTokens && this.props.userAccountDetails.PaymentTokens.length > 0) {
      accountDetails = this.props.userAccountDetails.PaymentTokens[0];
    }

    return (
      <div className="container-fluid">
        <div className="view-profile container">
          <h1 className="page-title">Your Profile</h1>
          {
            this.props.fetchingAccount ?
              (
                <div style={{ height: 300 }}>
                  <Loader />
                </div>
              ) : (
                this.props.userAccountDetails ? (
                  <div className="row d-flex flex-row flex-wrap justify-content-around">
                    <div className="d-flex flex-column justify-content-between col-lg-4">
                    <div className="mobile-section-head">
                        <h2 className="d-block d-md-none">Pet Parent Information</h2>
                    </div>
                      <div className="card-wrapper">
                        <div className="d-none d-md-block">
                          <div className="d-flex flex-lg-column justify-content-lg-between align-items-center section-header">
                            <i className="fa fa-address-card profile-font-icons text-primary" />
                            <h2 className="text-primary mb-0 pl-md-2 text-center">
                              Pet Parent Information
                      </h2>
                          </div>
                        </div>
                        <PetParentInfo
                          parentInfo={userDetails.PetParentInfo}
                          address={userDetails.PhysicalAddress}
                          mailingAddress={userDetails.MailingAddress}
                          hidePassword={false}
                          policyEffectiveDate={userDetails.PolicyEffectiveDate}
                          status = {userDetails.Status}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column justify-content-between col-lg-4">
                      <div className="mobile-section-head">
                        <h2 className="d-block d-md-none">Billing Information</h2>
                      </div>
                      <div className="card-wrapper">
                        <div className="d-none d-md-block">
                          <div className="d-flex flex-lg-column justify-content-lg-between align-items-center section-header">
                            <i className="fa fa-credit-card profile-font-icons text-primary" />
                            <h2 className="text-primary mb-0 pl-md-2 text-center">
                              {" "}
                              Billing Information
                            </h2>
                          </div>
                        </div>
                        <BillingInfo
                          creditCard={userDetails.Last4CreditCard}
                          billingInfo={userDetails.BillingAddress}
                          status={userDetails.Status}
                          billingDay={userDetails.BillingDay}
                          premium={userDetails.MonthlyPremium}
                          newMonthlyPremium={userDetails.NewMonthlyPremium}
                          changeEffectiveDate={userDetails.ChangeEffectiveDate}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column justify-content-between col-lg-4">
                      <div className="mobile-section-head">
                        <h2 className="d-block d-md-none align-self-start">
                          Claim Reimbursement Info
                  </h2>
                      </div>
                      <div className="card-wrapper">
                        <div className="d-none d-md-block">
                          <div className="d-flex flex-lg-column justify-content-lg-between align-items-center section-header">
                            <i className="fa fa-money profile-font-icons text-primary" />
                            <h2 className="text-primary mb-0 pl-md-2 text-center">
                              Claim Reimbursement Info
                      </h2>
                          </div>
                        </div>
                        <ClaimReimbursementInfo
                          claimReimbursementMethod={
                            userDetails.ClaimReimbursementMethod
                          }
                          mailingAddress={userDetails.MailingAddress}
                          accountDetails={accountDetails}
                        />
                      </div>
                    </div>
                  </div>
                ) :
                  (
                    this.props.error && <ErrorView> {this.props.error.errorMessage ? this.props.error.errorMessage : "Unable to fetch account details due to an error. Please try later."} </ErrorView>
                  )
              )
          }
        </div>
      </div>
    )
  }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  userAccountDetails: state.account.userAccountDetails,
  fetchingAccount: state.account.fetchingAccount,
  error: state.account.error
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getAccountInfo }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewProfile);
