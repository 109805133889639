import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RetryBilling from "./../../account/retry-billing/RetryBilling";
import {
  getAlertsByAccount,
  setNotificationDismiss,
  setCurrentPageAlerts
} from "./../../../store/actions/alerts-actions";
import {
  CreateAlertHeader,
  CreateAlertNotificationList,
  
} from "./AlertsComponents";
import { AlertConfigurationList } from "./AlertsConfigurationList";
import "./AlertsNotifications.css";

class AlertsNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAlertExpanded: false,
      pageLocation: null
    };

    this.alerts = [];
    this.notifications = [];

    this.dismissedNotifications = [];
    var filteredAlertLocations = {};
    this.retryBillingRef = React.createRef();

    //Filtering alert ids based on page locations
    //So that it will be easy to show alerts only to those pages from user alerts
    //This list will get prepared only once
    for (var i = 0; i < AlertConfigurationList.length; i++) {
      var pageLocations = AlertConfigurationList[i].pageLocation;
      for (var j = 0; j < pageLocations.length; j++) {
        let pageLocation = pageLocations[j];
        if (filteredAlertLocations[pageLocation]) {
          filteredAlertLocations[pageLocation].push(AlertConfigurationList[i]);
        } else {
          filteredAlertLocations[pageLocation] = [];
          filteredAlertLocations[pageLocation].push(AlertConfigurationList[i]);
          //filteredAlertLocations.push(pageLocation);
        }
      }
    }
    this.alertLocations = filteredAlertLocations;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.pageLocation !== state.pageLocation) {
      return {
        isAlertExpanded: false,
        pageLocation: props.pageLocation
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.actions.getAlertsByAccount();
  }

  componentDidUpdate() {
    //After the update completes
    //We are setting alerts for that route page
    //So that we can use these alerts for further logics inside pages
    this.props.actions.setCurrentPageAlerts(
      this.currentPageAlerts.map(alert => alert.message_name)
    );
  }

  retryBillingHandler = () => {
    this.retryBillingRef.current.retryBillingHandler();
  };

  dismissNotificationsHandler = () => {
    const dismissedNotificationIds = this.notifications.map(
      notification => notification.alertKey
    );
    this.props.actions.setNotificationDismiss(dismissedNotificationIds);
  };

  filterAlertsAndNotifications = () => {
    let alertsFromStore;

    //This holds alerts and notifications for the particular page
    this.currentPageAlerts = [];

    //If alert fetching has Error
    //We will not show ClientAlertAndNotification
    if (!this.props.canUserEdit && this.props.alertsFetchError) {
      //Alerts fetching is errored , but time falls on SF script run time
      //We need show disable alert , not error
      alertsFromStore = this.props.clientAlertsNotifications.filter(
        alert => alert.message_name === "POLICIY_CHANGES_APPLIED_KEY"
      );
    } else if (this.props.alertsFetchError) {
      alertsFromStore =
        this.props.alertsNotifications.length > 0
          ? this.props.alertsNotifications
          : [];
    } else {
      alertsFromStore = [
        ...this.props.alertsNotifications,
        ...this.props.clientAlertsNotifications
      ];
    }

    let filteredArray = [];

    //Filtering Out Notifications , which are dismissed by users
    //We should not show any dismissed notification for that instance.
    const dismissedIdsFromStorage = this.props.dismissedNotificationIds;

    if (dismissedIdsFromStorage.length > 0) {
      filteredArray = alertsFromStore.filter(
        alert => dismissedIdsFromStorage.indexOf(alert.message_name) === -1
      );
    } else {
      filteredArray = alertsFromStore;
    }

    const alertsNotifications = filteredArray;

    const currentPage = this.props.pageLocation;

    //This has list of alerts to show in the pages , from configuration
    const currentPageAlertsList = this.alertLocations[currentPage];

    if (currentPageAlertsList === undefined) {
      //if No alerts and Notification for a particular page
      this.alerts = [];
      this.notifications = [];
    } else {
      const userAlerts = alertsNotifications;

      for (var i = 0; i < currentPageAlertsList.length; i++) {
        for (var j = 0; j < userAlerts.length; j++) {
          if (
            userAlerts[j].message_name === currentPageAlertsList[i].alertKey
          ) {
            //Alert response from API and Our local Configuration is needed for UI logic
            //So both array objects are concatenated
            this.currentPageAlerts.push({
              ...userAlerts[j],
              ...currentPageAlertsList[i]
            });
          }
        }
      }

      this.alerts = this.currentPageAlerts.filter(
        alertMessage => alertMessage.message_type === "Alert"
      );
      this.notifications = this.currentPageAlerts.filter(
        alertMessage => alertMessage.message_type === "Notification"
      );
    }
  };

  toggleAlerts = () => {
    this.setState(prevState => ({
      isAlertExpanded: !prevState.isAlertExpanded
    }));
  };

  render() {
    const contentContainerHeight = this.state.isAlertExpanded ? "auto" : 0;

    //Filter this page specific Alerts and Notifications
    this.filterAlertsAndNotifications();

    const displayAlertHeader =
      this.alerts.length === 0 && this.notifications.length === 0
        ? false
        : true;

    let alertBgClass = "alerts-color";
    if (
      this.alerts.length > 0 &&
      this.props.alertsFetchError &&
      this.props.canUserEdit
    ) {
      alertBgClass = "alerts-error-color";
    } else if (this.alerts.length > 0) {
      alertBgClass = "alerts-color";
    } else {
      alertBgClass = "notifications-color";
    }

    return (
      <React.Fragment>   
        <RetryBilling ref={this.retryBillingRef}/>
        {(this.alerts.length > 0 || this.notifications.length > 0) &&
        !this.props.fetchingAlerts ? (
          <React.Fragment>
            <div
              id="alerts-header-container"
              className={"container-fluid " + alertBgClass}
              onClick={this.toggleAlerts}
              style={{
                minHeight: displayAlertHeader ? 41 : 0,
                paddingTop:this.state.isAlertExpanded ? 3 : 0
              }}
            >
              {" "}
              <div className="container d-flex align-items-center">
                <CreateAlertHeader
                  alertsCount={this.alerts.length}
                  notificationsCount={this.notifications.length}
                  alertsFetchError={this.props.alertsFetchError}
                  canUserEdit={this.props.canUserEdit}
                  isAlertExpanded={this.state.isAlertExpanded}
                />
                {this.state.isAlertExpanded ? (
                  <i className="drop-angle-icon fa fa-angle-up" />
                ) : (
                  <i className="drop-angle-icon fa fa-angle-down" />
                )}
              </div>
            </div>
            <div
              id="alerts-content-container"
              style={{ height: contentContainerHeight }}
            >
              <CreateAlertNotificationList
                dismissNotificationsHandler={this.dismissNotificationsHandler}
                retryBillingHandler={this.retryBillingHandler}
                show={this.state.isAlertExpanded}
                alertBgClass={alertBgClass}
                alerts={this.alerts}
                notifications={this.notifications}
              />
            </div>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  alertsNotifications: state.alerts.alertsNotifications,
  clientAlertsNotifications: state.alerts.clientAlertsNotifications,
  pageLocation: state.alerts.pageLocation,
  dismissedNotificationIds: state.alerts.dismissedNotificationIds,
  alertsFetchError: state.alerts.alertsFetchError,
  fetchingAlerts: state.alerts.fetchingAlerts,
  canUserEdit: state.account.canUserEdit
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAlertsByAccount,
      setNotificationDismiss,      
      setCurrentPageAlerts
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AlertsNotifications));
