import * as actionTypes from "../constants/action-types";
import { getMessagesByAccount } from "./../api/messages-api";
import { call, put, select } from "redux-saga/effects";

const getMessagesFromState = state => {
  return {
    messages: state.messages.messages,
    needsRefresh: state.messages.needsRefresh
  };
};

export function* getMessagesByAccountWorker(action) {
  try {
    let messages;
    const messagesFromState = yield select(getMessagesFromState);
    if (
      messagesFromState.messages.length === 0 ||
      messagesFromState.needsRefresh
    ) {
      messages = yield call(getMessagesByAccount);
    } else {
      messages = messagesFromState.messages;
    }
    if (messages) {
      yield put({ type: actionTypes.MESSAGES_FETCH_SUCCESS, messages });
      //We can call this below API to get message count , however we can calculate the count
      //using messages array itself ,so that we can avoid one more API call here.
      //const messagesCount = yield call(getMessagesCountByAccount);
    }
  } catch (error) {
    yield put({ type: actionTypes.MESSAGES_FETCH_ERROR, error });
  }
}
