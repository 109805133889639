import { axiosInstanceOldAPI, axiosInstanceWithAuth } from "./axios";
// import { ClaimInfo } from '../model/Claims';

// petID & documentURLs are mandatory and dcoumentURL array should contain atleast one item.
// claimDetails: ClaimInfo = {clinicID, invoiceNumber, invoiceTotal, dateOfService, examinationFee}

export const submitClaim = (petID, documentURLs, claimDetails = null) => {
  let url = "/Claims/SubmitClaim";

  let data = {
    PetID: petID,
    ImagesURLs: documentURLs
  };
  if (claimDetails) {
    data = { ...claimDetails.asAPIData(), ...data };
  }

  return axiosInstanceWithAuth
    .post(url, data)
    .then(response => {
      return response.data.Item;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};

export const updateClaim = (claimId, imageURLs, docType) => {
  let url = "/Claims/UpdateClaim";

  let data = {
    ClaimID: claimId,
    AdditionalImagesURLs: imageURLs,
    DocumentType: docType
  };

  return axiosInstanceWithAuth
    .post(url, data)
    .then(response => {
      
      return {
        data: response.data.Item
      };
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};

export const getClaimsByAccount = () => {
  let url = "/Claims/GetClaimsByAccount";
  return axiosInstanceWithAuth
    .post(url, {})
    .then(response => {
      return {
        pagination: response.data.Header,
        data: response.data.Items
      };
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};

export const submitMR = (petID, documentURLs, claimDetails = null) => {
  let url = "/Claims/SubmitMR";

  let data = {
    PetID: petID,
    ImagesURLs: documentURLs
  };
  if (claimDetails) {
    data = { ...claimDetails.asAPIData(), ...data };
  }

  return axiosInstanceWithAuth
    .post(url, data)
    .then(response => {
      return response.data.Item;
    })
    .catch(function(error) {
      return Promise.reject(error);
    });
};
