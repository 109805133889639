import { takeEvery, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./../constants/action-types";
import { logOut } from "./login-sagas";
import { getAccountInfoRequest, getTopAlertMessageRequest } from "./accounts-sagas";
import { getPetsByAccountWorker , petPhotoUpdateWorker, getAvailablePolicyOptionsWorker, getAnniversaryPolicyOptionsWorker } from "./pets-sagas";
import { getClaimsByAccountWorker} from "./claims-sagas";
import { getMessagesByAccountWorker} from "./messages-sagas";
import { getAlertsByAccountWorker} from "./alerts-sagas";
import { getPolicyDocsWorker } from "./documents-sagas";

export function* watchLogOutRequest() {
  yield takeLatest(actionTypes.LOGOUT_REQUEST, logOut);
}
// Watches for ACCOUNT_FETCH_REQUEST action type asyncronously
export function* watchAccountsRequest() {
  yield takeLatest(actionTypes.ACCOUNT_FETCH_REQUEST, getAccountInfoRequest);
}

// Watches for PETS_FETCH_REQUEST action type asyncronously
export function* watchPetsRequest() {
  yield takeLatest(actionTypes.PETS_FETCH_REQUEST, getPetsByAccountWorker);
}


// Watches for CLAIMS_FETCH_REQUEST action type asyncronously
export function* watchClaimsRequest() {
  yield takeEvery(actionTypes.CLAIMS_FETCH_REQUEST, getClaimsByAccountWorker);
}


// Watches for PET_PHOTO_UPDATE_REQUEST action type asyncronously
export function* watchPetPhotoUpdateReqeust() {
  yield takeLatest(actionTypes.PET_PHOTO_UPDATE_REQUEST, petPhotoUpdateWorker);
}


export function* watchMessagesByAccountRequest() {
  yield takeLatest(actionTypes.MESSAGES_FETCH_REQUEST, getMessagesByAccountWorker);
}


export function* watchAlertsByAccountRequest() {
  yield takeLatest(actionTypes.ALERTS_FETCH_REQUEST, getAlertsByAccountWorker);
}


export function* watchGetAvailablePolicyOptions() {
  yield takeLatest(actionTypes.GET_AVAILABLE_POLICY_OPTIONS, getAvailablePolicyOptionsWorker);
}


export function* watchGetAnniversaryPolicyOptions() {
  yield takeLatest(actionTypes.GET_ANNIVERSARY_POLICY_OPTIONS, getAnniversaryPolicyOptionsWorker);
}


export function* watchGetTopAlertMessage() {
  yield takeLatest(actionTypes.GET_TOP_ALERT_MESSAGE, getTopAlertMessageRequest);
}

export function* watchDocumentsRequest() {
  yield takeLatest(actionTypes.DOCUMENTS_FETCH_REQUEST, getPolicyDocsWorker);
}
