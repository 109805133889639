import React, { Component } from "react";
import { Link } from "react-router-dom";


import "./ChangePolicyConfirmation.component.css";

class ChangePolicyConfirmation extends Component {
  render() {
    const { newPolicyDetails, newTotalMonthlyPremium } = this.props;

    //coverage period and billing date are not returned commonly in the current response
    //since the dates are common, the values are retrieved from the first element in the response
    const coveragePeriod = newPolicyDetails.policies[0].policy.next_coverage_period_date;
    const billingDate = newPolicyDetails.policies[0].policy.next_billing_date;
    return (
      <div className="change-policy-confirmation">
        <div className="row">
          <div>
            <p>
              Please review your new policy options and billing information.
            </p>
            <div className="summary-content">
              {
                newPolicyDetails.policies.map((policyPlan,index) => (
                  <React.Fragment key={index}>
                    <div className="d-md-none">
                      <p className="mb-0 font-weight-bold-new">{policyPlan.pet.pet_name}'s NEW PLAN</p>
                      <p>{policyPlan.policy.reimbursement * 100}%/${policyPlan.policy.deductible}</p>
                    </div>
                    <div className="d-none d-md-block">
                      <p className="mb-0 font-weight-bold-new">{policyPlan.pet.pet_name}'s NEW PLAN</p>
                      <ul className="summary-list new-summary-list">
                        <li>{policyPlan.policy.reimbursement * 100}% Reimbursement</li>
                        <li>${policyPlan.policy.deductible} Annual Deductible</li>
                      </ul>
                    </div>
                  </React.Fragment>
                ))
              }
              <p className="mb-0 font-weight-bold-new">BILLING SUMMARY</p>
              <ul className="summary-list new-summary-list">
                <li>New total monthly premium: ${newTotalMonthlyPremium}</li>
                <li>Next coverage period: {new Date(coveragePeriod).toLocaleDateString('en-US')} </li>
                <li>Your next billing date: {new Date(billingDate).toLocaleDateString('en-US')}* </li>
              </ul>
              <div className="keep-in-mind-content">
                <p className="mb-0 font-weight-bold">*Keep in mind</p>
                <p className="mb-0">
                Please note that changes will not be reflected in your Healthy Paws customer profile until that time. Your monthly premium will be adjusted on your next bill date.
                </p>
              </div>
            </div>
            <div className="col-md-5 p-0">
              <Link to="/">
                <button className="button-secondary button" type="button">
                  Home
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePolicyConfirmation;
