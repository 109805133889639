import React from "react";
import "./ErrorView.css";

const ErrorView = props => {
  return (
    <div
      style={{ paddingLeft: 0 }}
      className={"d-flex flex-row error-border container " + (props.className ? props.className : "")}
    >
      <div>
        <div
          className="icon-color d-flex align-items-center justify-content-center"
          style={{ margin: "auto" }}
        >
          <i className="fa fa-exclamation-triangle error-icon" />
        </div>
      </div>
      <label className="error-text">
        {props.children}
        {/* {props.text
          ? props.text
          : "We encountered a problem updating your account with the new billing information. Please try re-entering your information. If you continue to have problems, please call us at 1-855-898-8991."} */}
      </label>
    </div>
  );
};

export default ErrorView;
