import React, { Component } from "react";
import injectExtole from "../extole-hoc/extole-hoc";
import { connect } from "react-redux";
import "./ReferFriend.css";

class ReferFriend extends Component {
  render() {
    return this.props.enableRAF ? (
      <div
        className="refer-friend"
        id="extole-refer-image"
        name="clickEvent"
        track="RAFShareWidget"
      >
        <div className="header text-primary">Refer a Friend</div>
        <div className="body text-center">
          <img src="/assets/images/banner_RAF.svg" alt="Refer A Friend" />
        </div>
        <div className="footer" />
      </div>
    ) : null;
  }
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
  enableRAF: state.account.enableRAF
});

// export default connect(
//   mapStateToProps,
//   null
// )(ReferFriend);


export default connect(
  mapStateToProps,
  null
)(injectExtole(ReferFriend, { ctaID: "extole-refer-image" }));
