import React from "react";
import "./DropDown.css";
import { asField } from "informed";

class DropDownSelect extends React.Component {
  render() {
    const {
      fieldApi,
      fieldState,
      className,
      initialValue,
      forwardedRef,
      onChange, 
      onBlur
    } = this.props;
    const { setValue, setTouched } = fieldApi;
    const { value } = fieldState;

    return (
      <select
        style={this.props.style}
        className={className + " dropdown-container"}
        ref={forwardedRef}
        value={value || initialValue || this.props.data[0].value || ''}
        onChange={e => {
          setValue(e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={e => {
          setTouched();
          if (onBlur) {
            onBlur(e);
          }
        }}
      >
        {this.props.data.map(item => (
          <option value={item.value} key={item.value}>
            {item.displayName}
          </option>
        ))}
      </select>
    );
  }
}

export default asField(DropDownSelect);
