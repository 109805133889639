import React, { Component } from "react";
import { Form } from "informed";
import { Link } from "react-router-dom";

import DropDownSelect from "./../../common/form-fields/drop-down/DropDownSelect";
import EditText from "./../../common/form-fields/edit-text/EditText";
import MaskValidateUtil from "../../common/util/MaskValidateUtil";
import Loader from "../../common/loader/Loader";
import ErrorView from "../../common/error-view/ErrorView";
import {  AppLinks} from "../../../constants/app-links";

import { cancelPolicy } from "../../../api/accounts-api";

import DontCancelYet from "../dontCancelYetComponent/dont-cancel-yet-component";

import "./cancel-component.css";

const NO_LONGER_PET = "I no longer own my pet";
const PET_PASSED_AWAY = "My pet passed away";

const PLEASE_SELECT_ONE = "Please select one";
export default class CancelComponent extends Component {
  constructor() {
    super();
    this.errorText = null;
    this.state = {
      options: [
        {
          displayName:
            "Please select one",
          value:
            "Please select one"
        },
        {
          displayName:
            "I am canceling due to pre-existing conditions restricting my coverage",
          value:
            "I am canceling due to pre-existing conditions restricting my coverage"
        },
        {
          displayName:
            "I am dissatisfied with the service or with the handling of a claim",
          value:
            "I am dissatisfied with the service or with the handling of a claim"
        },
        {
          displayName: "The monthly premium does not fit into my budget     ",
          value: "The monthly premium does not fit into my budget     "
        },
        {
          displayName: "I do not see the value of pet insurance",
          value: "I do not see the value of pet insurance"
        },
        {
          displayName: "I no longer own my pet",
          value: "I no longer own my pet"
        },
        {
          displayName: "My pet passed away",
          value: "My pet passed away"
        }
      ],
      showDontCancel: false,
      sendingToServer: false,
      serverError: false
    };

     //For google analytics
     window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname + '/Step1'
    });
  }


  cancelPolicy = values => {

    if(values.reason === PLEASE_SELECT_ONE) {

      this.errorText =  "Please select a reason";
        this.setState({
          sendingToServer: false,
          serverError: true
        });
        window.scrollTo(0, 0);
        return;
    }

    this.setState({
      sendingToServer: true
    });

    let petNameList = this.props.petDetails.map(pet => {
      return pet.Name;
    });

    let value = {
      cancel_reason: values.reason,
      is_deceased: this.isDeceased(values.reason),
      pet_names: petNameList
    };

    if (this.isLost(values.reason)) {
      value.pet_left_date = values.date;
    }

    cancelPolicy(value, true)
      .then(response => {
        this.props.setData(value, response.cancel_effective_date);

        if (this.isDeceased(values.reason)) {
          this.props.stepper.nextStep();
        } else {
          this.setState({
            showDontCancel: true,
            updateBillingSuccess: true,
            sendingToServer: false
          });
        }
      })
      .catch(error => {
        this.errorText = error.errorMessage
          ? error.errorMessage
          : "";
        this.setState({
          sendingToServer: false,
          serverError: true
        });
        window.scrollTo(0, 0);
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.showDontCancel ? (
          <DontCancelYet {...this.props} />
        ) : (
          <div className="container cancel-component">
            <div>
              <div className="row">
                <div className="col-12">
                  <h1 className="title-container d-flex align-items-center">
                    Cancel Your Insurance Policy
                  </h1>
                </div>
              </div>
              {this.state.serverError && (
                <ErrorView>{this.errorText? this.errorText: 
                  <span>Unable to fetch policy expiry date. Please <Link to={AppLinks.others.contact}>click here</Link> to contact us.</span>
                }</ErrorView>
              )}
              <div className="row">
                <div className="col-12">
                  <p className="mb-0">
                    <strong>
                      We're sorry you want to cancel {this.props.petDetailsText.trim()}
                      's health plan.
                    </strong>
                  </p>
                  <p>
                    Before you cancel, please help us understand more about your
                    decision. Using the form below will cancel all of your pets'
                    policies. If you wish to remove one pet from coverage,
                    please send us a request through{" "}
                    <Link to={AppLinks.others.contact}>contact us</Link> page.
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p>
                    <strong>Reason for cancellation</strong>
                  </p>
                  <Form onSubmit={values => this.cancelPolicy(values)}>
                    {({ formState }) => (
                      <React.Fragment>
                        <DropDownSelect
                          className="form-field dropdown-container mb-1"
                          maxHeight={300}
                          field="reason"
                          id="option_select"
                          initialValue={"Please select one"}
                          data={this.state.options}
                          search={false}
                        />

                        {formState.values.reason === NO_LONGER_PET && (
                          <div className="mt-3 mb-1">
                            <span>
                              We are sorry to hear that. To cancel your pet
                              insurance plan, please enter the date your pet
                              left your family and we'll process your
                              cancellation.
                            </span>

                            <EditText
                              required
                              className="mt-3 date-container"
                              id="date"
                              type="text"
                              title="Date Pet Left (MM/DD/YYYY)"
                              floatingTitle={true}
                              field="date"
                              mask={MaskValidateUtil.dateMask}
                              validate={MaskValidateUtil.validateDateWithNow}
                              validateOnBlur
                              validateOnChange={formState.errors.date}
                            />
                          </div>
                        )}
                        {formState.values.reason === PET_PASSED_AWAY && (
                          <div className="mt-3 mb-1">
                            <div className="d-flex flex-row">
                              <img
                                className="d-none d-md-block"
                                src="/assets/images/pet_passed_away.png"
                                alt="Pet passed away"
                              />

                              <div className="d-flex flex-column">
                                <div className="d-flex flex-row">
                                  <img
                                    className="d-md-none"
                                    src="/assets/images/pet_passed_away.png"
                                    alt="Pet passed away"
                                  />

                                  <span>
                                    We are very sorry to hear about your loss.
                                    Our deepest sympathies to you and your
                                    family.
                                  </span>
                                </div>
                                <span className="mt-2">
                                  To cancel your pet insurance plan, please
                                  enter the date of your pet's passing and we'll
                                  process your cancellation.
                                </span>
                              </div>
                            </div>
                            <EditText
                              required
                              className="mt-3 date-container"
                              id="date"
                              type="text"
                              title="Date Pet Left (MM/DD/YYYY)"
                              floatingTitle={true}
                              field="date"
                              mask={MaskValidateUtil.dateMask}
                              validate={MaskValidateUtil.validateDateWithNow}
                              validateOnBlur
                              validateOnChange={formState.errors.date}
                            />
                          </div>
                        )}

                        <div className="d-flex flex-column flex-md-row mt-3 mb-4">
                          <button className="button-primary-plus button-container">
                            Next
                          </button>

                          <button
                            className="button-secondary button-keep button-container ml-0 ml-md-4 mt-2 mt-md-0"
                            onClick={() => {
                              this.props.history.push("/");
                            }}
                          >
                            No, Keep My Policy
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </Form>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.sendingToServer && <Loader position="absolute" fullPage={true} />}
      </React.Fragment>
    );
  }

  isDeceased = reason => {
    return reason === PET_PASSED_AWAY;
  };
  isLost = reason => {
    return reason === NO_LONGER_PET || reason === PET_PASSED_AWAY;
  };
}
