import React from "react";
import "./UpdateProfileView.component.css";
import { Form } from "informed";

import FaqWidget from "../../common/faq/FaqWidget";
import { updateParentFAQLink } from "../../common/faq/FaqLink";
import MaskValidateUtil from "../../common/util/MaskValidateUtil";

import EditText from "../../common/form-fields/edit-text/EditText";
import Loader from "../../common/loader/Loader";

import ErrorView from "../../common/error-view/ErrorView";
 
export class UpdateProfileView extends React.Component {
  constructor(props) {
      super(props); 
      //this.handleClick = this.handleClick.bind(this);

      this.state = {
        showEmailNote: false,
          firstName: props.userAccountDetails.PetParentInfo.FirstName,
          lastName: props.userAccountDetails.PetParentInfo.LastName,
          showContactUsFirstName: false,
          showContactUsLastName: false
    };
  }
    

    handleClick = (e, data) => {

        console.log("handleclick id: " + e.target.id);
        let nameTmp = "";
         
        if (e.target.id === "parent-1-first-name") {
            console.log("parent1 first name clicked");
            nameTmp = this.props.userAccountDetails.PetParentInfo.FirstName;
            this.setState({

                firstName: "",
                showContactUsFirstName: true
            });

            setTimeout(() => {
                this.setState({
                    firstName: nameTmp,
                    showContactUsFirstName: false
                });
            }, 4000); 

        } else {
            console.log("parent2 first name clicked");

            nameTmp = this.props.userAccountDetails.PetParentInfo.LastName;

            this.setState({
                lastName: "",
                showContactUsLastName: true
            });

            setTimeout(() => {
                this.setState({
                    lastName: nameTmp,
                    showContactUsLastName: false
                });
            }, 4000);

        }


    };
     

  showHideEmailNote = show => {
    this.setState({
      showEmailNote: show
    });
  };

  fetchInitialValues = userData => {
    if (userData && userData.BillingAddress) {
      var PetParentInfo = userData.PetParentInfo;
      var PetParent2Info = userData.PetParent2Info;

      return {
        p1FirstName: PetParentInfo.FirstName,
        p1LastName: PetParentInfo.LastName,
        p1PrimaryPhoneNumber: MaskValidateUtil.phoneNumberMask(
          PetParentInfo.PrimaryPhone
        ),
        p1SecondaryPhone: MaskValidateUtil.phoneNumberMask(
          PetParentInfo.SecondaryPhone
        ),
        p1Email: PetParentInfo.Email,
        p1ConfirmEmail: PetParentInfo.Email,
        p2FirstName: PetParent2Info.FirstName,
        p2LastName: PetParent2Info.LastName,
        p2PrimaryPhoneNumber: MaskValidateUtil.phoneNumberMask(
          PetParent2Info.PrimaryPhone
        ),
        p2Email: PetParent2Info.Email
      };
    }

    return {};
  };

  emailNote = () => {
    return (
      <div>
        <i className="fa fa-question-circle note-icon" />
        <strong>Important:</strong> Your new email address will be used to login
        to your Customer Center, mobile app, reset the password for your account
        and receive emails about your policy. If you like to continue please
        click 'Save Change' button.
      </div>
    );
    };

    contactWidget = () => {
        return (
            <span>Please <a href="/contact">contact us</a> to change</span>
        );
    };

  render() {
    return (
      <div style={{position:this.props.canUserEdit ? "static" : "relative"}} className="container">
        {this.props.canUserEdit ? null :  <div className="disable-user-edit" />}
        <div className="d-flex flex-column update-profile-container">
          <h1 className="title-container d-flex align-items-center">
            Update Pet Parent Profile
          </h1>
          {this.props.serverError && (
            <ErrorView>{this.props.errorText}</ErrorView>
          )}

          <div className="row ">
            <div className="d-flex flex-column col-12 col-lg-8">
              <div className="d-none d-md-flex flex-row ">
                <span className="sub_title_1">Pet Parent Information </span>
              </div>
              {!this.props.userAccountDetailsFetching &&
                this.props.userAccountDetails ? (
                  <Form
                    onSubmit={values => this.props.updateProfile(values)}
                    initialValues={this.fetchInitialValues(
                      this.props.userAccountDetails
                    )}
                  >
                    {({ formState }) => (
                                        <React.Fragment>
                                            <div id="parent-1-first-name-container" className="input-container uneditable-input" > 

                                                <div className="uneditable-input-wrapper">
                                                    <label class="floating-label undefined floating-label-with-value floating-label-with-value-font uneditable-label">First Name</label>
                                                    <div id="parent-1-first-name" onClick={this.handleClick} className="uneditable-field cursor-pointer"   field="p1FirstName" type="text" readOnly={true}> {this.state.firstName} {this.state.showContactUsFirstName ? <this.contactWidget /> : null} </div>
                                                    </div>
                                            </div>

                                            <div id="parent-1-last-name-container" className="input-container uneditable-input">

                                                <div className="uneditable-input-wrapper">
                                                    <label class="floating-label undefined floating-label-with-value floating-label-with-value-font uneditable-label">Last Name</label>
                                                    <div id="parent-1-last-name" onClick={this.handleClick} className="uneditable-field cursor-pointer" field="p1LastName" type="text" readOnly={true}> {this.state.lastName} {this.state.showContactUsLastName ? <this.contactWidget /> : null} </div>
                                                </div>
                                            </div>
                         

                        <EditText
                          required
                          className="phone-number-container margin-bottom"
                          id="parent-1-primary-phone-number"
                          field="p1PrimaryPhoneNumber"
                          type="text"
                          title="Primary Phone #"
                          floatingTitle={true}
                          mask={MaskValidateUtil.phoneNumberMask}
                          validate={MaskValidateUtil.validatePhoneNumber}
                          validateOnBlur
                          validateOnChange={
                            formState.errors.p1PrimaryPhoneNumber
                          }
                        />

                        <EditText
                          className="phone-number-container margin-bottom"
                          id="parent-1-secondary-phone-number"
                          field="p1SecondaryPhone"
                          type="text"
                          title="Secondary Phone #"
                          floatingTitle={true}
                          mask={MaskValidateUtil.phoneNumberMask}
                        />

                        <EditText
                          required
                          email
                          className="input-container margin-bottom"
                          id="parent-1-email-address"
                          field="p1Email"
                          type="text"
                          title="Email Address"
                          validate={MaskValidateUtil.validateEmail}
                          validateOnBlur
                          validateOnChange={formState.errors.p1Email}
                          floatingTitle={true}
                        />

                        <EditText
                          required
                          email
                          className="input-container margin-bottom"
                          id="parent-1-email-address"
                          field="p1ConfirmEmail"
                          type="text"
                          title="Confirm Email Address"
                          validate={MaskValidateUtil.validateEmail}
                          validateOnBlur
                          validateOnChange={formState.errors.p1ConfirmEmail}
                          onFocus={() => {
                            this.showHideEmailNote(true);
                          }}
                          onBlur={() => {
                            this.showHideEmailNote(false);
                          }}
                          floatingTitle={true}
                          note={
                            this.state.showEmailNote ? this.emailNote() : null
                          }
                        />

                        <div className="d-flex flex-row align-items-end margin-bottom second-parent-title">
                          <span className="d-block d-md-none sub_title_1">
                            Second Pet Parent{" "}
                          </span>
                          <span className="d-none d-md-block sub_title_1">
                            Second Pet Parent Information{" "}
                          </span>
                          <span className="sub_title_2">(optional)</span>
                        </div>
                        <EditText
                          className="input-container margin-bottom"
                          id="parent-2-first-name"
                          field="p2FirstName"
                          type="text"
                          title="First Name"
                          floatingTitle={true}
                        />
                        <EditText
                          className="input-container margin-bottom"
                          id="parent-2-last-name"
                          field="p2LastName"
                          type="text"
                          title="Last Name"
                          floatingTitle={true}
                        />
                        <EditText
                          email
                          className="input-container margin-bottom"
                          id="parent-2-email-address"
                          field="p2Email"
                          type="text"
                          title="Email Address"
                          validate={MaskValidateUtil.validateEmail}
                          validateOnBlur
                          validateOnChange={formState.errors.p2Email}
                          floatingTitle={true}
                        />

                        <EditText
                          className="phone-number-container margin-bottom"
                          id="parent-2-primary-phone-number"
                          field="p2PrimaryPhoneNumber"
                          type="text"
                          title="Phone #"
                          floatingTitle={true}
                          mask={MaskValidateUtil.phoneNumberMask}
                        />
                        <div className="d-flex flex-column flex-md-row pb-3">
                          <button
                            className="input-container button-primary col-sm-12 margin-bottom"
                            type="submit"
                            disabled={
                              this.props.sendingToServer || formState.invalid
                            }
                          >
                            Save Changes
                          </button>
                          <button
                            className="button-link_cancel col-sm-12 col-md-4 margin-bottom"
                            type="submit"
                            onClick={this.props.backToHome}
                          >
                            Cancel
                          </button>
                        </div>
                      </React.Fragment>
                    )}
                  </Form>
                ) : (
                  this.props.userAccountFetchError &&
                  <ErrorView> {this.props.userAccountFetchError.errorMessage || "Unable to fetch information right now due to an unknown error. Please try later."} </ErrorView>
                )
              }
            </div>


          </div>
          {(this.props.sendingToServer ||
            this.props.userAccountDetailsFetching) && (
            <Loader position="absolute" fullPage={true} />
          )}
        </div>
      </div>
    );
  }
}
