import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import { Form } from "informed";
import CheckboxControl from "../../common/form-fields/checkbox/Checkbox";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPetsByAccount } from "../../../store/actions/pets-actions";
import { submitMR } from "../../../api/claims-api";
import { getPolicyDocs } from "../../../api/policy-docs-api";
import FaqWidget from "../../common/faq/FaqWidget";
import {submitMrFaqLinks} from "../../common/faq/FaqLink";
import PetSelector from "../components/PetSelector";
import UpdateDateJoinedFamilyModal from "../components/UpdateDateJoinedFamilyModal";
import { AppLinks } from "../../../constants/app-links";
import UploadMR from "./../../common/file-upload/FileUpload";
import Loader from "./../../common/loader/Loader";
import ErrorView from "./../../common/error-view/ErrorView";
import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";
import ConfirmNavigationModal from "./../../common/route-prevent-dialog/ConfirmNavigationModal";
import ClaimForm from "../components/claim-form/ClaimForm";
import "./UploadMedicalRecords.css";

import MedicalRecordsMaintenance from "./MedicalRecordsMaintenance.component"

class UploadMedicalRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPet: "",
      dateJoinFamilyModal: false,
      showUploadMedicalRecords: false,
      medicalRecordsList: [],
      mrSubmitSuccess: false,
      mrSubmissionError: false,
      submitting: false,
      policyDocs: [],
      policyDocsFetchError: false
    };
    this.policyDocsFetchErrorMessage = "";
    this.petSelectionHandler = this.petSelectionHandler.bind(this);
    this.reset = this.reset.bind(this);
    this.dateJoinedFamilyToggler = this.dateJoinedFamilyToggler.bind(this);  
     //For google analytics
     window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname
      });   
  }
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    

    if(this.props.location && this.props.location.state && this.props.location.state.PetID)
      this.setState({
        selectedPet: this.props.location.state.PetID,
        showUploadMedicalRecords: true,
    });

    getPolicyDocs()
      .then(res => {
        if (this._isMounted) {
          this.setState({
            policyDocs: res.policy_documents
          });
        }
      })
      .catch(error => {
        this.policyDocsFetchErrorMessage = error.errorMessage
          ? error.errorMessage
          : "Unable show claim form(s).Please try later.";
        if (this._isMounted) {
          this.setState({
            policyDocsFetchError: true
          });
        }
      });

    this.props.actions.getPetsByAccount();
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.petDetails !== state.petDetails) {
      //If the user has only one pet , it has to be selected by default
      const selectedPet =
        props.petDetails.length === 1 ? props.petDetails[0].PetID : "";
      const showUploadMedicalRecords = selectedPet === "" ? false : true;
      return {
        petDetails: props.petDetails,
        selectedPet,
        showUploadMedicalRecords
      };
    }
    return null;
  }

  petSelectionHandler(id, doj) {
    if (this.state.selectedPet !== id) {
      this.setState({
        dateJoinFamilyModal: doj ? false : true,
        selectedPet: id,
        showUploadMedicalRecords: true
      });
    }
  }

  dateJoinedFamilyToggler() {
    this.setState({ dateJoinFamilyModal: !this.state.dateJoinFamilyModal });
  }

  setMedicalRecordsList = (files, status) => {
    this.setState({
      medicalRecordsList: files,
      showUploadMedicalRecords: status
    });
  };

  editMedicalRecordsHandler = () => {
    this.setState(prevState => ({
      showUploadMedicalRecords: !prevState.showUploadMedicalRecords
    }));
  };

  reset() {
    this.setState({
      selectedPet: "",
      showUploadMedicalRecords: false,
      dateJoinFamilyModal: false,
      medicalRecordsList: [],
      mrSubmitSuccess: false,
      mrSubmissionError: false,
      submitting: false
    });
  }

  // Submit Handler
  submitMedicalRecord() {
    // TODO: Pass proper Data and handle error.
    // const selectedPetDetails = this.props.petDetails ? this.props.petDetails.find(x => x.PetID === this.state.selectedPet) : null;
    if (
      this.state.selectedPet &&
      this.state.medicalRecordsList &&
      this.state.medicalRecordsList.length > 0
    ) {
      let fileURLs = [...this.state.medicalRecordsList];
      let claimDetails = null;

      this.setState({
        submitting: true
      });

      submitMR(this.state.selectedPet, fileURLs, claimDetails)
        .then(response => {
          // TODO: Handle Submit Success
          //
          this.setState({
            mrSubmissionError: false,
            mrSubmitSuccess: true,
            submitting: false
          });

            //For google analytics
            window.dataLayer.push({
              'event': 'Pageview',
              'url': window.location.pathname + '/Confirm'
              });

          window.scrollTo(0, 0);
        })
        .catch(error => {
          // TODO: Handle Error.
          this.setState({
            mrSubmissionError: true,
            submitting: false
          });
          window.scrollTo(0, 0);
        });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {

    if (false) {
      return (
        <MedicalRecordsMaintenance
            backToHome={() => {
              this.props.history.push("/");
            }}
          />
      )
    }
     
    const selectedPetDetails = this.props.petDetails
      ? this.props.petDetails.find(x => x.PetID === this.state.selectedPet)
      : null;
    let petName = "";
    let petPolicyNo = "";
    if (selectedPetDetails) {
      petName = selectedPetDetails.Name;
      petPolicyNo = selectedPetDetails.PetPolicyNo;
    }

    const petsWithMRForms =
      this.props.petDetails && this.state.policyDocs
        ? this.state.policyDocs.filter(doc => {
            return this.props.petDetails.some(pet => {
              if (
                pet.PetID === doc.pet_id && doc.salesforce_type === "medical record"
              ) {
                doc.petName = pet.Name;
                return doc;
              }
            });
          })
        : [];

    if (!this.state.mrSubmitSuccess) {
      return (
              <React.Fragment>
          <div className="upload-medical-records mt-lg-0">
            <div className="container p-0">
              <h1 className="col-12 header  m-lg-0">
                Upload medical records or other documentation
              </h1>
            </div>
            <div className="d-lg-flex container p-0">
              <NavigationPrompt
                when={(crntLocation, nextLocation) => {
                  this.currentLocation = crntLocation;
                  this.nextLocation = nextLocation;
                  if (this.state.medicalRecordsList.length > 0) {
                    return true;
                  }
                }}
              >
                {({ onCancel, onConfirm }) => (
                  <ConfirmNavigationModal
                    when={true}
                    message="You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?"
                    title="Unsaved Data"
                    currentLocation={this.currentLocation}
                    nextLocation={this.nextLocation}
                    forceUpdate={this.state.mrSubmitSuccess}
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                  />
                )}
              </NavigationPrompt>

              { 
                this.props.error ? 
                (
                  <div className="upload-mr-content col-lg-8">
                    <ErrorView> {this.props.error.message ? this.props.error.message : "Some error occured. Please try later."} </ErrorView>
                  </div>
                )
                :
                (
                  <div className="upload-mr-content col-lg-8 position-static">
                <p>
                  Here you can upload your medical records (include doctor's
                  notes and laboratory results) or other documentation regarding
                  any of your pet's visits to a veterinarian. If you have an
                  invoice for a claim, please visit{" "}
                  <Link className="links" to={AppLinks.claims.fileClaim}>file a claim</Link>. If
                  you have missing claim documents that you need to submit,
                  please visit{" "}
                  <Link className="links" to={AppLinks.claims.claimStatus}>
                    view claim status
                  </Link>
                  .
                </p>
                {/* TODO: Show proper Error message */}
                {this.state.mrSubmissionError && (
                  <ErrorView>
                    Unable to upload documents. Please try later.
                  </ErrorView>
                )}

                <div className="mr-collapse-box">
                  {/* <PetSelection /> */}
                  <div
                    className={
                      this.state.selectedPet
                        ? "mr-box-item selected"
                        : "mr-box-item"
                    }
                  >
                    <div className="box-title d-block d-sm-none">
                      1. Select your pet
                    </div>

                    <div className="box-title d-none d-sm-block">
                      {this.state.selectedPet ? <SectionStatus /> : " 1. "}{" "}
                      Select your pet
                    </div>

                    {this.props.petDetails && (
                      <PetSelector
                        pets={this.props.petDetails}
                        selectionHandler={this.petSelectionHandler}
                        selectedPet={this.state.selectedPet}
                      />
                    )}

                    {this.state.dateJoinFamilyModal &&
                      this.state.selectedPet && (
                        <UpdateDateJoinedFamilyModal
                          isOpen={this.state.dateJoinFamilyModal}
                          toggleHandler={this.dateJoinedFamilyToggler}
                          petDetails={selectedPetDetails}
                        />
                      )}
                  </div>
                  {/* <MedicalRecordUpload /> */}
                  <div
                    className={
                      this.state.selectedPet
                        ? "mr-box-item"
                        : "mr-box-item disable"
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="box-title d-block d-sm-none">
                        2. Upload Medical Records{" "}
                        <div className="text-2 d-none d-md-inline-block">
                          or Other Documentation
                        </div>
                      </div>
                      <div className="box-title d-none d-sm-block">
                        {this.state.medicalRecordsList.length > 0 &&
                        !this.state.showUploadMedicalRecords ? (
                          <SectionStatus />
                        ) : (
                          " 2."
                        )}{" "}
                        Upload{" "}
                        {petName !== "" ? (
                          <span className="truncate" style={{ textTransform: "capitalize" }}>
                            <strong>
                              {petName}
                              's
                            </strong>
                          </span>
                        ) : null}{" "}
                        Medical Records{" "}
                        <div
                          className="text-2 d-none d-md-inline-block"
                          style={{ fontWeight: "650" }}
                        >
                          or Other Documentation
                        </div>
                      </div>
                      <div>
                        {" "}
                        {this.state.medicalRecordsList.length > 0 &&
                        !this.state.showUploadMedicalRecords ? (
                          <span
                            onClick={this.editMedicalRecordsHandler}
                            className="upload-file-edit d-none d-sm-block"
                          >
                            Edit
                          </span>
                        ) : null}
                        {this.state.showUploadMedicalRecords ? (
                          <i className="text-secondary fa fa-angle-up fa-2x d-block d-sm-none" />
                        ) : (
                          this.state.selectedPet && (
                            <i
                              className="text-secondary fa fa-angle-down fa-2x d-block d-sm-none"
                              onClick={this.editMedicalRecordsHandler}
                            />
                          )
                        )}{" "}
                      </div>
                    </div>              

                    <UploadMR
                      type="MR"
                      classification="UploadMR"
                      show={this.state.showUploadMedicalRecords}
                      petPolicyNo={petPolicyNo}
                      setUploadFiles={this.setMedicalRecordsList}
                    />                  
                  </div>
                  {/* <UploadDocuments /> */}
                  <div
                    className={
                      this.state.medicalRecordsList.length > 0
                        ? "mr-box-item"
                        : "mr-box-item disable"
                    }
                  >
                    <div className="box-title">
                      3. Upload Documents
                      {this.state.selectedPet ? (
                        <span style={{ fontWeight: "650" }}>
                          {" "}
                          for{" "}
                          <span
                            className="truncate"
                            style={{
                              textTransform: "capitalize",
                              fontWeight: "650"
                            }}
                          >
                            {petName}
                          </span>{" "}
                        </span>
                      ) : null}
                    </div>
                    {this.state.medicalRecordsList.length > 0 && (
                      <div className="tc-box">
                        <textarea
                          readOnly
                          value="By checking this box and clicking  &quot;Submit Claim!&quot;, I certify that the information provided is accurate to the best of my knowledge. I authorize any veterinary hospital or veterinarian to provide additional information about my pet to Healthy Paws Pet Insurance.&#13;&#10;
                                I understand that missing information or delays in delivering the pet's medical records may delay the processing of my claim. Claims must be submitted for processing within 90 days of invoice date.
                                "
                        />

                        <Form onSubmit={() => this.submitMedicalRecord()} >
                        {({ formState }) => (
                          <div>
                            <CheckboxControl 
                            field= "checkbox"
                            id= "submit-checkbox"
                            label= "I agree to these terms"
                            />

                            <div className="d-block d-md-flex justify-content space-between mt-2 mb-2">
                              <button
                            className={
                              formState.values.checkbox
                                ? "btn btn-secondary"
                                : "btn btn-secondary disabled-btn"
                            }
                            type= "submit"
                          >
                            {" "}
                            Upload Documents{" "}
                          </button>
                          <button
                            className="cancel-btn text-secondary d-none d-md-block"
                            onClick={this.reset}
                            type= "button"
                          >
                            {" "}
                            Cancel{" "}
                          </button>
                        </div>
                          </div>
                        )}
                        </Form>
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-none d-md-block d-lg-none">
                  <strong>Documents are not available?</strong>
                  <p>
                    Your veterinarian may also email these to{" "}
                    <a href={"mailto:"+ process.env.REACT_APP_RECORDS_2_MAILID}>records@healthypaws.com</a> or fax them to
                    844-333-0739.
                  </p>
                </div>


                
              </div>
                )
              }
              <div className="side-box d-none d-lg-block col-lg-4">
              {this.props.petDetails && (
                <ClaimForm
                  title="Do you prefer to use other options for the submission?"
                  petClaimForms={petsWithMRForms}
                  policyDocsError={this.state.policyDocsFetchError}
                  policyDocsFetchErrorMessage={this.policyDocsFetchErrorMessage}
                  subtitle="Send your medical records form and medical records or other documentation:"
                  Emailaddress= "records@healthypaws.com"
                />
              )}

              </div>
            </div>
          </div>
          {(this.state.submitting || this.props.fetchingPets ) && <Loader position="absolute" fullPage={true} />}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="d-none d-md-block">
            <UpdateSuccessView
              statusHeader="Your documents have been uploaded."
              title={
                <div className="d-inline" >
                Thank you for uploading {" "}
                <div className="d-inline" style={{textTransform: "capitalize"}} >
                  <span className="truncate">{petName}</span>
                </div>
                's documents
                </div>
              }
            >
            <div className="d-flex">
            <button
              className="button-primary-plus update-success-button"
              onClick={
                this.reset
              }
            >
              Upload Another Record
            </button>
              <button
                className="button-secondary update-success-button" style={{height: 40}}
                onClick={() => this.props.history.push("/")}
              >
                Home
              </button>
            </div>
            </UpdateSuccessView>
          </div>
          <UpdateSuccessView
            className="d-md-none"
            title={
              <div className="d-inline py-3" >
                Thank you for uploading {" "}
                <div className="d-inline" style={{textTransform: "capitalize"}} >
                  <span className="truncate">{petName}</span>
                </div>
                's documents
              </div>
            }
          >
            <button
              className="button-primary update-success-button"
              onClick={
                this.reset
              }
            >
              Upload Another Record
            </button>
            <button
              className="button-secondary update-success-button"
              onClick={() => this.props.history.push("/")}
            >
              Home
            </button>
          </UpdateSuccessView>
        </React.Fragment>
      );
    }
  }
}

const SectionStatus = () => {
  return (
    <span className="section-check-status">
      <i
        className="fa fa-check-circle"
        style={{ fontSize: 22, color: "#34A250" }}
      />
    </span>
  );
};

const mapStateToProps = state => ({
  petDetails: state.pets.petsDetails,
  fetchingPets: state.pets.fetchingPets,
  error: state.pets.error
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getPetsByAccount }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadMedicalRecords);
