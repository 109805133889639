import React, { Component } from "react";
import EditText from "./EditText";
import "./EditPassword.css";

export default class EditPassword extends Component {
  constructor(props) {
    super(props);
    this.state=({
      type: "password"
    });
  }

  passwordShowHide = e => {
    this.setState({
      type: this.state.type === "text" ? "password" : "text"
    });
  };

  render() {
    
    const {id, type, ...rest} = this.props;
    return (
      <div id={this.props.id} className={"password-conatiner " + this.props.className}>
        <EditText
          type={this.state.type}
          {...rest}
        />
        
          <i
            onClick={e => {
              this.passwordShowHide(e);
            }}
            className={
              "fa show-password-icon" +
              (this.state.type === "text"
                ? " fa-eye "
                : " fa-eye-slash ")
            }
            style={{ color: "#919191", fontSize: "25px" }}
          />
    
      </div>
    );
  }
}
