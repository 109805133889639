import React, { Component } from "react";
import DropDownSearchV2 from "./../../common/form-fields/drop-down/DropDownSearchV2";

class BreedSearchDropdownV2 extends Component {
  getGroupedOptions = () => {
    const mixedBreeds = this.props.source
      .filter(x => x.mixed_breed === true )
      .map(pet => ({ label: pet.name, value: pet.id }));
    const remainingBreeds = this.props.source
      .filter(x => x.mixed_breed === false )
      .map(pet => ({ label: pet.name, value: pet.id }));

    let groupedOptions = [];
    if (mixedBreeds.length > 0) {
      groupedOptions.push({
        label: "Mixed Breeds",
        options: mixedBreeds
      });
      groupedOptions.push({

        label: "others",
        options: remainingBreeds

      })
    } else {
      groupedOptions = remainingBreeds;
    }

    return groupedOptions;
  };

  render() {
    const groupedOptions = this.getGroupedOptions();
    let selectedOption;
    if(this.props.selectedBreedId) {
       let selectedBreed = this.props.source.find(breed => breed.id === this.props.selectedBreedId);
       selectedOption = { id: selectedBreed.id, label: selectedBreed.name };
    }
    const colourStyles = {
      control: styles => ({
        ...styles,
        backgroundColor: "transparent",

        height: 55,

        borderWidth: 0,
        borderColor: "#333333",
        borderRadius: 0,

        boxShadow: 0,
        border:"none",
        fontWeight: 400,
        fontSize: 20,
        textDecoration: "none",
        color: "#333333"
      }),
      groupHeading:(styles) =>{
        return{
          ...styles,
          fontSize:14
        }
      },
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
            backgroundColor: isFocused ? "#767676" : "#FFFFFF",          
          color: isFocused ? "white" : "#000000",
          minHeight: 18
        };
      },
      input: styles => ({ ...styles }),
      placeholder: styles => ({
        ...styles,
        fontWeight: 400,
        fontSize: 20,
        color: "#999999"
      }),
      singleValue: (styles, { data }) => ({ ...styles })
    };

    return (
      <div className={this.props.disabled ? "dropdown-disabled" : ""} style={{ maxWidth: "100%", marginBottom: 20 }}>

        <DropDownSearchV2
          placeholder="What breed is your pet?"
                
                className={""}
          field="breed"
          selectedOption={selectedOption}
          id="select-flavour"
          data={groupedOptions}
          styles={colourStyles}
          label="What breed is your breed?"
          petType={this.props.petType}
        />
      </div>
    );
  }
}

export default BreedSearchDropdownV2;
