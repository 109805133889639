import React, { Component } from "react";
import { Form } from "informed";
import { UncontrolledCollapse } from "reactstrap";
import { Link } from "react-router-dom";

import SegmentButton from "../../common/form-fields/segment-button/SegmentButton";
import ModalDialog from "../../common/modal-dialog/ModalDialog";
import { AppLinks } from "../../../constants/app-links";
import Util from '../../common/util/util';

import "./PetPolicyRate.component.css";

class PetPolicyRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedPlan: this.getCurrentPlan(),
      deductibleOptions: this.deductibleOptionsData(),
      reimbursementOptions: this.reimbursementOptionsData()
    };
    this.policyOptionSelectorFormApi = null;
    this.noMonthlyRate = false;
  }

  deductibleOptionsData = () => {
    const { policies } = this.props.policyOptions;
    const deductibleOptions = [];
    const defaultPlan = policies.find(policy => policy.default_plan);


    policies.map(policy => {
      if (!deductibleOptions.find(ele => ele.value === policy.deductible)) {
        let obj = { label: "$" + policy.deductible, value: policy.deductible, disabled: false }
        if (defaultPlan && defaultPlan.deductible === policy.deductible) {
          obj.info = 'Most Popular';
        }
        deductibleOptions.push(obj);
      }
    });
    deductibleOptions.sort((op1, op2) => {
      if (op1.value > op2.value) return 1;
      return -1;
    });
    return deductibleOptions;
  }

  reimbursementOptionsData = () => {
    const { policies } = this.props.policyOptions;
    const reimbursementOptions = [];
    const defaultPlan = policies.find(policy => policy.default_plan)

    policies.map(policy => {
      if (!reimbursementOptions.find(ele => ele.value === policy.reimbursement)) {
        let obj = { label: policy.reimbursement * 100 + "%", value: policy.reimbursement }
        if (defaultPlan && defaultPlan.reimbursement === policy.reimbursement) {
          obj.info = 'Most Popular';
        }
        reimbursementOptions.push(obj);
      }
    });
    reimbursementOptions.sort((op1, op2) => {
      if (op1.value > op2.value) return -1;
      return 1;
    });
    return reimbursementOptions;
  }



  toggleDialog = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  setPolicyOptionSelectorFormApi = (api) => {
    this.policyOptionSelectorFormApi = api;
  }

  reset = () => {
    this.policyOptionSelectorFormApi.reset();
    this.setState({ selectedPlan: this.getCurrentPlan() });
  }

  getCurrentPlan = () => {
    return this.props.policyOptions.policies.find(policy => policy.is_current_plan);
  }

  planChangeHandler = (formState) => {
    const { reimbursement, deductible } = formState.values;
    const { updateSelectedPlan, policyOptions } = this.props;
    const selectedPlan = policyOptions.policies.find(plan => (plan.reimbursement === reimbursement && plan.deductible === deductible));
    this.disablingOptions(reimbursement, deductible);
    if (selectedPlan) {
      this.setState({ selectedPlan });
      updateSelectedPlan(selectedPlan, policyOptions.pet.pet_id);
      this.noMonthlyRate = false;
    }
    else {
      this.noMonthlyRate = true;
    }
    
  }

  disablingOptions = (reimbursement, deductible) => {
    const deductibleOptions = [...this.state.deductibleOptions];

    deductibleOptions.map(deductible => {
      if (this.props.policyOptions.policies.find(policy => policy.reimbursement === reimbursement && policy.deductible === deductible.value)) {
        deductible.disabled = false;
      }
      else deductible.disabled = true;
    });
    this.setState({ deductibleOptions: deductibleOptions });
  }

  policyOptionSelector = (petName, selectedPlan) => {
    const currentPlan = this.getCurrentPlan();
    const optionsMaxLengthCheck = this.state.deductibleOptions.length > 3 || this.state.reimbursementOptions.length > 3;
    const optionsMinLengthCheck = this.state.deductibleOptions.length < 2 && this.state.reimbursementOptions.length < 2;
    return (
      <Form onChange={(formState) => this.planChangeHandler(formState)} getApi={(api) => this.setPolicyOptionSelectorFormApi(api)} >
        {
          ({ formApi, formState }) => {
            return (
              <div className="row m-0 rate-content">
                <div className={`rate-sub-content gray ${optionsMaxLengthCheck? "col-md-9": optionsMinLengthCheck? "col-md-5 mr-md-5": "col-md-7"}`}>
                  {this.state.reimbursementOptions.length > 1 || this.state.deductibleOptions.length > 1 ?
                    (
                      <div className="d-flex mx-0">
                        <div className={`p-0 d-none d-md-flex align-items-center mr-4 ${optionsMaxLengthCheck? "col-md-2": optionsMinLengthCheck ? "col-md-2" : "col-md-3"}`}>
                          <p className="text-center py-2 px-2 text-primary arrow_box mb-0">Available coverage options based on your pet's details</p>
                        </div>
                        <div className={`pl-0 ${optionsMaxLengthCheck? "col-md-10": optionsMinLengthCheck ? "col-md-10" : "col-md-9"}`}>
                        <div className="text-left mb-3">
                          <p className="mb-1 options-label">Select Reimbursement Options</p>
                          <SegmentButton
                            className="mb-1"
                            id="reimbursement"
                            initialValue={currentPlan.reimbursement}
                            value={currentPlan.reimbursement}
                            field="reimbursement"
                            data={this.state.reimbursementOptions}
                          />
                        </div>
                        <div className="text-left">
                          <p className="mb-1 options-label">Select Annual Deductible Options</p>
                          <SegmentButton
                            className="mb-1"
                            id="deductible"
                            initialValue={currentPlan.deductible}
                            value={currentPlan.deductible}
                            field="deductible"
                            data={this.state.deductibleOptions}
                            onDisabledClicked={this.toggleDialog}
                          />
                        </div>
                        <div className="mt-3 d-none d-md-block text-secondary link-text" onClick={() => formApi.reset()} >
                          Reset Options
                        </div>
                        </div>
                      </div>
                    ) :
                    (
                      <div className="text-left">
                        <h4 className="text-primary d-none d-md-block">Available coverage options based on your pet's details</h4>
                        <p className="mb-0">Reimbursement: <span className="font-weight-bold"> {selectedPlan.reimbursement * 100}% </span></p>
                        <p className="mb-0">Annual Deductible: <span className="font-weight-bold"> ${selectedPlan.deductible} </span></p>
                        {/* TODO: Annual limits returned as null in the API response currently - handle later */}
                        <p className="mb-1 mb-md-4">Maximum Payouts: <span className="font-weight-bold"> {selectedPlan.annual_limit === "99999" ? "Unlimited" : selectedPlan.annual_limit} </span></p>
                        
                        {/* Removed for now, as per requirement; Ref #163164172 https://www.pivotaltracker.com/story/show/163164172/comments/198400602 in Pivotal Tracker */}
                        {/* <div id="limited-options-toggler" className="limited-options-toggler">
                          <i className="fa fa-question-circle text-secondary mr-1"></i>
                          <strong className="text-secondary" style={{cursor: "pointer"}} >Why are my options limited?</strong>
                          <UncontrolledCollapse toggler="#limited-options-toggler" >
                            <p className="mb-0">Pets who are 8 years and older are eligible for 60% Reimbursement and $750 Annual Deductible options.</p>
                          </UncontrolledCollapse>
                        </div> */}
                      </div>
                    )
                  }
                  <div className="d-md-none text-center notice">
                    New Monthly Rate<br/>
                    Beginning {Util.getFormattedDate(this.props.effectiveDate)}
                  </div>
                  <h1 className="mt-3 d-md-none rate-text-large text-center text-dark">
                    ${this.noMonthlyRate && (this.state.reimbursementOptions.length > 1 && this.state.deductibleOptions.length > 1)? "xx.xx" : Number(selectedPlan.monthly_total_payment).toFixed(2)}
                    <small>/month</small>
                  </h1>
                </div>
                <div className={`d-none d-md-block rate-sub-content ${optionsMaxLengthCheck? "col-md-3": optionsMinLengthCheck? "col-md-5 ml-md-3": "col-md-4"}`}>
                  <h4><span className="truncate">{petName}</span>'s Rate</h4>
                  <div className="notice">
                    New Monthly Rate<br/>
                    Beginning {Util.getFormattedDate(this.props.effectiveDate)}
                  </div>
                  <h2 className="my-4 rate-text-large font-weight-bold text-dark">
                    ${this.noMonthlyRate && (this.state.reimbursementOptions.length > 1 && this.state.deductibleOptions.length > 1)? "xx.xx" : Number(selectedPlan.monthly_total_payment).toFixed(2)}
                    <small>/month</small>
                  </h2>
                </div>
              </div>
            )
          }
        }
      </Form>
    )
  }

  render = () => {
    const { Name, Type, Age, Breed } = this.props.pet;
    
    return (
      <React.Fragment>
        <ModalDialog
          isOpen={this.state.showModal}
          toggle={this.toggleDialog}
          modalHeader={"Unable to display this option."}
          modalBody={
            <div className="d-flex flex-column">
              <p>
              There was a problem displaying this option. Please try again later or <Link style={{textDecoration: "none"}} to={AppLinks.others.contact}>contact us</Link>.
              </p>
            </div>
          }
          modalFooter={
            <button
              className="button-primary-plus close-button"
              type="submit"
              onClick={this.toggleDialog}
            >
              Close
            </button>
          }
        />
        <div className="pet-policy-rate">
          <div className="m-3 m-lg-0 my-lg-2">
            <h2 className="d-md-inline mr-1 font-weight-bold">
              <span className="text-dark truncate" style={{verticalAlign: "sub"}}>{Name}</span>
            </h2>
            <span className="d-block d-md-inline ml-md-1 breedAgeText">
              {Age === 0? Type === "Dog"? "Puppy" : "Kitten" : "Age " + Age}, {Breed}
            </span>
          </div>
          {this.policyOptionSelector(Name, this.state.selectedPlan)}
        </div>
      </React.Fragment>
    );
  };
}

export default PetPolicyRate;
